import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import moment from 'moment';
import DetailRow from './todDatabaseDetailRow';
import TodDatabaseInfoModal from './todDatabaseInfoModal';
import DatabaseBackupModal
  from '../../shared/presentational/databaseBackup/databaseBackupModal';
import { showModal } from '../../../actions/modal.actions';
import { notifyError } from '../../../utilities/notifier';
import { SITE_CREATETODDATABASE } from '../../../utilities/permissions';
import Loader from '../../shared/presentational/loader';
import { Grid, Button } from '@arius';
import NoResults from '../../shared/presentational/noResults';
import ActionItems from '@app/tod/todDatabaseActions';

class TodDatabasesList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        params: PropTypes.object.isRequired,
        userKey: PropTypes.string,
        isFetching: PropTypes.bool,
        columnTypes: PropTypes.array,
        application: PropTypes.object,
        getDatabases: PropTypes.func,
        getCurrentDbVersion: PropTypes.func,
        selectDatabaseHandler: PropTypes.func,
        currentDatabase: PropTypes.object,
        getDatabase: PropTypes.func,
        getDatabaseColumns: PropTypes.func,
        getColumnTypes: PropTypes.func,
        deleteDatabaseHandler: PropTypes.func,
        databases: PropTypes.array,
        clearNotificationHandler: PropTypes.func,
        newSaveHandler: PropTypes.func,
        updateSaveHandler: PropTypes.func,
        serverNotification: PropTypes.object,
        uploads: PropTypes.array,
        getUploads: PropTypes.func,
        getColumnsForCsvFile: PropTypes.func,
        fileColumns: PropTypes.array,
        getDatabaseInfo: PropTypes.func,
        deleteValuationDateHandler: PropTypes.func,
        deleteDataLoadHandler: PropTypes.func,
        getBackups: PropTypes.func,
        createBackupHandler: PropTypes.func,
        deleteBackupHandler: PropTypes.func,
        deleteArchivedBackupHandler: PropTypes.func,
        restoreBackupHandler: PropTypes.func,
        restoreArchivedBackupHandler: PropTypes.func,
        archiveBackupHandler: PropTypes.func,
        backups: PropTypes.array,
        archivedBackups: PropTypes.array,
        isSaving: PropTypes.bool,
        errorMessage: PropTypes.string,
        validateFormulas: PropTypes.func,
        clearErrorMessageHandler: PropTypes.func,
        verifyPermission: PropTypes.func,
        dataLoadDeletesInProgress: PropTypes.array,
        valuationDateDeletesInProgress: PropTypes.array,
        getPointInTimeRestoreRange: PropTypes.func,
        restorePointInTimeHandler: PropTypes.func,

        retrieveLogFile: PropTypes.func,
        deleteLogFileFromServer: PropTypes.func,
        logFile: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            databaseInfoModalOpen: false,
            databaseBackupModalOpen: false,
            columnSelected: '',
            isDescending: false,
            currentDatabases: [],
            alreadyFetched: false,
            modalDatabaseId: null,
            permissions: [],
        };
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.closeDatabaseInfoModal = this.closeDatabaseInfoModal.bind(this);
        this.openDatabaseInfoModal = this.openDatabaseInfoModal.bind(this);
        this.closeDatabaseBackupModal = this.closeDatabaseBackupModal.bind(this);
        this.openDatabaseBackupModal = this.openDatabaseBackupModal.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getDetailTemplate = this.getDetailTemplate.bind(this);
    }

    componentDidMount() {
        if (this.props.databases && this.props.databases.length) {
            this.setState({ alreadyFetched: true, currentDatabases: this.props.databases });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { databases } = this.props;
        if (databases !== nextProps.databases) {
            this.setState({ alreadyFetched: true, currentDatabases: nextProps.databases});
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.errorMessage !== this.props.errorMessage &&
            this.props.errorMessage
        ) {
            notifyError(this.props.errorMessage);
            this.props.clearErrorMessageHandler();
        }
    }

    deleteClickHandler(id, e) {
        e.stopPropagation();

        const { dispatch, databases } = this.props;

        if (databases.length !== 0) {
            const selectedDatabase = databases.find(ws => ws.workspaceId === id),
            deleteMessageItems = [
            <li key={selectedDatabase.workspaceId} style={{ fontWeight: 800 }}>
                {selectedDatabase.workspaceName}
            </li>,
            ];

            deleteMessageItems.unshift(
                'Are you sure you want to delete the following databases?'
            );

            const yesClickHandler = () => {
                const { userKey, deleteDatabaseHandler } = this.props;
                deleteDatabaseHandler(userKey, id);
            };
            const noClickHandler = () => {};
            const action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
            );
            dispatch(action);
        }
    }

    closeDatabaseInfoModal() {
        this.setState({ databaseInfoModalOpen: false });
    }

    closeDatabaseBackupModal(refreshCurrentDatabase) {
        if (refreshCurrentDatabase) {
            const { modalDatabaseId } = this.state;
            const { userKey, getDatabase } = this.props;
            getDatabase(userKey, modalDatabaseId);
        }

        this.setState({ databaseBackupModalOpen: false, modalDatabaseId: null});
    }

    openDatabaseInfoModal(e, currentDatabase) {    
        const { userKey, getDatabaseInfo, databases } = this.props,
        databaseId = currentDatabase.id || currentDatabase.workspaceId;
        this.props.selectDatabaseHandler(currentDatabase);
        getDatabaseInfo(userKey, databaseId, currentDatabase.factTableGuid);
        const selectedDatabase = databases.find(
            db => db.workspaceId === databaseId
        );
        this.setState({ databaseInfoModalOpen: true, permissions: selectedDatabase.permissions });
    }

    openDatabaseBackupModal(id) {
        this.setState({ databaseBackupModalOpen: true, modalDatabaseId: id });
    }

    getActionItems(database) {
        const {
            deleteClickHandler,
            userPermissions,
            browserHistory
        } = this.props;

        return <ActionItems 
            database={database} 
            userPermissions={userPermissions}
            deleteClickHandler={deleteClickHandler}
            openBackupModalHandler={this.openDatabaseBackupModal}
            viewInfoHandler={this.openDatabaseInfoModal}
            browserHistory={browserHistory}
            deleteHandler={this.deleteClickHandler}
        />;
    }

    getGrid(data) {

        const columns = [
            { field: 'workspaceName', headerText: 'Name', width: '40%', template: (database) => {
                return <span>{database.workspaceName} {database.isOnline ? '' : ' (Offline)'}</span>
            }},
            { field: 'workspaceDescription', headerText: 'Description', width: '60%'},
            { field: 'createdBy', headerText: 'Created By', width: '150'},
            { field: 'createdDate', headerText: 'Created Date', type: 'datetime', width: '150'},
            { field: 'lastValuationDate', headerText: 'Latest Valuation', width: '130', 
                template: (r) => <span>
                    {r.lastValuationDate
                            ? moment.utc(r.lastValuationDate).format('L')
                            : ''}
                </span>},
            { headerText: 'Actions', width: '100', template: this.getActionItems},
        ];

        return <Grid 
                columns={columns}
                data={data}
                height='calc(100vh - 180px)' 
                detailTemplate={this.getDetailTemplate}
            />
    }

    getDetailTemplate(row) {
        const { columnTypes } = this.props;
        return <DetailRow row={row} columnTypes={columnTypes}/>;
    }

    render() {
        const {
            databases,
            isFetching,
            currentDatabase,
            userKey,
            deleteValuationDateHandler,
            deleteDataLoadHandler,
            serverNotification,
            clearNotificationHandler,
            getBackups,
            createBackupHandler,
            deleteBackupHandler,
            deleteArchivedBackupHandler,
            restoreBackupHandler,
            restorePointInTimeHandler,
            undoPITRHandler,
            restoreArchivedBackupHandler,
            archiveBackupHandler,
            getDatabases,
            verifyPermission,
            dataLoadDeletesInProgress,
            valuationDateDeletesInProgress,
            getPointInTimeRestoreRange,
            retrieveLogFile,
            deleteLogFileFromServer,
            logFile,
            jobStatus,
        } = this.props,
        { modalDatabaseId } = this.state;

        const modalDatabase = databases.find(db => db.workspaceId === modalDatabaseId) || databases.find(db => db.id === modalDatabaseId);
        const { currentDatabases, alreadyFetched } = this.state;
        let contentMarkup = <div style={{ width: '65vw', margin: '5vh auto' }}><Loader loadingItem="Databases" /></div>;

        if (!isFetching || alreadyFetched) {
            contentMarkup = (
                <div style={{ width: '65vw', margin: '5vh auto' }}>
                <NoResults />
                </div>
            );
            if (databases.length > 0) {
                contentMarkup = this.getGrid(currentDatabases);
            }
        }
        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
            <h4>Manage Triangles on Demand Databases</h4>
            <Button
                path="/trianglesOnDemand/databases/new"
                isAllowed={verifyPermission(SITE_CREATETODDATABASE)}
                toolTip={"Add new Database"}
                mode="add"
            />
            </div>
            {contentMarkup}
            <TodDatabaseInfoModal
                userKey={userKey}
                database={currentDatabase}
                modalId="databaseInfoModal"
                showModal={this.state.databaseInfoModalOpen}
                closeHandler={this.closeDatabaseInfoModal}
                permissions={this.state.permissions}
                deleteValuationDateHandler={deleteValuationDateHandler}
                deleteDataLoadHandler={deleteDataLoadHandler}
                dataLoadDeletesInProgress={dataLoadDeletesInProgress}
                valuationDateDeletesInProgress={valuationDateDeletesInProgress}
            />
            <DatabaseBackupModal
                userKey={userKey}
                modalId="todBackupModal"
                showModal={this.state.databaseBackupModalOpen}
                database={modalDatabase}
                closeHandler={this.closeDatabaseBackupModal}
                serverNotification={serverNotification}
                clearNotificationHandler={clearNotificationHandler}
                getBackupsHandler={getBackups}
                createBackupHandler={createBackupHandler}
                availableBackups={modalDatabase ? modalDatabase.backups : []}
                archivedBackups={modalDatabase ? modalDatabase.archived : []}
                deleteBackupHandler={deleteBackupHandler}
                restoreBackupHandler={restoreBackupHandler}
                restoreArchivedHandler={restoreArchivedBackupHandler}
                archiveBackupHandler={archiveBackupHandler}
                deleteArchivedHandler={deleteArchivedBackupHandler}
                getDatabases={getDatabases}
                getRestorePointInTimeRangeHandler={getPointInTimeRestoreRange}
                earliestRestorePoint={modalDatabase ? modalDatabase.earliestRestorePoint : ''}
                latestRestorePoint={modalDatabase ? modalDatabase.latestRestorePoint : ''}
                restorePointInTimeHandler={restorePointInTimeHandler}
                undoPITRHandler={undoPITRHandler}
                retrieveLogFile={retrieveLogFile}
                deleteLogFileFromServer={deleteLogFileFromServer}
                logFile={logFile}
                jobStatus={jobStatus}
                jobStatusPrefix = {'TOD'}
            />
        </div>
        );
    }
}

export default radium(TodDatabasesList);
