import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { Route, Switch, Redirect } from 'react-router-dom';
import AutomationTabSidebar from './automationTabSidebar';
import Scripts from './scripts';
import CreateScripts from './createScripts';
import Status from './status';
import { clearValuationDates, clearValuationDate } from '../../../actions/analysis/project.actions';
import { WORKSPACE_MANAGEJOBS, createPermissionChecker } from '../../../utilities/permissions';
import { fetchWorkspaces } from '../../../actions/analysis/workspace.actions';

class Automation extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        scripts: PropTypes.array,
        fetchScriptsHandler: PropTypes.func,
        isFetching: PropTypes.bool,
        scriptCommands: PropTypes.array,
        fetchScriptCommandsHandler: PropTypes.func,
        selectedScript: PropTypes.object,
        createScriptHandler: PropTypes.func,
        updateScriptHandler: PropTypes.func,
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        dispatch: PropTypes.func,
        userId: PropTypes.string,
        params: PropTypes.object,
        editScriptHandler: PropTypes.func,
        longRunningOperationsStatus: PropTypes.array,
        getLongRunningOperationsStatus: PropTypes.func,
        clearLongRunningOperationsStatus: PropTypes.func,
        projects: PropTypes.array,
        isFetchingWorkSpaces: PropTypes.bool,
        isFetchingProjects: PropTypes.bool,
        isFetchingCommandStatuses: PropTypes.bool,
        cancelBatch: PropTypes.func,
        userPermissions: PropTypes.array,
        mappers: PropTypes.array,
        fetchMappersHandler: PropTypes.func,
        fetchCommandStatuses: PropTypes.func,
        commandStatuses: PropTypes.array,
        collectionSet: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedSideNavOption: 'status',
            commandStatusHeight: '30',
            openedCommandStatus: null,
            commandJob: null,
            commandProject: null,
            commandStatuses: [],
        };
        this.state.selectedSideNavOption = this.determineTab();
        this.toggleCommandStatuses = this.toggleCommandStatuses.bind(this);
    }

    componentDidMount() {
        const { dispatch, userKey } = this.props;
        dispatch(fetchWorkspaces(userKey));
    }  

    componentWillReceiveProps(nextProps) {
        const { commandStatuses, currentWorkspace, dispatch } = this.props;
        if (commandStatuses !== nextProps.commandStatuses) {
            if (nextProps.commandStatuses.length) {
                this.setState({ commandStatuses: nextProps.commandStatuses.sort(this.compareCommandOrderNumbers) });
            } else {
                this.setState({ commandStatuses: nextProps.commandStatuses });
            }
        }
        if (currentWorkspace !== nextProps.currentWorkspace) {
            dispatch(clearValuationDates());
            dispatch(clearValuationDate());
        }
    }

    compareCommandOrderNumbers(a, b) {
        if (a.commandOrder < b.commandOrder) return -1;
        if (a.commandOrder > b.commandOrder) return 1;
        return 0;
    }

    // TODO remove
    determineTab() {
        const url = window.location.href;
        let tab = 'status';

        if (url.indexOf('/status') !== -1) {
            tab = 'status';
        } else if (url.indexOf('/scripts/') !== -1) {
            tab = 'createScripts';
        } else if (url.indexOf('/scripts') !== -1) {
            tab = 'manageScripts';
        }   
        return   tab;
    } 

    toggleCommandStatuses(e, id, job, projectName) {
        if (e) e.stopPropagation();
        // clearStatuses
        const { openedCommandStatus } = this.state,
        { fetchCommandStatuses, userKey, currentWorkspace } = this.props;
        if (id === openedCommandStatus) {
        this.setState({ openedCommandStatus: null });
        } else if (id) {
        fetchCommandStatuses({ userKey, databaseId: currentWorkspace.id, jobId: id });
        this.setState({ openedCommandStatus: id, commandJob: job, commandProject: projectName });
        } else {
        this.setState({ openedCommandStatus: null });
        }
    }
    render() {
        const { openedCommandStatus, commandJob, commandProject, commandStatuses } = this.state,
        {
            isFetching,
            isFetchingProjects,
            isFetchingWorkSpaces,
            scripts,
            fetchScriptsHandler,
            scriptCommands,
            selectedScript,
            createScriptHandler,
            updateScriptHandler,
            fetchScriptCommandsHandler,
            currentWorkspace,
            workspaces,
            dispatch,
            userKey,
            userId,
            params,
            editScriptHandler,
            longRunningOperationsStatus,
            clearLongRunningOperationsStatus,
            getLongRunningOperationsStatus,
            projects,
            cancelBatch,
            userPermissions,
            fetchMappersHandler,
            fetchCommandStatuses,
            isFetchingCommandStatuses,
            mappers,
            collectionSet,
            browserHistory,
            location
        } = this.props,
        verifyPermission = createPermissionChecker(userPermissions);

        let tableHeight,maxHeight;

        let childProps = {
            browserHistory:browserHistory,
            cancelBatch:cancelBatch,
            clearLongRunningOperationsStatus:clearLongRunningOperationsStatus,
            collectionSet:collectionSet,
            commandStatuses:commandStatuses,
            createScriptHandler:createScriptHandler,
            currentWorkspace:currentWorkspace,
            dbChangeAllowed:verifyPermission(WORKSPACE_MANAGEJOBS),
            dispatch:dispatch,
            editScriptHandler:editScriptHandler,
            fetchCommandStatuses:fetchCommandStatuses,
            fetchMappersHandler:fetchMappersHandler,
            fetchScriptCommandsHandler:fetchScriptCommandsHandler,
            fetchScriptsHandler:fetchScriptsHandler,
            getLongRunningOperationsStatus:getLongRunningOperationsStatus,
            isFetching:isFetching,
            isFetchingCommandStatuses:isFetchingCommandStatuses,
            isFetchingProjects:isFetchingProjects,
            isFetchingWorkSpaces:isFetchingWorkSpaces,
            job:commandJob,
            longRunningOperationsStatus:longRunningOperationsStatus,
            mappers:mappers,
            maxHeight:maxHeight,
            openedCommandStatus:openedCommandStatus,
            params:params,
            project:commandProject,
            projects:projects,
            scriptCommands:scriptCommands,
            scripts:scripts,
            selectedScript:selectedScript,
            tableHeight:tableHeight,
            toggleCommandStatuses:this.toggleCommandStatuses,
            updateScriptHandler:updateScriptHandler,
            userId:userId,
            userKey:userKey,
            workspaces:workspaces,
        };

        if (currentWorkspace && !params.workspaceId) {
            browserHistory.push(`${location.pathname}/${currentWorkspace.id}`);
        }
        return (
        <div style={{display: 'flex'}}>
            <AutomationTabSidebar />
            <Switch>
                <Route path="/analysis/automation/status/:databaseId" 
                    render={props => (<Status {...childProps}/>)}/>
                <Route path="/analysis/automation/status" 
                    render={props => (<Status {...childProps}/>)}/>
                <Route path="/analysis/automation/scripts/:databaseId/:scriptId" 
                    render={props => (<CreateScripts {...childProps}/>)}/>
                <Route path="/analysis/automation/scripts/:databaseId" 
                    render={props => (<Scripts {...childProps}/>)}/>
                <Route path="/analysis/automation/scripts" 
                    render={props => (<Scripts {...childProps}/>)}/>
                <Route path="/analysis/automation" render={props => <Redirect to='/analysis/automation/status'/>}/>
            </Switch>
        </div>
        );
    }                                 
}

const mapStateToProps = state => ({
  currentWorkspace: state.analysis.workspaces.current,
  segments: state.analysis.segments.items,
  workspaces: state.analysis.workspaces.items,
  projects: state.analysis.projects.items,
  isFetching: state.analysis.projects.isFetching,
  isFetchingProjects: state.analysis.projects.isFetchingProjects,
  isFetchingWorkspaces: state.analysis.workspaces.isFetching,
  isFetchingSegments: state.analysis.segments.isFetchingSegments,
  userKey: state.user.userKey,
  application: state.application,
  batchProjects: state.analysis.projects.batchProjects,
  longRunningOperationsStatus: state.analysis.projects
    .longRunningOperationsStatus,
  currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
  currentUdoVersionNbr: state.analysis.projects.currentUdoVersionNbr,
  copyingProject: state.analysis.projects.copyingProject,
  projectVersions: state.analysis.projects.projectVersions,
  scripts: state.analysis.automation.scripts,
  userPermissions: state.user.userPermissions,
  valuationDate: state.analysis.projects.valuationDate,
  shape: state.analysis.projects.shape,
  checkingUniqueness: state.analysis.projects.checkingUniqueness,
  copiedProjectId: state.analysis.projects.copiedProjectId,
  valuationDates: state.analysis.projects.valuationDates,
  exportList: state.analysis.projects.exportList,
  isFetchingExportList: state.analysis.projects.isFetchingExportList,
  excelList: state.analysis.projects.excelList,
  isFetchingExcelList: state.analysis.projects.isFetchingExcelList,
  printList: state.analysis.projects.printList,
  isFetchingPrintList: state.analysis.projects.isFetchingPrintList,
  collectionSet: state.analysis.projects.collectionSet,
  isFetchingCollectionSet: state.analysis.projects.isFetchingCollectionSet,

  current: state.analysis.projects.current,
  projectReviewState: state.analysis.projects.projectReviewState,
  projectReviewHistory: state.analysis.projects.projectReviewHistory,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Automation);
