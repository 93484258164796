import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import moment from 'moment';
import AnalysisTabSidebar from './analysisTabSideBar';
import AnalysisProjectList from './analysisProjectList';
import GenericList from './genericList';
import Reviewlevels from '@app/analysis/reviewLevels/summary';
import AnalysisToolbar from './analysisToolbar';
import { fetchWorkspace, clearWorkspace, changeReviewLevelStatus } from '../../../actions/analysis/workspace.actions';
import { 
  changeValuationDate, 
  tryFetchProjects, 
  receiveProjects,
  clearWorkspaceRelatedOnWorkspaceChange 
} from '../../../actions/analysis/project.actions';
import { getProjectDataShapes, getDataStructureParam, getProjectListUrl, getDateParam } from '@app/utilities/projectDataHelper';
import { makeClone } from '../../../utilities/helpers';
import { WORKSPACE_MANAGEJOBS, createPermissionChecker } from '../../../utilities/permissions';
import { clearScripts } from '../../../actions/analysis/automation.actions';
import {
  fetchSubscriptionUsers,
} from '../../../actions/portalManagement/subscriptionUser.actions';
const style = {
  mainContainer: { display: 'flex', flexFlow: 'row nowrap', width: '100vw' },
};

class AnalysisTabMain extends Component {
    static propTypes = {
        workspaces: PropTypes.array,
        currentWorkspace: PropTypes.object,
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        isFetching: PropTypes.bool,
        projects: PropTypes.array,
        workspaceId: PropTypes.number,
        params: PropTypes.object,
        projectsHaveDataShape: PropTypes.bool,
        longRunningOperationsStatus: PropTypes.array,
        getLongRunningOperationsStatus: PropTypes.func,
        clearLongRunningOperationsStatus: PropTypes.func,
        initiateBatchProcessHandler: PropTypes.func,
        apiUrl: PropTypes.object,
        getCurrentUdoVersion: PropTypes.func,
        cloneProject: PropTypes.func,
        currentUdoVersionNbr: PropTypes.number,
        runRollForward: PropTypes.func,
        fetchScriptsHandler: PropTypes.func,
        getProjectVersionsHandler: PropTypes.func,
        updateProjectVersionHandler: PropTypes.func,
        getCheckoutStatusHandler: PropTypes.func,
        scripts: PropTypes.array,
        segments: PropTypes.array,
        isFetchingSegments: PropTypes.bool,
        isFetchingWorkspaces: PropTypes.bool,
        checkingUniqueness: PropTypes.bool,
        userPermissions: PropTypes.array,
        projectVersions: PropTypes.array,
        valuationDate: PropTypes.string,
        copyingProject: PropTypes.object,
        copiedProjectId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        ]),
        getProjectsByValuationDateHandler: PropTypes.func,
        getValuationDatesHandler: PropTypes.func,
        valuationDates: PropTypes.array,
        exportList: PropTypes.array,
        printList: PropTypes.array,
        collectionSet: PropTypes.array,
        isFetchingExportList: PropTypes.bool,
        isFetchingCollectionSet: PropTypes.bool,
        isFetchingPrintList: PropTypes.bool,
        excelList: PropTypes.array,
        isFetchingExcelList: PropTypes.bool,
    };

    constructor(props) {
        
        super(props);
        this.state = {
            dataStructures: [],
            valuationDates: [],
            alreadyFetched: false,
            render: 0,
            isFetching: false,
        };
        if (props.currentWorkspace && !props.params.date) {
            //browserHistory.push(`/analysis/analysisTab/workspaces/${props.currentWorkspace.id}/projects`);
            this.state.alreadyFetched = true;
        } else if (props.params && props.params.workspaceId) {
            this.state.alreadyFetched = true;
        }
    
        this.state.selectedSideNavOption = this.determineTab();
        this.selectSideNavOption = this.selectSideNavOption.bind(this);
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
        this.changeValuationDateHandler = this.changeValuationDateHandler.bind(this);
        this.changeDataStructureHandler = this.changeDataStructureHandler.bind(this);
        this.filterByValuationDate = this.filterByValuationDate.bind(this);
        this.refreshProjects = this.refreshProjects.bind(this);
        this.update = this.update.bind(this);
        this.reRender = this.reRender.bind(this);
        this.getPermissionChecker = this.getPermissionChecker.bind(this);
    }

    componentDidMount() {
        let { 
            valuationDate,
            shape:dataStructure,
            dispatch,
            params} = this.props;

        if (params.date || !valuationDate){
            // this handles two cases:
            //      - the url contains params - use them rather than whatever is in state
            //      - the url does not contain params and there are none in state - use 'all'
            dispatch(changeValuationDate(getDateParam(params), getDataStructureParam(params)));
        } else {
            dispatch(changeValuationDate(valuationDate, dataStructure));
        }

        window.addEventListener('resize', this.reRender);
    }

    componentWillReceiveProps(nextProps) {
        const { userKey, currentWorkspace, getValuationDatesHandler, valuationDates, isFetching,
            projects: currentProjects, valuationDate, shape:dataStructure, dispatch, browserHistory, isFetchingValuationDates } = this.props,
        { isFetching: nextIsFetching, isFetchingProjects, projects: nextProjects } = nextProps,
        newState = {};

        // console.error('will receive =====================================================');
        // console.error('   currentWorkspace: ', currentWorkspace ? currentWorkspace.id : 'NONE', " > ",
        //   nextProps.currentWorkspace ? nextProps.currentWorkspace.id : 'NONE');
        // console.error('   valuationDates: ', valuationDates ? valuationDates : 'NONE', " > ",
        //   nextProps.valuationDates ? nextProps.valuationDates : 'NONE');
        //   console.error('   valuationDate: ', valuationDate ? valuationDate : 'NONE', " > ",
        //   nextProps.valuationDate ? nextProps.valuationDate : 'NONE');
        //   console.error('   isFetchingValuationDates: ', isFetchingValuationDates ? "YES" : 'NO', " > ",
        //   nextProps.isFetchingValuationDates ? 'YES' : 'NO');
          
          // PROJECTS HAVE CHANGED -> add some calculated props, put in state
        if (nextProjects !== currentProjects) {
            const projects = this.getProjectData(makeClone(nextProps.projects));
            newState.currentProjects = projects;
            newState.allProjects = projects.slice();
            newState.isFetching = false;
            if (nextProps.valuationDate) {
                browserHistory.push(getProjectListUrl(currentWorkspace.id, nextProps.valuationDate, dataStructure));
                if (nextProps.valuationDate !== 'all') {
                    this.filterByValuationDate(nextProps.valuationDate, projects);
                    delete newState.currentProjects;
                }
            }
        }
        
        if (isFetching !== nextIsFetching && nextIsFetching === true) {
            newState.isFetching = true;
        }

        // DATABASE HAS CHANGED -> load the valuation dates
        if (currentWorkspace !== nextProps.currentWorkspace) {
            let isLoading = isFetchingValuationDates || nextProps.isFetchingValuationDates;
            if (nextProps.currentWorkspace && !isLoading){
              getValuationDatesHandler(userKey, nextProps.currentWorkspace.id);
            }
        }
        
        // No valuation date is defined
        if (currentWorkspace && !valuationDate) {
            let justFinishedLoadingValuationDatesForThisDatabase = isFetchingValuationDates && !nextProps.isFetchingValuationDates;

            // You have (re)loaded valuation dates but there are none (db has no projects);
            if (justFinishedLoadingValuationDatesForThisDatabase) {
              dispatch(changeValuationDate('all', 'all'));
            } 

            // You need to load the valuation dates
            if (!isFetchingValuationDates){
              getValuationDatesHandler(userKey, currentWorkspace.id);
            }
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }
        
        // VALUATION DATES HAVE CHANGED -> use the first one
        if (!isFetchingProjects && nextProps.valuationDates && nextProps.valuationDates.length && valuationDates !== nextProps.valuationDates) {
            let date = valuationDate;
            let shape = dataStructure;
            if (!nextProps.valuationDates.find(x=> x === valuationDate)){
                date = nextProps.valuationDates[0];  // use the first one
                shape = 'all';
            }
            dispatch(changeValuationDate(date, shape));
        }

        // VALUATION DATE OR SHAPE CHANGED -> reload projects
        let updateNeeded = (nextProps.valuationDate && valuationDate !== nextProps.valuationDate) || dataStructure !== nextProps.shape;
        updateNeeded = updateNeeded ||  (!this.state.currentProjects); // date and shape haven't changed but projects were never loaded
        if (!this.props.isFetchingProjects && !isFetchingProjects && currentWorkspace && nextProps.valuationDate && updateNeeded) {
            this.getProjects(nextProps.valuationDate);
        }
    }

    componentWillUnmount() {
        const{dispatch}=this.props;
        window.removeEventListener('resize', this.reRender);
        dispatch(changeReviewLevelStatus());
    }

    stateNeedsUpdate() {
        const { valuationDate, shape:dataStructure, params } = this.props;

        if (!params.date && valuationDate){
            // case where there are no url parameters and there are existing values in state
            return false;
        }

        let dataStructureParam = getDataStructureParam(params);
        let valuationDateParam = getDateParam(params);

        return dataStructure !== dataStructureParam || valuationDate !== valuationDateParam;
    }

    getProjectData(projects) {
        let { valuationDates, projectsWithData } = getProjectDataShapes(projects),
            sortedDates = valuationDates.sort(this.dateSorter);

        let { shape:dataStructure } = this.props;

        let dataStructures = projectsWithData
            .map(p => p.dataShape)
            .filter((value, index, self) => self.indexOf(value) === index) // only unique values
            .sort();

        if (dataStructure && dataStructure !== 'all'){
            projectsWithData = projectsWithData.filter(p => p.dataShape === dataStructure);
        }

        this.setState({ dataStructure, dataStructures, valuationDates: sortedDates, projectsHaveDataShape: true });
        return projectsWithData;
    }
  
    selectSideNavOption(option) {
        const {dispatch,userKey}= this.props, { selectedSideNavOption } = this.state;
        if(option==='review levels'){
            dispatch(fetchSubscriptionUsers(userKey));
        }
        if (selectedSideNavOption !== option) {
            this.setState({ selectedSideNavOption: option });
        }
    }

    filterByValuationDate(date, projectedProjects) {
        let projects = projectedProjects || this.getProjectData(makeClone(this.props.projects));
        projects = projects.filter(project => date === moment(project.valuationDate).format('L'));
        this.setState({ currentProjects: projects, isFetching: false });
    }

    getProjects(date) {
        const { userKey,currentWorkspace, workspaceId, getProjectsByValuationDateHandler } = this.props,
            id = (currentWorkspace && currentWorkspace.id) || workspaceId;

        if (date === 'all') {
            //console.error(`dispatch(tryFetchProjects(${userKey}, ${id}));`)
            this.props.dispatch(tryFetchProjects(userKey, id));
        } else {
            //console.error(`getProjectsByValuationDateHandler(${userKey}, ${id}, ${moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')});`)
            getProjectsByValuationDateHandler(userKey, id, moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD'));
        }
    }

    refreshProjects() {
        this.props.dispatch(receiveProjects([]));  // clears the project list
        this.getProjects(this.props.valuationDate);
    }

    changeValuationDateHandler(e, dataStructure) {
        const { dispatch } = this.props,
            date = e && e.target && e.target.value ? e.target.value : e;

        if (e && e.target && e.target.value){
            dataStructure = 'all';  // the val date has change, reset dataStructure
        } else if (!dataStructure){
            dataStructure = this.props.shape;
        }
        dispatch(changeValuationDate(date, dataStructure));
    }

    changeDataStructureHandler(e) {
        const {dispatch} = this.props;
        dispatch(changeValuationDate(this.props.valuationDate, e.target.value));
    }

    determineTab() {
        const url = window.location.href;

        //let tab = 'review levels';
        let tab = 'projects';
        if (url.indexOf('/collection_sets/') !== -1) {
            tab = 'collection sets';
        } else if (url.indexOf('/prints_lists/') !== -1) {
            tab = 'print lists';
        } else if (url.indexOf('/export_lists/') !== -1) {
            tab = 'export lists';
        }
        return tab;
    }

    dateSorter(a, b) {
        return new Date(b) - new Date(a);
    }

    update() {
    }

    getPermissionChecker() {
      const { currentWorkspace, userPermissions, } = this.props;

      return createPermissionChecker(
          currentWorkspace ? [...userPermissions, ...currentWorkspace.permissions] : userPermissions);
    }

    changeWorkspaceHandler(workspaceId) {
        const {
            dispatch,
            userKey,
            fetchScriptsHandler,
            currentWorkspace,
            browserHistory,
        } = this.props,
            verifyPermission = this.getPermissionChecker(),
            currentWorkspaceId = (currentWorkspace || {}).id || (currentWorkspace || {}).workspaceId;

        if (currentWorkspaceId === workspaceId) { return; }
        dispatch(clearWorkspace());
        dispatch(clearWorkspaceRelatedOnWorkspaceChange());
        this.setState({ selectedScript: 'select', alreadyFetched: true });
        dispatch(fetchWorkspace(userKey, workspaceId));
        dispatch(clearScripts());
        if (verifyPermission(WORKSPACE_MANAGEJOBS)) {
            fetchScriptsHandler({ userKey, databaseId: workspaceId });
        }
        browserHistory.push(`/analysis/analysisTab/workspaces/${workspaceId}/projects`);
    }

    reRender() {
        this.forceUpdate();
    }

  render() {
    const
      {
        dispatch,
        workspaces,
        currentWorkspace,
        params,
        scripts,
        userKey,
        workspaceId,
        isFetchingProjects,
        isFetchingWorkspaces,
        projectsHaveDataShape,
        initiateBatchProcessHandler,
        apiUrl,
        getCurrentUdoVersion,
        cloneProject,
        currentUdoVersionNbr,
        runRollForward,
        fetchScriptsHandler,
        segments,
        isFetchingSegments,
        getProjectVersionsHandler,
        projectVersions,
        updateProjectVersionHandler,
        valuationDate,
        shape:dataStructure,
        checkingUniqueness,
        getCheckoutStatusHandler,
        copyingProject,
        copiedProjectId,
        valuationDates,
        excelList,
        printList,
        collectionSet,
        isFetchingCollectionSet,
        isFetchingExcelList,
        isFetchingPrintList,
        browserHistory,
      } = this.props,
      {
        selectedSideNavOption, 
        isFetching,
        editingMode,
        dataStructures,
        // valuationDates,
        currentProjects,
        alreadyFetched,
        allProjects,
      } = this.state;

      const verifyPermission = this.getPermissionChecker(),
      sidebar = (
        <AnalysisTabSidebar
          selectSideNavOption={this.selectSideNavOption}
          selectedSideNavOption={selectedSideNavOption}
        />
      ),
      toolbar = (
        <AnalysisToolbar
          disabled={editingMode}
          currentWorkspace={currentWorkspace}
          workspaces={workspaces}
          dispatch={dispatch}
          changeWorkspaceHandler={this.changeWorkspaceHandler}
          changeValuationDateHandler={this.changeValuationDateHandler}
          changeDataStructureHandler={this.changeDataStructureHandler}
          valuationDates={valuationDates}
          valuationDate={valuationDate}
          dataStructures={dataStructures}
          dataStructure={dataStructure}
          selectedSideNavOption={selectedSideNavOption}
        />
      );

    let mainContent,
      navHeight,
      toolbarHeight,
      remainingHeight,
      tableHeight;
    if (document.getElementById('mainNavbarContainer')
      && document.getElementById('analysisToolbar')) {   
      navHeight = document.getElementById('mainNavbarContainer').getBoundingClientRect().height;
      toolbarHeight = document.getElementById('analysisToolbar').getBoundingClientRect().height;
      remainingHeight = window.innerHeight - (navHeight + toolbarHeight);
      tableHeight = `${remainingHeight - 75}px`; 
    }
    switch (selectedSideNavOption) {
      case 'projects':
        mainContent = (
          <AnalysisProjectList
            allProjects={allProjects}
            copyingProject={copyingProject}
            update={this.update}
            remainingHeight={remainingHeight}
            alreadyFetched={alreadyFetched}
            changeWorkspaceHandler={this.changeWorkspaceHandler}
            params={params}
            scripts={scripts}
            workspaces={workspaces}
            currentWorkspace={currentWorkspace}
            currentProjects={currentProjects}
            userKey={userKey}
            dispatch={dispatch}
            isFetching={isFetching}
            workspaceId={workspaceId}
            projectsHaveDataShape={projectsHaveDataShape}
            initiateBatchProcessHandler={initiateBatchProcessHandler}
            apiUrl={apiUrl}
            getCurrentUdoVersion={getCurrentUdoVersion}
            cloneProject={cloneProject}
            currentUdoVersionNbr={currentUdoVersionNbr}
            runRollForward={runRollForward}
            fetchScriptsHandler={fetchScriptsHandler}
            verifyPermission={verifyPermission}
            segments={segments}
            isFetchingSegments={isFetchingSegments}
            isFetchingWorkspaces={isFetchingWorkspaces}
            tableHeight={tableHeight}
            getProjectVersionsHandler={getProjectVersionsHandler}
            projectVersions={projectVersions}
            updateProjectVersionHandler={updateProjectVersionHandler}
            valuationDate={valuationDate}
            checkingUniqueness={checkingUniqueness}
            getCheckoutStatusHandler={getCheckoutStatusHandler}
            copiedProjectId={copiedProjectId}
            browserHistory={browserHistory}
            refreshProjectsHandler={this.refreshProjects}
          />
        );
        break;

      case 'collection sets':
        mainContent = (
          <GenericList
            key="CollectionLibrary"
            listType="CollectionLibrary"
            isFetching={isFetchingCollectionSet}
            userKey={userKey}
            dispatch={dispatch}
            data={collectionSet}
            currentWorkspace={currentWorkspace} />
        );
        break;
      case 'print lists':
        mainContent = (
          <GenericList
            key="PrintToPdf"
            listType="PrintToPdf"
            isFetching={isFetchingPrintList}
            userKey={userKey}
            dispatch={dispatch}
            data={printList}
            currentWorkspace={currentWorkspace} />
        );
        break;
      case 'excel lists':
        mainContent = (
          <GenericList
            key="ExportToExcel"
            listType="ExportToExcel"
            data={excelList}
            isFetching={isFetchingExcelList}
            userKey={userKey}
            dispatch={dispatch}
            currentWorkspace={currentWorkspace} />
        );
        break;
        case 'review levels':
        mainContent = (
          <Reviewlevels
            userKey={userKey}
            dispatch={dispatch}
            currentWorkspace={currentWorkspace}
            verifyPermission={verifyPermission} />
        );
        break;

      default:
        mainContent = (
          <AnalysisProjectList
            allProjects={allProjects}
            copyingProject={copyingProject}
            update={this.update}
            remainingHeight={remainingHeight}
            alreadyFetched={alreadyFetched}
            changeWorkspaceHandler={this.changeWorkspaceHandler}
            params={params}
            scripts={scripts}
            workspaces={workspaces}
            currentWorkspace={currentWorkspace}
            currentProjects={currentProjects}
            userKey={userKey}
            dispatch={dispatch}
            isFetching={isFetching}
            isFetchingProjects={isFetchingProjects}
            workspaceId={workspaceId}
            projectsHaveDataShape={projectsHaveDataShape}
            initiateBatchProcessHandler={initiateBatchProcessHandler}
            apiUrl={apiUrl}
            getCurrentUdoVersion={getCurrentUdoVersion}
            cloneProject={cloneProject}
            currentUdoVersionNbr={currentUdoVersionNbr}
            runRollForward={runRollForward}
            fetchScriptsHandler={fetchScriptsHandler}
            verifyPermission={verifyPermission}
            segments={segments}
            isFetchingSegments={isFetchingSegments}
            isFetchingWorkspaces={isFetchingWorkspaces}
            tableHeight={tableHeight}
            getProjectVersionsHandler={getProjectVersionsHandler}
            projectVersions={projectVersions}
            updateProjectVersionHandler={updateProjectVersionHandler}
            valuationDate={valuationDate}
            checkingUniqueness={checkingUniqueness}
            getCheckoutStatusHandler={getCheckoutStatusHandler}
            copiedProjectId={copiedProjectId}
            browserHistory={browserHistory}
          />
        );
    }

    return (
      <div style={style.mainContainer}>
        {sidebar}
        <div>
          {toolbar}
          {mainContent}
        </div>
      </div>
    );
  }
}

export default radium(AnalysisTabMain);
