import { callApi } from '../utilities/callApi';

export function retrieveExtractDefinitionNames({ userKey, databaseId }) {
  return callApi(`/extract/definition/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function retrieveReportingDefinitions({ userKey, databaseId }) {
    return callApi(`/reporting/definition/${databaseId}`,
      { 'X-AE-USERKEY': userKey },
      'GET',
    );
  }

export function extractWithSelectedExtractDefinition({ userKey, databaseId, definitionId }) {
  return callApi(`/extract/full-refresh/${databaseId}/${definitionId}`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function refreshReportingWithSelectedExtractDefinition({ userKey, databaseId, definitionId }) {
    return callApi(`/reporting/full-refresh/${databaseId}/${definitionId}`,
      { 'X-AE-USERKEY': userKey },
      'GET',
    );
  }

export function createExtractDefinition({ userKey, databaseId, req }) {
  return callApi(
    `/extract/definition/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    req,
  );
}

export function editExtractDefinition({ userKey, databaseId, definitionId, req }) {
  return callApi(
    `/extract/definition/${databaseId}/${definitionId}`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    req,
  );
}

export function deleteExtractDefinition({ userKey, databaseId, definitionId }) {
  return callApi(
    `/extract/definition/${databaseId}/${definitionId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function exportToCsv({ userKey, format, databaseName, extractDefinitionName, apiKey }) {
  return callApi(
    `/extract/${format}/${databaseName}/${extractDefinitionName}?apikey=${apiKey}`,
    { 'X-AE-USERKEY': userKey, 'Accept': 'application/csv' },
    'GET'
  );
}

export function retrieveUserNamesForExtractDefinition({ userKey }) {
  return callApi(`users/extract`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}
