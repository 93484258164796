import {
  RECEIVED_WORKSPACE,
  RECEIVED_WORKSPACES,
  SELECTING_WORKSPACE,
  RECEIVED_BACKUPS,
  RECEIVED_PIT_RESTORE_RANGE,
  RECEIVED_ARCHIVED,
  FETCHING_WORKSPACES_FROM_SERVER,
  RECEIVED_DATABASE_UDO_VERSIONS,
  RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS,
  CHANGE_REVIEWLEVEL_STATUS ,
  RECEIVED_REVIEWLEVELS,
  RECEIVED_REVIEWLEVEL,
  RECEIVED_AE_DB_SCRIPT_VERSION,
  REQUESTING_EXTRACT_DATA_CSV,
  RECEIVED_EXTRACT_DATA_CSV,
  FAILED_EXTRACT,
  RECEIVED_BACKUPRESTORE_LOG_FILE,
  FETCHING_REVIEWLEVEL,
 
} from './analysis.actionTypes';
import * as workspaceServiceApi from '../../serviceApi/workspaces.serviceApi';
import { notifyError, notifySuccess, alertMessage, alertMessageWithResize } from '../../utilities/notifier';
import {
  retrieveCurrentDbVersion,
} from '../../serviceApi/application.serviceApi';
import { createMessageTable } from '../../utilities/helpers';
import { ServerError } from '../../utilities/callApi';
import { receivedJobStatus } from '../jobStatus.actions';

function fetchingFromServer() {
    return {
        type: FETCHING_WORKSPACES_FROM_SERVER,
    };
}

function receivedWorkspaces(json) {
  return {
    type: RECEIVED_WORKSPACES,
    workspaces: json,
    receivedAt: Date.now(),
  };
}

function failedExtract() {
  return {
    type: FAILED_EXTRACT,
  };
}

function receivedWorkspace(json) {
  return {
    type: RECEIVED_WORKSPACE,
    current: {
      id: json.workspaceId,
      name: json.workspaceName,
      description: json.workspaceDescription,
      dimensions: json.workspaceDimensions,
      isOnline: json.isOnline,
      permissions: json.permissions,
    },
    receivedAt: Date.now(),
  };
}

function receivedBackups(workspaceId, json) {
  return {
    type: RECEIVED_BACKUPS,
    workspaceId,
    backups: json,
    receivedAt: Date.now(),
  };
}

function receivedPointInTimeRestoreRange(workspaceId, json) {
    return {
        type: RECEIVED_PIT_RESTORE_RANGE,
        workspaceId,
        earliestRestorePoint: json.earliestPoint,
        latestRestorePoint: json.latestPoint,
        receivedAt: Date.now(),
    };
}

function receivedArchived(workspaceId, json) {
  return {
    type: RECEIVED_ARCHIVED,
    workspaceId,
    archived: json,
    receivedAt: Date.now(),
  };
}

function selectWorkspace(workspace) {
  return {
    type: SELECTING_WORKSPACE,
    current: workspace
      ? {
        id: workspace.workspaceId,
        name: workspace.workspaceName,
        description: workspace.workspaceDescription,
        dimensions: workspace.workspaceDimensions,
        isOnline: workspace.isOnline,
        permissions: workspace.permissions,
      }
      : null,
    receivedAt: Date.now(),
  };
}

function fetchWorkspace(userKey, workspaceId) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return workspaceServiceApi
      .retrieveWorkspace(userKey, workspaceId)
      .then(json => dispatch(receivedWorkspace(json)));
  };
}

function fetchWorkspaces(userKey) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return workspaceServiceApi
      .retrieveWorkspaces(userKey)
      .then(json => dispatch(receivedWorkspaces(json)));
  };
}

function createWorkspace({
  userKey,
  workspaceId,
  workspaceName,
  workspaceDescription,
  workspaceDimensions,
  createdBy,
  updatedBy,
}) {
  return dispatch => {
    
    const dimensions = JSON.stringify({
      system: ['Segment'],
      userDefined: workspaceDimensions.length ? workspaceDimensions.join().split(',').map(d => d.trim()) : [],
    });

    return workspaceServiceApi.createWorkspace(
    { userKey, createdBy, updatedBy, workspaceName, 
      workspaceId, workspaceDescription, workspaceDimensions: dimensions,})
    .then(json => dispatch(receivedJobStatus(json)));
  };
}

function updateWorkspace({
  userKey,
  workspaceId,
  workspaceName,
  workspaceDescription,
  workspaceDimensions,
  createdBy,
  updatedBy,
}) {
  return dispatch => {
    const dimensions = JSON.stringify({
      system: ['Segment'],
      userDefined: workspaceDimensions.length ? workspaceDimensions.join().split(',').map(d => d.trim()) : [],
    });
    return workspaceServiceApi.updateWorkspace({
      userKey,
      createdBy,
      updatedBy,
      workspaceName,
      workspaceId,
      workspaceDescription,
      workspaceDimensions: dimensions,
    });
  };
}

// function createWorkspace({ userKey, workspaceName, workspaceDesc, userDefinedDims }) {
//   return () => {
//     const dimensions = JSON.stringify({
//       system: ['Segment'],
//       userDefined: userDefinedDims.split(',').map(d => d.trim()),
//     });
//     return workspaceServiceApi
//              .createWorkspace(userKey, workspaceName, workspaceDesc, dimensions);
//   };
// }

function trySaveWorkspace({
  userKey,
  workspaceName,
  workspaceDesc,
  userDefinedDims,
}) {
  return (dispatch, getState) => {
    const state = getState();

    if (!state.workspaces.isSaving) {
      return dispatch(
        createWorkspace({
          userKey,
          workspaceName,
          workspaceDesc,
          userDefinedDims,
        })
      );
    }
    return null;
  };
}

function shouldFetchWorkspaces(state) {
  const workspaces = state.workspaces;
  if (!workspaces) {
    return true;
  }

  if (workspaces.isFetching) {
    return false;
  }

  return true;
}

function fetchWorkspacesIfNeeded(userKey) {
  return (dispatch, getState) => {
    if (shouldFetchWorkspaces(getState())) {
      return dispatch(fetchWorkspaces(userKey));
    }
    return null;
  };
}

function deleteWorkspace(userKey, workspaceId) {
  return dispatch =>
    workspaceServiceApi
      .deleteWorkspace(userKey, workspaceId)
      .then(() => dispatch(selectWorkspace(null)))
      .then(() => dispatch(fetchWorkspaces(userKey)));
}

function createBackup(userKey, workspaceId, description) {
  return dispatch => {
    workspaceServiceApi.createBackup(userKey, workspaceId, description)
    .then(json => dispatch(receivedJobStatus(json)));
  }
}

function databaseUpdate(userKey, databseId, file) {
  return () => {
    workspaceServiceApi.databaseUpdate(userKey, databseId, file)
      .then((res) => {
        if (res && res.notFound) {
          alertMessageWithResize('Direct import failed for the following', createMessageTable(res.notFound));
        } else {
          notifySuccess('Direct import process initiated. See Status page for more info.');
        }
      }, err => {
        if (err instanceof ServerError) {
          if (err.code === 403) {
            notifyError('You are not authorized to access this functionality');
          } else {
            alertMessage('direct import failed', err.data);
          }
          return null;
        } else {
          throw err;
        }
      });
  };
}

// function fetchArchivedForAllBackups(userKey, workspaceId, backups) {
//   return (dispatch, getState) => {
//     const bckups = !backups ? getState().workspaces.backups : backups;
//     return new Promise(resolve => {
//       const promises = bckups.map(b =>
//         workspaceServiceApi
//           .retrieveArchived(userKey, workspaceId, b.id)
//           .then(json => json)
//       );
//       Promise.all(promises).then(archived => {
//         const currentArchived = archived.reduce((a, b) => a.concat(b));
//         resolve(currentArchived);
//       });
//     }).then(allArchived => dispatch(receivedArchived(workspaceId, allArchived)));
//   };
// }

function fetchArchived(userKey, workspaceId) {
  return dispatch =>
    workspaceServiceApi
    .retrieveArchived(userKey, workspaceId)
    .then(json => dispatch(receivedArchived(workspaceId, json)));
}


function fetchBackups(userKey, workspaceId) {
  return dispatch =>
    workspaceServiceApi
      .retrieveBackups(userKey, workspaceId)
      .then(json => {
        dispatch(receivedBackups(workspaceId, json));
        //dispatch(fetchArchivedForAllBackups(userKey, workspaceId, json));
        dispatch(fetchArchived(userKey, workspaceId));
      });
}

function fetchPointInTimeRestoreRange(userKey, workspaceId) {
    return dispatch =>
        workspaceServiceApi
            .retrievePointInTimeRestoreRange(userKey, workspaceId)
            .then(json => dispatch(receivedPointInTimeRestoreRange(workspaceId, json)));
}

function deleteBackup(userKey, workspaceId, backupId) {
  return dispatch =>
    workspaceServiceApi
      .deleteBackup(userKey, workspaceId, backupId)
      .then(() => dispatch(fetchBackups(userKey, workspaceId)));
}

// function fetchArchivedForBackup(userKey, workspaceId, backupId) {
//   return (dispatch, getState) =>
//     workspaceServiceApi
//       .retrieveArchived(userKey, workspaceId, backupId)
//       .then(json => receivedArchivedForBackup(json, getState()));
// }

function archiveBackup(userKey, workspaceId, backupId, description) {
  return dispatch =>
    workspaceServiceApi.archiveBackup(userKey, workspaceId, backupId, description)
    .then(json => dispatch(receivedJobStatus(json)));
}

function deleteArchivedBackup(userKey, workspaceId, backupId, archiveId) {
  return dispatch =>
    workspaceServiceApi
      .deleteArchived(userKey, workspaceId, backupId, archiveId)
      //.then(() => dispatch(fetchArchivedForAllBackups(userKey, workspaceId)));
      .then(() => {
        // we don't track progress
        // dispatch(receivedJobStatus(json));
        dispatch(fetchArchived(userKey, workspaceId));
      });
}

function restoreBackup(userKey, workspaceId, backupId) {
  return dispatch =>
    workspaceServiceApi.restoreBackup(userKey, workspaceId, backupId)
    .then(json => dispatch(receivedJobStatus(json)));
}

function restorePointInTime(userKey, workspaceId, timestamp) {
    return dispatch => {
        workspaceServiceApi.restorePointInTime(userKey, workspaceId, timestamp)
        .then(json => dispatch(receivedJobStatus(json)));
    }
}

function undoPITR(userKey, workspaceId, timestamp) {
    return dispatch => {
        workspaceServiceApi.undoPITR(userKey, workspaceId, timestamp)
        .then(json => dispatch(receivedJobStatus(json)));
    }
}

function restoreArchivedBackup(userKey, workspaceId, backupId, archiveId) {
  return dispatch =>
    workspaceServiceApi.restoreArchivedBackup(userKey, workspaceId, backupId, archiveId)
    .then(json => dispatch(receivedJobStatus(json)));
}

function receivedDatabaseUdoVersions(json) {
  return {
    type: RECEIVED_DATABASE_UDO_VERSIONS,
    databaseUdoVersions: json,
  };
}
function receivedDatabaseUdoLibrarylockstatus(lockStatus){
  return {
  type:RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS,
  UdoLibrarylockstatus:lockStatus.status === 200?false:true,
  };
}

function receivedReviewLevels(result) {
  return {
    type: RECEIVED_REVIEWLEVELS,
    reviewLevels:result
  };
}

function receivedReviewLevel(reviewLevel) {
  return {
    type: RECEIVED_REVIEWLEVEL,
    reviewLevel: reviewLevel
  };
}

function changeReviewLevelStatus(){
  return {
    type: CHANGE_REVIEWLEVEL_STATUS,
  };
}
function emptyDatabaseUdoVersions() {
  return {
    type: RECEIVED_DATABASE_UDO_VERSIONS,
    databaseUdoVersions: [],
  };
}

function fetchDatabaseUdoVersions(userKey, workspaceId) {
  return dispatch =>
    workspaceServiceApi
      .retrieveUdoLibraryVersions(userKey, workspaceId)
      .then(data => dispatch(receivedDatabaseUdoVersions(data)));
}
function fetchDatabaseUdoLibrarylockstatus(userKey, databaseId) {
  return dispatch =>
    workspaceServiceApi
      .retrieveDatabaseUdoLibrarylockstatus(userKey, databaseId)
      .then(data => dispatch(receivedDatabaseUdoLibrarylockstatus(data)));
}
function DatabaseUdoLibrarylock(userKey, databaseId) {
  return dispatch =>
    workspaceServiceApi
      .lockUdoLibrary(userKey, databaseId)
      .then(data=> dispatch(receivedDatabaseUdoLibrarylockstatus(data)));
}
function DatabaseUdoLibraryUnlock(userKey, databaseId) {
  return dispatch =>
    workspaceServiceApi
      .unLockUdoLibrary(userKey, databaseId)
      .then(data=> dispatch(receivedDatabaseUdoLibrarylockstatus(data)));
}

function createReviewLevel(userKey, databaseId, reviewLevel) {
  return dispatch =>
    workspaceServiceApi
        .createReviewLevel(userKey, databaseId, reviewLevel)
        .then(() => dispatch(fetchReviewLevels(userKey, reviewLevel.databaseId)));
}

function updateReviewLevel(userKey, databaseId, reviewLevel) {
  return dispatch =>
    workspaceServiceApi
        .updateReviewLevel(userKey, databaseId, reviewLevel)
        .then(() => dispatch(fetchReviewLevels(userKey, reviewLevel.databaseId)));
}

function deleteReviewLevel(userKey, databaseId, reviewLevelId) {
  return dispatch =>
    workspaceServiceApi
        .deleteReviewLevel(userKey, databaseId, reviewLevelId)
        .then(() => dispatch(fetchReviewLevels(userKey, databaseId)));
}

function fetchReviewLevels(userKey, workspaceId) {
  return dispatch => {
    return workspaceServiceApi
      .retrieveReviewLevels(userKey, workspaceId)
      .then(result => dispatch(receivedReviewLevels(result)));
  };
}

function fetchReviewLevel(userKey, reviewLevelId) {
  return dispatch => {
    dispatch({type: FETCHING_REVIEWLEVEL});
    return workspaceServiceApi
      .retrieveReviewLevel(userKey, reviewLevelId)
      .then(result => {
        dispatch(receivedReviewLevel(result))});
  };
}

function clearWorkspace() {
  return {
    type: RECEIVED_WORKSPACE,
    current: null,
    receivedAt: Date.now(),
  };
}

function updateUDOComment({ userKey, databaseId, version, comment }) {
  return dispatch =>
    workspaceServiceApi
      .updateUDOComment({ userKey, databaseId, version, comment })
      .then(() => dispatch(fetchDatabaseUdoVersions(userKey, databaseId)));
}

function receivedCurrentAEDbScriptVersion(currentAEVersion) {
  return {
    type: RECEIVED_AE_DB_SCRIPT_VERSION,
    currentAEVersion,
  };
}

function fetchCurrentAEDbScriptVersion(userKey) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return retrieveCurrentDbVersion(userKey, 2).then(json =>
      dispatch(receivedCurrentAEDbScriptVersion(json))
    );
  };
}

function requestingExtractDataToCsv() {
  return {
    type: REQUESTING_EXTRACT_DATA_CSV,
    isExtractToCsvProcessing: true,
  };
}

function recievedDownloadExtractDataToCsv(file) {
  const csvExtractFile = new Blob([file], { type: 'text/csv;charset=utf-8;' });
  return {
    type: RECEIVED_EXTRACT_DATA_CSV,
    isExtractToCsvProcessing: false,
    csvExtractFile,
  };
}

function exportExtractToCsv(userKey, workspaceId, layoutType) {
  return dispatch => {
    dispatch(requestingExtractDataToCsv());
    workspaceServiceApi
      .requestExtractDataToCsv(userKey, workspaceId, layoutType)
      .then(csv => {
        if (!csv || csv.error) {
          dispatch(failedExtract());
        } else if (typeof csv !== 'string') {
          notifyError('This database has no data to export');
          dispatch(failedExtract());
        } else {
          dispatch(recievedDownloadExtractDataToCsv(csv));
        }
      });
  };
}

function reloadExtractTable(userKey, databaseId) {
  return () =>
    workspaceServiceApi
      .reloadExtractTable(userKey, databaseId)
      .then(res => {
        if (res && res.status === 204) notifySuccess('Initiated refreshing extract table');
      });
}

function receivedTextFile(file) {
  const txtFile = new Blob([file], { type: 'text/plain;charset=utf-8;' });
  return {
    type: RECEIVED_BACKUPRESTORE_LOG_FILE,
    txtFile,
  };
}

function fetchBackupRestoreLogFile(userKey, databaseId) {
  return (dispatch) => workspaceServiceApi
    .retrieveBackupRestoreLogFile(userKey, databaseId)
    .then(txt => dispatch(receivedTextFile(txt)));
}

function deleteBackupRestoreLogFile(userKey, databaseId) {
  return () => workspaceServiceApi.deleteBackupRestoreLogFile(userKey, databaseId);
}

export {
  selectWorkspace,
  fetchWorkspacesIfNeeded,
  trySaveWorkspace,
  fetchWorkspace,
  fetchWorkspaces,
  deleteWorkspace,
  fetchBackups,
  fetchPointInTimeRestoreRange,
  deleteBackup,
  createBackup,
  createWorkspace,
  updateWorkspace,
  fetchArchived,
  archiveBackup,
  deleteArchivedBackup,
  restoreBackup,
  restorePointInTime,
  undoPITR,
  restoreArchivedBackup,
  fetchDatabaseUdoVersions,
  fetchDatabaseUdoLibrarylockstatus,
  DatabaseUdoLibrarylock,
  DatabaseUdoLibraryUnlock,
  createReviewLevel,
  updateReviewLevel,
  deleteReviewLevel,
  fetchReviewLevels,
  fetchReviewLevel,
  receivedReviewLevel,
  changeReviewLevelStatus,
  emptyDatabaseUdoVersions,
  updateUDOComment,
  fetchCurrentAEDbScriptVersion,
  exportExtractToCsv,
  reloadExtractTable,
  databaseUpdate,
  fetchBackupRestoreLogFile,
  deleteBackupRestoreLogFile,
  clearWorkspace
};
