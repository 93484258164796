import {
  FETCHING_QUERIES,
  RECEIVED_QUERIES,
  RECEIVED_QUERY,
  RECEIVED_QUERY_DATASOURCE,
  FETCHING_QUERY_DATA_SOURCE,
  SAVING_TOD_QUERY,
  RECEIVED_SEGMENT_PREVIEW,
  RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS,
  FETCHING_ROLLUP_DEFIINITION_FROM_SERVER,
  RECEIVED_ROLLUP_DEFINITION,
  RECEIVED_DATASET_COLUMNS,
  LOAD_ROLLUPDEFINITIONS_SNAPSHOT,
  CLEAR_ROLLUPDEFINITIONS_SNAPSHOT,
  CLEAR_ROLLUPDEFINITIONS,
  PROCESSING_NEW_ROLLUP_DEFINITION,
  FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION,
  STORE_QUERY,
  CLEAR_QUERY,
  FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER,
  RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER,
  RECEIVED_QUERIES_USING_ROLLUP,
  RECEIVED_V2_QUERY,
  FETCHING_DATAGROUPS,
  FETCHING_DATAGROUP,
  RECEIVED_DATAGROUPS,
  RECEIVED_DATAGROUP,
  FETCHING_ROLLUPS,
  RECEIVED_ROLLUPS,
  FETCHING_FACTCOLUMNS,
  FETCHING_OPERATORS,
  RECEIVED_FACTCOLUMNS,
  RECEIVED_OPERATORS,
  FETCHING_QUERY_VALIDATION,
  RECEIVED_QUERY_VALIDATION,
  FETCHING_VALUES,
  RECEIVED_VALUES,
  FETCHING_QUERY_SEGMENTS,
  RECEIVED_QUERY_SEGMENTS,
} from '../../actions/tod/tod.actionTypes';

const queriesReducer = (state = {
  items: [],
  isFetching: false,
  isFetchingDataGroups: false,
  isFetchingQueries: false,
  isDataSourceFetching: false,
  isSaving: false,
  dataSource: null,
  segmentPreview: null,
  datasetSpecificRollupDefinitions: [],
  allRollupDefinitions: [],
  rollupDefinition: null,
  rollupDefinitionsComponentSnapshot: {
    loaded: false,
    state: {},
    props: {},
  },
  isProcessingNewRollupDefinition: false,
  datasetColumns: [],
  queryInfo: {},
  queriesUsingRollup: [],
}, action) => {
  switch (action.type) {
    case SAVING_TOD_QUERY:
      return Object.assign({}, state, {
        isSaving: true,
      });
    case FETCHING_QUERIES:
      return Object.assign({}, state, {
      isFetching: true,
      isFetchingQueries: true,
      isFetchingQuery: true
    });
    case FETCHING_QUERY_VALIDATION:
      return Object.assign({}, state, {
      isFetching: true,
      isFetchingQueryValidation: true
    });
    
    
    case FETCHING_DATAGROUP:
    return Object.assign({}, state, {
        isFetching: true,
        isFetchingDataGroup: true,
      });
    case FETCHING_FACTCOLUMNS:
    case FETCHING_OPERATORS:
    case FETCHING_ROLLUPS:
    case FETCHING_ROLLUP_DEFIINITION_FROM_SERVER:
    case FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER:
      return Object.assign({}, state, {
            isFetching: true,
      });
    case FETCHING_DATAGROUPS:
        return Object.assign({}, state, {
              isFetchingDataGroups: true,
              isFetching: true,
        });
    case FETCHING_VALUES:
      return Object.assign({}, state, {
        isFetchingValues: true,
      });
    case FETCHING_QUERY_DATA_SOURCE:
      return Object.assign({}, state, {
        isDataSourceFetching: true,
      });
    case RECEIVED_DATAGROUPS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingDataGroups: false,
        isSaving: false,
        dataGroups: action.data,
      });
    case RECEIVED_ROLLUPS:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        rollups: action.data, // TODO why store two places?
        rollupDefinitions: action.data,
      });
    case RECEIVED_FACTCOLUMNS:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        factColumns: action.data,
      });
    case RECEIVED_VALUES:
      return Object.assign({}, state, {
        isFetchingValues: false,
        valuesPagingResult: action.data,
      });
    case RECEIVED_OPERATORS:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        operators: action.data,
      });
      case RECEIVED_DATAGROUP:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingDataGroup: false,
        isSaving: false,
        dataGroup: action.data,
      });
    case RECEIVED_QUERIES:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingQueries: false,
        isSaving: false,
        items: action.items,
      });
    case RECEIVED_V2_QUERY: 
      return Object.assign({}, state, {
        currentV2Query: action.item,
      });
    case RECEIVED_QUERY:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingQuery: false,
        isFetchingQueries: false,
        isSaving: false,
        current: action.current,
      });
    case RECEIVED_QUERY_VALIDATION:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingQueryValidation: false,
        queryValidationResponse: action.data,
      });
    case FETCHING_QUERY_SEGMENTS:
      return Object.assign({}, state, {
        isFetching: true,
        querySegments: null,
      });
    case RECEIVED_QUERY_SEGMENTS:
      return Object.assign({}, state, {
        isFetching: false,
        querySegments: action.stringRecordSet,
      });
    case RECEIVED_QUERY_DATASOURCE:
      return Object.assign({}, state, {
        dataSource: action.dataSource,
        isDataSourceFetching: false,
      });
    case RECEIVED_SEGMENT_PREVIEW:
      return Object.assign({}, state, {
        segmentPreview: action.segmentPreview,
        isFetching: false,
      });
    case RECEIVED_QUERIES_USING_ROLLUP:
      return Object.assign({}, state, {
        queriesUsingRollup: action.queriesUsingRollup,
        isFetching: false,
      });
    case RECEIVED_DATASET_COLUMNS:
      return Object.assign({}, state, {
        datasetColumns: action.columns,
        isFetching: false,
      });
    case RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS:
      return Object.assign({}, state, {
        datasetSpecificRollupDefinitions: action.items,
        isFetching: false,
      });
    case CLEAR_ROLLUPDEFINITIONS:
      return Object.assign({}, state, {
        datasetSpecificRollupDefinitions: [],
        isFetching: false,
      });
    case RECEIVED_ROLLUP_DEFINITION:
      return Object.assign({}, state, {
        rollupDefinition: action.items,
        isFetching: false,
      });
    case RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER:
      return Object.assign({}, state, {
        allRollupDefinitions: action.items,
        isFetching: false,
      });
    case LOAD_ROLLUPDEFINITIONS_SNAPSHOT:
      return Object.assign({}, state, {
        rollupDefinitionsComponentSnapshot: action.payload,
        isFetching: false,
      });
    case CLEAR_ROLLUPDEFINITIONS_SNAPSHOT:
      return Object.assign({}, state, {
        rollupDefinitionsComponentSnapshot: {
          loaded: false,
          state: {},
          props: {},
        },
        isFetching: false,
      });
    case PROCESSING_NEW_ROLLUP_DEFINITION:
      return Object.assign({}, state, {
        isProcessingNewRollupDefinition: true,
      });
    case FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION:
      return Object.assign({}, state, {
        isProcessingNewRollupDefinition: false,
      });
    case CLEAR_QUERY:
      return Object.assign({}, state, {
        queryInfo: {},
      });
    case STORE_QUERY:
      return Object.assign({}, state, {
        queryInfo: action.query,
      });
    default:
      return state;
  }
};

export default queriesReducer;
