import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { Switch, Route } from 'react-router-dom';
import { clearErrorMessage } from '../../../actions/application.actions';
import {
  fetchDatabases,
  selectDatabase,
  fetchDatbaseColumns,
  fetchDatabase,
} from '../../../actions/tod/todDatabases.actions';
import {
  fetchQueries,
  deleteQuery,
  fetchQuery,
  fetchQueryDataSource,
  createQuery,
  updateQuery,
  fetchSegmentPreview,
  fetchRollupDefinitionsForDataSet,
  fetchAllRollupDefinitions,
  fetchRollupDefinition,
  createRollupDefinition,
  deleteRollupDefinition,
  updateRollupDefinition,
  fetchDatasetColumns,
} from '../../../actions/tod/queries.actions';
import { createPermissionChecker } from '../../../utilities/permissions';

import QueriesList from '@app/tod/queriesList';
import CreateQuery from '@app/tod/createQuery';
import RollupDefinitionsList from '@app/tod/rollupDefinitionsList';
import CreateEditRollupDefinitions from '@app/tod/createEditRollupDefinitions';

class QueriesTab extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    allDatabases: PropTypes.array,
    queries: PropTypes.array,
    changeDatabase: PropTypes.func,
    isFetching: PropTypes.bool,
    isSaving: PropTypes.bool,
    currentDb: PropTypes.object,
    getQuery: PropTypes.func,
    getQueryDataSource: PropTypes.func,
    getSegmentPreview: PropTypes.func,
    newSaveHandler: PropTypes.func,
    updateSaveHandler: PropTypes.func,
    deleteQueryHandler: PropTypes.func,
    dataSource: PropTypes.object,
    isDataSourceFetching: PropTypes.bool,
    segmentPreview: PropTypes.object,
    currentQuery: PropTypes.object,
    errorMessage: PropTypes.string,
    application: PropTypes.object,
    getDatabases: PropTypes.func,
    getQueries: PropTypes.func,
    getDatabaseColumns: PropTypes.func,
    getDatabase: PropTypes.func,
    isFetchingDatabases: PropTypes.bool,
    roles: PropTypes.array,
    getRollupDefinitionsForDataSet: PropTypes.func,
    getAllRollupDefinitions: PropTypes.func,
    getRollupDefinition: PropTypes.func,
    createRollupDefinitionHandler: PropTypes.func,
    updateRollupDefinitionHandler: PropTypes.func,
    deleteRollupDefinitionHandler: PropTypes.func,
    datasetSpecificRollupDefinitions: PropTypes.array,
    queriesUsingRollup: PropTypes.array,
    allRollupDefinitions: PropTypes.array,
    rollupDefinition: PropTypes.object,
    datasetColumns: PropTypes.array,
    getDatasetColumns: PropTypes.func,
    rollupDefinitionsComponentSnapshot: PropTypes.object,
    isProcessingNewRollupDefinition: PropTypes.bool,
    backRoute: PropTypes.object,
    queryInfo: PropTypes.object,
    clearErrorMessageHandler: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      databases: [],
    };
  }

  componentWillMount() {
    const { changeDatabase } = this.props;
    changeDatabase(null);
  }

  componentDidMount() {
    const { userKey, getDatabases } = this.props;
    this.filterDatabases(this.props.allDatabases.slice());
    getDatabases(userKey);
  }

  componentDidUpdate(prevProps) {
    const {
      currentDb,
      getQueries,
      userKey,
      match,
      getDatabaseColumns,
      getDatabase,
      isFetchingDatabases,
      isFetching,
      allDatabases,
      history: browserHistory
    } = this.props;

    let params = match.params;

    if (currentDb && prevProps.currentDb !== currentDb && !isFetching) {
      getQueries(userKey, currentDb.id);
    }

    if (isFetchingDatabases) {
      return;
    }

    if (params.databaseId) {
      const databaseId = parseInt(params.databaseId, 10);
      if (databaseId) {
        // TODO: Fix TOD Permissions
        const isDbUser = true, isQueryAdmin = true;
        if (!isDbUser && !isQueryAdmin) {
          browserHistory.push('/trianglesOnDemand/queries');
        }
        if (!currentDb || currentDb.id !== databaseId) {
          getDatabase(userKey, databaseId);
        } else if (
          currentDb && !currentDb.withColumns && !currentDb.isFetching
        ) {
          getDatabaseColumns(userKey, databaseId, currentDb.factTableGuid);
        }
      }
    }
    if (prevProps.allDatabases !== allDatabases) {
      this.filterDatabases(allDatabases.slice());
    }
  }

  filterDatabases(allDatabases) {
    // TODO: Fix TOD Permissions
    let databases = allDatabases.filter(db => db.hasDataLoaded);
    databases = databases.sort((a, b) =>
      a.workspaceName.toLowerCase().localeCompare(b.workspaceName.toLowerCase())
    );
    this.setState({ databases });
  }

  render() {
    const {
      userKey,
      match,
      history,
      dispatch,
      queries,
      changeDatabase,
      isFetching,
      isSaving,
      currentDb,
      getQuery,
      getQueryDataSource,
      getSegmentPreview,
      newSaveHandler,
      updateSaveHandler,
      deleteQueryHandler,
      dataSource,
      isDataSourceFetching,
      segmentPreview,
      currentQuery,
      errorMessage,
      getRollupDefinitionsForDataSet,
      getRollupDefinition,
      createRollupDefinitionHandler,
      updateRollupDefinitionHandler,
      deleteRollupDefinitionHandler,
      datasetSpecificRollupDefinitions,
      allRollupDefinitions,
      rollupDefinition,
      rollupDefinitionsComponentSnapshot,
      datasetColumns,
      getDatasetColumns,
      isProcessingNewRollupDefinition,
      backRoute,
      queryInfo,
      getAllRollupDefinitions,
      clearErrorMessageHandler,
      queriesUsingRollup,
    } = this.props;
    const { databases } = this.state;

    let childProps = {
        userKey,
        browserHistory: history,
        params: match.params,
        dispatch,
        databases,
        queries,
        changeDatabase,
        isFetching,
        isSaving,
        currentDb,
        getQuery,
        getQueryDataSource,
        getSegmentPreview,
        newSaveHandler,
        updateSaveHandler,
        deleteQueryHandler,
        dataSource,
        isDataSourceFetching,
        segmentPreview,
        currentQuery,
        errorMessage,
        getRollupDefinitionsForDataSet,
        getRollupDefinition,
        rollupDefinitionsComponentSnapshot,
        datasetColumns,
        getDatasetColumns,
        isProcessingNewRollupDefinition,
        backRoute,
        queryInfo,
        createRollupDefinitionHandler,
        updateRollupDefinitionHandler,
        deleteRollupDefinitionHandler,
        datasetSpecificRollupDefinitions,
        allRollupDefinitions,
        rollupDefinition,
        getAllRollupDefinitions,
        clearErrorMessageHandler,
        queriesUsingRollup,
        verifyPermission: createPermissionChecker(
          currentDb ? currentDb.permissions : []
        ),
    };

    return (
        <Switch>
            <Route path="/trianglesOnDemand/queries/rollupDefinitionsList/:databaseId/:dimensionName" render={props => <RollupDefinitionsList {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/trianglesOnDemand/queries/rollupDefinitionsList" render={props => <RollupDefinitionsList {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/trianglesOnDemand/queries/createEditRollupDefinitions/:databaseId/:dimensionName/:rollupDefinitionId/edit" render={props => <CreateEditRollupDefinitions {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/trianglesOnDemand/queries/createEditRollupDefinitions" render={props => <CreateEditRollupDefinitions {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/trianglesOnDemand/queries/:databaseId/new" render={props => <CreateQuery {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/trianglesOnDemand/queries/:databaseId/:queryId" render={props => <CreateQuery {...{...childProps, params: props.match.params}}/>}/>
            <Route path="/trianglesOnDemand/queries" render={props => <QueriesList {...{...childProps, params: props.match.params}}/>}/>
        </Switch>
    );
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  application: state.application,
  allDatabases: state.tod.databases.items,
  isFetchingDatabases: state.tod.databases.isFetching,
  queries: state.tod.queries.items,
  currentDb: state.tod.databases.current,
  currentQuery: state.tod.queries.current,
  isFetching: state.tod.queries.isFetching,
  isSaving: state.tod.queries.isSaving,
  dataSource: state.tod.queries.dataSource,
  isDataSourceFetching: state.tod.queries.isDataSourceFetching,
  segmentPreview: state.tod.queries.segmentPreview,
  datasetSpecificRollupDefinitions: state.tod.queries
    .datasetSpecificRollupDefinitions,
  allRollupDefinitions: state.tod.queries.allRollupDefinitions,
  rollupDefinition: state.tod.queries.rollupDefinition,
  isProcessingNewRollupDefinition: state.tod.queries
    .isProcessingNewRollupDefinition,
  backRoute: state.application.backRoute,
  rollupDefinitionsComponentSnapshot: state.tod.queries
    .rollupDefinitionsComponentSnapshot,
  datasetColumns: state.tod.queries.datasetColumns,
  queryInfo: state.tod.queries.queryInfo,
  queriesUsingRollup: state.tod.queries.queriesUsingRollup,
  errorMessage: state.application.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  getDatabases: userKey => {
    dispatch(fetchDatabases(userKey));
  },
  getDatabaseColumns: (userKey, databaseId, factTableGuid) => {
    dispatch(fetchDatbaseColumns(userKey, databaseId, factTableGuid));
  },
  getDatabase: (userKey, databaseId) => {
    dispatch(fetchDatabase(userKey, databaseId));
  },
  getQueryDataSource: (userKey, databaseId, filters, splits) => {
    dispatch(fetchQueryDataSource(userKey, databaseId, filters, splits));
  },
  getSegmentPreview: (userKey, databaseId, filters, splits) => {
    dispatch(fetchSegmentPreview(userKey, databaseId, filters, splits));
  },
  getQueries: (userKey, databaseId) => {
    dispatch(fetchQueries(userKey, databaseId));
  },
  getQuery: (userKey, databaseId, queryId) => {
    dispatch(fetchQuery(userKey, databaseId, queryId));
  },
  getDatasetColumns: ({ userKey, databaseId }) => {
    dispatch(fetchDatasetColumns({ userKey, databaseId }));
  },
  newSaveHandler: ({
    userKey,
    databaseId,
    queryName,
    queryDescription,
    columns,
    defaultDateY,
  }) => {
    dispatch(
      createQuery({
        userKey,
        databaseId,
        queryName,
        queryDescription,
        columns,
        defaultDateY,
      })
    );
  },
  updateSaveHandler: ({
    userKey,
    databaseId,
    queryId,
    queryName,
    queryDescription,
    columns,
    defaultDateY,
  }) => {
    dispatch(
      updateQuery({
        userKey,
        databaseId,
        queryId,
        queryName,
        queryDescription,
        columns,
        defaultDateY,
      })
    );
  },
  deleteQueryHandler: (userKey, databaseId, queryId) => {
    dispatch(deleteQuery(userKey, databaseId, queryId));
  },
  changeDatabase: db => {
    dispatch(selectDatabase(db));
  },
  getRollupDefinitionsForDataSet: ({
    userKey,
    factDatabaseId,
    dataSetColumnId,
  }) => {
    dispatch(
      fetchRollupDefinitionsForDataSet({
        userKey,
        factDatabaseId,
        dataSetColumnId,
      })
    );
  },
  getAllRollupDefinitions: (userKey, databaseId) => {
    dispatch(fetchAllRollupDefinitions(userKey, databaseId));
  },
  getRollupDefinition: ({
    userKey,
    factDatabaseId,
    dataSetColumnId,
    rollupDefinitionId,
  }) => {
    dispatch(
      fetchRollupDefinition({
        userKey,
        factDatabaseId,
        dataSetColumnId,
        rollupDefinitionId,
      })
    );
  },
  createRollupDefinitionHandler: ({
    userKey,
    factDatabaseId,
    dataSetColumnId,
    rollupName,
    rollupDescription,
    allSelected,
    rollupData,
  }) => {
    dispatch(
      createRollupDefinition({
        userKey,
        factDatabaseId,
        dataSetColumnId,
        rollupName,
        rollupDescription,
        allSelected,
        rollupData,
      })
    );
  },
  updateRollupDefinitionHandler: ({
    userKey,
    factDatabaseId,
    rollupDefinitionId,
    rollupName,
    rollupDescription,
    allSelected,
    rollupData,
    dataSetColumnId,
  }) => {
    dispatch(
      updateRollupDefinition({
        userKey,
        factDatabaseId,
        rollupDefinitionId,
        rollupName,
        rollupDescription,
        allSelected,
        rollupData,
        dataSetColumnId,
      })
    );
  },
  deleteRollupDefinitionHandler: ({
    userKey,
    factDatabaseId,
    rollupDefinitionId,
    dataSetColumnId,
  }) => {
    dispatch(
      deleteRollupDefinition({
        userKey,
        factDatabaseId,
        rollupDefinitionId,
        dataSetColumnId,
      })
    );
  },
  clearErrorMessageHandler: () => {
    dispatch(clearErrorMessage());
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(QueriesTab);
