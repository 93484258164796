import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import moment from 'moment';
import * as Colors from '../../../utilities/colors';
import { getAppSettings } from '../../../config';
import { saveAs } from 'file-saver';
import { uuidv4 } from '../../../utilities/uuid';
import { notifyError } from '../../../utilities/notifier';
import { Table } from '@arius';

const style = {
  menuIcon: {
    fontSize: '20px',
    padding: '5px',
    color: Colors.darkGrey,
    cursor: 'pointer',
  },
  menuIconHover: {
    cursor: 'pointer',
    ':hover': {
      color: Colors.purple,
      transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
    },
  },
  comboIcon: {
    bottom: '10px',
    padding: '5px',
    color: Colors.darkGrey,
    right: '4px',
  },
  comboIconHover: {
    cursor: 'pointer',
    ':hover': {
      color: Colors.purple,
      transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
    },
  },
};

class ExtractDefinitionTableRow extends Component {
  static propTypes = {
    idx: PropTypes.number,
    extractDefinition: PropTypes.object,
    actionMenuCell: PropTypes.object,
    extractWithSelectedExtractDefinition: PropTypes.func,
    deleteExtractTableDefinition: PropTypes.func,
    editClickHandler: PropTypes.func,
    apiKey: PropTypes.string,
    currentWorkspace: PropTypes.object,
    extractKickingOff: PropTypes.bool,
    isFetchingWideCsv: PropTypes.bool,
    isFetchingNarrowCsv: PropTypes.bool,
    exportCsv: PropTypes.func,
    csv: PropTypes.object,
    userKey: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
      csvid: ''
    };
    this.downloadExtractCsv = this.downloadExtractCsv.bind(this);
    this.copyCsvUrlToClipboard = this.copyCsvUrlToClipboard.bind(this);
  }

  removeElement(arr, el) {
    var index = arr.indexOf(el);
    if (index > -1) {
      return arr.splice(index, 1);
    } else {
      return arr;
    }
  }

  componentDidUpdate() {
    const { csv } = this.props;
    const { csvid } = this.state;
    if (csv && csvid && csvid === csv.id) {
      this.setState({ csvid: '' });
      saveAs(csv.file, csv.fileName);
    }
  }
  
  downloadExtractCsv(format) {
    const { extractDefinition, apiKey, currentWorkspace, userKey, exportCsv } = this.props;
    const { csvid } = this.state;
    if (csvid) {
      return;
    }
    const id = uuidv4();
    this.setState({ csvid: id });
    exportCsv(userKey, id, format, currentWorkspace.name, extractDefinition.name, apiKey);
  }
  
  copyCsvUrlToClipboard(format) {
    const { extractDefinition, apiKey, currentWorkspace } = this.props;

    getAppSettings().then(settings => {
      const { baseServiceUrl } = settings;
      const url = 'https://' + baseServiceUrl + '/extract/' + format + '/' + currentWorkspace.name + '/' + extractDefinition.name + '?apikey=' + apiKey;

      const copyText = document.getElementById('csv-url');
      copyText.value = encodeURI(url);
      copyText.style.visibility = '';
      copyText.select();
      document.execCommand('Copy');
      copyText.style.visibility = 'hidden';
    });
  }

  render() {
    const {
      idx,
      extractWithSelectedExtractDefinition,
      extractDefinition: {
        id,
        name,
        description,
        lastModified,
        lastModifiedBy,
        lastSubmitted,
        lastSubmittedBy,
        count
      },
      actionMenuCell,
      editClickHandler,
      apiKey,
      deleteExtractTableDefinition,
      extractKickingOff,
      isFetchingWideCsv,
      isFetchingNarrowCsv
    } = this.props,
      extractIcon = extractKickingOff ? (
        <span
          className="fa-stack fa-sm"
          style={[style.comboIcon, style.comboIconHover]}
          key={`reload-extraction-table-${idx}`}>
          <i className="fa fa-refresh fa-stack-2x fa-spin fa-fw"></i>
          <span className="sr-only">Loading...</span>
          <i className="fa fa-table fa-stack-1x"></i>
        </span>
      ) : (
        <span
          className="fa-stack fa-sm"
          style={[style.comboIcon, style.comboIconHover]}
          key={`reload-extraction-table-${idx}`}>
          {/* <i className="fa fa-refresh fa-stack-2x"></i> */}
          <i className="fa fa-table fa-stack-1x"></i>
        </span>
      ),
      wideDownloadIcon = isFetchingWideCsv ? (
        <span
          className="fa-stack fa-sm"
          style={[style.comboIcon, style.comboIconHover]}
          key={`wide-download-${idx}`}>
          <i className="fa fa-refresh fa-stack-2x fa-spin fa-fw"></i>
          <span className="sr-only">Querying...</span>
          <i className="fa fa-download fa-stack-1x"></i>
        </span>
      ) : (
        <span
          className="fa-stack fa-sm"
          style={[style.comboIcon, style.comboIconHover]}
          key={`wide-download-${idx}`}>
          {/* <i className="fa fa-refresh fa-stack-2x"></i> */}
          <i className="fa fa-download fa-stack-1x"></i>
        </span>
      ),
      narrowDownloadIcon = isFetchingNarrowCsv ? (
        <span
          className="fa-stack fa-sm"
          style={[style.comboIcon, style.comboIconHover]}
          key={`narrow-download-${idx}`}>
          <i className="fa fa-refresh fa-stack-2x fa-spin fa-fw"></i>
          <span className="sr-only">Querying...</span>
          <i className="fa fa-download fa-stack-1x"></i>
        </span>
      ) : (
        <span
          className="fa-stack fa-sm"
          style={[style.comboIcon, style.comboIconHover]}
          key={`narrow-download-${idx}`}>
          {/* <i className="fa fa-refresh fa-stack-2x"></i> */}
          <i className="fa fa-download fa-stack-1x"></i>
        </span>
      ),
      actionMenu = (
        <Popover id={`menu-popover-${idx}`}>
<span onClick={() => extractWithSelectedExtractDefinition(id)}>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id={`extract-definition-tooltip-${idx}`}>Update Extract Table</Tooltip>
                }
              >
                {extractIcon}
              </OverlayTrigger>
            </span>
            <span onClick={() => editClickHandler(id)}>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Edit Definition</Tooltip>}
              >
                <i
                  key={`555-${idx}`}
                  style={[style.menuIcon, { position: 'relative', bottom: '3px' }]}
                  className="fa fa-pencil"
                />
              </OverlayTrigger>
            </span>
            <span onClick={() =>apiKey === null || apiKey === ""? notifyError("You must have API key assigned") :this.downloadExtractCsv('wide')}>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Download wide format CSV</Tooltip>}
              >
                {wideDownloadIcon}
              </OverlayTrigger>
            </span>
            <span onClick={() => apiKey === null || apiKey === ""? notifyError("You must have API key assigned") :this.copyCsvUrlToClipboard('wide')}>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Copy wide CSV url to clipboard</Tooltip>}
              >
                <i
                  key={"clipboard-wide-csv-icon"}
                  style={[style.menuIcon, style.menuIconHover]}
                  className="material-icons"
                >
                  content_copy
                </i>
              </OverlayTrigger>
            </span>
            <span onClick={() =>apiKey === null || apiKey === ""? notifyError("You must have API key assigned") : this.downloadExtractCsv('narrow')}>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Download narrow format CSV</Tooltip>}
              >
                {narrowDownloadIcon}
              </OverlayTrigger>
            </span>
            <span onClick={() =>apiKey === null || apiKey === ""? notifyError("You must have API key assigned") : this.copyCsvUrlToClipboard('narrow')}>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Copy narrow CSV url to clipboard</Tooltip>}
              >
                <i
                  key={"clipboard-narrow-csv-icon"}
                  style={[style.menuIcon, style.menuIconHover]}
                  className="material-icons"
                >
                  content_copy
                </i>
              </OverlayTrigger>
            </span>
            <span onClick={() => deleteExtractTableDefinition(id)}>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="tooltip">Delete Table</Tooltip>}
              >
                <i
                  key={"delete-icon"}
                  style={[style.menuIcon, style.menuIconHover]}
                  className="material-icons"
                >
                  delete
                </i>
              </OverlayTrigger>
            </span>
        </Popover>
    );

    return (
      <Table.tr key={`extract-definition-table-${name}-${idx}`} pointer='false'>
        <Table.td width='40%'>{name}</Table.td>
        <Table.td width='60%'>{description}</Table.td>
       <Table.td width='130px'>{lastModified && moment.utc(lastModified).local().format('L LT')}</Table.td>
        <Table.td width='130px'>{lastModifiedBy}</Table.td>
        <Table.td width='130px'>{lastSubmitted && moment.utc(lastSubmitted).local().format('L LT')}</Table.td>
        <Table.td width='130px'>{lastSubmittedBy}</Table.td>
        <Table.td width='100px' style={{textAlign: 'right'}}>{count.toLocaleString()}</Table.td>
        <Table.td width='120px' style={{ ...{ paddingRight: '18px'}}}>
        <div style={actionMenuCell}>
            <OverlayTrigger  trigger="click" placement="auto" overlay={actionMenu} rootClose={true} >
                <i
                    id={`action-overlay-${idx}`}
                    key={`action-items-${idx}`}
                    onClick={(e) => e.stopPropagation()}
                    style={[style.menuIcon, style.menuIconHover]}
                    className="material-icons"
                >
                    more_horiz
                </i>
            </OverlayTrigger>
        </div>
        </Table.td>
      </Table.tr>
    );
  }
}

export default radium(ExtractDefinitionTableRow);
