import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';
import { alphabetize } from '../../../utilities/helpers';
import { retrieveExportsByListType, deleteExportListItem } from '../../../actions/analysis/project.actions';
import { showModal } from '../../../actions/modal.actions';
import { Grid, Button } from '@arius';
import Loader from '@app/shared/presentational/loader';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';

const style = {
    addIcon: {
      color: Colors.darkGrey,
      borderRadius: '5px',
      marginRight: '5vw',
      cursor: 'pointer',
      fontSize: '4.5vmin',
      ':hover': {
        backgroundColor: Colors.purple,
        color: 'white',
        transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
      },
    },
    searchIcon: {
      // visibility: 'hidden',
      // pointerEvents: 'none',
      color: Colors.darkGrey,
      position: 'relative',
      padding: '6px',
      bottom: '3px',
      borderRadius: '5px',
      marginBottom: '2px',
      cursor: 'pointer',
      fontSize: '2vmin',
      ':hover': {
        backgroundColor: Colors.purple,
        color: 'white',
        transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
      },
    },
  },
  listType = 'ExtractTable';

class ExportLists extends Component {
  static propTypes = {
    exportList: PropTypes.array,
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
    currentWorkspace: PropTypes.object,
    isFetchingExportList: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      sortedBy: 'listName',
      ascending: true,
      exportList: [],
    };
    this.sortList = this.sortList.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.getGrid = this.getGrid.bind(this);
    this.getActionItems = this.getActionItems.bind(this);
  }

  componentWillMount() {
    const { userKey, dispatch, currentWorkspace, exportList } = this.props,
      { ascending } = this.state;
    if (currentWorkspace) {
      dispatch(
        retrieveExportsByListType({ 
          userKey, 
          databaseId: currentWorkspace.id || currentWorkspace.workspaceId,
          listType,
        })
      );
    }
    if (exportList) {
      this.setState({ exportList: alphabetize(exportList, 'listName', ascending) });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { userKey, dispatch, currentWorkspace, exportList } = this.props,
      { currentWorkspace: nextWorkspace, exportList: nextExportList } = nextProps,
      { ascending } = this.state;
    if (nextWorkspace && currentWorkspace !== nextWorkspace) {
      dispatch(
        retrieveExportsByListType({ 
          userKey, 
          databaseId: nextWorkspace.id || nextWorkspace.workspaceId,
          listType,
        })
      );
    }
    if (exportList !== nextExportList) {
      this.setState({ exportList: alphabetize(nextExportList, 'listName', ascending) });
    }
  }

  sortList(list, property) {
    const { sortedBy } = this.state;
    let { ascending } = this.state;
    if (sortedBy === property) {
      ascending = !ascending;
    }
    this.setState({ exportList: alphabetize(list, property, ascending), ascending, sortedBy: property });    
  }

  // deleteItem(exportListId) {
  //   const { dispatch, userKey, currentWorkspace } = this.props;
  //   dispatch(deleteExportListItem({ userKey, exportListId, databaseId: currentWorkspace.id || currentWorkspace.workspaceId, listType }));
  // }

  deleteItem(exportListId) {
    const { dispatch, userKey, currentWorkspace, exportList } = this.props;

    if (exportList.length !== 0) {
      const selectedExport = exportList.find(
        def => def.exportListId === exportListId
      ),
        deleteMessageItems = [
          <li key={exportListId} style={{ fontWeight: 800 }}>
            {selectedExport.listName}
          </li>,
        ];

      deleteMessageItems.unshift(
        'Are you sure you want to delete the following item?'
      );

      const yesClickHandler = () => {
        dispatch(deleteExportListItem({ userKey, exportListId, databaseId: currentWorkspace.id || currentWorkspace.workspaceId, listType }));
      };
      const noClickHandler = () => {},
        action = showModal(
          'confirmation',
          deleteMessageItems,
          yesClickHandler,
          noClickHandler
        );
      dispatch(action);
    }
  }
  
    
    getActionItems(row) {
        return  (
            <Button 
                iconName="delete" 
                onClick={(e) => {this.deleteItem(row.exportListId)}}
                toolTip='Delete Export List'/>     
        );
    }

    getGrid(data) {
        let columns = [
            { field: 'listName', headerText: 'Name'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By'},
            { field: 'lastModifiedDate', headerText: 'Last Modified Date', type:'datetime'},
            { headerText: 'Actions', width: '100px', template: this.getActionItems},
        ];

        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)'/>
    }

  render() {
    const { exportList } = this.state,
      { isFetchingExportList, currentWorkspace } = this.props,
      titleRow = (
        <div
          style={{
            borderBottom: '2px solid #BDBDBD',
            margin: 'initial',
            height: '75px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div style={{ margin: 'initial', paddingLeft: 15, display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <OverlayTrigger
                placement="top"
                focus
                overlay={
                  <Tooltip id="searcIconTooltip">Open search filter</Tooltip>
                }
              >
                <i 
                  className="fa fa-search"
                  key="projects-search-filter-icon"
                  onClick={(e) => this.toggleSearchFilter(e)}
                  style={[style.searchIcon, { visibility: 'hidden', pointerEvents: 'none' }]}></i>
              </OverlayTrigger>
              &nbsp;
              <h3>
                Extract Table Lists
              </h3>
            </div>
          </div>
          <div>
            <OverlayTrigger
              placement="top"
              focus
              overlay={
                <Tooltip id="addNewProjectTooltip">not currently available</Tooltip>
              }
            >
              <i
                id="add-project-plus-icon"
                style={[style.addIcon, { visibility: 'hidden', pointerEvents: 'none' }]}
                className="material-icons"
              >
                add
              </i>
            </OverlayTrigger>
          </div>
        </div>
      );
      let renderTable;
      if (exportList && exportList.length) {
        renderTable = this.getGrid(exportList);
      } else if (isFetchingExportList) {
        renderTable = (
          <div style={{ width: '85vw', margin: '5vh auto' }}>
            <Loader loadingItem="Export List" />
          </div>
        );
      } else if (!currentWorkspace) {
        renderTable = (
          <div
            style={{ width: '85vw', margin: '5vh auto' }}>
            <PlaceHolderMessage message={'Please select a database'} />
          </div>
        );
      }

    return (
      <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
        <div style={{ width: '85vw', maxHeight: 'calc(100vh - 106px)' }}>
          {titleRow}
          {renderTable}
        </div>
      </div>
    );
  }
}

export default radium(ExportLists);
