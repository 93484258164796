import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import ExtractDefinitionTable from './extractDefinitionTable';
import { showModal } from '../../../actions/modal.actions';
import { extractWithSelectedExtractDefinition } from '../../../actions/analysis/reporting.actions';
import { alphabetize } from '../../../utilities/helpers';
import { notifyError } from '../../../utilities/notifier';
import { MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import { Button } from '@arius';

const style = {
    clickable: {
        cursor: 'pointer',
    },
    statusLink: {
        display: 'flex',
        alignSelf: 'inherit',
        margin: '10px 0px 0px 20px',
        color: '#337AB7',
        fontWeight: 'lighter',
        textDecoration: 'underline',
        fontSize: 'small',
    },
};

class ExtractList extends Component {
    static propTypes = {
        selectSideNavOption: PropTypes.func,
        extractDefinitions: PropTypes.array,
        isFetching: PropTypes.bool,
        firstSearchPerformed: PropTypes.bool,
        extractKickingOff: PropTypes.bool,
        isFetchingWideCsv: PropTypes.bool,
        isFetchingNarrowCsv: PropTypes.bool,
        userKey: PropTypes.string,
        databaseId: PropTypes.number,
        dispatch: PropTypes.func,
        editClickHandler: PropTypes.func,
        deleteExtractDefinitionHandler: PropTypes.func,
        currentWorkspace: PropTypes.object,
        apiKey: PropTypes.string,
        exportCsv: PropTypes.func,
        csv: PropTypes.object,   
        canCreateEditExtractTable: PropTypes.bool,
        canDeleteExtractTable: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
        sortedBy: 'name',
        extractDefinitions: [],
        ascending: true,
        };
        this.extract = this.extract.bind(this);
        this.sortList = this.sortList.bind(this);
        this.deleteExtractTableDefinition = this.deleteExtractTableDefinition.bind(this);
    }

    componentWillMount() {
        const { extractDefinitions } = this.props,
        { ascending } = this.state;

        if (extractDefinitions) {
        this.setState({ extractDefinitions: alphabetize(extractDefinitions, 'name', ascending) });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { extractDefinitions } = this.props,
        { extractDefinitions: nextExtractDefinitions } = nextProps,
        { ascending } = this.state;
        
        if (extractDefinitions !== nextExtractDefinitions) {
        this.setState({ extractDefinitions: alphabetize(nextExtractDefinitions, 'name', ascending) });
        }
    }

    extract(definitionId) {
        const { userKey, databaseId, dispatch } = this.props;
        dispatch(extractWithSelectedExtractDefinition({ userKey, databaseId, definitionId }));
    }

    deleteExtractTableDefinition(id) {
        const { dispatch, extractDefinitions, deleteExtractDefinitionHandler, databaseId, canDeleteExtractTable } = this.props;

        if (!canDeleteExtractTable) {
        notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
        return;
        }

        if (extractDefinitions.length !== 0) {
        const selectedExtractDefinition = extractDefinitions.find(
            def => def.id === id
        ),
            deleteMessageItems = [
            <li key={id} style={{ fontWeight: 800 }}>
                {selectedExtractDefinition.name}
            </li>,
            ];

        deleteMessageItems.unshift(
            'Are you sure you want to delete the following extract table definition?'
        );

        const yesClickHandler = () => {
            const { userKey } = this.props;
            dispatch(deleteExtractDefinitionHandler({ userKey, databaseId, definitionId: id }));
        };
        const noClickHandler = () => {},
            action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
            );
        dispatch(action);
        }
    }

    sortList(list, property) {
        const { sortedBy } = this.state;
        let { ascending } = this.state;
        if (sortedBy === property) {
        ascending = !ascending;
        }
        this.setState({ extractDefinitions: alphabetize(list, property, ascending), ascending, sortedBy: property });    
    }

    navToStatus() {
        const { databaseId, browserHistory } = this.props;
        if (databaseId) {
        browserHistory.push(`/analysis/automation/status/${databaseId}`);
        }
    }

    render() {
        const { selectSideNavOption,
        isFetching,
        firstSearchPerformed,
        editClickHandler,
        extractKickingOff,
        currentWorkspace,
        apiKey,
        exportCsv,
        csv,
        userKey,
        isFetchingWideCsv,
        isFetchingNarrowCsv } = this.props,
        { sortedBy, ascending, extractDefinitions } = this.state,
        statusLink = (
            <span style={[style.clickable]} onClick={() => this.navToStatus()}>
            See status reports
            </span>
        );


        return (
            <div className="list-container-arius">
                <div className="list-header-arius">
                    <span style={{display:'flex'}}>
                        <h4>Extract Tables</h4>
                        <div style={{ ...style.statusLink }}>
                            {statusLink}
                        </div>
                    </span>
                    <Button
                        toolTip = "Create new table definition"
                        mode="add"
                        onClick={() => {
                            const { canCreateEditExtractTable } = this.props;
                            if (!canCreateEditExtractTable) {
                                notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
                                return;
                            }
                            selectSideNavOption('create_extract'); 
                        }}
                    />
                </div>
                <ExtractDefinitionTable
                    sortList={this.sortList}
                    sortedBy={sortedBy}
                    ascending={ascending}
                    currentWorkspace={currentWorkspace}
                    extractKickingOff={extractKickingOff}
                    isFetchingWideCsv={isFetchingWideCsv}
                    isFetchingNarrowCsv={isFetchingNarrowCsv}
                    firstSearchPerformed={firstSearchPerformed}
                    isFetching={isFetching}
                    extractDefinitions={extractDefinitions}
                    editClickHandler={editClickHandler}
                    apiKey={apiKey}
                    exportCsv={exportCsv}
                    csv={csv}
                    deleteExtractTableDefinition={this.deleteExtractTableDefinition}
                    extractWithSelectedExtractDefinition={this.extract}
                    userKey={userKey} />
            </div>
        )
    }
}

export default radium(ExtractList);
