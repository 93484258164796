import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import * as GlobalStyles from '../../../utilities/globalStyles';
import { notifySuccess } from '../../../utilities/notifier';
import {
  fetchSubscriptionUser,
} from '../../../actions/portalManagement/subscriptionUser.actions';
import { fetchGroups } from '../../../actions/portalManagement/group.actions';
import { notAuthorized } from '../../shared/containers/conditionalLink';
import SaveButtons from '../../shared/presentational/saveButtons';

class UserAddUpdate extends Component {
  static propTypes = {
    admin: PropTypes.bool,
    userName: PropTypes.string,
    roles: PropTypes.array,
    users: PropTypes.object,
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
    params: PropTypes.object,
    newSaveHandler: PropTypes.func,
    editSaveHandler: PropTypes.func,
    groups: PropTypes.array,
    isUserUpdated: PropTypes.bool,
    readOnly: PropTypes.bool,
  };
  static defaultProps = {
    availableRoles: [],
    assignedRoles: [],
    admin: false,
    userName: '',
  };
  constructor() {
    super();
    this.state = {
      availableGroups: [],
      assignedGroups: [],
      admin: false,
      defaultAvailableGroups: [],
      defaultAssignedGroups: [],
      userName: '',
      userDescription: '',
      selectedUser: null,
      userId: null,
      userEmail: '',
      editing: false,
      userRoles: [],
      userGroups: [],
      isUpdated: false,
    };
    this.saveUser = this.saveUser.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.groupsListboxChangeHandler = this.groupsListboxChangeHandler.bind(
      this,
    );
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.backToUsers = this.backToUsers.bind(this);
  }

  componentDidMount() {
    const { params, dispatch, userKey, browserHistory } = this.props,
      userId = params.userId,
      editing = !!userId;
      
    if (editing && userId) {
      dispatch(fetchSubscriptionUser(userKey, userId));
    }

    if (this.props.readOnly) {
      browserHistory.replace('/portalManagement/users');
    }
    dispatch(fetchGroups(userKey));
    this.setState({ userId, editing });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.users && prevProps.users) {
      const { users } = this.props;
      if (
        users.subscriptionUser &&
        prevProps.users.subscriptionUser !== users.subscriptionUser
      ) {
        const user = users.subscriptionUser;
        this.setState({
          userName: user.name,
          userDescription: user.description,
          userEmail: user.email,
          userId: user.userId,
          userRoles: user.roles,
          userGroups: user.groups,
        });
      }
    }

    if (
      (this.props.groups && this.props.groups !== prevProps.groups) ||
      prevState.userGroups !== this.state.userGroups
    ) {
      this.filterUserGroups();
    }

    if (
      this.props.isUserUpdated !== prevProps.isUserUpdated &&
      this.props.isUserUpdated &&
      this.state.isUpdated
    ) {
      this.setState({ isUpdated: true });
      notifySuccess('User Updated!');
    }
  }

  filterUserGroups() {
    const { userId, userGroups } = this.state;
    if (userId && userGroups) {
      let defaultAssignedGroups = this.props.groups.filter(
        group => !!userGroups.find(g => g === group.groupId),
      ),
        defaultAvailableGroups = this.props.groups.filter(
          group => !userGroups.find(g => g === group.groupId),
        );
      defaultAssignedGroups = this.mapGroupsToListBoxItems(
        defaultAssignedGroups,
      );
      defaultAvailableGroups = this.mapGroupsToListBoxItems(
        defaultAvailableGroups,
      );
      this.setState({
        defaultAssignedGroups,
        defaultAvailableGroups,
      });
    } else {
      this.setState({
        defaultAssignedGroups: [],
        defaultAvailableGroups: this.mapGroupsToListBoxItems(this.props.groups),
      });
    }
  }

  mapRolesToListBoxItems(roles) {
    return roles.map(role => {
      const r = {
        name: role.name,
        value: role.roleId,
      };
      return r;
    });
  }

  mapGroupsToListBoxItems(groups) {
    return groups.map(group => {
      const gr = {
        name: group.name,
        value: group.groupId,
      };
      return gr;
    });
  }

  groupsListboxChangeHandler(assignedItems, availableItems) {
    const isUpdated = !(assignedItems === this.state.defaultAssignedGroups &&
      availableItems === this.state.defaultAvailableGroups);
    this.setState({
      isUpdated,
      assignedGroups: assignedItems,
      availableGroups: availableItems,
    });
  }

  handleUserNameChange(e) {
    this.setState({ userName: e.target.value, isUpdated: true });
  }

  handleDescriptionChange(e) {
    this.setState({ userDescription: e.target.value, isUpdated: true });
  }

  handleEmailChange(e) {
    this.setState({ userEmail: e.target.value, isUpdated: true });
  }

  backToUsers() {
    const { browserHistory } = this.props;
    browserHistory.push('/portalManagement/users');
  }

  saveUser() {
    const {
      userDescription,
      userName,
      userEmail,
      userId,
      editing,
      assignedGroups,
      isUpdated,
      userRoles,
    } = this.state,
      { userKey, readOnly, browserHistory } = this.props;
    if (readOnly) {
      notAuthorized();
    } else if (userKey && isUpdated) {
      if (!editing) {
        this.props.newSaveHandler({
          userKey,
          description: userDescription,
          name: userName,
          email: userEmail,
          roles: userRoles,
          groups: assignedGroups.map(g => g.value),
          browserHistory
        });
      } else {
        this.props.editSaveHandler({
          userId,
          userKey,
          description: userDescription,
          name: userName,
          email: userEmail,
          roles: userRoles,
          groups: assignedGroups.map(g => g.value),
        });
      }
    }
  }

  render() {
    const {
      userName,
      userEmail,
      userDescription,
      editing,
    } = this.state;

    return (
      <div>
        <div style={{ width: '100vw', padding: '0vh 10vw', overflowY: 'auto', height: 'calc(100vh - 106px)' }}>
          <div
            style={{
              borderBottom: '2px solid #BDBDBD',
              height: 75,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {editing
              ? <h2 style={{ paddingLeft: 15 }}>Edit User</h2>
              : <h2 style={{ paddingLeft: 15 }}>Add User</h2>}
          </div>
          <div style={[
            GlobalStyles.card,
            { 
              display: 'flex',
              flexFlow: 'column nowrap',
              width: '75vw',
              padding: '50px',
              overflowY: 'auto',
            },
          ]}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-group">
                <label htmlFor="userName">Name</label>
                <input
                  id="userName"
                  value={userName}
                  onChange={this.handleUserNameChange}
                  className="form-control"
                  type="text"
                  style={{ margin: 10 }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-group">
                <label htmlFor="userName">Email</label>
                <input
                  id="userName"
                  value={userEmail}
                  onChange={this.handleEmailChange}
                  className="form-control"
                  type="email"
                  style={{ margin: 10 }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="form-group">
                <label htmlFor="userDescription">Description</label>
                <textarea
                  id="userDescription"
                  value={userDescription}
                  onChange={this.handleDescriptionChange}
                  className="form-control"
                  type="text"
                  style={{ margin: 10, resize: 'vertical' }}
                />
              </div>
            </div>
          </div>
        </div>
        <SaveButtons
          saveHandler={this.saveUser}
          backButtonHander={this.backToUsers}
          backButtonText="Back to Users"
        />
      </div>
    );
  }
}

export default radium(UserAddUpdate);
