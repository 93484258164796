import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Table, Button as AButton } from '@arius';

const style = {
    tableCellContent: {
        height: 'initial',
        fontSize: 12,
        paddingTop: 2,
        paddingRight: 2,
        paddingBottom: 2,
        paddingLeft: 8,
    },
    cellPositioning: {
        margin: 0,
        verticalAlign: 'middle',
    },
};

class ReviewListTableRow extends Component {
    static propTypes = {
        collection: PropTypes.object,
        idx: PropTypes.number,
        deleteItem: PropTypes.func,
        workSpaceId: PropTypes.number,
    };
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            data,
            editHandler,
            deleteHandler
        } = this.props;

        const { tr:Tr, td:Td } = Table;

        return (
            <Tr>
                <Td width='60px'>{data.level}</Td>
                <Td>{data.description}</Td>
                <Td width='100px' style={{...style.tableCellContent, ...style.cellPositioning}}>
                    <AButton toolTip="Edit" placement="left" iconName="edit" onClick={(e) => editHandler(data)} />
                    { data.canDelete ? (<AButton toolTip="Delete" placement="left" iconName="delete" onClick={(e) => deleteHandler(data)} />): ('')}
                </Td>
            </Tr>
        );
    }
}

export default radium(ReviewListTableRow);
