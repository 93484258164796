import {
  RECEIVED_ASSIGNED_ROLES,
  FETCHING_ASSIGNED_ROLES,
  FETCHING_AVAILABLE_ROLES,
  RECEIVED_ROLE_DESCRIPTIONS,
  FETCHING_ROLE_DESCRIPTIONS,
  RECEIVED_AVAILABLE_PERMISSIONS,
  FETCHING_AVAILABLE_PERMISSIONS,
  RECEIVED_AVAILABLE_USERS,
  RECEIVED_AVAILABLE_RESOURCES,
  FETCHING_AVAILABLE_RESOURCES,
  FETCHING_AVAILABLE_USERS,
  CLEAR_AVAILABLE_PERMISSIONS,
  UPDATED_USER_ROLE,
  RECEIVED_ROLE_DESCRIPTION,
  ASSIGNING_ROLES,
  CLEAR_ROLE_DESCRIPTION,
} from '../../actions/portalManagement/portalManagement.actionTypes';

const rolesReducer = (
  state = {
    isFetching: false,
    isUpdated: false,
    assignedRoles: [],
    roleDescriptions: [],
    availablePermissions: [],
    availableResources: [],
    availableUsers: [],
    selectedRole: {},
  },
  action,
) => {
  switch (action.type) {
    case FETCHING_ASSIGNED_ROLES:
    case FETCHING_AVAILABLE_ROLES:
    case FETCHING_ROLE_DESCRIPTIONS:
    case FETCHING_AVAILABLE_PERMISSIONS:
    case FETCHING_AVAILABLE_RESOURCES:
    case FETCHING_AVAILABLE_USERS:
    case ASSIGNING_ROLES:
      return Object.assign({}, state, { isFetching: true });
    case RECEIVED_ASSIGNED_ROLES:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        assignedRoles: action.roles,
      });
    case RECEIVED_ROLE_DESCRIPTIONS:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        roleDescriptions: action.roles,
      });
    case RECEIVED_ROLE_DESCRIPTION: {
      const role = state.roleDescriptions.find(r => r.roleId === action.roleId);
      if (!role) {
        return state;
      }
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        selectedRole: { ...role, permissions: action.permissions },
      });
    }
    case CLEAR_ROLE_DESCRIPTION: {
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        selectedRole: null,
      });
    }
    case RECEIVED_AVAILABLE_PERMISSIONS:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        availablePermissions: action.permissions,
      });
    case RECEIVED_AVAILABLE_RESOURCES:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        availableResources: action.resources,
      });
    case RECEIVED_AVAILABLE_USERS:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        availableUsers: action.users,
      });
    case CLEAR_AVAILABLE_PERMISSIONS:
      return Object.assign({}, state, {
        isFetching: false,
        isUpdated: false,
        availablePermissions: [],
      });
    case UPDATED_USER_ROLE:
      return Object.assign({}, state, {
        isUpdated: false,
      });
    default:
      return state;
  }
};

export default rolesReducer;
