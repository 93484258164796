import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import {
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';
import * as GlobalStyles from '../../../utilities/globalStyles';
import { Link } from 'react-router-dom';
import { notifyError } from '../../../utilities/notifier';
import { validateName } from '../../../utilities/validators';
import ColumnRow from './columnRow';
import SaveButtons from '../../shared/presentational/saveButtons';
import CreateAEDatabaseModal from './createAEDatabaseModal';
import {
  createPermissionChecker,
  SITE_CREATEDATABASE,
} from '../../../utilities/permissions';
import { resetScrollTop } from '../../../utilities/helpers';

const style = {
  addIcon: {
    color: Colors.darkGrey,
    borderRadius: '5px',
    marginRight: '5vw',
    cursor: 'pointer',
    fontSize: '4.5vmin',
    ':hover': {
      backgroundColor: Colors.purple,
      color: 'white',
      transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
    },
  },
  menuIcon: {
    fontSize: '20px',
    padding: '5px',
    color: Colors.darkGrey,
    cursor: 'pointer',
  },
  tableRow: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
  },
  tableBody: {
    display: 'block',
    maxHeight: 400,
    overflow: 'auto',
  },
  cellPositioning: {
    margin: 0,
    verticalAlign: 'middle',
  },
};

class CreateAriusAnalysisDatabasePage extends Component {
  static propTypes = {
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        workspaceId: PropTypes.number,
        workspaceName: PropTypes.string,
        workspaceDesc: PropTypes.string,
        workspaceDims: PropTypes.string,
      })
    ),
    userKey: PropTypes.string.isRequired,
    isFetching: PropTypes.bool,
    newSaveHandler: PropTypes.func,
    getWorkspaces: PropTypes.func,
    current: PropTypes.object,
    currentTodDatabase: PropTypes.object,
    clearNotificationHandler: PropTypes.func,
    serverNotification: PropTypes.object,
    getUploads: PropTypes.func,
    errorMessage: PropTypes.string,
    clearErrorMessageHandler: PropTypes.func,
    getDatabaseDimensions: PropTypes.func,
    todDatabases: PropTypes.array,
    userPermissions: PropTypes.array,
    params: PropTypes.object,
    updateSaveHandler: PropTypes.func,
    getWorkspace: PropTypes.func,
  };
  static defaultProps = {
    userKey: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      databaseColumns: [],
      databaseDesc: '',
      databaseName: '',
      isEditing: false,
      databases: [],
      selectedDatabase: null,
      allColumnsSelected: false,
      isCreateModalOpen: false,
      selectedColumnIndex: null,
      selectedColumn: null,
      softErrorMode: true,
      uploads: [],
      serverNotification: null,
      userPermissions: [],
      workspaceId: null,
      workspace: null,
    };
    if (props.params && props.params.id) {
      this.state.isEditing = true;
      this.state.workspaceId = props.params.id;
    }
    this.descChangeHandler = this.descChangeHandler.bind(this);
    this.nameChangeHandler = this.nameChangeHandler.bind(this);
    this.addColumn = this.addColumn.bind(this);
    this.deleteColumn = this.deleteColumn.bind(this);
    this.saveDatabase = this.saveDatabase.bind(this);
    this.getDatabaseColumns = this.getDatabaseColumns.bind(this);
    this.changeColumnName = this.changeColumnName.bind(this);
    this.closeCreateModal = this.closeCreateModal.bind(this);
    this.openCreateModal = this.openCreateModal.bind(this);
    this.selectAllColumns = this.selectAllColumns.bind(this);
    this.deleteSelectedColumns = this.deleteSelectedColumns.bind(this);
    this.validateColumnName = this.validateColumnName.bind(this);
    this.getNameValidationError = this.getNameValidationError.bind(this);
    this.compare = this.compare.bind(this);
  }

  componentDidMount() {
    const { todDatabases, userPermissions, getWorkspace, userKey, browserHistory } = this.props,
      { isEditing, workspaceId } = this.state;
    if (isEditing) {
      getWorkspace(userKey, workspaceId);
    }

    if (todDatabases) {
      // const databases = todDatabases.sort(this.compare);
      this.setState({ databases: todDatabases });
    }

    if (userPermissions && userPermissions.length) {
      if (userPermissions.indexOf(SITE_CREATEDATABASE) === -1) {
        browserHistory.push('/unauthorized');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { current } = this.props, newState = this.state;

    if (current !== nextProps.current) {
      const newWorkspace = nextProps.current;
      newState.workspace = newWorkspace;
      if (newWorkspace) {
        newState.databaseName = newWorkspace.name;
        newState.databaseDesc = newWorkspace.description;

        // newWorkspace.dimensions
      }
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps) {
    const { todDatabases, currentTodDatabase, userPermissions, browserHistory } = this.props;
    if (
      todDatabases &&
      todDatabases.length > 0 &&
      !prevProps.todDatabases.length > 0
    ) {
      // const databases = todDatabases.sort(this.compare);
      this.setState({ databases: this.props.todDatabases });
    }

    if (
      currentTodDatabase &&
      currentTodDatabase !== prevProps.currentTodDatabase &&
      this.state.selectedDatabase
    ) {
      this.populateColumnsFromTodDatabase(currentTodDatabase);
    }

    if (
      userPermissions &&
      userPermissions.length &&
      userPermissions !== prevProps.userPermissions
    ) {
      if (userPermissions.indexOf(SITE_CREATEDATABASE) === -1) {
        browserHistory.push('/unauthorized');
      }
    }
  }

  getDatabaseColumns() {
    const { selectedDatabase } = this.state,
      { getDatabaseDimensions, userKey } = this.props;

    if (selectedDatabase) {
      getDatabaseDimensions(userKey, selectedDatabase.workspaceId);
    } else {
      notifyError('No database selected');
    }
  }

  getNameValidationError(soft) {
    const { databaseName, workspaceId } = this.state,
      { workspaces } = this.props;

    let trimmed = databaseName.trim();
    // const { current } = this.props; this needs to be added in order to ensure that if we have a current working database in edit mode
    // it doesn't mess up the validation by saying this already exists, change after adding an edit mode

    const error = validateName(trimmed, soft, false, true);

    if (error !== '') {
      return error;
    }

    trimmed = trimmed.toLowerCase();
    const found = workspaces.find(
      ws => ws.workspaceName.toLowerCase() === trimmed
    );
    if (found && found.workspaceId != workspaceId) { // eslint-disable-line eqeqeq
      return 'Already exists';
    }

    return '';
  }

  setSoftErrorMode(mode) {
    const { softErrorMode } = this.state;
    if (softErrorMode !== mode) {
      this.setState({ softErrorMode: mode });
    }
  }

  nameChangeHandler(newName) {
    let databaseName = newName;
    if (databaseName && typeof databaseName !== 'string') {
      databaseName = '';
    }
    this.setState({ databaseName });
  }

  descChangeHandler(e) {
    this.setState({ databaseDesc: e.target.value });
  }

  addColumn() {
    // const { current } = this.props;
    const scrollTop = document.getElementById('create-aa-container').scrollTop;
    const databaseColumns = this.state.databaseColumns.slice();
    const columnType = 'dimension';
    // if (current) {
    //   columnType = current.hasDataLoaded ? 'calc_measure' : 'measure';
    // }
    databaseColumns.push({
      factColumnDisplayName: '',
      columnType,

      // formula: '',
    });
    this.setState({ databaseColumns }, () => resetScrollTop('create-tod-container', scrollTop));
  }

  deleteColumn(idx) {
    const { databaseColumns } = this.state;
    databaseColumns.splice(idx, 1);
    this.setState({ databaseColumns });
  }

  deleteSelectedColumns() {
    let { databaseColumns } = this.state;
    databaseColumns = databaseColumns.filter(col => !col.isSelected);
    this.setState({ databaseColumns, allColumnsSelected: false });
  }

  changeColumnName(index, newName) {
    let { databaseColumns } = this.state;
    const column = databaseColumns.find((c, idx) => idx === index);
    if (column) {
      databaseColumns = databaseColumns.map((col, idx) => {
        const c = col;
        if (idx === index) {
          c.factColumnDisplayName = newName;
        }
        return c;
      });
      this.setState({ databaseColumns });
    }
  }

  changeColumnType(index, e) {
    let { databaseColumns } = this.state;
    const column = databaseColumns.find((c, idx) => idx === index);
    if (column) {
      databaseColumns = databaseColumns.map((col, idx) => {
        const c = col;
        if (idx === index) {
          c.columnType = e.target.value;
        }
        return c;
      });
      this.setState({ databaseColumns });
    }
  }

  populateColumnsFromTodDatabase(currentTodDatabase) {
    const { databaseColumns } = this.state;

    if (currentTodDatabase) {
      currentTodDatabase.columns.forEach(dbc => {
        if (dbc.columnType === 'dimension') {
          databaseColumns.push({
            factColumnDisplayName: dbc.factColumnDisplayName,
          });
        }
      });
      this.setState({ databaseColumns });
    }
  }

  compare(a, b) {
    const nameA = a.workspaceName.toLowerCase(),
      nameB = b.workspaceName.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  }

  saveDatabase() {
    const {
      databaseColumns,
      databaseDesc,
      databaseName,
      isEditing,
    } = this.state,
      { newSaveHandler, userKey, updateSaveHandler } = this.props;
    if (!this.validate(false)) {
      this.setSoftErrorMode(false);
      return;
    }

    let workspaceId, createdBy, updatedBy;

    if (isEditing) {
      updateSaveHandler({
        workspaceName: databaseName,
        workspaceDescription: databaseDesc,
        workspaceDimensions: databaseColumns.map(
          dbc => dbc.factColumnDisplayName
        ),
        userKey,
        createdBy: this.state.workspace.createdBy,
        updatedBy: this.state.workspace.updatedBy,
        workspaceId: this.state.workspaceId,
      });
    } else {
      newSaveHandler({
        workspaceName: databaseName,
        workspaceDescription: databaseDesc,
        workspaceDimensions: databaseColumns.map(
          dbc => dbc.factColumnDisplayName
        ),
        userKey,
        createdBy,
        updatedBy,
        workspaceId,

        // createdBy: string,
      });
      this.openCreateModal();
    }

    // { workspaceId, workspaceName, workspaceDescription, workspaceDimensions, createdBy, updatedBy }

    // this.props.newSaveHandler({ userKey, workspaceId, workspaceName, workspaceDescription, workspaceDimensions, createdBy, updatedBy });
  }

  closeCreateModal() {
    this.setState({ isCreateModalOpen: false });
  }

  openCreateModal() {
    this.setState({ isCreateModalOpen: true });
  }

  selectAllColumns(e) {
    const { current } = this.props, { databaseColumns } = this.state;
    databaseColumns.forEach(col => {
      if (
        !current || (current && !current.hasDataLoaded) || !col.factColumnGuid
      ) {
        col.isSelected = e.target.checked;
      }
    });
    this.setState({ databaseColumns, allColumnsSelected: e.target.checked });
  }

  selectColumn(idx) {
    const { databaseColumns } = this.state;
    databaseColumns.forEach((col, index) => {
      if (index === idx) {
        col.isSelected = !col.isSelected;
      }
    });
    this.setState({ databaseColumns });
  }

  validateColumnName(idx, soft) {
    const { databaseColumns } = this.state;
    const col = databaseColumns[idx];
    return col ? validateName(col.factColumnDisplayName || '', soft, false, true) : '';
  }

  validateColumnsComposition() {
    const { databaseColumns } = this.state, nameMap = {};

    let dupName = false, nameError = '', columnError = null;

    databaseColumns.every((col, idx) => {
      nameError = validateName(col.factColumnDisplayName, false, false, true);
      if (nameError) {
        columnError = `Column ${idx + 1} name: ${nameError}`;
        return false;
      }
      const lowerCaseName = col.factColumnDisplayName.toLowerCase();

      if (nameMap[lowerCaseName]) {
        dupName = true;
      } else {
        nameMap[lowerCaseName] = true;
      }

      return true;
    });

    if (nameError) {
      return columnError;
    }

    if (dupName) {
      columnError = 'Column names must be unique';
    }

    return columnError;
  }

  validate(soft) {
    const dbNameError = this.getNameValidationError(soft);
    if (dbNameError) {
      notifyError(`Database name: ${dbNameError}`);
      return false;
    }
    if (!soft) {
      const columnSetError = this.validateColumnsComposition();
      if (columnSetError) {
        notifyError(columnSetError);
        return false;
      }
    }
    return !dbNameError;
  }

  render() {
    const {
      databaseColumns,
      isEditing,
      allColumnsSelected,
      softErrorMode,
      databases,
    } = this.state,
      {
        clearNotificationHandler,
        serverNotification,
        getWorkspaces,
        userKey,
        errorMessage,
        current,
        // isCurrentUserAdmin,
        clearErrorMessageHandler,
        userPermissions,
        browserHistory,
        jobStatus,
      } = this.props,
      dbNameValidationError = this.getNameValidationError(softErrorMode),
      verifyPermission = createPermissionChecker(userPermissions);

    return (
      <div id="create-aa-container" style={{ padding: '0vh 10vw', height: 'calc(100vh - 52px)', overflowY: 'auto' }}>
        <div
          style={{
            borderBottom: '2px solid #BDBDBD',
            margin: 'initial',
            height: 75,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2 style={{ margin: 'initial', paddingLeft: 15 }}>
            {isEditing ? 'Edit Database' : 'Create Database'}
          </h2>
          <Link to="/analysis/workspaces">
            <OverlayTrigger
              placement="left"
              focus
              overlay={
                <Tooltip id="addWorkSpaceTooltip">
                  Back to Database List
                </Tooltip>
              }
            >
              <i
                style={style.addIcon}
                className="material-icons"
                key="back-arrow"
              >
                arrow_back
              </i>
            </OverlayTrigger>
          </Link>
        </div>

        <div style={GlobalStyles.card}>
          <h3>Enter Database Information</h3>
          <div style={{ fontWeight: 800, display: 'flex', flexDirection: 'row' }}>
            Name:
          </div>
          <Row>
            <Col>
              <Form.Group controlId="databaseName">
                <Form.Control
                    type="text"
                    maxLength="100"
                    placeholder="Name"
                    value={this.state.databaseName}
                    isInvalid={dbNameValidationError}
                    onChange={e => this.nameChangeHandler(e.target.value)}
                    onBlur={e => this.nameChangeHandler(e.target.value.trim())}
                />
                <Form.Control.Feedback type="invalid">{dbNameValidationError}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <div
            style={{ fontWeight: 800, display: 'flex', flexDirection: 'row' }}
          >
            Description:
          </div>
          <div className="row form-group">
            <Col>
              <Form.Control
                type="text"
                maxLength="1024"
                id="databaseDesc"
                placeholder="Description"
                as="textarea"
                value={this.state.databaseDesc}
                onChange={this.descChangeHandler}
              />
                </Col>
          </div>
          <div>
            <Form.Group controlId="fileSelect">
              <Form.Label>Select TOD Database</Form.Label>
              <Form.Control
                as="select"
                placeholder="Select a database"
                onChange={e =>
                  this.setState({
                    selectedDatabase: databases.find(
                      db => db.factTableGuid === e.target.value
                    ) || null,
                  })}
              >
                <option value="select">-- Select a database --</option>
                {databases.map(db => (
                  <option key={`${db.factTableGuid}`} value={db.factTableGuid}>
                    {db.workspaceName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div style={{ margin: 10 }}>
              <button
                className="btn btn-primary"
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={this.getDatabaseColumns}
              >
                <i className="material-icons" key="add-column">add</i>
                <span>Populate Columns Below from selected TOD Database</span>
              </button>
            </div>
          </div>
        </div>
        <div style={[GlobalStyles.card, { marginBottom: 100 }]}>
          <h4>Database Schema</h4>
          <div style={{ margin: 10, display: 'flex', flexDirection: 'row' }}>
            <button
              onClick={this.addColumn}
              className="btn btn-primary"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <i className="material-icons" key="add-column">add</i>
              <span>Add Column</span>
            </button>
            <button
              onClick={this.deleteSelectedColumns}
              className="btn btn-danger"
              style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}
            >
              <i className="material-icons" key="delete-columns">remove</i>
              <span>Remove Columns</span>
            </button>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              className="table-responsive"
              style={{
                paddingTop: 10,
                paddingLeft: 15,
                paddingRight: 15,
                minWidth: '65vw',
                margin: 'auto',
              }}
            >
              <table className="table table-striped">
                <thead style={style.tableRow}>
                  <tr>
                    <th style={[style.cellPositioning, { width: '5%' }]}>
                      <input
                        type="checkbox"
                        onChange={this.selectAllColumns}
                        checked={allColumnsSelected}
                      />
                    </th>
                    <th style={[style.cellPositioning, { width: '20%' }]}>
                      Column Name
                    </th>
                    <th style={[style.cellPositioning, { width: '10%' }]} />
                    <th style={[style.cellPositioning, { width: '20%' }]} />
                    <th style={[style.cellPositioning, { width: '10%' }]} />
                    <th style={[style.cellPositioning, { width: '20%' }]} />
                    <th style={[style.cellPositioning, { width: '20%' }]} />
                  </tr>
                </thead>
                <tbody style={style.tableBody} id="databaseColumnsTable">
                  {databaseColumns.map((column, idx) => {
                    const canEdit = !isEditing || false;
                    return (
                      <ColumnRow
                        column={{ ...column }}
                        idx={idx}
                        key={`column-row-${idx}`}
                        canEdit={canEdit}
                        onSelectChanged={() => this.selectColumn(idx)}
                        onNameChanged={e => this.changeColumnName(idx, e)}
                        onTypeChanged={e => this.changeColumnType(idx, e)}
                        deleteColumn={() => this.deleteColumn(idx)}
                        validationError={this.validateColumnName(
                          idx,
                          softErrorMode
                        )}
                        hasDataLoaded={current && current.hasDataLoaded}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <SaveButtons
          saveHandler={
            verifyPermission(SITE_CREATEDATABASE)
              ? this.saveDatabase
              : () =>
                  notifyError(
                    'You are not authorized to access this functionality'
                  )
          }
          // isSaveButtonDisabled={!isCurrentUserAdmin}
          backButtonHander={() => browserHistory.push('/analysis/workspaces')}
          backButtonText="Back to Databases"
        />
        <CreateAEDatabaseModal
          modalId="createAEDatabaseModal"
          showModal={this.state.isCreateModalOpen}
          closeHandler={this.closeCreateModal}
          clearNotificationHandler={clearNotificationHandler}
          serverNotification={serverNotification}
          getWorkspaces={getWorkspaces}
          errorMessage={errorMessage}
          clearErrorMessageHandler={clearErrorMessageHandler}
          userKey={userKey}
          browserHistory={browserHistory}
          jobStatus={jobStatus}
        />
      </div>
    );
  }
}

export default radium(CreateAriusAnalysisDatabasePage);
