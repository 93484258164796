import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';

const style = {
  tableRow: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
  },
  cellPositioning: {
    margin: 0,
    verticalAlign: 'middle',
  },
};

class MappingRow extends Component {
  static propTypes = {
    column: PropTypes.object,
    idx: PropTypes.number,
    dbColumns: PropTypes.array,
    validateDates: PropTypes.bool,
    dateFormats: PropTypes.array,
    dateDelimiters: PropTypes.array,
    changeDbColumn: PropTypes.func,
    changeDateFormat: PropTypes.func,
    changeDateFormatDelimiter: PropTypes.func,
    disabled: PropTypes.bool,
    canEditDateFormats: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    const cCol = this.props.column;
    const nCol = nextProps.column;

    return this.props.idx !== nextProps.idx || this.props.validateDates !== nextProps.validateDates
      || nCol.externalColumnName !== cCol.externalColumnName
      || nCol.duplicate !== cCol.duplicate
      || nCol.factColumnGuid !== cCol.factColumnGuid
      || nCol.dateFormat !== cCol.dateFormat
      || nCol.dateFormatDelimiter !== cCol.dateFormatDelimiter;
  }

  render() {
    const {
      column,
      idx,
      dbColumns,
      validateDates,
      dateFormats,
      dateDelimiters,
      changeDbColumn,
      changeDateFormat,
      changeDateFormatDelimiter,
    } = this.props;

    let
      dateFormatError = null,
      dateDelimiterError = null;

    if (validateDates && column.isDateType) {
      if (!column.dateFormat) {
        dateFormatError = 'Required';
      }
    }

    return (
          <tr style={style.tableRow} key={`newColumn-${idx}`}>
              <td style={style.cellPositioning}>{column.externalColumnName}</td>
              <td style={style.cellPositioning}>
                    <Form.Group>
                        <Form.Control 
                            isInvalid={column.duplicate}
                            as="select"
                            placeholder=""
                            value={column.factColumnGuid}
                            onChange={(e) => changeDbColumn(idx, e)}
                            style={column.factColumnGuid === 'default' ? { fontStyle: 'italic' } : undefined }
                        >
                            <option value="default" style={{ fontStyle: 'italic' }}>-- Not Mapped --</option>
                            {dbColumns.map(dbColumn => {
                                if (dbColumn.columnType !== 'calc_measure') {
                                return (
                                    <option key={dbColumn.factColumnGuid}
                                    style={{ fontStyle: 'normal' }}
                                    value={dbColumn.factColumnGuid}>{dbColumn.factColumnDisplayName}
                                    </option>
                                );
                                } else {
                                return null;
                                }
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Duplicate</Form.Control.Feedback>
                    </Form.Group>
              </td>
              <td style={style.cellPositioning}>
                  {column.isDateType ? (
                        <Form.Group>
                            <Form.Control
                                isInvalid={dateFormatError}
                                as="select"
                                placeholder=""
                                value={column.dateFormat}
                                onChange={(e) => changeDateFormat(idx, e)}
                                disabled={this.props.canEditDateFormats}
                            >
                                {dateFormats.map((df, index) => (
                                <option key={`date-format-${index}`} value={df}>{df}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback>{dateFormatError}</Form.Control.Feedback>
                        </Form.Group>
                  ) : (<span></span>)}
              </td>
              <td style={style.cellPositioning}>
                  {column.isDateType ? (
                        <Form.Group>
                            <Form.Control 
                                isInvalid={dateDelimiterError}
                                as="select"
                                placeholder=""
                                value={column.dateFormatDelimiter}
                                onChange={(e) => changeDateFormatDelimiter(idx, e)} disabled={this.props.canEditDateFormats}
                            >
                                {dateDelimiters.map(dfd => (
                                <option key={dfd.value} value={dfd.value}>{dfd.label}</option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{dateDelimiterError}</Form.Control.Feedback>
                        </Form.Group>
                  ) : (<span></span>)}
              </td>
              <td style={style.cellPositioning}></td>
          </tr>);
  }
}

export default radium(MappingRow);
