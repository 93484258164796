import {
  SELECTING_TOD_DATABASE,
  RECEIVED_TOD_DATABASE,
  RECEIVED_TOD_DATABASES,
  RECEIVED_TOD_DATABASE_BACKUPS,
  RECEIVED_TOD_DATABASE_ARCHIVED,
  RECEIVED_ARCHIVED_FOR_TOD_DATABASE_BACKUP,
  FETCHING_TOD_DATABASES,
  RECEIVED_DATABASE_MAPPINGS,
  RECEIVED_DATABASE_MAPPING,
  RECEIVED_TOD_UPLOADS,
  FETCHING_TOD_UPLOADS,
  FETCHING_TOD_DATA_LOADS,
  SAVING_TOD_UPLOADS,
  RECEIVED_COLUMNS_FOR_FILE,
  RECEIVED_DATE_FORMATS,
  RECEIVED_DATE_FORMAT_DELIMITERS,
  RECEIVED_COLUMN_TYPES,
  SAVING_DATA_MAPPINGS,
  SAVING_TOD_DATABASE,
  RECEIVED_COLUMNS_FOR_TOD_DB,
  RECEIVED_TOD_DATABASE_INFO,
  FETCHING_TOD_DATABASE_INFO,
  FETCHING_QUERIES,
  RECEIVED_QUERIES,
  RECEIVED_QUERY,
  RECEIVED_QUERY_DATASOURCE,
  RECEIVED_SEGMENT_PREVIEW,
  FETCHING_QUERY_DATA_SOURCE,
  SAVING_TOD_QUERY,
  RECEIVED_TOTALS_FOR_TOD_DB,
  FETCHING_TOD_TOTALS,
  RECEIVED_TOTALS_TABLE,
  FETCHING_TOD_TOTALS_TABLE,
  RECEIVED_COLUMN_VALIDATION,
  RECEIVED_TOD_DB_VERSION,
  RECEIVED_TOD_DATABASE_DATALOADS,
  RECEIVED_TOD_CSV_FILE,
  RECEIVED_TOD_EXCEL_FILE,
  RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS,
  FETCHING_ROLLUP_DEFIINITION_FROM_SERVER,
  RECEIVED_ROLLUP_DEFINITION,
  RECEIVED_DATASET_COLUMNS,
  LOAD_ROLLUPDEFINITIONS_SNAPSHOT,
  CLEAR_ROLLUPDEFINITIONS_SNAPSHOT,
  CLEAR_ROLLUPDEFINITIONS,
  PROCESSING_NEW_ROLLUP_DEFINITION,
  FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION,
  STORE_QUERY,
  CLEAR_QUERY,
  FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER,
  RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER,
  CLEAR_TOD_DATABASE,
  DELETED_DATA_LOADS,
  DELETED_VALUATION_DATE,
  DELETING_DATA_LOADS,
  DELETING_VALUATION_DATE,
  RECEIVED_QUERIES_USING_ROLLUP,
  RECEIVED_TOD_DATA_LOAD_LOG_FILE,
  RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE,
  RECEIVED_BACKUPRESTORE_LOG_FILE,
  RECEIVED_V2_QUERY,
  FETCHING_DATAGROUPS,
  FETCHING_DATAGROUP,
  RECEIVED_DATAGROUPS,
  RECEIVED_DATAGROUP,
  FETCHING_ROLLUPS,
  RECEIVED_ROLLUPS,
  FETCHING_FACTCOLUMNS,
  FETCHING_OPERATORS,
  RECEIVED_FACTCOLUMNS,
  RECEIVED_OPERATORS,
  FETCHING_QUERY_VALIDATION,
  RECEIVED_QUERY_VALIDATION,
  FETCHING_VALUES,
  RECEIVED_VALUES,
  RECEIVED_QUERY_SEGMENTS,
  FETCHING_QUERY_SEGMENTS,
} from '../actions/tod/tod.actionTypes';
import queriesReducer from './tod/queries.reducer';
import todDatabaseReducer from './tod/todDatabases.reducer';
import totalsReducer from './tod/totals.reducer';
import uploadsReducer from './tod/uploads.reducer';

function sameDatabase(db1, db2) {
  if (db1 === null) {
    return db2 === null;
  }
  if (db2 === null) {
    return false;
  }
  return db1.id === db2.id;
}

const tod = (state = {
  databases: {
    items: [],
    current: null,
    receivedAt: null,
    isFetching: false,
    mappings: { items: [], isSaving: false, current: null },
    dateFormats: [],
    dateFormatDelimiters: [],
    columnTypes: [],
    backups: [],
    archived: [],
    columnValidation: null,
    currentVersion: null,
    dataLoads: [],
    dataLoadDeletesInProgress: [],
    valuationDateDeletesInProgress: [],
    txtFile: null,
  },
  uploads: { items: [], isFetching: false, isSaving: false, columns: [] },
  queries: {
    items: [],
    isFetching: false,
    isFetchingQueries: false,
    isSaving: false,
    dataSource: null,
    isDataSourceFetching: false,
    segmentPreview: null,
    datasetSpecificRollupDefinitions: [],
    allRollupDefinitions: [],
    rollupDefinition: null,
    rollupDefinitionsComponentSnapshot: {
      loaded: false,
      state: {},
      props: {},
    },
    isProcessingNewRollupDefinition: false,
    datasetColumns: [],
    queryInfo: {},
    queriesUsingRollup: [],
  },
  totals: {
    isFetching: false,
    dataSource: null,
    isSaving: false,
    isFetchingTable: false,
    table: null,
    csvFile: null,
    excelFile: null,
  },
}, action) => {
  let newState = state;
  switch (action.type) {
    case SELECTING_TOD_DATABASE:
    case RECEIVED_TOD_DATABASE:
    case RECEIVED_TOD_DATABASES:
    case FETCHING_TOD_DATABASES:
    case RECEIVED_DATABASE_MAPPINGS:
    case RECEIVED_DATABASE_MAPPING:
    case SAVING_DATA_MAPPINGS:
    case RECEIVED_DATE_FORMATS:
    case RECEIVED_DATE_FORMAT_DELIMITERS:
    case RECEIVED_COLUMNS_FOR_TOD_DB:
    case RECEIVED_TOD_DATABASE_INFO:
    case FETCHING_TOD_DATABASE_INFO:
    case RECEIVED_COLUMN_TYPES:
    case RECEIVED_TOD_DATABASE_BACKUPS:
    case RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE:
    case RECEIVED_TOD_DATABASE_ARCHIVED:
    case RECEIVED_ARCHIVED_FOR_TOD_DATABASE_BACKUP:
    case SAVING_TOD_DATABASE:
    case RECEIVED_COLUMN_VALIDATION:
    case RECEIVED_TOD_DB_VERSION:
    case RECEIVED_TOD_DATABASE_DATALOADS:
    case FETCHING_TOD_DATA_LOADS:
    case CLEAR_TOD_DATABASE:
    case DELETED_DATA_LOADS:
    case DELETED_VALUATION_DATE:
    case DELETING_DATA_LOADS:
    case DELETING_VALUATION_DATE:
    case RECEIVED_TOD_DATA_LOAD_LOG_FILE:
    case RECEIVED_BACKUPRESTORE_LOG_FILE:
      newState = Object.assign({}, state, { databases: todDatabaseReducer(state.databases, action) });
      if (!sameDatabase(state.databases.current, newState.databases.current)) {
        newState = Object.assign({}, newState, { queries: queriesReducer(undefined, {}) });
      }
      return newState;
    case SAVING_TOD_UPLOADS:
    case FETCHING_TOD_UPLOADS:
    case RECEIVED_TOD_UPLOADS:
    case RECEIVED_COLUMNS_FOR_FILE:
      return Object.assign({}, state, { uploads: uploadsReducer(state.uploads, action) });
    case FETCHING_QUERY_VALIDATION:
    case RECEIVED_QUERY_VALIDATION:
    case FETCHING_QUERIES:
    case RECEIVED_QUERIES:
    case RECEIVED_QUERY:
    case RECEIVED_V2_QUERY:
    case FETCHING_DATAGROUPS:
    case FETCHING_ROLLUPS:
    case FETCHING_DATAGROUP:
    case RECEIVED_DATAGROUPS:
    case RECEIVED_ROLLUPS:
    case RECEIVED_DATAGROUP:
    case FETCHING_FACTCOLUMNS:
    case FETCHING_OPERATORS:
    case RECEIVED_FACTCOLUMNS:
    case RECEIVED_OPERATORS:
    case RECEIVED_QUERY_DATASOURCE:
    case FETCHING_QUERY_DATA_SOURCE:
    case RECEIVED_SEGMENT_PREVIEW:
    case SAVING_TOD_QUERY:
    case RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS:
    case FETCHING_ROLLUP_DEFIINITION_FROM_SERVER:
    case RECEIVED_ROLLUP_DEFINITION:
    case RECEIVED_DATASET_COLUMNS:
    case LOAD_ROLLUPDEFINITIONS_SNAPSHOT:
    case CLEAR_ROLLUPDEFINITIONS_SNAPSHOT:
    case CLEAR_ROLLUPDEFINITIONS:
    case PROCESSING_NEW_ROLLUP_DEFINITION:
    case FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION:
    case STORE_QUERY:
    case CLEAR_QUERY:
    case FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER:
    case RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER:
    case RECEIVED_QUERIES_USING_ROLLUP:
    case FETCHING_VALUES:
    case RECEIVED_VALUES:
    case FETCHING_QUERY_SEGMENTS:
    case RECEIVED_QUERY_SEGMENTS:
      return Object.assign({}, state, { queries: queriesReducer(state.queries, action) });
    case RECEIVED_TOTALS_FOR_TOD_DB:
    case FETCHING_TOD_TOTALS:
    case RECEIVED_TOTALS_TABLE:
    case FETCHING_TOD_TOTALS_TABLE:
    case RECEIVED_TOD_CSV_FILE:
    case RECEIVED_TOD_EXCEL_FILE:
      return Object.assign({}, state, { totals: totalsReducer(state.totals, action) });
    default:
      return state;
  }
};

export default tod;
