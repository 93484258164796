import { callApi } from '../utilities/callApi';

export function getAccessTokenForReport(userKey, workspaceId, reportId) {
  return callApi(
    `/report/workspaces/${workspaceId}/reports/${reportId}/apptoken`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT'
  );
}

export function retrieveReports(userKey, workspaceId) {
  return callApi(
    `/report/workspaces/${workspaceId}/reports`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrievePBIReports(userKey) {
    return callApi(
      `report/powerbi/reports`,
      { 'X-AE-USERKEY': userKey }
    );
}

export function retrievePBIReportToken(userKey, reportId) {
    return callApi(
      `report/powerbi/${reportId}/apptoken`,
      { 'X-AE-USERKEY': userKey }
    );
}

export function refreshPBIDataset(userKey, datasetId) {
    return callApi(
        `report/powerbi/datasets/${datasetId}/refresh`,
      { 'X-AE-USERKEY': userKey }
    );
}