import React, { Component } from 'react';
import Feature from '@app/utilities/routing/routeSafeFeature';
import { connect } from '@app/utilities/routing';
import { Route, Switch } from 'react-router-dom';

import DownloadsList from '@app/analysis/downloads/downloadsList';

class DownloadsTab extends Component {
  static propTypes = {  };

  componentWillMount() {
    // const {
    //   userKey,
    //   getCurrentAEDbScriptVersion,
    //   currentAEDbScriptVersion,
    //   getCurrentUserPermissions,
    // } = this.props;
    // if (!currentAEDbScriptVersion) {
    //   getCurrentAEDbScriptVersion(userKey);
    // }
    // getCurrentUserPermissions();
  }

  componentDidUpdate(prevProps) {
    // const {
    //   dispatch,
    //   current,
    //   isFetching,
    //   userKey,
    //   params,
    //   serverNotification,
    // } = this.props;

    // if (isFetching) {
    //   return;
    // }

    // if (params.downloadId) {
    //   const downloadId = parseInt(params.downloadId, 10);
    //   if (downloadId) {
    //     if (current && current.id && current.id === downloadId) {
    //       return;
    //     }
    //     dispatch(fetchDownload(userKey, downloadId));
    //   }
    // }

    // if (
    //   prevProps.serverNotification &&
    //   prevProps.serverNotification.message !==
    //     SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE &&
    //   serverNotification &&
    //   serverNotification.message === SERVER_NOTIFICATION_MESSAGES.DB_COMPLETE
    // ) {
    //   dispatch(fetchDownloads(userKey));
    // }
  }

  render() {
    const {
      workspaces,
      isFetching,
      history,
      current,
      userKey,
      dispatch,
      serverNotification,
      clearNotificationHandler,
      selectWorkspaceHandler,
      errorMessage,
      clearErrorMessageHandler,
      getCurrentUserPermissions,
      userPermissions,
      getWorkspace,
      getWorkspaces,
    } = this.props;

    let childProps = {
        workspaces,
        isFetching,
        browserHistory: history,
        current,
        userKey,
        dispatch,
        serverNotification,
        clearNotificationHandler,
        selectWorkspaceHandler,
        errorMessage,
        clearErrorMessageHandler,
        getCurrentUserPermissions,
        userPermissions,
        getWorkspace,
        getWorkspaces,
    };

    return (
      <Feature flag="ariusAnalysis">
        <Switch>
            <Route path="/analysis/downloads" render={props => (<DownloadsList {...childProps}/>)}/>}
        </Switch>  
      </Feature>
    );
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  application: state.application,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsTab);
