import {
  FETCHING_SUBSCRIPTION_USERS_FROM_SERVER,
  FETCHING_SUBSCRIPTION_USER_FROM_SERVER,
  RECEIVED_SUBSCRIPTION_USERS,
  RECEIVED_SUBSCRIPTION_USER,
  UPDATED_USER,
} from './portalManagement.actionTypes';
import { SAVING_TO_SERVER } from '../actionTypes';
import * as userServiceApi from '../../serviceApi/users.serviceApi';
import { MEMBER_TYPE_ID } from '../../utilities/constants';

function savingToServer() {
  return {
    type: SAVING_TO_SERVER,
  };
}

function updatedUser() {
  return {
    type: UPDATED_USER,
  };
}

function fetchingSubscriptionUsersFromServer() {
  return {
    type: FETCHING_SUBSCRIPTION_USERS_FROM_SERVER,
  };
}

function fetchingSubscriptionUserFromServer() {
  return {
    type: FETCHING_SUBSCRIPTION_USER_FROM_SERVER,
  };
}

function receivedSubscriptionUsers(json) {
  return {
    type: RECEIVED_SUBSCRIPTION_USERS,
    subscriptionUsers: json,
    receivedAt: Date.now(),
  };
}

function receivedSubscriptionUser(json) {
  return {
    type: RECEIVED_SUBSCRIPTION_USER,
    subscriptionUser: json,
    receivedAt: Date.now(),
  };
}

function fetchSubscriptionUsers(userKey) {
  return (dispatch) => {
    dispatch(fetchingSubscriptionUsersFromServer(userKey));
    return userServiceApi
            .retrieveSubscriptionUsers()
            .then(json => dispatch(receivedSubscriptionUsers(json)));
  };
}

function fetchSubscriptionUser(userKey, userId) {
  return (dispatch) => {
    dispatch(fetchingSubscriptionUserFromServer());
    return userServiceApi
            .retrieveSubscriptionUser(userKey, userId)
            .then(json => dispatch(receivedSubscriptionUser(json)));
  };
}

function shouldFetchUsers(state) {
  const users = state.users;

  if (!users) {
    return true;
  }

  if (users.isFetching) {
    return false;
  }
  return true;
}

function fetchUsersIfNeeded(userKey) {
  return (dispatch, getState) => {
    if (shouldFetchUsers(getState())) {
      return dispatch(fetchSubscriptionUsers(userKey));
    }
    return null;
  };
}

function deleteSubscriptionUser({ userKey, userId }) {
  return (dispatch) =>
    userServiceApi.deleteSubscriptionUser({ userKey, userId })
      .then(() => dispatch(fetchSubscriptionUsers()));
}

function updateSubscriptonUser({ userId, userKey, description, email, name, roles, groups }) {
  return (dispatch) => {
    dispatch(savingToServer());
    return userServiceApi
             .updateSubscriptonUser({
               userId,
               userKey,
               description,
               email,
               name,
               groups,
               memberTypeId: MEMBER_TYPE_ID.user,
               isActive: false,
               roles,
             })
             .then(() => dispatch(updatedUser(userKey, userId)))
             .then(() => dispatch(fetchSubscriptionUser(userKey, userId)))
             .then(() => dispatch(fetchSubscriptionUsers(userKey)));
  };
}

function createSubscriptionUser({ userKey, description, email, name, roles, groups, browserHistory }) {
  return (dispatch) => {
    dispatch(savingToServer());
    return userServiceApi
             .createSubscriptionUser({
               userKey,
               description,
               email,
               name,
               roles,
               groups,
               memberTypeId: MEMBER_TYPE_ID.user,
               isActive: false,
             })
             .then(() => dispatch(fetchSubscriptionUsers()))
             .then(() => browserHistory.push('/portalManagement/users'));
  };
}

export {
  createSubscriptionUser,
  fetchSubscriptionUsers,
  fetchSubscriptionUser,
  fetchUsersIfNeeded,
  deleteSubscriptionUser,
  updateSubscriptonUser,
};
