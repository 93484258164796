import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal, OverlayTrigger, Tooltip, Form, ButtonGroup, Button } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';
import moment from 'moment';
import { updateUDOComment,DatabaseUdoLibrarylock,DatabaseUdoLibraryUnlock } from '../../../actions/analysis/workspace.actions';
import {
  WORKSPACE_LOCKUDO,
  WORKSPACE_UNLOCKUDO
} from '../../../utilities/permissions';
import Dotdotdot from 'react-dotdotdot';
import { Table } from '@arius'
import { notifyError } from '../../../utilities/notifier';

class UdoLibraryModal extends Component {
  static propTypes = {
    modalId: PropTypes.string.isRequired,
    closeHandler: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    modalTitle: PropTypes.string,
    udoVersions: PropTypes.array,
    lockStatus :PropTypes.bool,
    Permissions:PropTypes.array,
    database: PropTypes.object,
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedVersion: null,
      editingComment: false,
      udoVersions: [],
      databaseID:'',
      currentComment: '',
      isMouseInside: false,
    };
  }
  
  componentWillReceiveProps(nextProps) {
    const { udoVersions, showModal } = this.props;
    if (nextProps && nextProps.udoVersions !== udoVersions) {
      this.setState({ udoVersions: JSON.parse(JSON.stringify(nextProps.udoVersions))});
    }
    if (nextProps.showModal !== showModal) {
      this.restoreInitialState();
    }
  }

  onCloseClick() {
    this.props.closeHandler();
    this.toggleEditMode();
  }

  restoreInitialState() {
    this.setState({
      selectedVersion: null,
      editingComment: false,
      isMouseInside: false,
    });
  }

  toggleCommentBox(selectedVersion) {
    if (this.state.selectedVersion === selectedVersion) {
      this.setState({ selectedVersion: null, editingComment: false, isMouseInside: false });
    } else {
      this.setState({ selectedVersion, isMouseInside: false, editingComment: true, currentComment: selectedVersion.comment });
    }
  }

  toggleEditMode() {
    const { editingComment, selectedVersion } = this.state;
    this.setState({ editingComment: !editingComment,
      currentComment: selectedVersion ? selectedVersion.comment : '',
      isMouseInside: false,
      selectedVersion: null,
    });
  }

  editComment(e) {
    this.setState({ currentComment: e.target.value });
  }

  updateComment() {
    const { udoVersions, currentComment, selectedVersion } = this.state,
      { database, userKey, dispatch } = this.props,
      found = udoVersions.find(udo => udo === this.state.selectedVersion),
      databaseId = database.id || database.workspaceId;
    let version;

    if (found) {
      found.comment = currentComment;
      version = found.version;
    }
    dispatch(updateUDOComment({ databaseId, comment: currentComment, version, userKey }));
    this.toggleCommentBox(selectedVersion);
  }

  mouseEnter() {
    this.setState({ isMouseInside: true });
  }
  mouseExit() {
    this.setState({ isMouseInside: false });
  }
  changeLockStatus(Status){
    const { database, userKey,Permissions, dispatch} = this.props;
   if(Permissions.indexOf(WORKSPACE_LOCKUDO) !== -1){
    dispatch(DatabaseUdoLibrarylock(userKey,database.id || database.workspaceId));
   }
   else if(database.permissions.indexOf(WORKSPACE_LOCKUDO) === -1){
    notifyError('You are not authorized to access this functionality');
   }
   else {
    dispatch(DatabaseUdoLibrarylock(userKey,database.id || database.workspaceId));
   }
  
  }
  changeUnLockStatus(Status){
    const { database, userKey,Permissions, dispatch} = this.props;
  if(Permissions.indexOf(WORKSPACE_UNLOCKUDO) !== -1){
    dispatch(DatabaseUdoLibraryUnlock(userKey,database.id || database.workspaceId))
  }
   else if(database.permissions.indexOf(WORKSPACE_UNLOCKUDO) === -1){
      notifyError('You are not authorized to access this functionality');
    }
    else{
      dispatch(DatabaseUdoLibraryUnlock(userKey,database.id || database.workspaceId))
    }
  }
  render() {
    const {
      lockStatus
    } = this.props,
      { 
        udoVersions,
        selectedVersion,
        editingComment,
        currentComment,
        isMouseInside,
      } = this.state,
      showEditIconStyle = isMouseInside ? {} : { visibility: 'hidden' },
      pBorderStyle = isMouseInside ? {
        outline: 'none',
        borderColor: '#9ecaed',
        boxShadow: '0 0 10px #9ecaed',
      } : {};
    let {tr:Tr, td:Td, th:Th, tbody:Tbody, thead:Thead, responsiveTable:Tbl} = Table;
    let renderUdoVersions = null;
    if (udoVersions && udoVersions.length) {
      renderUdoVersions = (
        udoVersions.map((version, idx) => {
          const comment = version.comment,
            commentArray = comment ? comment.split(/\n/ig) : [],
            commentJSX = commentArray.map((ca, index) => {
              if (ca) {
                return <div key={`comment-${ca}-${index}`}>{ca}</div>;
              } else {
                return <br key={`break-${ca}-${index}`} />;
              }
            }),
            commentPreview = (
              <Dotdotdot
                clamp={2}
                style={{ 
                  overflow: 'hidden',
                  display: 'inline-block',
                  width: '100%',
                }}>
                {commentArray[0]}
              </Dotdotdot>
            ),
            addCommentPlaceholder = (
              <span style={{ opacity: 0.5, fontStyle: 'italic', cursor: 'text' }}>
                add comment
              </span>
            ),
            popover = (
              <Tooltip className="udo-tooltip" id={`tooltip-${version.version}-${idx}`}>
                {commentArray.length ? commentJSX : 'There is no comment for this UDO'}
              </Tooltip>
            );
          return (
            <Tr key={`udoVersion-${version.version}-${idx}`}>
              <Td width='15%'>{version.version}</Td>
              <Td width='15%'>{version.createdBy}</Td>
              <Td width='15%'>{moment.utc(version.created).local().format('L LT')}</Td>
              <Td 
                width='55%'
                style={{ 
                  cursor: 'pointer',
                  maxWidth: '40vw',
                }}
                onClick={() => this.toggleCommentBox(version)}>
                <OverlayTrigger
                  placement="left"
                  overlay={popover}>
                  <span>{commentArray.length ? commentPreview : addCommentPlaceholder}</span>
                </OverlayTrigger>
              </Td>
            </Tr>
          );
        })
      );
    }     

    let renderUdoTable = (
      <div className="row">
        <Tbl>
          <Thead>
            <Tr>
              <Th width='15%'>Version</Th>
              <Th width='15%'>Updated By</Th>
              <Th width='15%'>Updated Date</Th>
              <Th width='55%'>Comment</Th>
            </Tr>
          </Thead>
          <Tbody>
            {renderUdoVersions}
          </Tbody>
        </Tbl>
      </div>
    ),
     lockStatusBtn = lockStatus?
    (<button type="button"
      onClick={() => this.changeUnLockStatus(lockStatus)}
      className="btn btn-primary">Unlock</button>)
 : (<button type="button"
 onClick={() =>  this.changeLockStatus(lockStatus)}
className="btn btn-primary">Lock </button>);
    if (selectedVersion) {
      if (editingComment) {
        renderUdoTable = (
          <Form.Group controlId="editcommentTextarea">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonGroup
                style={{ borderBottom: 'none' }}>
                <Button
                  style={{ 
                    color: 'green',
                    borderBottomLeftRadius: '0',
                    position: 'relative',
                    top: '1.5px',
                  }}
                  onClick={() => this.updateComment()}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
                <Button
                  style={{
                    color: 'red',
                    borderBottomRightRadius: '0',
                    position: 'relative',
                    top: '1.5px',
                  }}
                  onClick={() => this.toggleEditMode()}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </Button>
              </ButtonGroup>
            </div>
            <Form.Control 
              as="textarea"
              placeholder="write comment"
              autoFocus
              value={currentComment}
              rows={5}
              onChange={(e) => this.editComment(e)}
              style={{
                resize: 'vertical',
                borderTopRightRadius: '0',
              }} />
          </Form.Group>
        );
      } else {
        renderUdoTable = (
          <div>
            <div style={[{ display: 'flex', justifyContent: 'flex-end' }, showEditIconStyle]}>
              <i
                style={{ marginBottom: '4px' }}
                className="fa fa-pencil"
                aria-hidden="true"></i>
            </div>
            <p
              style={[pBorderStyle, { cursor: 'text' }]}
              onClick={() => this.toggleEditMode()}
              onMouseEnter={() => this.mouseEnter()}
              onMouseLeave={() => this.mouseExit()}>
              {selectedVersion.comment}
            </p>
          </div>
        );
      }
    }

    return (
      <Modal id={this.props.modalId} show={this.props.showModal} onHide={this.props.closeHandler} enforceFocus dialogClassName="modal-90w">
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
          <Modal.Title>
            <small>
              <span style={{marginRight: '10px'}}>
                {lockStatus ? (<i className="fa fa-lock" aria-hidden="true"></i>) : (<i className="fa fa-unlock" aria-hidden="true"></i>)}
              </span>
              UDO Library for: {this.props.modalTitle}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small>{renderUdoTable}</small>
        </Modal.Body>
        <Modal.Footer>
          {lockStatusBtn}
          <Button variant="outline-dark" onClick={() => this.onCloseClick()}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default radium(UdoLibraryModal);
