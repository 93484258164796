import {
  FETCHING_SCRIPTS,
  SCRIPTS_RECEIVED,
  SCRIPT_RECEIVED,
  RECEIVED_SCRIPT_COMMANDS,
  CREATING_SCRIPT,
  SCRIPT_CREATED,
  MAPPERS_RECEIVED,
  COMMAND_STATUSES_RECEIVED,
  FETCHING_COMMAND_STATUSES,
  CLEAR_SCRIPTS,
} from './analysis.actionTypes';

import * as automationServiceApi from '../../serviceApi/automation.serviceApi';
import { notifySuccess } from '../../utilities/notifier';

const mockCommands = [
  {
    id: 1,
    value: 'Append New Evaluation Period',
    params: [
      {
        options: ['Continue adding exposure periods'],
        values: [true, false],
        type: 'boolean',
        description: `Select TRUE to continue to add exposure periods to your triangles,
        otherwise select FALSE (for example, if your data is in runoff).
        Development periods will always be added.`,
      },
      {
        options: ['Clear All Assumptions'],
        values: [true, false],
        type: 'boolean',
        description: `Select TRUE to clear all your selected development
        factors and all the data entered into assumptions tables
        (for example, Average Loss Rates, etc.) or select FALSE
        to retain all selected development factors and assumptions.`,
      },
    ],
  },
  {
    id: 2,
    value: 'Load Data from TOD',
    params: [
      {
        name: 'TOD date',
        type: 'input',
      },
      {
        name: 'Measure Mapper name',
        type: 'input',
      },
      {
        name: 'Load last diagonal only',
        type: 'boolean',
      },
    ],
  },
  {
    id: 3,
    value: 'Append Oldest Exposure Period',
  },
  {
    id: 4,
    value: 'Remove Last Evaluation Period',
    // params: [{ name: 'Number of evaluation periods' }],
  },
  {
    id: 5,
    value: 'Remove Oldest Exposure Period',
    // params: [{ name: 'Number of exposure periods' }],
  },
  {
    id: 6,
    value: 'Modify Date Parameters',
    params: [
      {
        options: ['Modification date value'],
        values: [
          'Ending Month of First Exposure Period',
          'First Development Age',
          'Length of Last Calendar Period',
        ],
      },
      {
        options: ['Set period length'],
        values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
    ],
  },
  {
    id: 7,
    value: 'Save',
  },
  {
    id: 15,
    value: 'Save As Copy',
    params: [
      {
        name: 'Project Name Suffix',
        type: 'input',
      },
    ],
  },
  {
    id: 9,
    value: 'Compress Columns',
    params: [
      { 
        name: 'New Development Period',
        options: [
          'Quarter',
          'Half Year',
          'Year',
        ],
      },
      {
        name: 'Copy Compressed SDFs into manual selected row',
        value: 'Copy Compressed SDFs',
        options: [
          true,
          false,
        ],
      },
    ],
  },
  {
    id: 10,
    value: 'Compress Rows',
    params: [
      {
        name: 'New Exposure Period',
        options: [
          'Quarter',
          'Half Year',
          'Year',
        ],
      },
      {
        name: 'Copy Compressed Ultimates into manual selected column',
        value: 'Copy Compressed Ultimates',
        options: [
          true,
          false,
        ],
      },
    ],
  },
  {
    id: 11,
    value: 'Append All Prior Row',
  },
  {
    id: 12,
    value: 'Remove All Prior Row',
  },
  {
    id: 13,
    value: 'Apply SDF Defaults',
    params: [{
      name: 'Segments',
      options: [
        {
          label: 'All segments',
          value: 'all',
        }, {
          label: 'Selected segments in project',
          value: 'selected',
        },
      ],
    }],
  },
  {
    id: 14,
    value: 'Apply Data Defaults',
    params: [{
      name: 'Segments',
      options: [
        {
          label: 'All segments',
          value: 'all',
        }, {
          label: 'Selected segments in project',
          value: 'selected',
        },
      ],
    }],
  },
  {
    id: 16,
    value: 'Clear Manual Selections',
    params: [{
      name: 'Option',
      options: [
        {
          label: 'SDFs',
          value: 'SDFs',
        }, 
        {
          label: 'Interpolated SDFs',
          value: 'Interpolated SDFs',
        },
        {
          label: 'Ultimates',
          value: 'Ultimates',
        },
      ],
    }],
  },
  {
    id: 18,
    value: 'Save SDFs to Historical Factor Library',
  },
  {
    id: 20,
    value: 'Load Collection Set',
    params: [
      {
        name: 'Collection Set Name',
        type: 'input',
      },
    ],
  },
  ],
  mockScript = {
    id: 1,
    name: '1Q Roll-Forward',
    description: 'This does a 1Q roll-forward without Data Load',
    commands: [
      'Append New Evaluation Period(true, true)',
      'Remove Oldest Exposure Period',
      'Modify Date Parameters(First Development Age, 3)',
      'Save To AE Database',
    ],
    lastModifiedBy: 'Jackie Russell',
  };

function fetchingScripts() {
  return {
    type: FETCHING_SCRIPTS,
  };
}

function clearScripts() {
  return {
    type: CLEAR_SCRIPTS,
  };
}

function fetchingCommandStatuses() {
  return {
    type: FETCHING_COMMAND_STATUSES,
  };
}

function creatingScript() {
  return {
    type: CREATING_SCRIPT,
  };
}

function scriptCreated() {
  return {
    type: SCRIPT_CREATED,
  };
}

function receivedScripts(res) {
  const scripts = res || [];
  return {
    type: SCRIPTS_RECEIVED,
    scripts,
  };
}

function receivedCommandStatuses(res) {
  const commandStatuses = res || [];
  return {
    type: COMMAND_STATUSES_RECEIVED,
    commandStatuses,
  };
}

function receivedMappers(mappers) {
  return {
    type: MAPPERS_RECEIVED,
    mappers,
  };
}

function receivedScript(script) {
  return {
    type: SCRIPT_RECEIVED,
    script,
  };
}

function receivedScriptCommands(scriptCommands) {
  return {
    type: RECEIVED_SCRIPT_COMMANDS,
    scriptCommands,
  };
}

function fetchScripts({ userKey, databaseId }) {
  return dispatch => {
    dispatch(fetchingScripts());
    return automationServiceApi
      .getScripts({ userKey, databaseId })
      .then(res => dispatch(receivedScripts(res)));
  };
}

function getCommandStatuses({ userKey, databaseId, jobId }) {
  return dispatch => {
    dispatch(fetchingCommandStatuses());
    return automationServiceApi
      .getCommandStatuses({ userKey, databaseId, jobId })
      .then(res => dispatch(receivedCommandStatuses(res)));
  };
}

function fetchMappers({ userKey, databaseId }) {
  return dispatch => {
    dispatch(fetchingScripts());
    return automationServiceApi
      .getMappers({ userKey, databaseId })
      .then(res => dispatch(receivedMappers(res)));
  };
}

function createScript({
  userKey,
  scriptName,
  scriptDescription,
  commands,
  databaseId,
  createBy,
}) {
  return dispatch => {
    dispatch(creatingScript());
    return automationServiceApi
      .createScript({
        userKey,
        scriptName,
        scriptDescription,
        commands,
        databaseId,
        createBy,
      })
      .then(() => dispatch(scriptCreated()))
      .then(() => dispatch(fetchScripts({ userKey, databaseId })));
  };
}

function runRollForwardBatchProcess({
  userKey,
  databaseId,
  scriptId,
  projects,
}) {
  return () =>
    automationServiceApi
      .runRollForwardBatchProcess({ userKey, databaseId, scriptId, projects })
      .then(res => {
        if (res && typeof res === 'object') {
          notifySuccess('Batch process initiated');
        }
      });
}

function cancelBatchProcess({
  userKey,
  databaseId,
  batchJobId,
}) {
  return () =>
    automationServiceApi
      .cancelBatchProcess({ userKey, databaseId, batchJobId })
      .then(res => {
        if (res && typeof res === 'object') {
          if (res.jobType === 1) {
            notifySuccess('Batch process cancelled');
          } else {
            notifySuccess('Extract process cancelled');
          }
        }
      });
}

function updateScript({
  batchScriptId,
  userKey,
  scriptName,
  scriptDescription,
  commands,
  databaseId,
  updateBy,
}) {
  return dispatch => {
    dispatch(creatingScript());
    return automationServiceApi
      .updateScript({
        batchScriptId,
        userKey,
        scriptName,
        scriptDescription,
        commands,
        databaseId,
        updateBy,
      })
      .then(() => dispatch(scriptCreated()))
      .then(() => dispatch(fetchScripts({ userKey, databaseId })));
  };
}

function fetchScriptCommands() {
  return dispatch => dispatch(receivedScriptCommands(mockCommands));
}

function deleteScript({ userKey, databaseId, scriptId }) {
  return dispatch => {
    automationServiceApi
      .deleteScript({ userKey, databaseId, scriptId })
      .then(() => dispatch(fetchScripts({ userKey, databaseId })));
  };
}

function fetchSelectedScript() {
  return dispatch => dispatch(receivedScript(mockScript));
}

export {
  clearScripts,
  getCommandStatuses,
  fetchMappers,
  cancelBatchProcess,
  fetchScripts,
  createScript,
  updateScript,
  fetchScriptCommands,
  deleteScript,
  fetchSelectedScript,
  runRollForwardBatchProcess,
};
