import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import {
  Form,
  Row,
  Col,
  Card
} from 'react-bootstrap';
import * as style from '@app/utilities/globalStyles';
import FormulaModal from './formulaModal';
import CreateTodDatabaseModal from './createTodDatabaseModal';
import { notifySuccess, notifyError } from '@app/utilities/notifier';
import { validateName } from '@app/utilities/validators';
import { resetScrollTop } from '@app/utilities/helpers';
import ColumnRow from './columnRow';
import SaveButtons from '@app/shared/presentational/saveButtons';
import { SITE_CREATETODDATABASE, DATA_MODIFY } from '@app/utilities/permissions';
import { Table, Button } from '@arius';
import Feature from '@app/utilities/routing/routeSafeFeature';

class CreateTodDatabasePage extends Component {
    static propTypes = {
        params: PropTypes.object.isRequired,
        userKey: PropTypes.string.isRequired,
        isFetching: PropTypes.bool,
        columnTypes: PropTypes.array,
        application: PropTypes.object,
        getDatabases: PropTypes.func,
        getCurrentDbVersion: PropTypes.func,
        selectDatabaseHandler: PropTypes.func,
        currentDatabase: PropTypes.object,
        getDatabase: PropTypes.func,
        getDatabaseColumns: PropTypes.func,
        getColumnTypes: PropTypes.func,
        databases: PropTypes.array,
        clearNotificationHandler: PropTypes.func,
        newSaveHandler: PropTypes.func,
        updateSaveHandler: PropTypes.func,
        serverNotification: PropTypes.object,
        uploads: PropTypes.array,
        getUploads: PropTypes.func,
        getColumnsForCsvFile: PropTypes.func,
        fileColumns: PropTypes.array,
        archivedBackups: PropTypes.array,
        isSaving: PropTypes.bool,
        errorMessage: PropTypes.string,
        validateFormulas: PropTypes.func,
        clearErrorMessageHandler: PropTypes.func,
        userPermissions: PropTypes.array,
        verifyPermission: PropTypes.func,
    };
    static defaultProps = {
        uploads: [],
        userKey: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            databaseColumns: [],
            allColumnsSelected: false,
            databaseDesc: '',
            databaseName: '',
            isEditing: false,
            selectedFile: null,
            isFormulaModalOpen: false,
            isCreateModalOpen: false,
            buildWithDiagonals: false,
            selectedColumnIndex: null,
            selectedColumn: null,
            softErrorMode: true,
            useADX: false,
            uploads: [],
        };
        this.descChangeHandler = this.descChangeHandler.bind(this);
        this.nameChangeHandler = this.nameChangeHandler.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
        this.saveDatabase = this.saveDatabase.bind(this);
        this.populateColumnsFromCsv = this.populateColumnsFromCsv.bind(this);
        this.getFileColumns = this.getFileColumns.bind(this);
        this.changeColumnName = this.changeColumnName.bind(this);
        this.closeFormulaModal = this.closeFormulaModal.bind(this);
        this.closeCreateModal = this.closeCreateModal.bind(this);
        this.openCreateModal = this.openCreateModal.bind(this);
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.deleteSelectedColumns = this.deleteSelectedColumns.bind(this);
        this.validateFormula = this.validateFormula.bind(this);
        this.changeColumnFormula = this.changeColumnFormula.bind(this);
        this.validateColumnName = this.validateColumnName.bind(this);
        this.getNameValidationError = this.getNameValidationError.bind(this);
        this.getSchemaGrid = this.getSchemaGrid.bind(this);
        this.getInfoControls = this.getInfoControls.bind(this);
    }

    componentDidMount() {
        // document.body.style.overflow = 'hidden';
        const {
            params,
            browserHistory,
            currentDatabase,
            userKey,
            getUploads,
            selectDatabaseHandler,
            userPermissions,
            verifyPermission,
        } = this.props;
        const databaseId = parseInt(params.databaseId, 10);
        if (databaseId) {
            this.setState({
                isEditing: true,
                databaseDesc: currentDatabase ? currentDatabase.description : '',
                databaseName: currentDatabase ? currentDatabase.name : '',
                databaseColumns: currentDatabase ? JSON.parse(JSON.stringify(currentDatabase.columns)) : [],
                buildWithDiagonals: currentDatabase ? currentDatabase.accByDiagonals : false,
                useADX: currentDatabase ? currentDatabase.useADX : false,
                uploads: [],
            });
        } else {
            selectDatabaseHandler(null);
        }
        getUploads(userKey);
        if (userPermissions && userPermissions.length) {
            if (!params.databaseId) {
                if (userPermissions.indexOf(SITE_CREATETODDATABASE) === -1) {
                    browserHistory.push('/unauthorized');
                }
            }
            else {
                if ((userPermissions.indexOf(DATA_MODIFY) === -1) && 
                    verifyPermission(DATA_MODIFY)) {
                    browserHistory.push('/unauthorized');
                }
            }
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {
            params,
            browserHistory,
            currentDatabase,
            fileColumns,
            isSaving,
            uploads,
            userPermissions,
        } = this.props;

        if (
            currentDatabase &&
            params.databaseId &&
            prevProps.currentDatabase !== currentDatabase
        ) {
            this.setState({
                databaseDesc: currentDatabase.description,
                databaseName: currentDatabase.name,
                databaseColumns: JSON.parse(JSON.stringify(currentDatabase.columns)),
                buildWithDiagonals: currentDatabase.accByDiagonals,
                useADX: currentDatabase.useADX
            });

            if (currentDatabase.isFetching) {
                return;
            }
        }

        if (
            userPermissions &&
            userPermissions.length &&
            userPermissions !== prevProps.userPermissions
        ) {
            if (userPermissions.indexOf(SITE_CREATETODDATABASE) === -1) {
                browserHistory.push('/unauthorized');
            }
        }

        if (uploads !== prevProps.uploads) {
            this.setState({
                uploads: uploads.sort((a, b) => a.name.localeCompare(b.name)),
            });
        }

        if (prevProps.fileColumns !== fileColumns) {
            this.populateColumnsFromCsv(fileColumns);
        }

        if (prevProps.isSaving && !isSaving) {
            notifySuccess('Database saved!');
        }
        if (prevState.databaseColumns.length < this.state.databaseColumns.length) {
            const databaseColumnsTable = document.getElementById(
                'databaseColumnsTable',
            );
            if (databaseColumnsTable) {
                databaseColumnsTable.scrollTop = databaseColumnsTable.scrollHeight;

                const lastRow = databaseColumnsTable.lastChild;
                lastRow.scrollIntoView(true);

                const nameInput = document.getElementById(
                    `nameInput${this.state.databaseColumns.length - 1}`,
                );
                if (nameInput) {
                    nameInput.focus();
                }
            }
        }
    }

    componentWillUnmount() {
        // document.body.style.overflow = 'auto';
    }

    getNameValidationError(soft) {
        const { databaseName } = this.state;

        let trimmed = databaseName.trim();
        const { databases, currentDatabase, isSaving } = this.props;

        const error = validateName(trimmed, soft, false, true);

        if (error !== '') {
            return error;
        }

        trimmed = trimmed.toLowerCase();

        trimmed = trimmed.toLowerCase();

        if (
          databases.find(
            db =>
              db.workspaceName.toLowerCase() === trimmed &&
              !isSaving &&
              (!currentDatabase || currentDatabase.id !== db.workspaceId),
          )
        ) {
          return 'Already exists';
        }
        return '';
    }

    setSoftErrorMode(mode) {
        const { softErrorMode } = this.state;
        if (softErrorMode !== mode) {
            this.setState({ softErrorMode: mode });
        }
    }

    nameChangeHandler(newName) {
        let databaseName = newName;
        if (databaseName && typeof databaseName !== 'string') {
            databaseName = '';
        }
        this.setState({ databaseName });
    }

    getFileColumns() {
        const { selectedFile } = this.state,
        { getColumnsForCsvFile, userKey } = this.props;

        if (selectedFile) {
            getColumnsForCsvFile(userKey, selectedFile.id);
        } else {
            notifyError('No file selected');
        }
    }

    addColumn() {
        const scrollTop = document.getElementById('create-tod-container').scrollTop;
        const { currentDatabase } = this.props;
        const databaseColumns = this.state.databaseColumns.slice();
        let columnType = 'measure';
        if (currentDatabase) {
            columnType = currentDatabase.hasDataLoaded ? 'calc_measure' : 'measure';
        }
        databaseColumns.push({
            isSelected: false,
            isCumulative: false,
            factColumnDisplayName: '',
            columnType,
            formula: '',
        });
        this.setState({ databaseColumns }, () => resetScrollTop('create-tod-container', scrollTop));
    }

    deleteColumn(idx) {
        const { databaseColumns } = this.state;
        databaseColumns.splice(idx, 1);
        this.setState({ databaseColumns });
    }

    deleteSelectedColumns() {
        let { databaseColumns } = this.state;
        databaseColumns = databaseColumns.filter(col => !col.isSelected);
        this.setState({ databaseColumns, allColumnsSelected: false });
    }

    changeColumnName(index, newName) {
        let { databaseColumns } = this.state;
        const column = databaseColumns.find((c, idx) => idx === index);
        if (column) {
            databaseColumns = databaseColumns.map((col, idx) => {
                const c = col;
                if (idx === index) {
                    c.factColumnDisplayName = newName;
                }
                return c;
            });
            this.setState({ databaseColumns });
        }
    }

    changeColumnType(index, e) {
        let { databaseColumns } = this.state;
        const column = databaseColumns.find((c, idx) => idx === index);
        if (column) {
            databaseColumns = databaseColumns.map((col, idx) => {
                const c = col;
                if (idx === index) {
                    c.columnType = e.target.value;
                }
                return c;
            });
            this.setState({ databaseColumns });
        }
    }

    changeColumnCumulativeValue(index, e) {
        let { databaseColumns } = this.state;
        const column = databaseColumns.find((c, idx) => idx === index);
        if (column) {
            databaseColumns = databaseColumns.map((col, idx) => {
                const c = col;
                if (idx === index) {
                    c.isCumulative = e.target.checked;
                }
                return c;
            });
            this.setState({ databaseColumns });
        }
    }

    changeColumnFormula(index, formula) {
        let { databaseColumns } = this.state;
        const column = databaseColumns.find((c, idx) => idx === index);
        if (column) {
            databaseColumns = databaseColumns.map((col, idx) => {
                const c = col;
                if (idx === index) {
                    c.formula = formula;
                }
                return c;
            });
            this.setState({ databaseColumns });
        }
    }

    descChangeHandler(e) {
        this.setState({ databaseDesc: e.target.value });
    }

    populateColumnsFromCsv(columns) {
        const { currentDatabase } = this.props;
        const { databaseColumns } = this.state;
        let columnType = 'measure';
        if (currentDatabase) {
            columnType = currentDatabase.hasDataLoaded ? 'calc_measure' : 'measure';
        }
        columns.forEach(name =>
            databaseColumns.push({
                factColumnDisplayName: name,
                columnType,
                formula: '',
                isCumulative: false,
                isSelected: false
        }));
        this.setState({ databaseColumns });
    }

    saveDatabase() {
        const {
            databaseColumns,
            databaseDesc,
            databaseName,
            buildWithDiagonals,
            isEditing,
            useADX
        } = this.state;
        const {
            newSaveHandler,
            userKey,
            currentDatabase,
            updateSaveHandler,
            browserHistory
        } = this.props;
        if (!this.validate(false)) {
            this.setSoftErrorMode(false);
            return;
        }

        if (isEditing) {
            updateSaveHandler({
                userKey,
                databaseId: currentDatabase.id,
                name: databaseName,
                description: databaseDesc,
                accByDiagonals: buildWithDiagonals,
                columns: databaseColumns,
                browserHistory
            });
        } else {
            newSaveHandler({
                userKey,
                name: databaseName,
                description: databaseDesc,
                accByDiagonals: buildWithDiagonals,
                columns: databaseColumns,
                useADX
            });
            this.openCreateModal();
        }
    }

    closeFormulaModal() {
        this.setState({ isFormulaModalOpen: false });
    }

    closeCreateModal() {
        this.setState({ isCreateModalOpen: false });
    }

    openFormulaModal(index) {
        const { databaseColumns } = this.state;
        const selectedColumn = databaseColumns.find((c, idx) => idx === index);
        this.setState({
            isFormulaModalOpen: true,
            selectedColumnIndex: index,
            selectedColumn,
        });
    }

    openCreateModal() {
        this.setState({ isCreateModalOpen: true });
    }

    selectAllColumns(e) {
        const { currentDatabase } = this.props;
        const { databaseColumns } = this.state;
        databaseColumns.forEach(col => {
            if (!currentDatabase ||
                (currentDatabase && !currentDatabase.hasDataLoaded) ||
                !col.factColumnGuid
            ) {
                col.isSelected = e.target.checked;
            }
        });
        this.setState({ databaseColumns, allColumnsSelected: e.target.checked });
    }

    selectColumn(idx) {
        const { databaseColumns } = this.state;
        databaseColumns.forEach((col, index) => {
            if (index === idx) {
                col.isSelected = !col.isSelected;
            }
        });
        this.setState({ databaseColumns });
    }

    validateFormula(columns) {
        const { userKey, validateFormulas } = this.props;
        validateFormulas({ userKey, columns });
    }

    validateColumnName(idx, soft) {
        const { databaseColumns } = this.state;
        const col = databaseColumns[idx];
        return col ? validateName(col.factColumnDisplayName || '', soft, false, true) : '';
    }

    validateColumnsComposition() {
        const { databaseColumns } = this.state, nameMap = {}, typeMap = {};
        let dupName = false, emptyFormula, nameError = '', columnError = null;

        databaseColumns.every((col, idx) => {
            nameError = validateName(col.factColumnDisplayName, false, false, true);
            if (nameError) {
                columnError = `Column ${idx + 1} name: ${nameError}`;
                return false;
            }
            const lowerCaseName = col.factColumnDisplayName.toLowerCase();

            if (nameMap[lowerCaseName]) {
                dupName = true;
            } else {
                nameMap[lowerCaseName] = true;
            }

            if (col.columnType === 'calc_measure' && !col.formula) {
                emptyFormula = true;
            }

            typeMap[col.columnType] = (typeMap[col.columnType] || 0) + 1;

            return true;
        });

        if (nameError) {
            return columnError;
        }

        if (dupName) {
            columnError = 'Column names must be unique';
        }

        if (!typeMap.measure || !typeMap.dimension || !typeMap.dateY || typeMap.dateX !== 1) {
            columnError = 'A database must be defined with at least one of the following column types:' +
                'dimension, measure, exposure date, and only one development date';
        }

        if (emptyFormula) {
            columnError = 'Calculated Measures must contain a formula';
        }

        return columnError;
    }

    validate(soft) {
        const dbNameError = this.getNameValidationError(soft);
        if (dbNameError) {
            notifyError(`Database name: ${dbNameError}`);
            return false;
        }
        if (!soft) {
            const columnSetError = this.validateColumnsComposition();
            if (columnSetError) {
                notifyError(columnSetError);
                return false;
            }
        }
        return !dbNameError;
    }

    getSchemaGrid() {
        const {
            databaseColumns,
            isEditing,
            allColumnsSelected,
            softErrorMode,
        } = this.state;
        const {
            columnTypes,
            currentDatabase,
        } = this.props;
        let {tr:Tr, th:Th, tbody:Tbody, thead:Thead, responsiveTable:Tbl} = Table;
        return (
            <Tbl>
                <Thead>
                <Tr>
                    <Th style={{ width: '5%' }}>
                        <input
                            type="checkbox"
                            onChange={this.selectAllColumns}
                            checked={allColumnsSelected}
                        />
                    </Th>
                    <Th style={{ width: '20%' }}>Column Name</Th>
                    <Th style={{ width: '20%' }}>Column Type</Th>
                    <Th style={{ width: '10%' }}>Cumulative</Th>
                    <Th style={{ width: '20%' }}/>
                    <Th style={{ width: '20%' }}>Formula</Th>
                    <Th style={{ width: '10%' }}/>
                </Tr>
                </Thead>
                <Tbody style={style.tableBody} scroll="false" id="databaseColumnsTable">
                {databaseColumns.map((column, idx) => {
                    const canEdit = !(isEditing &&
                    currentDatabase &&
                    currentDatabase.hasDataLoaded &&
                    column.factColumnGuid);
                    return (
                    <ColumnRow
                        column={{ ...column }}
                        idx={idx}
                        key={`column-row-${idx}`}
                        canEdit={canEdit}
                        columnTypes={columnTypes}
                        onSelectChanged={() => this.selectColumn(idx)}
                        onNameChanged={e => this.changeColumnName(idx, e)}
                        onTypeChanged={e => this.changeColumnType(idx, e)}
                        onCumulativeChanged={e =>
                        this.changeColumnCumulativeValue(idx, e)}
                        openFormulaModal={() => this.openFormulaModal(idx)}
                        deleteColumn={() => this.deleteColumn(idx)}
                        validationError={this.validateColumnName(
                        idx,
                        softErrorMode,
                        )}
                        hasDataLoaded={
                        currentDatabase && currentDatabase.hasDataLoaded
                        }
                    />
                    );
                })}
                </Tbody>
            </Tbl>
        )
    }
    getSchemaControls() {
        return (
            <Card style={{backgroundColor: '#F9F9F9', border: 'none', margin: '20px'}}>
                <Card.Body>
                    <Card.Title>Database Schema</Card.Title>  
                    <Row>
                        <Button variant="primary" onClick={this.addColumn} style={{ marginLeft: 10}}>
                            <i className="material-icons">add</i>Add Column
                        </Button>
                        <Button variant="danger" onClick={this.deleteSelectedColumns} style={{ marginLeft: 10}}>
                            <i className="material-icons" >remove</i>
                            <span>Remove Columns</span>
                        </Button>
                    </Row>
                    <Row style={{padding: 10}}>
                        {this.getSchemaGrid()}
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    getInfoControls() {
        const {
            isEditing,
            buildWithDiagonals,
            softErrorMode,
            uploads,
            useADX
        } = this.state;
        const {
            currentDatabase,
        } = this.props;

        const dbNameValidationError = this.getNameValidationError(softErrorMode);

        return (
            <Card style={{backgroundColor: '#F9F9F9', border: 'none', margin: '20px'}}>
                <Card.Body>
                    <Card.Title>Enter Database Information</Card.Title>  
                    <Row>
                        <Form.Group as={Col} md={9}>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                                type="text"
                                maxLength="100"
                                id="databaseDesc"
                                placeholder="Name"
                                value={this.state.databaseName}
                                isInvalid={dbNameValidationError}
                                onChange={e => this.nameChangeHandler(e.target.value)}
                                onBlur={e => this.nameChangeHandler(e.target.value.trim())}
                            />
                            <Form.Control.Feedback type="invalid">{dbNameValidationError}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={9}>
                            <Form.Label>Description:</Form.Label>
                            <Form.Control
                                type="text"
                                maxLength="1024"
                                id="databaseDesc"
                                placeholder="Description"
                                as="textarea"
                                value={this.state.databaseDesc}
                                onChange={this.descChangeHandler}/>
                        </Form.Group>
                        <Form.Group as={Col} md={12}>
                            <Form.Check 
                                custom
                                id='buildTrianglesChbx'
                                style={{ marginRight: 10 }}
                                label='Build Triangles using diagonals'
                                checked={buildWithDiagonals}
                                onChange={() => this.setState({ buildWithDiagonals: !buildWithDiagonals })}
                            />
                        </Form.Group>
                    <Feature flag="adxReporting">
                        <Form.Group as={Col} md={12}>
                            <Form.Check 
                                custom
                                id='useADXChbx'
                                style={{ marginRight: 10 }}
                                label='Use ADX'
                                disabled={isEditing}
                                checked={useADX}
                                onChange={() => this.setState({ useADX: !useADX })}
                            />
                        </Form.Group>
                    </Feature>

                        {isEditing && currentDatabase && currentDatabase.hasDataLoaded
                    ? ''
                    : <div>
                        <Form.Group controlId="fileSelect" as={Col} md={12}>
                            <Form.Label>Select File:</Form.Label>
                            <Form.Control
                                as="select"
                                placeholder="Select a CSV File"
                                onChange={e =>
                                this.setState({
                                    selectedFile: uploads.find(
                                    u => u.id === e.target.value,
                                    ),
                                })}
                            >
                                <option value="select">-- Select a CSV file --</option>
                                {uploads.map(u => (
                                <option key={`${u.id}`} value={u.id}>{u.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Col md={12}>
                            <button
                                className="btn btn-primary"
                                style={{ display: 'flex', alignItems: 'center' }}
                                onClick={this.getFileColumns}
                            >
                                <i className="material-icons" key="add-column">add</i>
                                <span>Populate Columns Below from CSV File</span>
                            </button>
                        </Col>
                    </div>}
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    render() {
        const {
            databaseColumns,
            isEditing,
            selectedColumnIndex,
            selectedColumn,
        } = this.state;
        const {
            browserHistory,
            clearNotificationHandler,
            serverNotification,
            getDatabases,
            userKey,
            errorMessage,
            clearErrorMessageHandler,
            jobStatus,
        } = this.props;

        return (
            <div id="create-tod-container" className="list-container-arius"
                 style={{maxHeight: 'calc(100vh - 106px)', overflowY: 'auto'}}>
                <div className="list-header-arius">
                    <h4>{isEditing ? 'Edit Database' : 'Create Database'}</h4>
                    <Button mode='back' path='/trianglesOnDemand/databases' toolTip='Back to Database List'/>
                </div>
                {this.getInfoControls()}
                {this.getSchemaControls()}
                <SaveButtons
                    saveHandler={this.saveDatabase}
                    backButtonHander={() =>
                        browserHistory.push('/trianglesOnDemand/databases')}
                    backButtonText="Back to Databases"
                />
                <CreateTodDatabaseModal
                    browserHistory={browserHistory}
                    modalId="createTodDatabaseModal"
                    showModal={this.state.isCreateModalOpen}
                    closeHandler={this.closeCreateModal}
                    clearNotificationHandler={clearNotificationHandler}
                    clearErrorMessageHandler={clearErrorMessageHandler}
                    errorMessage={errorMessage}
                    serverNotification={serverNotification}
                    getDatabases={getDatabases}
                    userKey={userKey}
                    jobStatus={jobStatus}
                />
                <FormulaModal
                    modalId="formulaModal"
                    columnIndex={selectedColumnIndex}
                    column={selectedColumn}
                    errorMessage={errorMessage}
                    clearErrorMessageHandler={clearErrorMessageHandler}
                    measures={databaseColumns.filter(col => col.columnType === 'measure')}
                    formulaChangeHandler={this.changeColumnFormula}
                    validateFormulaHandler={this.validateFormula}
                    showModal={this.state.isFormulaModalOpen}
                    closeHandler={this.closeFormulaModal}
                />
            </div>
        )
    }
}

export default radium(CreateTodDatabasePage);
