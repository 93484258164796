import {
  RECEIVED_INTEGRATION_SETTINGS,
  RECEIVED_API_VERSION,
  RECEIVED_ERROR_FROM_SERVER,
  CLEAR_ERROR_MESSAGE,
  RECEIVED_APP_SETTINGS,
  CLEAR_BACK_ROUTE,
  SETTING_BACK_ROUTE,
  RECEIVED_ARIUS_VERSION,
} from '../actions/actionTypes';

const application = (state = {
  name: '',
  tab: '',
  apiVersion: null,
  ariusVersion: null,
  errorMessage: null,
  backRoute: {},
  settings: null
}, action) => {
  switch (action.type) {
    case RECEIVED_APP_SETTINGS:
      return Object.assign({}, state, {
        settings: action.settings,
      });
    case RECEIVED_INTEGRATION_SETTINGS:
      return Object.assign({}, state, {
        lockDataFromTOD: action.settings.lockDataFromDTO,
      });
    case RECEIVED_API_VERSION:
      return Object.assign({}, state, {
        apiVersion: action.apiVersion,
      });
    case RECEIVED_ARIUS_VERSION:
      return Object.assign({}, state, {
        ariusVersion: action.ariusVersion,
      });
    case RECEIVED_ERROR_FROM_SERVER:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
      });
    case CLEAR_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: null,
      });
    case CLEAR_BACK_ROUTE:
      return Object.assign({}, state, {
        backRoute: {},
      });
    case SETTING_BACK_ROUTE:
      return Object.assign({}, state, {
        backRoute: {
          path: action.route,
        },
      });
    default:
      return state;
  }
};

export default application;
