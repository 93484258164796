import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import * as Colors from '@app/utilities/colors';
import Loader from '@app/shared/presentational/loader';
import { ProgressBar } from 'react-bootstrap';
import { showModal } from '@app/actions/modal.actions';
import { notAuthorized } from '@app/shared/containers/conditionalLink';
import { Grid, Button } from '@arius';

class UploadsList extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        uploads: PropTypes.array.isRequired,
        isSaving: PropTypes.bool,
        isFetching: PropTypes.bool,
        getUploads: PropTypes.func,
        deleteFile: PropTypes.func,
        uploadNewFile: PropTypes.func,
        errorMessage: PropTypes.string,
        clearError: PropTypes.func,
        uploadNewFile2: PropTypes.func,
        hasWriteAccess: PropTypes.bool,
    };
    static defaultProps = {
        uploads: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            isDescending: false,
            columnSelected: '',
            uploadForm: (
                <form id="file-upload-form-0">
                <input type="file" id="file-upload-input-0" style={{ visibility: 'hidden' }}
                    onChange={(e) => this.onFileInputChange(e)}
                    accept=".csv,text/plain,text/csv" />
                </form>
            ),
            uploadFormId: 0,
            method: null,
        };
        this.uploadFile = this.uploadFile.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { isSaving } = this.props;
        if (prevProps.isSaving && !isSaving) {
            const uploadFormId = this.state.uploadFormId + 1;
            this.setState({
                uploadForm: (
                <form id={`file-upload-form-${uploadFormId}`}>
                    <input type="file" id={`file-upload-input-${uploadFormId}`}style={{ visibility: 'hidden' }}
                    onChange={(e) => this.onFileInputChange(e)}
                    accept=".csv,text/plain,text/csv" />
                </form>
                ),
                uploadFormId,
        });
        }

        if (prevState.uploadForm !== this.state.uploadForm) {
            const input = document.getElementById(`file-upload-input-${this.state.uploadFormId}`);
            input.value = '';
        }
    }

    onFileInputChange(e) {
        const method = this.state.method;
        if (method) {
            const files = e.target.files;
            const file = files[0];
            if (file) {
                const { userKey, uploadNewFile2, clearError } = this.props;
                const data = new FormData();

                data.append('file', file);

                clearError();
                uploadNewFile2(userKey, data);

                this.setState({ isUploading: true });
            }
        } else {
            const files = e.target.files;
            const file = files[0];
            if (file) {
                const { userKey, uploadNewFile, clearError } = this.props;
                const data = new FormData();

                data.append('file', file);

                clearError();
                uploadNewFile(userKey, data);

                this.setState({ isUploading: true });
            }
        }
    }

    uploadFile() {
        this.setState({ method: false });
        const input = document.getElementById(`file-upload-input-${this.state.uploadFormId}`);
        if (input) {
            input.click();
        }
    }

    deleteFile(id, e) {
        e.stopPropagation();

        const { dispatch, uploads } = this.props;

        if (id && uploads.length > 0) {
            const selectedUpload = uploads.find(u => u.id === id);
            let deleteMessageItems = selectedUpload.files.map(x=> (
                <li key={x.id} style={{ fontWeight: 800 }}>{x.name}</li>
            ));

            deleteMessageItems.unshift('Are you sure you want to delete the following files?');

            const yesClickHandler = () => {
                const { userKey, deleteFile } = this.props;
                deleteFile(userKey, id);
            };
            const noClickHandler = () => {};
            const action = showModal('confirmation', deleteMessageItems, yesClickHandler, noClickHandler);
            dispatch(action);
        }
    }

    getGrid() {
        const { hasWriteAccess, uploads: data } = this.props;

        let columns = [
            { field: 'id', isPrimaryKey: true, visible: false},
            { field: 'displayName', headerText: 'Name', minWidth: '500', width: '50%'},
            { field: 'uploadedByUTC', headerText: 'Uploaded', type: 'datetime', width: '130px'},
            { field: 'uploadedBy', headerText: 'Uploaded By', width: '130px'},
            { field: 'size', headerText: 'Size', width: '70px', template: (r) => <span>{r.sizeFormatted}</span>},
            { headerText: 'Actions', width: '100px', template: (row) => {
                return <Button toolTip="Delete" iconName="delete" onClick={
                    (e) => hasWriteAccess ? this.deleteFile(row.id, e) : notAuthorized()}/>
            }}
        ];
        
        return (<Grid 
            data={data} 
            columns={columns}
            height='calc(100vh - 250px)'
            detailTemplate={this.getDetailTemplate}
        />)
    }

    getDetailTemplate(row) {
        if (row.files.length === 1 && row.files[0].sourceFileName === row.name){
            return <div>[ Nothing to expand ]</div>
        }

        let columns = [
            { field: 'id', isPrimaryKey: true, visible: false},
            { field: 'name', headerText: 'Name', minWidth: '500', width: '50%'},
            { field: 'uploadedByUTC', headerText: 'Uploaded', type: 'datetime', width: '130px'},
            { field: 'uploadedBy', headerText: 'Uploaded By', width: '130px'},
            { field: 'size', headerText: 'Size', width: '70px', template: (r) => <span>{r.sizeFormatted}</span>},
        ];
        
        return (<Grid 
            data={row.files} 
            columns={columns}
            height='auto'
        />)
    }

    render() {
        const { isSaving, isFetching, errorMessage, hasWriteAccess, uploads } = this.props;
        const { uploadForm } = this.state;

        let contentMarkup = (<div>You have no uploaded files</div>);

        if (isFetching) {
            contentMarkup = (<Loader loadingItem="Uploads" />);
        }

        if (uploads.length > 0) {
            contentMarkup = this.getGrid();
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>CSV Uploads</h4>
                <Button toolTip="Upload CSV Files" mode="add" onClick={hasWriteAccess ? this.uploadFile : notAuthorized}/>
            </div>
            <div style={{ minHeight: 50 }}>
            {isSaving && !errorMessage ? (
                <div>
                <ProgressBar animated now={100} />
                <h5 style={{ textAlign: 'center' }}>Loading...</h5>
                </div>
            ) : (<span></span>)}
                <h5 style={{ textAlign: 'center', color: Colors.red }}>{ errorMessage }</h5>
            </div>
            {contentMarkup}
            {uploadForm}
        </div>
        )
    }
}

export default radium(UploadsList);
