import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form, DropdownButton, Dropdown } from 'react-bootstrap';
import * as GlobalStyles from '../../../utilities/globalStyles';
import * as Colors from '../../../utilities/colors';
import Loader from '../../shared/presentational/loader';
import moment from 'moment';
import { saveAs } from 'file-saver';
import TotalsSelections from './totalsSelections';
import TotalsTable from './totalsTable';

const style = {
  table: {
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    backgroundColor: 'white',
    overflow: 'scroll',
  },
  tableCell: {
    border: '1px solid black',
    textAlign: 'right',
    padding: 10,
  },
  tableHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  checkboxRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    margin: 10,
    cursor: 'pointer',
  },
  checkboxValue: {
    width: '100%',
    paddingLeft: 10,
  },
  checkboxInput: {
    padding: 20,
    marginRight: 5,
  },
};

class TotalsTabContent extends Component {
  static propTypes = {
    userKey: PropTypes.string,
    currentDb: PropTypes.object,
    getDatabase: PropTypes.func,
    getTotals: PropTypes.func,
    isFetchingDatabases: PropTypes.bool,
    isFetching: PropTypes.bool,
    application: PropTypes.object,
    getDatabases: PropTypes.func,
    allDatabases: PropTypes.array,
    changeDatabase: PropTypes.func,
    dataSource: PropTypes.object,
    getTotalsTable: PropTypes.func,
    clearTableHandler: PropTypes.func,
    exportCsv: PropTypes.func,
    exportExcel: PropTypes.func,
    totalsTable: PropTypes.object,
    isFetchingTable: PropTypes.bool,
    clearDataSourceHandler: PropTypes.func,
    csvFile: PropTypes.object,
    excelFile: PropTypes.object,
    databases: PropTypes.array,
  };
  static defaultProps = {
    databases: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      rows: [],
      measures: [],
      filters: [],
      asOfDateSelected: null,
      selectedFilter: null,
      databases: [],
      tableRows: [],
    };
    this.changeDatabase = this.changeDatabase.bind(this);
    this.selectAllMeasures = this.selectAllMeasures.bind(this);
    this.addRemoveRow = this.addRemoveRow.bind(this);
    this.addRemoveColumn = this.addRemoveColumn.bind(this);
    this.addRemoveMeasure = this.addRemoveMeasure.bind(this);
    this.addRemoveFilter = this.addRemoveFilter.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.selectAllForSelectedFilter = this.selectAllForSelectedFilter.bind(
      this
    );
    this.exportToCsv = this.exportToCsv.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
  }

  componentDidMount() {
    const { dataSource } = this.props;
    if (dataSource) {
      this.configureFilters(dataSource);
      this.configureAsOfDateSelected(dataSource);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dataSource,
      currentDb,
      csvFile,
      excelFile,
      totalsTable,
    } = this.props,
      { tableRows } = this.state;

    if (prevProps.dataSource !== dataSource && dataSource && currentDb) {
      this.configureFilters(dataSource);
      this.configureAsOfDateSelected(dataSource);
    }

    if (prevProps.csvFile !== csvFile) {
      saveAs(csvFile, 'Export.csv');
    }

    if (prevProps.excelFile !== excelFile) {
      saveAs(excelFile, 'Export.xlsx');
    }

    if (prevProps.totalsTable !== totalsTable && totalsTable) {
      if (totalsTable.error && totalsTable.error.message === 'The service encountered an unexpected error') {
        this.setState({ tableRows: [] });
      }
      else {
        this.setState({ tableRows: this.getPivotTableRows(totalsTable.rows) });
      }
    }

    if (tableRows !== prevState.tableRows) {
      const totalsTableElement = document.getElementById(
        'reconciliationTotalsTable'
      );
      if (totalsTableElement) {
        // alignWithTop = true;
        totalsTableElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  componentWillUnmount() {
    this.props.changeDatabase(null);
    this.props.clearTableHandler();
  }

  getPivotTableRows(rows) {
    return rows.map((row, rowIndex) => {
      const tds = row.map((td, tdIndex) => {
        if (td.isHeader) {
          return (
            <td
              key={`header-${rowIndex}-${tdIndex}`}
              colSpan={td.span}
              style={[style.tableCell, style.tableHeader]}
            >
              {td.value}
            </td>
          );
        } else {
          const cellStyle = td.value || td.hasBorders ? style.tableCell : {};
          return (
            <td key={`td-${rowIndex}-${tdIndex}`} style={cellStyle}>
              {td.value}
            </td>
          );
        }
      });
      return (
        <tr style={{ backgroundColor: 'white' }} key={`tr-${rowIndex}`}>
          {tds}
        </tr>
      );
    });
  }

  changeDatabase(e) {
    const {
      databases,
      changeDatabase,
      clearDataSourceHandler,
      clearTableHandler,
    } = this.props,
      database = databases.find(
        d => d.workspaceId.toString() === e.target.value
      );
    this.setState({
      columns: [],
      rows: [],
      measures: [],
      filters: [],
      asOfDateSelected: null,
      selectedFilter: null,
    });
    clearDataSourceHandler();
    clearTableHandler();
    changeDatabase(database);
  }

  configureFilters(dataSource) {
    const filters = dataSource.classFields.map(dim => {
      const values = dataSource.fieldValues[`${dim}`];
      return {
        values,
        name: dim,
        available: values.slice(),
      };
    });
    this.setState({ filters, selectedFilter: filters[0].name });
  }

  configureAsOfDateSelected(dataSource) {
    const dates = dataSource.asOfDates.map(date => date.text);
    this.setState({ asOfDateSelected: dates[0] });
  }

  addRemoveMeasure(measure) {
    const { measures } = this.state;
    if (measures.includes(measure)) {
      measures.splice(measures.indexOf(measure), 1);
    } else {
      measures.push(measure);
    }

    this.setState({ measures });
  }

  addRemoveRow(dim) {
    const { rows, columns } = this.state;
    if (rows.includes(dim)) {
      rows.splice(rows.indexOf(dim), 1);
    } else {
      rows.push(dim);
    }
    if (columns.includes(dim)) {
      columns.splice(columns.indexOf(dim), 1);
    }

    this.setState({ rows, columns });
  }

  addRemoveColumn(dim) {
    const { rows, columns } = this.state;
    if (columns.includes(dim)) {
      columns.splice(columns.indexOf(dim), 1);
    } else {
      columns.push(dim);
    }
    if (rows.includes(dim)) {
      rows.splice(rows.indexOf(dim), 1);
    }

    this.setState({ rows, columns });
  }

  selectAllMeasures() {
    const { dataSource } = this.props;
    let { measures } = this.state;

    if (dataSource.dataFields.length === measures.length) {
      measures = [];
    } else {
      measures = dataSource.dataFields.slice();
    }

    this.setState({ measures });
  }

  selectAllForSelectedFilter(filterChecked) {
    const { selectedFilter } = this.state;
    let { filters } = this.state;
    filters = filters.map(f => {
      const filter = f;
      if (filter.name === selectedFilter) {
        filter.values = !filterChecked ? filter.available.slice() : [];
      }
      return filter;
    });
    this.setState({ filters });
  }

  addRemoveFilter(value) {
    const { selectedFilter } = this.state;
    let { filters } = this.state;
    filters = filters.map(f => {
      const filter = f;
      if (filter.name === selectedFilter) {
        if (filter.values.includes(value)) {
          filter.values.splice(filter.values.indexOf(value), 1);
        } else {
          filter.values.push(value);
        }
      }
      return filter;
    });
    this.setState({ filters });
  }

  updateTable() {
    const { columns, measures, rows, filters, asOfDateSelected } = this.state,
      { userKey, getTotalsTable, currentDb } = this.props;
    getTotalsTable({
      userKey,
      databaseId: currentDb.id,
      valuationDate: moment(asOfDateSelected, 'M/D/YYYY').format('YYYY-MM-DD'),
      columns,
      measures,
      rows,
      filters,
    });
  }

  exportToCsv() {
    const { columns, measures, rows, filters, asOfDateSelected } = this.state,
      { userKey, exportCsv, currentDb } = this.props;

    exportCsv({
      userKey,
      databaseId: currentDb.id,
      valuationDate: moment(asOfDateSelected).format('YYYY-MM-DD'),
      columns,
      measures,
      rows,
      filters,
    });
  }

  exportToExcel() {
    const { columns, measures, rows, filters, asOfDateSelected } = this.state,
      { userKey, exportExcel, currentDb } = this.props;

    exportExcel({
      userKey,
      databaseId: currentDb.id,
      valuationDate: moment(asOfDateSelected).format('YYYY-MM-DD'),
      columns,
      measures,
      rows,
      filters,
    });
  }

  isUpdateButtonDisabled() {
    const { measures, columns, rows, asOfDateSelected } = this.state,
      { currentDb } = this.props,
      dimensions = [...columns, ...rows];
    if (
      currentDb &&
      asOfDateSelected &&
      dimensions.length > 0 &&
      measures.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const {
      databases,
      currentDb,
      isFetching,
      dataSource,
      totalsTable,
      isFetchingTable,
    } = this.props,
      {
        filters,
        columns,
        rows,
        measures,
        asOfDateSelected,
        selectedFilter,
        tableRows,
      } = this.state;

    const updateButtonDisabled = this.isUpdateButtonDisabled();
    const exportButtonsDisabled = updateButtonDisabled || !totalsTable;

    let selectionsMarkup = <Loader />,
      tableMarkup = <Loader />,
      buttonsMarkup = <div />;

    if (!isFetchingTable) {
      tableMarkup = <div />;
      if (totalsTable) {
        tableMarkup = <TotalsTable rows={tableRows} />;
      }
    } else {
      if (totalsTable) {
        tableMarkup = <TotalsTable rows={tableRows} />;
      }
    }

    if (!isFetching) {
      selectionsMarkup = <div />;
      if (currentDb) {
        selectionsMarkup = <div>No data to display</div>;
        if (dataSource) {
          selectionsMarkup = (
            <TotalsSelections
              filters={filters}
              columns={columns}
              rows={rows}
              measures={measures}
              selectedFilter={selectedFilter}
              dataSource={dataSource}
              addRemoveRow={this.addRemoveRow}
              addRemoveColumn={this.addRemoveColumn}
              selectAllMeasures={this.selectAllMeasures}
              addRemoveMeasure={this.addRemoveMeasure}
              selectAllForSelectedFilter={this.selectAllForSelectedFilter}
              addRemoveFilter={this.addRemoveFilter}
              selectFilter={e =>
                this.setState({ selectedFilter: e.target.value })}
            />
          );
          buttonsMarkup = (
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                backgroundColor: Colors.grey,
                width: '100vw',
                padding: 10,
                height: '54px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <button
                  key="update"
                  type="button"
                  style={{ ...GlobalStyles.button }}
                  onClick={this.updateTable}
                  className="btn"
                  disabled={updateButtonDisabled}
                >
                  Update
                </button>

                <DropdownButton variant="arius" title="Export" disabled={exportButtonsDisabled}>
                    <Dropdown.Item eventKey="1" onClick={this.exportToExcel}>Excel</Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={this.exportToCsv}>CSV</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          );
        }
      }
    }

    return (
      <div>
        <div className="list-container-arius" style={{ maxHeight: 'calc(100vh - 115px)', overflowY: 'auto'}}>
          <div className="list-header-arius">
            <h4>Reconciliation</h4>
          </div>
          
          <div style={{ display: 'flex' }}>
            <Form.Group
              controlId="selectDatabaseControl"
              style={{ width: '25vw', marginTop: 10 }}
              value={currentDb ? currentDb.id : 'default'}
              onChange={this.changeDatabase}
            >
              <Form.Label>Select Database</Form.Label>
              <Form.Control as="select" placeholder="select">
                <option value="default">Select a database</option>
                {databases.map(db => (
                  <option key={`${db.workspaceId}`} value={db.workspaceId}>
                    {db.workspaceName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {dataSource && !isFetching && currentDb && asOfDateSelected
              ? <Form.Group
                controlId="selectAsOfDateControl"
                style={{ width: '25vw', margin: 10 }}
                >
                  <Form.Label>Select as of Date</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="select"
                    value={asOfDateSelected}
                    onChange={e =>
                      this.setState({ asOfDateSelected: e.target.value })}
                  >
                    {dataSource.asOfDates.map(date => (
                      <option key={`${date.text}`} value={date.text}>
                        {date.text}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              : <span />}
          </div>
          
          <div style={{ marginBottom: '15px' }}>
            {selectionsMarkup}
          </div>
          {tableMarkup}
        </div>
        {buttonsMarkup}
      </div>
    );
  }
}

export default radium(TotalsTabContent);
