import React from 'react';
import { NavTabs, NavTab } from '@app/utilities/routing';

function TODNav() {
    return (
        <NavTabs>
            <NavTab label={'Databases'} to={'/trianglesOnDemand/databases'}/>
            <NavTab label={'Uploads'} to={'/trianglesOnDemand/uploads'}/>
            <NavTab label={'Queries'} to={'/trianglesOnDemand/queries'}/>
            <NavTab label={'Advanced Queries'} to={'/trianglesOnDemand/advancedQueries'}/>
            <NavTab label={'Totals'} to={'/trianglesOnDemand/totals'}/>
        </NavTabs>
    )
}   

export default TODNav;
