import React, { Component } from 'react';
import radium from 'radium';
import moment from 'moment';
import { ProgressBar, Form, Button } from 'react-bootstrap';
import { Table, Button as AButton } from '@arius';

class ArchivedTab extends Component {
	static propTypes = {};
	static defaultProps = {};
	constructor(props) {
		super(props);
		this.state = {};
    }

    render() {
      const {
        restoreArchiveMessage,
        archivedBackups,
        selectedArchived,
        isRestoringArchived,
        restoreArchivedBackup,
        deleteArchived,
        selectArchived,
      } = this.props;

      const {tr:Tr, td:Td, th:Th, tbody:Tbody, thead:Thead, responsiveTable:Tbl} = Table;

      const columnWidth = ['5%','20%','20%','15%','15%','15%','10%' ];
      const isActive = isRestoringArchived;
      const isComplete = restoreArchiveMessage.indexOf('Completed') > -1;
      const buttonsDisabled = !selectedArchived || (isActive && !isComplete);

      const archivedTab = archivedBackups.length > 0 ? (
          <Form style={{padding: 15}}>
            
            <Form.Group controlId="archiveActions">
              <Button variant="arius" onClick={restoreArchivedBackup} disabled={buttonsDisabled}>Restore Archived Backup</Button>
            </Form.Group>

            {(isActive && !isComplete) ? (<ProgressBar animated now={100} />) : ('')}
            <Form.Text style={{color: isActive ? 'purple' : 'green', paddingLeft: 12, height: 19}}>{restoreArchiveMessage}</Form.Text> 

            <Form.Group controlId="listArchives">
              <Tbl>
                <Thead>
                  <Tr>
                    <Th style={{width:columnWidth[0]}}></Th>
                    <Th style={{width:columnWidth[1]}}>Description</Th>
                    <Th style={{width:columnWidth[2]}}>Size</Th>
                    <Th style={{width:columnWidth[3]}}>Status</Th>
                    <Th style={{width:columnWidth[4]}}>Date Created</Th>
                    <Th style={{width:columnWidth[5]}}>Created By</Th>
                    <Th style={{width:columnWidth[6]}}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {archivedBackups.map(backup =>
                    (<Tr key={`archive-${backup.id}`}
                      onClick={() => selectArchived(backup.id)} style={{ cursor: 'pointer' }}>
                      <Td style={{width:columnWidth[0]}}>
                        <input checked={selectedArchived === backup} onChange={() => {}} type="radio" />
                      </Td>
                      <Td style={{width:columnWidth[1]}}>{backup.description}</Td>
                      <Td style={{width:columnWidth[2]}}>{backup.size}</Td>
                      <Td style={{width:columnWidth[3]}}>{backup.status}</Td>
                      <Td style={{width:columnWidth[4]}}>{moment.utc(backup.createdDate).local().format('L LT')}</Td>
                      <Td style={{width:columnWidth[5]}}>{backup.createdBy}</Td>
                      <Td style={{width:columnWidth[6]}}>
                        <AButton toolTip="Delete Archive" placement="left" iconName="delete" onClick={(e) => deleteArchived(backup.id)} />
                      </Td>
                    </Tr>)
                  )}
                </Tbody>
              </Tbl>
            </Form.Group>

          </Form>
        ) : (<div style={{ margin: 10 }}>You have no archived backups</div>);

        return archivedTab;
    }

}

export default radium(ArchivedTab);