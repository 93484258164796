import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import radium from 'radium';
import * as Colors from '@app/utilities/colors';
import * as GlobalStyles from '../../../utilities/globalStyles';

class PurpleButton extends Component {

    static propTypes = {
        message: PropTypes.string,
        id: PropTypes.string,
        type: PropTypes.string
    };

    render() {

        const {message, id, type, clickHandler, waiting}=this.props;

        let isBusy = waiting === true;
        let iconType = 'add';
        let btnStyle = {
            color: Colors.darkGrey,
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '4.5vmin',
            height: '4.5vmin',
            width: '4.5vmin',
            textAlign: 'center',
            ':hover': {
                backgroundColor: Colors.purple,
                color: 'white',
                transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
            },
        };
        let busyStyle = {
            color: Colors.purple,
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '4.5vmin',
            height: '4.5vmin',
            width: '4.5vmin',
            textAlign: 'center',
            position:'absolute', 
            top: '0', left: '0',
            display: isBusy ? '' : 'none'
        };

        switch(type) {
            case 'save':
                let side = '3.0vmin';
                btnStyle.fontSize = side;
                btnStyle.paddingTop = '0px';
                btnStyle.height = side;
                btnStyle.width = side;
                busyStyle.height = side;
                busyStyle.width = side;
                busyStyle.fontSize = side;
                iconType = 'save';
                break;
            case 'download':
                btnStyle.fontSize = '3.75vmin';
                btnStyle.paddingTop = '5px';
                iconType = 'get_app';
                break;
            case 'change':
                btnStyle.fontSize = '3.75vmin';
                btnStyle.paddingTop = '5px';
                iconType = 'change_history';
                break;
            case 'import':
                btnStyle.fontSize = '3.75vmin';
                btnStyle.paddingTop = '5px';
                btnStyle.fontSize = '3vmin';
                iconType = 'create';
                break;
            default:
                
        } 

        return (
 <OverlayTrigger
    placement="left"
    focus
    overlay={<Tooltip id={'tootipId_' + id} style={GlobalStyles.toolTipFix}>{message}</Tooltip>}
    >
    <div style={{position:'relative'}}>
        <i
            style={btnStyle}
            className="material-icons"
            onClick={clickHandler}
        >
            {iconType}
        </i>
        <i style={busyStyle} className="fa fa-refresh fa-spin fa-fw"></i>          
    </div>
</OverlayTrigger> 
        );
    }
}

export default radium(PurpleButton);
