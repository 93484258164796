import {
  RECEIVED_TOD_UPLOADS,
  FETCHING_TOD_UPLOADS,
  SAVING_TOD_UPLOADS,
  RECEIVED_COLUMNS_FOR_FILE } from './tod.actionTypes';
import * as uploadsServiceApi from '../../serviceApi/uploads.serviceApi';

function fetchingFromServer() {
  return {
    type: FETCHING_TOD_UPLOADS,
  };
}

function savingToServer() {
  return {
    type: SAVING_TOD_UPLOADS,
  };
}

function receivedUploads(json) {
  return {
    type: RECEIVED_TOD_UPLOADS,
    items: json,
    receivedAt: Date.now(),
  };
}

function receivedFileColumns(json) {
  return {
    type: RECEIVED_COLUMNS_FOR_FILE,
    columns: json,
    receivedAt: Date.now(),
  };
}

function fetchUploads(userKey) {
  return (dispatch) => {
    dispatch(fetchingFromServer());
    return uploadsServiceApi.retrieveUploads(userKey)
             .then(json => dispatch(receivedUploads(json)));
  };
}

function fetchGroupedUploads(userKey) {
    return (dispatch) => {
      dispatch(fetchingFromServer());
      return uploadsServiceApi.retrieveGroupedUploads(userKey)
               .then(json => dispatch(receivedUploads(json)));
    };
  }

function fetchColumnsForFile(userKey, fileId) {
  return (dispatch) => {
    if (fileId) {
      dispatch(fetchingFromServer());
      return uploadsServiceApi.retrieveColumnsForFile(userKey, fileId)
              .then((json) => dispatch(receivedFileColumns(json)));
    } else {
      return dispatch(receivedFileColumns([]));
    }
  };
}

function uploadFile(userKey, file) {
  return (dispatch) => {
    dispatch(savingToServer());
    return uploadsServiceApi.uploadFile(userKey, file)
            .then(() => dispatch(fetchGroupedUploads(userKey)));
  };
}

function deleteUploadedFile(userKey, uploadId) {
  return (dispatch) => {
    dispatch(savingToServer());
    return uploadsServiceApi.deleteUpload(userKey, uploadId)
             .then(() => dispatch(fetchGroupedUploads(userKey)));
  };
}

export {
  fetchUploads,
  uploadFile,
  deleteUploadedFile,
  fetchColumnsForFile,
  fetchGroupedUploads,
};
