import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';

export const universalFilter = (arrayToFilter, currentFilterValues, field, input) =>
  arrayToFilter.filter((atf) => {
    let match = true;
    const fields = Object.keys(currentFilterValues);
    for (const key of fields) {
      if (!match) break;
      let currentField = atf[key];
      // This is where we modify current field to align it for comparing to our filter (if needs be)
      if (typeof currentField === 'number') currentField = String(currentField);
      // The next section evaluates matches across every field of the filter object, and the current field vs. input
      if (key === field) {
        if (!(currentField && currentField.toLowerCase().indexOf(input.trim().toLowerCase()) !== -1)) match = false;
      } else if (!(currentField && currentField.toLowerCase().indexOf(currentFilterValues[key].toLowerCase()) !== -1)) match = false;
      // Anything below here takes special circumstances into account to allow for a match
      
      // The below code was added to accommodate null values for fields
      if (currentField === null && key !== field) {
        if (!currentFilterValues[key]) match = true;
      } else if (currentField === null) {
        if (!input) {
          match = true;
        }
      }
    }
    return match;
  }),
  filterInputs = (e, field) => {
    const input = e || '',
      { filter, arrayToFilter, setParentState } = this.props,
      newState = {};
    newState.filter = { ...filter };
    const localFilter = newState.filter,
      filteredMatches = universalFilter(arrayToFilter, filter, field, input);
    if (field) localFilter[field] = input;
    const filterValues = Object.values(localFilter),
      foundFilterInput = filterValues.find(fv => fv.length);
    newState.useFilteredMatches = !!foundFilterInput || false;
    newState.filteredMatches = filteredMatches;
    newState.noFilteredMatches = !filteredMatches.length || false;
    setParentState(newState);
  };

class UniversalFilter extends Component {
  static propTypes = {
    filter: PropTypes.object,
    filterDetailsConfig: PropTypes.object,
    arrayToFilter: PropTypes.array,
    setParentState: PropTypes.func,
    leftFormCustomStyle: PropTypes.object,
    rightFormCustomStyle: PropTypes.object,
    filterContainerCustomStyle: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
    };
    this.universalFilter = this.universalFilter.bind(this);
    this.filterInputs = this.filterInputs.bind(this);
  }

  universalFilter(arrayToFilter, currentFilterValues, field, input) {
    return arrayToFilter.filter((atf) => {
      let match = true;
      const fields = Object.keys(currentFilterValues);
      for (const key of fields) {
        if (!match) break;
        let currentField = atf[key];
        // This is where we modify current field to align it for comparing to our filter (if needs be)
        if (typeof currentField === 'number') currentField = String(currentField);
        // The next section evaluates matches across every field of the filter object, and the current field vs. input
        if (key === field) {
          if (!(currentField && currentField.toLowerCase().indexOf(input.trim().toLowerCase()) !== -1)) match = false;
        } else if (!(currentField && currentField.toLowerCase().indexOf(currentFilterValues[key].toLowerCase()) !== -1)) match = false;
        // Anything below here takes special circumstances into account to allow for a match
        
        // The below code was added to accommodate null values for fields
        if (currentField === null && key !== field) {
          if (!currentFilterValues[key]) match = true;
        } else if (currentField === null) {
          if (!input) {
            match = true;
          }
        }
      }
      return match;
    });
  }

  filterInputs(e, field) {
    const input = e || '',
      { filter, arrayToFilter, setParentState } = this.props,
      newState = {},
      localUniversalFilter = this.universalFilter;
    newState.filter = { ...filter };
    const localFilter = newState.filter,
      filteredMatches = localUniversalFilter(arrayToFilter, filter, field, input);
    if (field) localFilter[field] = input;
    const filterValues = Object.values(localFilter),
      foundFilterInput = filterValues.find(fv => fv.length);
    newState.useFilteredMatches = !!foundFilterInput || false;
    newState.filteredMatches = filteredMatches;
    newState.noFilteredMatches = !filteredMatches.length || false;
    setParentState(newState);
  }

  getFilter(key, i, placeholder, displayName, leftOrRight)
  {
      if (key) {
        return ([
            <label htmlFor={`filter-field-${leftOrRight}-${i}`} key={`filter-field-${leftOrRight}-${i}`} 
                   style={{textAlign: 'right', fontWeight: 'bold'}}
                   className="col-sm-2 col-form-label">
              {displayName}
            </label>,
              <div className="col-sm-4">
                  <input id={`filter-field-${leftOrRight}-${i}`} type="text"
                         className="form-control"
                         placeholder={placeholder}
                         onChange={(e) => this.filterInputs(e.target.value, key)} />
              </div>
        ]);
    }
    else {
        return <div key={`filter-field-${leftOrRight}-${i}`} className="col-sm-6"></div>
    }
  }

    render() {
        const
        { filter, filterDetailsConfig } = this.props,
        filterKeys = Object.keys(filter),
        filterLength = filterKeys.length,
        halfwayIndex = Math.floor(filterLength / 2),
        arrFilterKeys = [];

    for (var i = 0; i < halfwayIndex; i++) {
        arrFilterKeys.push([filterKeys[i], filterKeys[i + halfwayIndex]]);
    }
    if (arrFilterKeys.length < filterLength) {
        arrFilterKeys.push([null, filterKeys[filterLength-1]]);
    }

    let searchFilterJSX = (
        <form style={{marginTop: '20px', marginBottom: '20px'}}>
            <div className="form-group">
            {
                arrFilterKeys.map((key, i) => {
                    const displayName1 = key[0] ? filterDetailsConfig[key[0]].displayName : null,
                          placeholder1 = key[0] ? filterDetailsConfig[key[0]].placeholder : null,
                          displayName2 = key[1] ? filterDetailsConfig[key[1]].displayName : null,
                          placeholder2 = key[1] ? filterDetailsConfig[key[1]].placeholder : null;
                    return (
                        <div className="row" style={{marginTop: '5px'}} key={`filter-span-${i}`}>
                            { [this.getFilter(key[0], i, placeholder1, displayName1, "left"),
                               this.getFilter(key[1], i, placeholder2, displayName2, "right")] }
                        </div> 
                    )
                })
            }
            </div>
        </form>
    )
    return (
      <div style={{marginRight: '40px'}}>
        {searchFilterJSX}
      </div>
    );
  }
}

export default radium(UniversalFilter);
