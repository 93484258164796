import {
  FETCHING_EXTRACT_DEFINITIONS,
  RECEIVED_EXTRACT_DEFINITION_NAMES,  
  FETCHING_REPORTING_DEFINITIONS,
  RECEIVED_REPORTING_DEFINITIONS,
  INITIATING_EXTRACT,
  EXTRACT_INITIATED,
  RECEIVED_REPORTING_CSV_FILE,
  FETCHING_REPORTING_WIDE_CSV,
  FETCHING_REPORTING_NARROW_CSV,
  RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION,
} from './analysis.actionTypes';
import * as analysisReportingServiceApi from '../../serviceApi/analysisReporting.serviceApi';
import { notifySuccess } from '../../utilities/notifier';

function fetchingExtractDefinitionNames() {
  return {
    type: FETCHING_EXTRACT_DEFINITIONS,
  };
}

function receivedExtractDefinitionNames(extractDefinitions) {
  return {
    type: RECEIVED_EXTRACT_DEFINITION_NAMES,
    extractDefinitions,
  };
}

function fetchingReportingDefinitions() {
    return {
      type: FETCHING_REPORTING_DEFINITIONS,
    };
  }
  
  function receivedReportingDefinitions(reportingDefinitions) {
    return {
      type: RECEIVED_REPORTING_DEFINITIONS,
      reportingDefinitions,
    };
  }

function initiatingExtract() {
  return {
    type: INITIATING_EXTRACT,
  };
}

function extractInitiated() {
  return {
    type: EXTRACT_INITIATED,
  };
}

function retrieveExtractDefinitionNames({ userKey, databaseId }) {
  return (dispatch) => {
    dispatch(fetchingExtractDefinitionNames());
    return analysisReportingServiceApi
      .retrieveExtractDefinitionNames({ userKey, databaseId })
      .then((res) => {
        if (Array.isArray(res)) dispatch(receivedExtractDefinitionNames(res));
        else dispatch(receivedExtractDefinitionNames([]));
      });
  };
}

function retrieveReportingDefinitions({ userKey, databaseId }) {
    return (dispatch) => {
      dispatch(fetchingReportingDefinitions());
      return analysisReportingServiceApi
        .retrieveReportingDefinitions({ userKey, databaseId })
        .then((res) => {
          if (Array.isArray(res)) dispatch(receivedReportingDefinitions(res));
          else dispatch(receivedReportingDefinitions([]));
        });
    };
  }

function extractWithSelectedExtractDefinition({ userKey, databaseId, definitionId }) {
  return (dispatch) => {
    dispatch(initiatingExtract());
    return analysisReportingServiceApi
      .extractWithSelectedExtractDefinition({ userKey, databaseId, definitionId })
      .then((res) => {
        dispatch(extractInitiated());
        if (!res) return;
        notifySuccess(res); 
      });
  };
}

function refreshReportingDatabase({ userKey, databaseId, definitionId }) {
    return (dispatch) => {
      dispatch(initiatingExtract());
      return analysisReportingServiceApi
        .refreshReportingWithSelectedExtractDefinition({ userKey, databaseId, definitionId })
        .then((res) => {
          dispatch(extractInitiated());
          if (!res) return;
          notifySuccess(res); 
        });
    };
  }


function createExtractDefinition({ userKey, databaseId, req }) {
  return (dispatch) => {
    analysisReportingServiceApi
      .createExtractDefinition({ userKey, databaseId, req })
      .then(() => dispatch(retrieveExtractDefinitionNames({ userKey, databaseId })));
  };
}

function editExtractDefinition({ userKey, databaseId, definitionId, req }) {
  return (dispatch) => {
    analysisReportingServiceApi
      .editExtractDefinition({ userKey, databaseId, definitionId, req })
      .then(() => {
        dispatch(retrieveExtractDefinitionNames({ userKey, databaseId }));
      });
  };
}

function deleteExtractDefinition({ userKey, databaseId, definitionId }) {
  return (dispatch) => {
    analysisReportingServiceApi
      .deleteExtractDefinition({ userKey, databaseId, definitionId })
      .then(() => {
        dispatch(retrieveExtractDefinitionNames({ userKey, databaseId }));
      });
  };
}

function initiatingCsvDownload(type) {
  return {
    type
  };
}

function receivedCsvFile(file, id, format, databaseName, extractDefinitionName) {
  const csvFile = new Blob([file], { type: 'text/csv;charset=utf-8;' });
  return {
    type: RECEIVED_REPORTING_CSV_FILE,
    csv: {
      id: id,
      file: csvFile,
      fileName: extractDefinitionName + ' - ' + databaseName + ' - ' + format + '.csv'
    }
  };
}

function exportToCsv({userKey, id, format, databaseName, extractDefinitionName, apiKey}) {
  return (dispatch) => {
    dispatch(initiatingCsvDownload(format === 'wide' ? FETCHING_REPORTING_WIDE_CSV : FETCHING_REPORTING_NARROW_CSV));
    analysisReportingServiceApi.exportToCsv({
      userKey,
      format,
      databaseName,
      extractDefinitionName,
      apiKey
    }).then(csv => dispatch(receivedCsvFile(csv, id, format, databaseName, extractDefinitionName)));
  };
}

function receivedUserNamesForExtractDefinition(userNamesForExtractDefinition) {
  return {
    type: RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION,
    userNamesForExtractDefinition,
  };
}

function retrieveUserNamesForExtractDefinition(userKey) {
  return (dispatch) => {
    analysisReportingServiceApi.retrieveUserNamesForExtractDefinition({userKey})
    .then((userNamesForExtractDefinition) => {
      if (Array.isArray(userNamesForExtractDefinition)) dispatch(receivedUserNamesForExtractDefinition(userNamesForExtractDefinition));
      else dispatch(receivedUserNamesForExtractDefinition([]));
    });
  };
}

export {
  createExtractDefinition,
  deleteExtractDefinition,
  editExtractDefinition,
  extractWithSelectedExtractDefinition,
  refreshReportingDatabase,
  retrieveExtractDefinitionNames,
  retrieveReportingDefinitions,
  exportToCsv,
  retrieveUserNamesForExtractDefinition,
};
