import {
  SELECTING_TOD_DATABASE,
  RECEIVED_TOD_DATABASE,
  RECEIVED_TOD_DATABASES,
  FETCHING_TOD_DATABASES,
  RECEIVED_DATABASE_MAPPING,
  RECEIVED_DATABASE_MAPPINGS,
  RECEIVED_DATE_FORMATS,
  RECEIVED_DATE_FORMAT_DELIMITERS,
  RECEIVED_COLUMN_TYPES,
  RECEIVED_COLUMNS_FOR_TOD_DB,
  RECEIVED_TOD_DATABASE_BACKUPS,
  RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE,
  RECEIVED_TOD_DATABASE_ARCHIVED,
  SAVING_DATA_MAPPINGS,
  RECEIVED_TOD_DATABASE_INFO,
  FETCHING_TOD_DATABASE_INFO,
  SAVING_TOD_DATABASE,
  RECEIVED_TOD_DB_VERSION,
  RECEIVED_TOD_DATABASE_DATALOADS,
  FETCHING_TOD_DATA_LOADS,
  CLEAR_TOD_DATABASE,
  DELETED_DATA_LOADS,
  DELETED_VALUATION_DATE,
  DELETING_DATA_LOADS,
  DELETING_VALUATION_DATE,
  RECEIVED_TOD_DATA_LOAD_LOG_FILE,
} from './tod.actionTypes';
import * as todDatabaseServiceApi
  from '../../serviceApi/todDatabases.serviceApi';
import {
  retrieveCurrentDbVersion,
  retrieveTodDataLoadLogFile,
  deleteTodDataLoadLogFile as deleteTodDataLoadLogFileFromServer,
} from '../../serviceApi/application.serviceApi';
import { fetchCurrentUser } from '../user.actions';
import { notifySuccess } from '../../utilities/notifier';
import { receivedErrorFromServer, clearErrorMessage } from '../application.actions';
import { receivedJobStatus } from '../jobStatus.actions';

function fetchingFromServer() {
  return {
    type: FETCHING_TOD_DATABASES,
  };
}

function fetchingDataLoads() {
  return {
    type: FETCHING_TOD_DATA_LOADS,
  };
}

function deletingValuationDate(id) {
  return {
    type: DELETING_VALUATION_DATE,
    id,
  };
}

function deletingDataLoad(id) {
  return {
    type: DELETING_DATA_LOADS,
    id,
  };
}

function deletedValuationDate(id) {
  return {
    type: DELETED_VALUATION_DATE,
    id,
  };
}

function deletedDataLoad(id) {
  return {
    type: DELETED_DATA_LOADS,
    id,
  };
}

function fetchingDatabaseInfo(state) {
  const old = state.tod.databases.current || {};
  const current = JSON.parse(JSON.stringify(old));
  current.isFetching = true;
  return {
    type: FETCHING_TOD_DATABASE_INFO,
    current,
    receivedAt: Date.now(),
  };
}

function savingDataMappings(state) {
  const mappings = state.tod.databases.mappings;
  mappings.isSaving = true;

  return {
    type: SAVING_DATA_MAPPINGS,
    mappings,
  };
}

function savingDatabase() {
  return {
    type: SAVING_TOD_DATABASE,
  };
}

function receivedCurrentDbVersion(currentVersion) {
  return {
    type: RECEIVED_TOD_DB_VERSION,
    currentVersion,
  };
}

function receivedDatabases(json) {
  return {
    type: RECEIVED_TOD_DATABASES,
    databases: json,
    receivedAt: Date.now(),
  };
}

function receivedColumnTypes(json) {
  return {
    type: RECEIVED_COLUMN_TYPES,
    columnTypes: json,
    receivedAt: Date.now(),
  };
}

function receivedDateFormats(json) {
  return {
    type: RECEIVED_DATE_FORMATS,
    dateFormats: json,
    receivedAt: Date.now(),
  };
}

function receivedDateFormatDelimiters(json) {
  return {
    type: RECEIVED_DATE_FORMAT_DELIMITERS,
    dateFormatDelimiters: json,
    receivedAt: Date.now(),
  };
}

function receivedDatabaseColumns(json, state) {
  const old = state.tod.databases.current || {};
  const current = JSON.parse(JSON.stringify(old));
  current.columns = json;
  current.isFetching = false;
  current.withColumns = true;
  return {
    type: RECEIVED_COLUMNS_FOR_TOD_DB,
    current,
  };
}

function receivedDatabase(json) {
  return {
    type: RECEIVED_TOD_DATABASE,
    current: {
      id: json.workspaceId,
      name: json.workspaceName,
      description: json.workspaceDescription,
      isOnline: json.isOnline,
      factTableGuid: json.factTableGuid,
      columns: json.columns,
      withColumns: true,
      isFetching: false,
      permissions: json.permissions,
      createdBy: json.createdBy,
      useADX: json.useADX,
      accByDiagonals: json.accByDiagonals,
      hasDataLoaded: json.hasDataLoaded,
    },
    receivedAt: Date.now(),
  };
}

function clearDatabase() {
  return {
    type: CLEAR_TOD_DATABASE,
    current: null,
    receivedAt: Date.now(),
  };
}

function receivedDatabaseInfo(json, state) {
  const old = state.tod.databases.current || {};
  const current = JSON.parse(JSON.stringify(old));
  current.info = json;
  current.isFetching = false;
  return {
    type: RECEIVED_TOD_DATABASE_INFO,
    current,
    receivedAt: Date.now(),
  };
}

function receivedDatabaseMappings(json, databaseId, state) {
  const current = state.tod.databases.mappings.current;
  return {
    type: RECEIVED_DATABASE_MAPPINGS,
    mappings: {
      items: json,
      databaseId,
      isSaving: false,
      current,
    },
    receivedAt: Date.now(),
  };
}

function receivedDataMapper(json, databaseId, state) {
  const mappings = Object.assign({}, state.tod.databases.mappings, {
    databaseId,
    current: json,
  });
  return {
    type: RECEIVED_DATABASE_MAPPING,
    mappings,
    receivedAt: Date.now(),
  };
}

function receivedPointInTimeRestoreRange(databaseId, json) {
    return {
        type: RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE,
        databaseId,
        earliestRestorePoint: json.earliestPoint,
        latestRestorePoint: json.latestPoint,
        receivedAt: Date.now(),
    };
}

function receivedBackups(databaseId, json) {
  return {
    type: RECEIVED_TOD_DATABASE_BACKUPS,
    databaseId,
    backups: json,
    receivedAt: Date.now(),
  };
}

function receivedArchived(databaseId, json) {
  return {
    type: RECEIVED_TOD_DATABASE_ARCHIVED,
    databaseId,
    archived: json,
    receivedAt: Date.now(),
  };
}

function receivedDataLoads(json) {
  return {
    type: RECEIVED_TOD_DATABASE_DATALOADS,
    dataLoads: json,
  };
}

function selectDatabase(db) {
  return {
    type: SELECTING_TOD_DATABASE,
    current: db
      ? {
        id: db.workspaceId,
        name: db.workspaceName,
        permissions: db.permissions,
        description: db.workspaceDescription,
        dimensions: db.workspaceDimensions,
        isOnline: db.isOnline,
        useADX: db.useADX,
        factTableGuid: db.factTableGuid,
        createdBy: db.createdBy,
        columns: [],
        hasDataLoaded: db.hasDataLoaded,
      }
      : null,
    receivedAt: Date.now(),
  };
}

function receivedTextFile(file) {
  const txtFile = new Blob([file], { type: 'text/plain;charset=utf-8;' });
  return {
    type: RECEIVED_TOD_DATA_LOAD_LOG_FILE,
    txtFile,
  };
}

function fetchCurrentDbVersion(userKey) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return retrieveCurrentDbVersion(userKey, 1).then(json =>
      dispatch(receivedCurrentDbVersion(json))
    );
  };
}

function fetchDatabase(userKey, databaseId) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return todDatabaseServiceApi
      .retrieveDatabase(userKey, databaseId)
      .then(json => dispatch(receivedDatabase(json)));
  };
}

function fetchDatabaseInfo(userKey, databaseId, factTableGuid) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(fetchingDatabaseInfo(state));
    return todDatabaseServiceApi
      .retrieveDatabaseInfo(userKey, databaseId, factTableGuid)
      .then(json => dispatch(receivedDatabaseInfo(json, state)));
  };
}

function fetchDataLoads(userKey, databaseId) {
  return dispatch => {
    dispatch(fetchingDataLoads());
    return todDatabaseServiceApi
      .retrieveDataLoads(userKey, databaseId)
      .then(json => dispatch(receivedDataLoads(json)));
  };
}

function fetchDatbaseColumns(userKey, databaseId, factTableGuid) {
  return (dispatch, getState) => {
    dispatch(fetchingDatabaseInfo(getState()));
    return todDatabaseServiceApi
      .retrieveDatabaseColumns(userKey, databaseId, factTableGuid)
      .then(json => dispatch(receivedDatabaseColumns(json, getState())));
  };
}

function fetchDatabaseMappings(userKey, databaseId) {
  return (dispatch, getState) => {
    dispatch(fetchingFromServer());
    return todDatabaseServiceApi
      .retrieveDatabaseMappings(userKey, databaseId)
      .then(json =>
        dispatch(receivedDatabaseMappings(json, databaseId, getState()))
      );
  };
}

function fetchDataMapper(userKey, databaseId, mapperId) {
  return (dispatch, getState) => {
    if (mapperId) {
      dispatch(fetchingFromServer());
      todDatabaseServiceApi
        .retrieveDatabaseMapping(userKey, databaseId, mapperId)
        .then(json =>
          dispatch(receivedDataMapper(json, databaseId, getState()))
        );
    } else {
      dispatch(receivedDataMapper(null, databaseId, getState()));
    }
  };
}

function selectMapper(mapper, databaseId) {
  return (dispatch, getState) =>
    dispatch(receivedDataMapper(mapper, databaseId, getState()));
}

function fetchDatabases(userKey) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return todDatabaseServiceApi
      .retrieveDatabases(userKey)
      .then(json => dispatch(receivedDatabases(json)));
  };
}

function fetchColumnTypes(userKey) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return todDatabaseServiceApi
      .retrieveColumnTypes(userKey)
      .then(json => dispatch(receivedColumnTypes(json)));
  };
}

function fetchDateFormats(userKey) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return todDatabaseServiceApi
      .retrieveDateFormats(userKey)
      .then(json => dispatch(receivedDateFormats(json)));
  };
}

function fetchDateFormatDelimiters(userKey) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return todDatabaseServiceApi
      .retrieveDateFormatDelimiters(userKey)
      .then(json => dispatch(receivedDateFormatDelimiters(json)));
  };
}

// function fetchArchivedForAllBackups(userKey, databaseId, backups) {
//   return (dispatch, getState) => {
//     const bckups = !backups ? getState().tod.databases.backups : backups;

//     return new Promise(resolve => {
//       const promises = bckups.map(b =>
//         todDatabaseServiceApi
//           .retrieveArchived(userKey, databaseId, b.id)
//           .then(json => json)
//       );
//       Promise.all(promises).then(archived => {
//         resolve(archived.reduce((a, b) => a.concat(b)));
//       });
//     }).then(allArchived => dispatch(receivedArchived(databaseId, allArchived)));
//   };
// }

function fetchArchived(userKey, databaseId) {
  return dispatch =>
  todDatabaseServiceApi
    .retrieveArchived(userKey, databaseId)
    .then(json => dispatch(receivedArchived(databaseId, json)));
}

function fetchBackups(userKey, databaseId) {
  return dispatch =>
    todDatabaseServiceApi
      .retrieveBackups(userKey, databaseId)
      .then(json => {
        dispatch(receivedBackups(databaseId, json));
       // dispatch(fetchArchivedForAllBackups(userKey, databaseId, json));
       dispatch(fetchArchived(userKey, databaseId));
      });
}

function fetchPointInTimeRestoreRange(userKey, databaseId) {
    return dispatch =>
        todDatabaseServiceApi
            .retrievePointInTimeRestoreRange(userKey, databaseId)
            .then(json => dispatch(receivedPointInTimeRestoreRange(databaseId, json)));
}

function createDataMapper({
  userKey,
  browserHistory,
  databaseId,
  mapperName,
  mapperDescription,
  factTableGuid,
  columns,
}) {
  return (dispatch, getState) => {
    dispatch(savingDataMappings(getState()));
    return todDatabaseServiceApi
      .createDataMapper({
        userKey,
        databaseId,
        mapperName,
        mapperDescription,
        factTableGuid,
        columns,
      })
      .then(() => {
          dispatch(fetchDatabaseMappings(userKey, databaseId));
          browserHistory.push(`/trianglesOnDemand/databases/${databaseId}/mappings`);
      });
  };
}

function createDatabase({
  userKey,
  name,
  description,
  accByDiagonals,
  columns,
  useADX
}) {
  return dispatch =>{
    return todDatabaseServiceApi.createDatabase({
      userKey,
      name,
      description,
      accByDiagonals,
      columns,
      useADX
    })
    .then(json => dispatch(receivedJobStatus(json)));
  }
}

function cancelDataLoad(userKey, databaseId) {
  return () => todDatabaseServiceApi.cancelDataLoad(userKey, databaseId);
}

function createBackup(userKey, databaseId, description) {
  return dispatch => {
    todDatabaseServiceApi.createBackup(userKey, databaseId, description)
    .then(json => dispatch(receivedJobStatus(json)));
  }
}

function deleteDatabase(userKey, databaseId) {
  return dispatch =>
    todDatabaseServiceApi
      .deleteDatabase(userKey, databaseId)
      .then(() => dispatch(selectDatabase(null)))
      .then(() => dispatch(fetchDatabases(userKey)));
}

function deleteDataMapper(userKey, databaseId, mapperId) {
  return dispatch =>
    todDatabaseServiceApi
      .deleteDataMapper(userKey, databaseId, mapperId)
      .then(() => dispatch(selectMapper(null, databaseId)))
      .then(() => dispatch(fetchDatabaseMappings(userKey, databaseId)));
}

function deleteValuationDate(
  userKey,
  databaseId,
  factTableGuid,
  valuationDate
) {
  return dispatch => {
    dispatch(deletingValuationDate(valuationDate));
    todDatabaseServiceApi
      .deleteValuationDate(userKey, databaseId, factTableGuid, valuationDate)
      .then(() => {
        dispatch(fetchDatabaseInfo(userKey, databaseId, factTableGuid));
        dispatch(deletedValuationDate(valuationDate));
      });
  };
}

function deleteDataLoad(
  userKey,
  databaseId,
  dataLoadId,
  factTableGuid,
) {
  return dispatch => {
    dispatch(deletingDataLoad(dataLoadId));
    todDatabaseServiceApi
      .deleteDataLoad(userKey, databaseId, dataLoadId, factTableGuid)
      .then((res) => {
        if (res !== null) {
          notifySuccess(`${(typeof res === 'number' && res) || ''} CSV records deleted`);
        }
        dispatch(deletedDataLoad(dataLoadId));
        dispatch(fetchDatabaseInfo(userKey, databaseId, factTableGuid));
      });
  };
}

function deleteBackup(userKey, databaseId, backupId) {
  return dispatch =>
    todDatabaseServiceApi
      .deleteBackup(userKey, databaseId, backupId)
      .then(() => dispatch(fetchBackups(userKey, databaseId)));
}

function deleteArchivedBackup(userKey, databaseId, backupId, archiveId) {
  return dispatch =>
    todDatabaseServiceApi
      .deleteArchived(userKey, databaseId, backupId, archiveId)
      //.then(() => dispatch(fetchArchivedForAllBackups(userKey, databaseId)));
      .then(() => {
        // we don't track progress
        //dispatch(receivedJobStatus(json));
        dispatch(fetchArchived(userKey, databaseId));
      });
}

function updateDataMapper({
  userKey,
  browserHistory,
  databaseId,
  mapperId,
  mapperName,
  mapperDescription,
  factTableGuid,
  columns,
}) {
  return (dispatch, getState) => {
    dispatch(savingDataMappings(getState()));
    return todDatabaseServiceApi
      .updateDataMapper({
        userKey,
        databaseId,
        mapperId,
        mapperName,
        mapperDescription,
        factTableGuid,
        columns,
      })
      .then(() => dispatch(fetchDatabaseMappings(userKey, databaseId)))
      .then(() =>
        browserHistory.push(
          `/trianglesOnDemand/databases/${databaseId}/mappings`
        )
      );
  };
}

function updateDatabase({
  userKey,
  databaseId,
  name,
  description,
  accByDiagonals,
  columns,
  browserHistory
}) {
  return dispatch => {
    dispatch(savingDatabase());
    return todDatabaseServiceApi
      .updateDatabase({
        userKey,
        databaseId,
        name,
        description,
        accByDiagonals,
        columns,
      })
      .then(() => dispatch(clearDatabase()))
      .then(() => dispatch(fetchDatabases(userKey)))
      .then(() => browserHistory.push('/trianglesOnDemand/databases'));
  };
}

function updateDatabaseUsers(userKey, databaseId, users) {
  return dispatch => {
    dispatch(savingDatabase());
    return (
      todDatabaseServiceApi
        .updateDatabaseUsers(userKey, databaseId, users)
        // .then(() => dispatch(fetchDatabaseUsers(userKey, databaseId)))
        .then(() => dispatch(fetchCurrentUser()))
    );
  };
}

function updateDatabaseGroups(userKey, databaseId, groups) {
  return dispatch => {
    dispatch(savingDatabase());
    return todDatabaseServiceApi
      .updateDatabaseGroups(userKey, databaseId, groups)
      .then(() => dispatch(fetchCurrentUser()));
  };
}

function loadData({
  userKey,
  databaseId,
  mapperId,
  csvFileName,
  valuationDate,
}) {
  return dispatch =>
    todDatabaseServiceApi.loadData({ userKey, databaseId, mapperId, csvFileName, valuationDate})
    .then(json => dispatch(receivedJobStatus(json)));
}

function archiveBackup(userKey, databaseId, backupId, description) {
  return dispatch =>
    todDatabaseServiceApi.archiveBackup(userKey, databaseId, backupId, description)
    .then(json => dispatch(receivedJobStatus(json)));
}

function restoreBackup(userKey, databaseId, backupId) {
  return dispatch =>
    todDatabaseServiceApi.restoreBackup(userKey, databaseId, backupId)
    .then(json => dispatch(receivedJobStatus(json)));
}

function restoreArchivedBackup(userKey, databaseId, backupId, archiveId) {
  return dispatch =>
    todDatabaseServiceApi.restoreArchivedBackup(userKey, databaseId, backupId, archiveId)
    .then(json => dispatch(receivedJobStatus(json)));
}

function restorePointInTime(userKey, databaseId, timestamp) {
    return dispatch => {
        todDatabaseServiceApi.restorePointInTime(userKey, databaseId, timestamp)
        .then(json => dispatch(receivedJobStatus(json)));
    }
}

function undoPITR(userKey, databaseId, timestamp) {
    return dispatch =>
        todDatabaseServiceApi.undoPITR(userKey, databaseId, timestamp)
        .then(json => dispatch(receivedJobStatus(json)));
}

function validateCMFormulas({ userKey, columns }) {
  return (dispatch) => todDatabaseServiceApi
    .validateCMFormulas({ userKey, columns })
    .then(response => {
      if (response.hasError)
        dispatch(receivedErrorFromServer(response.errorMessage));
      else
        dispatch(clearErrorMessage());
    });
}

function fetchDataLoadLogFile(userKey) {
  return (dispatch) =>
    retrieveTodDataLoadLogFile(userKey).then(txt => dispatch(receivedTextFile(txt)));
}

function deleteDataLoadLogFile(userKey) {
  return () => deleteTodDataLoadLogFileFromServer(userKey);
}

function fetchBackupRestoreLogFile(userKey, databaseId) {
  return (dispatch) => todDatabaseServiceApi
    .retrieveBackupRestoreLogFile(userKey, databaseId).then(txt => dispatch(receivedTextFile(txt)));
}

function deleteBackupRestoreLogFile(userKey, databaseId) {
    return () => todDatabaseServiceApi.deleteBackupRestoreLogFile(userKey, databaseId);
}

export {
  fetchDatabases,
  fetchDatabase,
  fetchDataLoads,
  fetchDatabaseMappings,
  fetchDatbaseColumns,
  fetchDatabaseInfo,
  fetchCurrentDbVersion,
  deleteDatabase,
  deleteDataMapper,
  deleteValuationDate,
  deleteDataLoad,
  fetchDataMapper,
  selectDatabase,
  createDatabase,
  updateDatabase,
  updateDataMapper,
  fetchColumnTypes,
  fetchDateFormats,
  fetchDateFormatDelimiters,
  createDataMapper,
  loadData,
  cancelDataLoad,
  //fetchArchivedForAllBackups,
  fetchArchived,
  fetchBackups,
  fetchPointInTimeRestoreRange,
  createBackup,
  deleteBackup,
  restoreBackup,
  restorePointInTime,
  undoPITR,
  archiveBackup,
  deleteArchivedBackup,
  restoreArchivedBackup,
  validateCMFormulas,
  updateDatabaseUsers,
  updateDatabaseGroups,
  selectMapper,
  clearDatabase,
  fetchDataLoadLogFile,
  deleteDataLoadLogFile,
  fetchBackupRestoreLogFile,
  deleteBackupRestoreLogFile,
};
