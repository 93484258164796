import React, { Component } from 'react';
import radium from 'radium';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Grid, Button } from '@arius';
import ProjectInfo from './projectInfo';
import ProjectStatusEditor from '@app/analysis/projectStatusEditor';
import ProjectEditModal from './projectEditModal';
import $ from 'jquery';

class ProjectsGrid extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showModal: false, 
            selectedProject: null
        };
        this.getProjectInfoTemplate = this.getProjectInfoTemplate.bind(this);
        this.getProjectUpdateTemplate = this.getProjectUpdateTemplate.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getUDOWarning = this.getUDOWarning.bind(this);
        this.refresh = this.refresh.bind(this);
        this.editProjectName = this.editProjectName.bind(this);
        this.saveProjectName = this.saveProjectName.bind(this);
    }

    getProjectInfoTemplate(r) {
        const { currentWorkspace } = this.props;
        return <ProjectInfo project={r} currentWorkspace={currentWorkspace}/>
    }

    getProjectUpdateTemplate(r) {
        const { userKey, currentWorkspace, valuationDate, canModifyStatus} = this.props;
        let databaseId = currentWorkspace ? currentWorkspace.id : 0;
        return (
            <ProjectStatusEditor 
                userKey={userKey}
                databaseId={databaseId} 
                canModifyStatus={canModifyStatus}
                getprojectIds={() => this.props.selectedProjectIds}
                valuationDate={valuationDate}
                refreshProjectsHandler={this.refresh}>
            </ProjectStatusEditor>
        )
    }

    handleActionClick(handler, project){
        $(`#action-overlay-${project.projectId}`).click();  // make action menu close.  TODO find better solution...
        handler(project);
    }

    getActionItems(project) {
        const { 
            checkOutHandler,
            openReadOnlyHandler,
            deleteProjectHandler,
            copyProjectHandler,
            seeVersions,
            reviewProjectHandler,
            undoCheckoutHandler
        } = this.props;
        let id = project.projectId;
        let isCheckedOut = !!project.checkedOutUser;
        let h = this.handleActionClick;

        const popover = (
            <Popover>
                <Button 
                    toolTip={`Open${project.checkoutStatus !== 'Owned' ? ' & Check out' : ' local file'}`} 
                    iconName="fa-folder-open" 
                    onClick={()=> {h(checkOutHandler,project)}}/>
                <Button 
                    toolTip="Open & Read Only" 
                    iconName="remove_red_eye" 
                    onClick={()=> {h(openReadOnlyHandler,project)}}/>
                <Button 
                    toolTip="Delete" 
                    iconName="delete" 
                    onClick={()=> {h(deleteProjectHandler,project)}}/>
                <Button 
                    toolTip="Copy Project" 
                    iconName="content_copy" 
                    onClick={()=> {h(copyProjectHandler,project)}}/>
                <Button 
                    toolTip="Version History" 
                    iconName="fa-code-fork" 
                    onClick={()=> {h(seeVersions,project)}}/>
                <Button 
                    toolTip="Review" 
                    iconName="rate_review" 
                    onClick={()=> {h(reviewProjectHandler,project)}}/>
                {
                    isCheckedOut ? 
                    <Button 
                        toolTip="Undo Checkout" 
                        iconName="undo" 
                        onClick={()=> {h(undoCheckoutHandler,project)}}/> : null
                }
            </Popover>
        );

        return (
        <OverlayTrigger  trigger="click" placement="left" overlay={popover} rootClose={true} >
            <i
                id={`action-overlay-${id}`}
                key={`action-items-${id}`}
                onClick={(e) => e.stopPropagation()}
                className="material-icons menu-icon"
            >
                more_horiz
            </i>
        </OverlayTrigger>
        )
    }
    
    getUDOWarning(project) {
        const { currentUdoVersionNbr } = this.props;
        const warningTooltipMessage = `UDO Library version outdated...project's version: ${project.tableLibraryVersion}`;
        
        let outdated = currentUdoVersionNbr !== project.tableLibraryVersion && currentUdoVersionNbr !== 0;
        return outdated ? <Button toolTip={warningTooltipMessage} mode="error"/> : null;
    }

    refresh() {
        const { refreshProjectsHandler } = this.props;
        refreshProjectsHandler();  // reload the projects
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { searchFilterOpen, data } = this.props;
        return nextProps.selectedProjectIds.length === 0 ||
            nextProps.data !== data || 
            nextProps.searchFilterOpen !== searchFilterOpen;
    }

    editProjectName(r) {
        this.setState({showModal: true, selectedProject: r});
    }

    saveProjectName(newName, projectId) {
        const { validateText, updateProjectName } = this.props;
        let isValid = validateText(newName, projectId);
        if (isValid) {
            updateProjectName(projectId, newName);
            this.setState({showModal: false});
        }
    }

    render() {
        const {
            selectHandler,
            searchFilterOpen,
            data,
        } = this.props;
        const {
            showModal,
            selectedProject
        } = this.state;

        let columns = [
            { type:'checkbox', width:'45'}, // NOTE: make sure a primary key column is defined when using checkbox column
            { headerText: '', width: '40', template: (r) => r.locked ? (
                <div style={{ margin: '5px'}}><i className="fa fa-lock" aria-hidden="true"></i></div>
             ) : null},
            { headerText: 'ID', field: 'projectId', width: '40', isPrimaryKey: true},
            { headerText: '', template: (r) => this.getProjectInfoTemplate(r), width: '30'},
            { headerText: 'Project Name', field: 'projectName',width: '30%', minWidth: '200',
                validationRules:this.projectNameValidationRules, template: (r) => {
                    return (
                        <span className="edit-text" onDoubleClick={()=> {this.editProjectName(r)}}>{r.projectName}</span>
                    )
            }},
            { headerText: 'Valuation Date', field: 'valuationDate', type:'dateUTC', width: '10%',minWidth: '100'},
            { headerText: 'Data Structure', field: 'dataShape',width: '30%', minWidth: '200'},
            { headerText: 'Checked Out By', field: 'checkedOutUser', template: (r) => {
                return <span>{!!r.checkedOutUser ? r.checkedOutUser : ''}</span>
            },width: '15%', minWidth: '100'},
            { headerText: 'Checked Out', field: 'checkedOutTimestamp', type: 'datetime', template: (r) => {
                return <span>{!!r.checkedOutUser && r.checkedOutTimestamp ? r.checkedOutTimestamp: ''}</span>
                },width: '15%', minWidth: '100'},
            { headerText: 'Last Updated By', field: 'updatedBy',width: '15%', minWidth: '100'},
            { headerText: 'Last Updated', field: 'updatedDate', type: 'datetime', width: '15%', minWidth: '100'},
            { headerTemplate:() => this.getProjectUpdateTemplate(), width: '40', template:(r) => this.getUDOWarning(r)},
            { headerText: 'Status', field: 'status', width: '10%', minWidth: '100'},
            { headerText: 'Actions', template: this.getActionItems,width: '100'}
        ];

        return (
            <div>
                <Grid 
                    columns={columns}
                    checkBoxHandler={(e) => selectHandler(e)}
                    data={data}
                    height={`calc(100vh - ${searchFilterOpen ? 530 : 270}px)`}
                    editOptions={this.editOptions}
                    getEditMessage={(row) => `<span style='font-weight:bold'>${row.projectName}</span>` }
                    beginEdit={(r) => {this.currentProject = r}}
                    onSave={this.saveProjectName}
                />   
                <ProjectEditModal
                    show={showModal}
                    project={selectedProject}
                    closeHandler={()=> {this.setState({showModal: false})}}
                    saveHandler={this.saveProjectName}
                />             
            </div>
        )
    }
}


export default radium(ProjectsGrid);