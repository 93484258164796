import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { notifyError } from '../../../utilities/notifier';
import * as Colors from '../../../utilities/colors';
import * as GlobalStyles from '../../../utilities/globalStyles';
import {Form} from 'react-bootstrap';
import Loader from '../../shared/presentational/loader';
import SaveButtons from '../../shared/presentational/saveButtons';
import {
  clearRollupDefinitionsSnapshot,
  clearRollupDefinitions,
  processNewRollupDefinition,
} from '../../../actions/tod/queries.actions';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import AdxQueryWarning from '@app/shared/presentational/adxQueryWarning';

const style = {
  contentContainer: {
    minWidth: '500px',
  },
  dimensionLabel: {
    marginRight: '.5em',
  },
  dimensionRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  selectBox: {
    // width: '20vw',
    height: '50vh',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '1em',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
};

class CreateEditRollupDefinitions extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    userKey: PropTypes.string,
    currentDb: PropTypes.object,
    isFetching: PropTypes.bool,
    params: PropTypes.object,
    getRollupDefinition: PropTypes.func,
    createRollupDefinitionHandler: PropTypes.func,
    updateRollupDefinitionHandler: PropTypes.func,
    rollupDefinition: PropTypes.object,
    datasetColumns: PropTypes.array,
    getDatasetColumns: PropTypes.func,
    dataSource: PropTypes.object,
    allRollupDefinitions: PropTypes.array,
    verifyPermission: PropTypes.func,
  };
  static defaultProps = {
    dimensionValues: [],
  };
  constructor(props) {
    super(props);
    this.state = {
      databaseId: null,
      dataSetColumnId: null,
      dimensionName: null,
      selectedDimension: null,
      dimensions: [],
      dataSource: PropTypes.object,
      editMode: false,
      rollupDefinitionName: '',
      rollupDescription: '',
      selectedDimensionValues: [],
      rollupDefinitionId: null,
      rollupDefinition: null,
      softErrorMode: true,
      dimensionValues: [],
      selectedValuesOption: 'specific',
    };
    if (props.params) {
      if (props.params.databaseId) {
        this.state.databaseId = props.params.databaseId;
      }

      if (props.params.dimensionName && props.datasetColumns) {
        this.state.dimensionName = props.params.dimensionName;
        this.state.dataSetColumnId = (props.datasetColumns.find(
          dmra => dmra.attributeName === props.params.dimensionName
        ) || {}).dataSetColumnId;
      }

      if (props.params.rollupDefinitionId) {
        this.state.editMode = true;
        this.state.rollupDefinitionId = props.params.rollupDefinitionId;
      }
    }
    this.saveRollupDefinition = this.saveRollupDefinition.bind(this);
    this.backToRollupDefinitionsList = this.backToRollupDefinitionsList.bind(
      this
    );
    this.updateRollupDefinitionName = this.updateRollupDefinitionName.bind(
      this
    );
    this.updateRollupDescription = this.updateRollupDescription.bind(this);
    this.selectDimensionValue = this.selectDimensionValue.bind(this);
    this.changeDimension = this.changeDimension.bind(this);
    this.getNameValidationError = this.getNameValidationError.bind(this);
    this.selectValuesOption = this.selectValuesOption.bind(this);
  }

  componentDidMount() {
    const {
      dataSource,
      getRollupDefinition,
      userKey,
      datasetColumns,
      getDatasetColumns,
      currentDb,
    } = this.props,
      { dimensionName, rollupDefinitionId, dataSetColumnId } = this.state;

    let { databaseId } = this.state;

    if (!databaseId && currentDb) {
      databaseId = currentDb.id;
      this.setState({ databaseId });
    }

    // if (dimensionValues) {
    //   this.setState({ dimensionValues });
    // }
    if (datasetColumns && !datasetColumns.length && databaseId) {
      getDatasetColumns({
        userKey,
        databaseId,
      });
    }

    if (rollupDefinitionId) {
      getRollupDefinition({
        userKey,
        factDatabaseId: databaseId,
        dataSetColumnId,
        rollupDefinitionId,
      });
    }
    if (dataSource && dataSource.values) {
      const dimensions = dataSource.values.map(ds => ds.name),
        selectedDimension = dataSource.values.find(
          ds => ds.name === dimensionName
        );
      let dimensionValues = [];
      if (selectedDimension) {
        dimensionValues = selectedDimension.values.filter(
          v => v.value.indexOf('@') !== 0
        );
      }

      this.setState({ dimensions, selectedDimension, dimensionValues });
    }
  }

  componentDidUpdate(prevProps) {
    const { dataSource, rollupDefinition, currentDb } = this.props,
      { dimensionName, editMode } = this.state;
    // if (dimensionValues && dimensionValues !== prevProps.dimensionValues) {
    //   this.setState({ dimensionValues });
    // }

    let { databaseId } = this.state;

    if (!databaseId && currentDb) {
      databaseId = currentDb.id;
      this.setState({ databaseId });
    }

    if (rollupDefinition && rollupDefinition !== prevProps.rollupDefinition) {
      let { rollupName: rollupDefinitionName } = rollupDefinition;
      if (editMode) {
        rollupDefinitionName = rollupDefinitionName.substring(1);
      }
      this.setState({
        rollupDefinition,
        rollupDefinitionName,
        rollupDescription: rollupDefinition.rollupDescription,
        selectedDimensionValues: JSON.parse(rollupDefinition.rollupData),
        selectedValuesOption: rollupDefinition.allSelected ? 'all' : 'specific',
      });
    }

    if (
      dataSource &&
      dataSource.values &&
      prevProps.dataSource &&
      prevProps.dataSource.values &&
      dataSource.values !== prevProps.dataSource.values
    ) {
      const dimensions = dataSource.values.map(ds => ds.name),
        selectedDimension = dataSource.values.find(
          ds => ds.name === dimensionName
        ),
        dimensionValues = selectedDimension
          ? selectedDimension.values.filter(v => v.value.indexOf('@') !== 0)
          : [];

      this.setState({ dimensions, selectedDimension, dimensionValues });
    }
  }

  setSoftErrorMode(mode) {
    const { softErrorMode } = this.state;
    if (softErrorMode !== mode) {
      this.setState({ softErrorMode: mode });
    }
  }

  getNameValidationError(soft) {
    const { allRollupDefinitions } = this.props;
    const { dimensionName, rollupDefinitionName } = this.state;
    const trimmed = rollupDefinitionName.trim();

    if (soft)
      return;

    let error = '';

    if (!trimmed) {
      error = 'Please Specify Rollup Definition Name';
    } else if (trimmed.indexOf('"') !== -1 || trimmed.indexOf("'") !== -1) {
      error = `Rollup Definition Name Cannot use ' or " in name`; // eslint-disable-line quotes
    }

    if (error !== '') {
      return error;
    }

    const found = allRollupDefinitions.find(
        rd =>
          rd.attributeName === dimensionName &&
          rd.rollupName.substring(1).trim().toLowerCase() ===
            rollupDefinitionName.trim().toLowerCase() 
      );
    if (found && found.rollupDefinitionId != this.state.rollupDefinitionId) {  // eslint-disable-line eqeqeq
      return 'Rollup Definition Name Already Exists';
    }
    return '';
  }

  validate(soft) {
    const rollupNameError = this.getNameValidationError(soft);
    if (rollupNameError) {
      notifyError(`${rollupNameError}`);
      return false;
    }
    return !rollupNameError;
  }

  selectValuesOption(value) {
    const { selectedValuesOption } = this.state;

    if (selectedValuesOption !== value) {
      this.setState({
        selectedValuesOption: value,
        selectedDimensionValues: [],
      });
    }
  }

  saveRollupDefinition() {
    const {
      selectedDimensionValues,
      rollupDefinitionName,
      rollupDescription,
      databaseId,
      dimensionName,
      editMode,
      rollupDefinitionId,
      selectedDimension,
      selectedValuesOption,
    } = this.state,
      {
        createRollupDefinitionHandler,
        updateRollupDefinitionHandler,
        userKey,
        datasetColumns,
        dispatch,
        browserHistory,
      } = this.props,
      dataSetColumnId = (datasetColumns.find(
        dmra => dmra.attributeName === dimensionName
      ) || {}).dataSetColumnId;

    let allSelected = false;


    if (!this.validate(false)) {
      this.setSoftErrorMode(false);
      return;
    }

    if (!selectedDimension) {
      notifyError('Must select Dimension');
      return;
    }

    if (
      !selectedDimensionValues.length && selectedValuesOption === 'specific'
    ) {
      notifyError('Must select at least one dimension value');
      return;
    }

    if (selectedValuesOption === 'all') {
      allSelected = true;
    }

    if (editMode) {
      updateRollupDefinitionHandler({
        userKey,
        factDatabaseId: databaseId,
        dataSetColumnId,
        rollupDefinitionId,
        rollupName: rollupDefinitionName.trim(),
        rollupDescription,
        rollupData: JSON.stringify(selectedDimensionValues),
        allSelected,
      });
    } else {
      createRollupDefinitionHandler({
        userKey,
        factDatabaseId: databaseId,
        dataSetColumnId,
        rollupName: rollupDefinitionName.trim(),
        rollupDescription,
        rollupData: JSON.stringify(selectedDimensionValues),
        allSelected,
      });
    }
    dispatch(clearRollupDefinitionsSnapshot());
    dispatch(clearRollupDefinitions());
    dispatch(processNewRollupDefinition());
    browserHistory.push(
      `/trianglesOnDemand/queries/rollupDefinitionsList/${databaseId}/${dimensionName}`
    );
  }

  updateRollupDefinitionName(e) {
    this.setState({ rollupDefinitionName: e.target.value });
  }

  updateRollupDescription(e) {
    this.setState({ rollupDescription: e.target.value });
  }

  backToRollupDefinitionsList() {
    const { browserHistory } = this.props;

    browserHistory.goBack();
  }

  changeDimension(e) {
    if (e !== 'default') {
      const { dataSource } = this.props,
        selectedDimension = dataSource.values.find(
          ds => ds.name === e.target.value
        ),
        dimensionValues = selectedDimension
          ? selectedDimension.values.filter(v => v.value.indexOf('@') !== 0)
          : [],
        dimensionName = selectedDimension ? selectedDimension.name : '';
      this.setState({ selectedDimension, dimensionName, dimensionValues });
    } else {
      this.setState({ selectedDimension: e.target.value });
    }

    // TODO retrieve rollup defs for newly selected dimension
  }

  selectDimensionValue(value) {
    const { selectedDimensionValues } = this.state,
      index = selectedDimensionValues.indexOf(value);

    if (index === -1) {
      selectedDimensionValues.push(value);
    } else {
      selectedDimensionValues.splice(index, 1);
    }

    this.setState({ selectedDimensionValues });
  }

  selectAllDimensionValues() {
    const { dimensionValues, selectedDimensionValues } = this.state,
      selectedDimensionValuesMap = selectedDimensionValues.length ===
        dimensionValues.length
        ? []
        : dimensionValues.map(dv => dv.value);

    this.setState({ selectedDimensionValues: selectedDimensionValuesMap });
  }

  render() {
    const {
      editMode,
      dimensionValues,
      dimensions,
      dimensionName,
      selectedDimensionValues,
      rollupDefinitionName,
      rollupDescription,
      softErrorMode,
      selectedValuesOption,
      selectedDimension,
    } = this.state,
      { isFetching, dataSource, verifyPermission, currentDb } = this.props,
      rollupNameValidationError = this.getNameValidationError(softErrorMode);

    const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);

    let body = <div />,
      dimensionValuesJSX = <div />,
      dimensionHeaderJSX = (
        <div style={style.dimensionRow}>
          <h4 style={style.dimensionLabel}>Dimension</h4>
          <h4>{dimensionName}</h4>
        </div>
      ),
      options = dimensions.map((d, idx) => (
        <option key={`dimensions-option-${idx}`} value={d}>{d}</option>
      )),
      // definitionNameJSX = (
      //   <Form.Group
      //     controlId="rollupDefinitionControl"
      //     style={{ marginTop: 10 }}
      //   >
      //     <Form.Label>Rollup Definition Name</Form.Label>
      //     <h4>{rollupDefinitionName}</h4>
      //   </Form.Group>
      // );
      definitionNameJSX = (
        <Form.Group controlId="rollupDefinitionControl" style={{ marginTop: 10 }}>
            <Form.Label>Rollup Definition Name</Form.Label>
            <Form.Control
              as="input"
              placeholder="enter name"
              onChange={e => this.updateRollupDefinitionName(e)}
              value={rollupDefinitionName}
              isInvalid={rollupNameValidationError}
            />
            <Form.Control.Feedback type="invalid">{rollupNameValidationError}</Form.Control.Feedback>
        </Form.Group>
      );

    if (dimensionValues && dimensionValues.length) {
      dimensionValuesJSX = dimensionValues.map((dv, idx) => (
        <Form.Check type="checkbox"
          key={`valuesSelection-${idx}`}
          checked={selectedDimensionValues.indexOf(dv.value) !== -1}
          style={style.checkbox}
          onChange={() => this.selectDimensionValue(dv.value)}
          label={dv.value}/>
      ));
    }

    if (!editMode) {
      dimensionHeaderJSX = (
        <div style={style.dimensionRow}>
          <h4 style={style.dimensionLabel}>Dimension</h4>
          <Form.Control
            as="select"
            placeholder="select"
            value={dimensionName || 'default'}
            onChange={e => this.changeDimension(e)}
          >
            <option value="default">Select a dimension</option>
            {options}
          </Form.Control>
        </div>
      );
    }

    if (currentDb && currentDb.useADX){
        body = <AdxQueryWarning/>;
    }
    else if (isFetching) {
      body = (
        <div style={[GlobalStyles.card, style.contentContainer]}>
          <Loader loadingItem="Rollup Information" />
        </div>
      );
    } else {
      body = (
        <div style={[GlobalStyles.card, style.contentContainer]}>
          {dimensionHeaderJSX}
          {definitionNameJSX}
          <Form.Group
            controlId="rollupDescrptionControl"
            style={{ marginTop: 10 }}
          >
            <Form.Label>Rollup Description</Form.Label>
            <Form.Control
              as="input"
              placeholder="enter description"
              onChange={e => this.updateRollupDescription(e)}
              value={rollupDescription}
            />
          </Form.Group>
          <div
            style={{ padding: '0 1em 1em 1em', display: 'flex' }}
            key={"selectAllValuesSelection"}
          >
              <Form.Group>
                  <Form.Check
                    id='radio-specific'
                    type="radio"
                    checked={selectedValuesOption === 'specific'}
                    onChange={() => this.selectValuesOption('specific')}
                    inline
                    label='Specific'
                  />
                  <Form.Check
                    id='radio-all'
                    type="radio"
                    checked={selectedValuesOption === 'all'}
                    onChange={() => this.selectValuesOption('all')}
                    inline
                    label='All(*)'
                  />
              </Form.Group>
            {selectedDimension && selectedDimension.limitReached
              ? <span style={{color: Colors.red, fontWeight: 'bold', fontSize: '14px', marginLeft: '1em'}}>
                Limit of {dataSource.maxDimensionValues} values has been reached
              </span>
              : ''}
            
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={style.selectBox}>
                <Form.Check type="checkbox"
                  id="selectAllValuesSelection"
                  checked={selectedDimensionValues.length === dimensionValues.length &&
                    selectedDimensionValues.length !== 0}
                  style={style.checkbox}
                  onChange={() => this.selectAllDimensionValues()}
                  label="(Select All)" />
              {dimensionValuesJSX}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div style={{ width: '100vw', padding: '0vh 10vw', overflowY: 'auto', height: 'calc(100vh - 106px)' }}>
          {body}
        </div>
        <SaveButtons
          saveHandler={this.saveRollupDefinition}
          backButtonHander={this.backToRollupDefinitionsList}
          backButtonText="Back to Rollup Definitions"
          isSaveButtonDisabled={!hasPermissions}
        />
      </div>
    );
  }
}

export default radium(CreateEditRollupDefinitions);
