import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import { notifyError } from '@app/utilities/notifier';
import {
    fetchFactColumns,
    fetchDataGroup,
    createDataGroup,
    updateDataGroup,
} from '@app/actions/tod/advancedQueries.actions';
import {RECEIVED_DATAGROUP} from '@app/actions/tod/tod.actionTypes';
import * as GlobalStyles from '@app/utilities/globalStyles';
import ManualEntryControl from '@app/tod/advancedQueries/editors/manualEntryControl';
import ValuesPicker from '@app/tod/advancedQueries/editors/valuesPicker';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import { Card } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';

class CreateDataGroup extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            mode: 'enter',
        }
        this.saveDataGroup = this.saveDataGroup.bind(this);
        this.validate = this.validate.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    get includeOtherColumns() {
        const { currentDb } = this.props;
        return currentDb ? currentDb.useADX : false;
    }
    
    componentDidMount() {
        const {dispatch } = this.props;
        dispatch({type: RECEIVED_DATAGROUP, data: null}); // force a reload
    }

    componentDidUpdate(prevProps) {
        const {dataGroup, isFetching, currentDb, params} = this.props;
        if (null === dataGroup && currentDb && currentDb.id === parseInt(params.databaseId, 10) && !isFetching) {
            // only now can we begin loading things - the state machine resets after currentDb loads from parent...
            this.refresh();
        }
    }

    refresh() {
        const {dispatch, params, userKey} = this.props;
        dispatch(fetchFactColumns(userKey, params.databaseId));
        if (!!params.groupId){
            dispatch(fetchDataGroup(userKey, params.databaseId, params.groupId));
        } else {
            dispatch(fetchDataGroup(userKey, params.databaseId, 0));  // get default from server
        }
    }

    validate() {
        const { dataGroup } = this.props;
        let name = dataGroup ? dataGroup.name : '';
        let factColumnGuid = dataGroup ? dataGroup.factColumnGuid : '';
        if (!name) {
            notifyError(`You must enter a name for this data group.`);
            return false;
        }
    
        if (!factColumnGuid || factColumnGuid === "00000000-0000-0000-0000-000000000000") {
            notifyError('You must select a column.');
            return false;
        }
    
        return true;
    }

    saveDataGroup(){
        const { dispatch, userKey, params, dataGroup } = this.props;
        if (!this.validate()){
            return;
        }

        let values = dataGroup.values;
        values = values.filter(x=> x);  // drop blank values
        values = values.filter((value, index, self) => self.indexOf(value) === index); // only unique values
        dataGroup.values = values;
        if (dataGroup.id > 0){
            dispatch(updateDataGroup(userKey, params.databaseId, dataGroup));
        } else {
            dispatch(createDataGroup(userKey, params.databaseId, dataGroup));
        }
        this.goBack();
    }

    goBack = () => {
        const { browserHistory } = this.props;
        browserHistory.push('/trianglesOnDemand/advancedQueries/dataGroups');
    }

    handleChange(e) {
        const {dispatch} = this.props;
        if (e.target.id === 'mode'){
            this.setState({mode: e.target.value});
            return;
        }

        if (e.target.id === 'factColumnGuid' && this.state.mode === 'select'){
            const {factColumns} = this.props;
            let factColumnGuid = e.target.value;
            let column = factColumns ? factColumns.find(o=> o.factColumnGuid === factColumnGuid) : {};
            let columnType = column ? column.columnType : '___';
            if (columnType.toLowerCase() !== "dimension"){
                this.setState({mode: 'enter'});  // switch away from select mode
            }
        }

        let o = JSON.parse(JSON.stringify(this.props.dataGroup));

        if (o){
            if (e.target.id === 'values'){
                o.values = JSON.parse(e.target.value);
            }
            else {
                o[e.target.id] = e.target.value === 'null' ? null : e.target.value;
            }
        }
        dispatch({type: RECEIVED_DATAGROUP, data: o}); 
    }

    getFormControls(mode){
        const {dataGroup, factColumns} = this.props;
        let factColumnGuid = dataGroup ? dataGroup.factColumnGuid : '';
        let values = dataGroup ? dataGroup.values : [];
        let column = factColumns ? factColumns.find(o=> o.factColumnGuid === factColumnGuid) : {};
        switch(mode) {
            case 'select':
                return (
                    <div className="row col-sm-12" style={{}}>
                        <div className="form-group" style={{width:'100%'}}>
                            <ValuesPicker
                                id="values"
                                values={JSON.stringify(values)}
                                handleChange={this.handleChange}
                                column={column}
                                includeOtherColumns={this.includeOtherColumns}
                        ></ValuesPicker>
                        </div>
                    </div>
                );
            case 'enter':
                return (
                    <ManualEntryControl
                        id="values"
                        values={JSON.stringify(values)}
                        column={column}
                        handleChange={this.handleChange}
                    />
                );
            case 'csv':
                let fname = 'Crazy Claim Ids.csv';
                return (
                    <div className="col-sm-12" style={{}}>
                        <div className="form-group">
                            <label htmlFor="name">Select uploaded CSV file:</label>
                            <select className="form-control" id="file" key="file" value={fname} onChange={this.handleChange} >
                                <option value={fname}>{fname}</option>
                                <option value="ExposureDate">Other.csv</option>
                            </select>
                        </div>
                    </div>
                );
            default:
                return (<div>error with selection mode</div>);
            }
    }

    getColumnsList() {
        const {factColumns} = this.props;
        if (this.includeOtherColumns){
            // include other and claim id if using ADX
            return factColumns ? factColumns.filter(
                col => col.isClaimId || col.columnType.toLowerCase() === 'dimension' || 
                    col.columnType.toLowerCase() === 'property'
            ) : [];
        }
        return factColumns ? factColumns.filter(
            col => col.isClaimId || col.columnType.toLowerCase() === 'dimension'
        ) : [];
    }

    render() {
        const {dataGroup, verifyPermission} = this.props;
        const {mode} = this.state;
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        let name = dataGroup ? dataGroup.name : '';
        let description = dataGroup ? dataGroup.description : '';
        let factColumnGuid = dataGroup ? dataGroup.factColumnGuid : '';
        let columns = this.getColumnsList();
        let column = columns.find(o=> o.factColumnGuid === factColumnGuid);
        column = column ? column : {};
        let modes = [
            {id:'select', label: ' Select values'},
            {id:'enter', label: ' Enter values'},
        ];
        let btnStyle = GlobalStyles.button;
        btnStyle.marginRight = '10px';

        let disableSelect = this.includeOtherColumns ? false : column.columnType !== "Dimension";
        return (
            <div className='container' 
                style={{ position: 'fixed', bottom: '0px', top: '51px',
                    border: '', width: '100%', margin:0, padding: '15px', maxWidth:'none'}}>

                <div style={{overflowY:'auto', height: '100%'}}>
                            <Card style={{border: '', height: 'auto'}}>
                                <Card.Body style={{border: '', height:'auto'}}>
                                    <fieldset style={{margin:0,}}>
                                        <div className="row">
                                            <div className="col-sm-6" style={{}}>
                                                <div className="form-group">
                                                    <label htmlFor="name">Group Name:</label>
                                                    <input
                                                        id="name"
                                                        key="name"
                                                        value={name}
                                                        onChange={this.handleChange}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder='enter a name'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6" style={{}}>
                                                <div className="form-group">
                                                    <label htmlFor="group">Dimension:</label>
                                                    <select className="form-control" id="factColumnGuid" key="factColumnGuid" value={factColumnGuid} onChange={this.handleChange} >
                                                        <option value="">-- choose a dimension --</option>
                                                        {columns.map((m, idx) => (
                                                            <option key={m.factColumnGuid} value={m.factColumnGuid}>{m.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12" style={{}}>
                                                <div className="form-group">
                                                    <label htmlFor="description">Description:</label>
                                                    <input
                                                        id="description"
                                                        value={description}
                                                        onChange={this.handleChange}
                                                        onDrop={(e) => e.preventDefault()}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder='enter a description'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                                {modes.map((m, idx) => (
                                                    <div className='col-sm-4' key={idx}>
                                                        <div className="radio">
                                                            <label>
                                                                <input type="radio" 
                                                                    id='mode'
                                                                    key={`mode-${m.id}`}
                                                                    value={m.id}
                                                                    checked={mode === m.id} 
                                                                    disabled = {m.id === "select" && disableSelect}
                                                                    onChange={this.handleChange} />
                                                                    {m.label}
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                            <div className="col-sm-12" style={{overflowY:'auto'}}>
                                                {/* Values: {this.state.values} */}
                                                {this.getFormControls(mode)}
                                            </div>
                                    </fieldset> 
                                </Card.Body>
                            </Card>
                </div>
                <div style={{width: '100%', position: 'fixed',
                        bottom: 0,left: 0,display:'flex', alignItems:'center',justifyContent: 'flex-end',
                        backgroundColor: Colors.grey, padding: 10, height: '54px', paddingBottom: '15px'}}>
                    <button
                        key="save"
                        type="button"
                        style={btnStyle}
                        onClick={this.saveDataGroup}
                        disabled={!hasPermissions}
                        className="btn btn-default"
                        >
                            Save
                        </button>
                    <button
                        key="cancel"
                        type="button"
                        style={btnStyle}
                        className="btn btn-default"
                        onClick={this.goBack}
                    >
                        Back to Groups
                    </button>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    application: state.application,
    isSaving: state.tod.queries.isSaving,
    dataGroup: state.tod.queries.dataGroup,
    factColumns: state.tod.queries.factColumns,
    isFetching: state.tod.queries.isFetchingDataGroup,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(CreateDataGroup));
