import { callApi } from '../utilities/callApi';

export function retrieveProject(userKey, workspaceId, projectId) {
  return callApi(
    `/workspaces/${workspaceId}/projects/${projectId}`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrieveProjects(userKey, workspaceId) {
  return callApi(
    `/workspaces/${workspaceId}/projects`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function getCheckoutStatus(userKey, workspaceId, projectId) {
  return callApi(
    `/workspaces/${workspaceId}/projects/${projectId}/checkout-status`,
    { 'X-AE-USERKEY': userKey },
  );
}

export function getProjectStatusChangeRequest(userKey, workspaceId, projectIds) {
  return callApi(
    `/workspaces/${workspaceId}/projectstatus/request`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    { projectIds }
  );
}

export function getProjectBulkDeleteRequest(userKey, workspaceId, projectIds) {
    return callApi(
        `/workspaces/${workspaceId}/bulkdelete/request`,
        { 'X-AE-USERKEY': userKey },
        'POST',
        { projectIds },
  );
}

export function processProjectStatusChangeRequest(userKey, workspaceId, projectStatusChangeRequest) {
  return callApi(
    `/workspaces/${workspaceId}/projectstatus`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    projectStatusChangeRequest,
  );
}

export function processProjectBulkDeleteRequest(userKey, workspaceId, projectBulkDeleteRequest) {
  return callApi(
    `/workspaces/${workspaceId}/bulkdelete`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    projectBulkDeleteRequest,
  );
}

export function getValuationDates(userKey, databaseId) {
  return callApi(
    `/workspaces/${databaseId}/valuation-dates`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function getProjectsByValuationDate(userKey, databaseId, valuationDate) {
  return callApi(
    `/workspaces/${databaseId}/projects/valuation-dates/${valuationDate}`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function getProjectVersions(userKey, databaseId, projectId) {
  return callApi(
    `/workspaces/${databaseId}/projects/${projectId}/versions`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function updateProjectVersion(userKey, databaseId, projectId, req) {
  return callApi(
    `/workspaces/${databaseId}/projects/${projectId}/versions`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    req,
  );
}

export function checkOutProject(userKey, workspaceId, projectId) {
  return callApi(
    `/workspaces/${workspaceId}/projects/${projectId}/checkout`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
  );
}

export function undoProjectCheckout(userKey, workspaceId, projectId) {
  return callApi(
    `/workspaces/${workspaceId}/projects/${projectId}/checkout/undo`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
  );
}

export function deleteProject(userKey, workspaceId, projectId) {
  return callApi(
    `/workspaces/${workspaceId}/projects/${projectId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function checkProjectForUniqueness(userKey, databaseId, projectId, body) {
  return callApi(
    `/workspaces/${databaseId}/projects/${projectId}/name`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    body
  );
}

export function requestBatchProcess(userKey, projects, operation) {
  return callApi(
    `/workspaces/projects/aebatch/initiate/${operation}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    projects
  );
}

export function retrieveLongRunningOperationsStatus(userKey, databaseId) {
  return callApi(
    `/workspaces/${databaseId}/operationsstatus`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function retreiveCurrentUdoVersion(userKey, databaseId) {
  return callApi(
    `/workspaces/${databaseId}/udoversion`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function copyProject(userKey, databaseId, projectId, body) {
  return callApi(
    `/integration/projects/${databaseId}/${projectId}/copy`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    body
  );
}

export function retrieveExportsByListType({ userKey, databaseId, listType }) {
  return callApi(
    `aeexportedlists/${databaseId}/${listType}`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function deleteExportListItem({ userKey, databaseId, exportListId }) {
  return callApi(
    `aeexportedlists/${databaseId}/${exportListId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function getProjectReviewState(userKey, databaseId, projectId) {
  console.log(`api getProjectReviewState. ${databaseId}, ${projectId}`);
  // return new Promise((resolve, reject)=> {
  //   resolve({
  //   projectId: projectId,
  //   projectName: 'pname1',
  //   level: 0, // 1, 2, ...
  //   description: 'review not started', // description from ReviewLevels
  //   actions: ['submit','approve', 'reject'],
  // })});
  return callApi(
    `/review/state/${databaseId}/${projectId}`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function getProjectReviewHistory(userKey, databaseId, projectId) {
  console.log(`api getProjectReviewHistory. ${databaseId}, ${projectId}`);
  // return new Promise((resolve, reject)=> {
  //   resolve([
  //   { timestamp: '1/1/2018', level: 0, state: 'Submitted',  user: 'last, first', comments: 'comments zzz' },
  //   { timestamp: '1/1/2018', level: 1, state: 'Approved',  user: 'last, first', comments: 'comments 123' },
  // ])});
  return callApi(
    `/review/history/${databaseId}/${projectId}`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function sendProjectReviewAction(userKey, databaseId, projectId, action, comments, currentLevel) {
  console.log(`api sendProjectReviewAction. ${databaseId}, ${projectId}, ${action}, ${comments}, ${currentLevel}`);
  return callApi(
    `/review/action`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    {
      databaseId,
      projectId,
      level: currentLevel,
      action,
      comments,
    }
  );
}

export function exportSignoffReport(userKey, databaseId, valuationDate) {
  let d = new Date();
  let timeZoneOffset = -Math.floor(d.getTimezoneOffset()/60);
  return callApi(
    `/reports/signoff/${databaseId}/${valuationDate}/${timeZoneOffset}`,
    { 'X-AE-USERKEY': userKey, 'Accept': 'application/csv' },
    'GET'
  );
}
