import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import ReportingSidebar from './reportingSidebar';
import CreateExtract from './createExtract';
import ExtractList from './extractList';
import ExportLists from './exportLists';
import ReportingDefinitionsList from './reportingDefinitionsList';
import AnalysisToolbar from './analysisToolbar';
import {
  retrieveExtractDefinitionNames,
  retrieveReportingDefinitions,
  deleteExtractDefinition,
} from '../../../actions/analysis/reporting.actions';
import { fetchWorkspace } from '../../../actions/analysis/workspace.actions';
import { clearValuationDates, clearValuationDate } from '../../../actions/analysis/project.actions';
import { clearScripts } from '../../../actions/analysis/automation.actions';
import { WORKSPACE_CREATEEDITEXTRACTTABLES, WORKSPACE_DELETEEXTRACTTABLES, MESSAGE_NOT_AUTHORIZED_TO_ACCESS } from '@app/utilities/permissions';
import { notifyError } from '../../../utilities/notifier';

const style = {
  mainContainer: { display: 'flex', flexFlow: 'row nowrap', width: '100vw' },
};

class ReportingMain extends Component {
  static propTypes = {
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
    workspaces: PropTypes.array,
    currentWorkspace: PropTypes.object,
    extractDefinitions: PropTypes.array,
    isFetching: PropTypes.bool,
    extractKickingOff: PropTypes.bool,
    isFetchingWideCsv: PropTypes.bool,
    isFetchingNarrowCsv: PropTypes.bool,
    params: PropTypes.object,
    exportList: PropTypes.array,
    isFetchingExportList: PropTypes.bool,
    apiKey: PropTypes.string,
    exportCsv: PropTypes.func,
    csv: PropTypes.object,
    userPermissions: PropTypes.array,   
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedSideNavOption: 'extract_list',
      firstSearchPerformed: false,
      extractTableDefinition: null,
    };
    this.determineTab = this.determineTab.bind(this);
    this.state.selectedSideNavOption = this.determineTab();
    this.selectSideNavOption = this.selectSideNavOption.bind(this);
    this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
    this.editClickHandler = this.editClickHandler.bind(this);
    this.clearExtractTableDefinition = this.clearExtractTableDefinition.bind(this);
    this.canCreateEditExtractTable = this.canCreateEditExtractTable.bind(this);
    this.canDeleteExtractTable = this.canDeleteExtractTable.bind(this);
  }

  componentWillMount() {
    const { currentWorkspace, dispatch, userKey } = this.props;
    if (currentWorkspace) {
      dispatch(retrieveExtractDefinitionNames({ userKey, databaseId: currentWorkspace.id }));
      dispatch(retrieveReportingDefinitions({ userKey, databaseId: currentWorkspace.id }));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { currentWorkspace, dispatch, userKey, extractDefinitions, params } = this.props;
    if (nextProps.currentWorkspace && nextProps.currentWorkspace !== currentWorkspace) {
      dispatch(retrieveExtractDefinitionNames({ userKey, databaseId: nextProps.currentWorkspace.id }));
      dispatch(retrieveReportingDefinitions({ userKey, databaseId: nextProps.currentWorkspace.id }));
    }
    if (extractDefinitions !== nextProps.extractDefinitions) {
      let extractTableDefinition = null;
      if (params && params.definitionId && params.definitionId !== 'create') {
        extractTableDefinition = nextProps.extractDefinitions.find(ed => ed.id === Number(params.definitionId));
      }
      this.setState({ firstSearchPerformed: true, extractTableDefinition });
    }
    if (currentWorkspace !== nextProps.currentWorkspace) {
      dispatch(clearValuationDates());
      dispatch(clearValuationDate());
    }
  }

  selectSideNavOption(option) {
    const { selectedSideNavOption } = this.state,
      { currentWorkspace, params, browserHistory } = this.props;
    if (selectedSideNavOption !== option) {
      this.setState({ selectedSideNavOption: option });
      if (currentWorkspace) {
        if (option === 'extract_list') {
          browserHistory.push(`/analysis/reporting/workspaces/${currentWorkspace.id || currentWorkspace.workspaceId}`);
        } else if (option !== 'export lists' && option !== 'reporting definitions') {
          browserHistory.push(
            `/analysis/reporting/workspaces/${currentWorkspace.id || currentWorkspace.workspaceId}/definitions/${params.definitionId || 'create'}`
          );
        }
      }
    }
  }

  determineTab() {
    const { params, dispatch, userKey, browserHistory } = this.props;
    let tab = 'extract_list',
      firstOptURLString = '',
      secondOptURLString = '';

    if (params && params.workspaceId) {
      firstOptURLString = `/workspaces/${Number(params.workspaceId)}`;
      if (params && params.definitionId) {
        const par = params.definitionId === 'create' ? 'create' : Number(params.definitionId);
        secondOptURLString = `/definitions/${par}`;
        tab = 'create_extract';
      }
      dispatch(fetchWorkspace(userKey, params.workspaceId));
      browserHistory.push(`/analysis/reporting${firstOptURLString}${secondOptURLString}`);
    }

    return tab;
  }

  changeWorkspaceHandler(workspaceId) {
    const {
      dispatch,
      userKey,
      browserHistory,
    } = this.props,
      { selectedSideNavOption } = this.state;
    dispatch(fetchWorkspace(userKey, workspaceId));
    dispatch(clearScripts());
    let optURLString = '';
    if (selectedSideNavOption === 'create_extract') optURLString = '/definitions/create';
    browserHistory.push(`/analysis/reporting/workspaces/${workspaceId}${optURLString}`);
  }

  canCreateEditExtractTable() {
    if (this.props.currentWorkspace === null) return false;
    if (this.props.currentWorkspace.permissions.indexOf(WORKSPACE_CREATEEDITEXTRACTTABLES) === -1 &&
        this.props.userPermissions.indexOf(WORKSPACE_CREATEEDITEXTRACTTABLES) === -1) {
      return false;
    }
    return true;
  }

  canDeleteExtractTable() {
    if (this.props.currentWorkspace === null) return false;
    if (this.props.currentWorkspace.permissions.indexOf(WORKSPACE_DELETEEXTRACTTABLES) === -1 &&
        this.props.userPermissions.indexOf(WORKSPACE_DELETEEXTRACTTABLES) === -1) {
      return false;
    }
    return true;
  }

  editClickHandler(definitionId) {
    if (!this.canCreateEditExtractTable()) {
      notifyError(MESSAGE_NOT_AUTHORIZED_TO_ACCESS);
      return;
    }
    const extractDefinitions = this.props.extractDefinitions.slice(),
      extractTableDefinition = extractDefinitions.find(ed => ed.id === definitionId),
      { currentWorkspace, browserHistory } = this.props,
      workspaceId = currentWorkspace.workspaceId || currentWorkspace.id;
    this.setState({ extractTableDefinition }, () => this.selectSideNavOption('create_extract'));
    browserHistory.push(`/analysis/reporting/workspaces/${workspaceId}/definitions/${definitionId}`);
  }

  clearExtractTableDefinition() {
    this.setState({ extractTableDefinition: null });
  }

  render() {
    const { selectedSideNavOption, firstSearchPerformed, extractTableDefinition } = this.state,
      {
        userKey,
        dispatch,
        workspaces,
        currentWorkspace,
        extractDefinitions,
        reportingDefinitions,
        isFetching,
        params,
        extractKickingOff,
        isFetchingWideCsv,
        isFetchingNarrowCsv,
        exportList,
        isFetchingExportList,
        apiKey,
        exportCsv,
        csv,
        browserHistory,
      } = this.props,
      sidebar = (
        <ReportingSidebar
          selectSideNavOption={this.selectSideNavOption}
          selectedSideNavOption={selectedSideNavOption}
        />
      ),
      toolbar = (
        <AnalysisToolbar
          currentWorkspace={currentWorkspace}
          workspaces={workspaces}
          dispatch={dispatch}
          changeWorkspaceHandler={this.changeWorkspaceHandler}
          selectedSideNavOption={selectedSideNavOption}
        />
      );
    let mainContent;
    switch (selectedSideNavOption) {
      case 'create_extract':
        mainContent = (
          <CreateExtract
            dispatch={dispatch}
            userKey={userKey}
            params={params}
            extractTableDefinition={extractTableDefinition}
            extractDefinitions={extractDefinitions}
            databaseId={currentWorkspace && (currentWorkspace.workspaceId || currentWorkspace.id)}
            clearExtractTableDefinition={this.clearExtractTableDefinition}
            exportList={exportList}
            isFetchingExportList={isFetchingExportList}
            selectSideNavOption={this.selectSideNavOption} />
        );
        break;
      case 'extract_list':
        mainContent = (
          <ExtractList
            browserHistory={browserHistory}
            extractKickingOff={extractKickingOff}
            isFetchingWideCsv={isFetchingWideCsv}
            isFetchingNarrowCsv={isFetchingNarrowCsv}
            firstSearchPerformed={firstSearchPerformed}
            isFetching={isFetching}
            currentWorkspace={currentWorkspace}
            selectSideNavOption={this.selectSideNavOption}
            extractDefinitions={extractDefinitions}
            dispatch={dispatch}
            editClickHandler={this.editClickHandler}
            deleteExtractDefinitionHandler={deleteExtractDefinition}
            databaseId={currentWorkspace && (currentWorkspace.workspaceId || currentWorkspace.id)}
            userKey={userKey}
            apiKey={apiKey}
            exportCsv={exportCsv}
            csv={csv}
            canCreateEditExtractTable = {this.canCreateEditExtractTable()}
            canDeleteExtractTable = {this.canDeleteExtractTable()}
          />
        );
        break;
      case 'export lists':
        mainContent = (
          <ExportLists
            isFetchingExportList={isFetchingExportList}
            userKey={userKey}
            dispatch={dispatch}
            exportList={exportList}
            currentWorkspace={currentWorkspace} />
        );
        break;
    case 'reporting definitions':
        mainContent = (
            <ReportingDefinitionsList
            browserHistory={browserHistory}
            extractKickingOff={extractKickingOff}
            isFetchingWideCsv={isFetchingWideCsv}
            isFetchingNarrowCsv={isFetchingNarrowCsv}
            firstSearchPerformed={firstSearchPerformed}
            isFetching={isFetching}
            currentWorkspace={currentWorkspace}
            selectSideNavOption={this.selectSideNavOption}
            reportingDefinitions={reportingDefinitions}
            dispatch={dispatch}
            editClickHandler={this.editClickHandler}
            deleteExtractDefinitionHandler={deleteExtractDefinition}
            databaseId={currentWorkspace && (currentWorkspace.workspaceId || currentWorkspace.id)}
            userKey={userKey}
            apiKey={apiKey}
            exportCsv={exportCsv}
            csv={csv}
            canCreateEditExtractTable = {this.canCreateEditExtractTable()}
            canDeleteExtractTable = {this.canDeleteExtractTable()}
            />
        );
        break;
    

      default:
        mainContent = <ExtractList selectSideNavOption={this.selectSideNavOption} />;
    }

    return (
      <div style={style.mainContainer}>
         {sidebar} 
        <div>
          {toolbar}
          {mainContent}
        </div>
      </div>
    );
  }
}

export default radium(ReportingMain);
