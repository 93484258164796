import {
  RECEIVED_WORKSPACE,
  RECEIVED_WORKSPACES,
  SELECTING_WORKSPACE,
  RECEIVED_ARCHIVED,
  RECEIVED_ARCHIVED_FOR_BACKUP,
  RECEIVED_BACKUPS,
  RECEIVED_PIT_RESTORE_RANGE,
  FETCHING_BACKUPS,
  FETCHING_WORKSPACES_FROM_SERVER,
  RECEIVED_PROJECT,
  RECEIVE_PROJECTS,
  FETCHING_PROJECTS_FROM_SERVER,
  CHECKING_OUT_PROJECT,
  SELECTING_PROJECT,
  FETCHING_REPORT_FROM_SERVER,
  FETCHING_REPORTS_FROM_SERVER,
  FETCHING_REPORT_ACCESS_TOKEN,
  RECEIVED_REPORT_ACCESS_TOKEN,
  SELECTING_REPORT,
  RECEIVED_REPORTS,
  RECEIVE_SEGMENTS,
  FETCHING_SEGMENTS_FROM_SERVER,
  RECEIVED_DATABASE_UDO_VERSIONS,
  INITIATING_BATCH_PROCESS,
  RECEIVED_LONG_RUNNING_OPERATIONS_STATUS,
  RECEIVED_AE_DB_SCRIPT_VERSION,
  FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER,
  RECEIVED_CURRENT_UDO_VERSION,
  RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS,
  LOCK_UDO_LIBRARY,
  UNLOCK_UDO_LIBRARY,
  FETCHING_REVIEWLEVEL,
  RECEIVED_REVIEWLEVEL,
  RECEIVED_REVIEWLEVELS,
  CHANGE_REVIEWLEVEL_STATUS,
  COPYING_PROJECT,
  REQUESTING_EXTRACT_DATA_CSV,
  RECEIVED_EXTRACT_DATA_CSV,
  RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION,
  FAILED_EXTRACT,
  REFRESHING_CUBE,
  CUBE_REFRESHED,
  FETCHING_SCRIPTS,
  SCRIPTS_RECEIVED,
  FETCHING_SCRIPT,
  SCRIPT_RECEIVED,
  RECEIVED_SCRIPT_COMMANDS,
  FETCHING_SCRIPT_COMMANDS,
  CREATING_SCRIPT,
  SCRIPT_CREATED,
  CLEAR_SEGMENTS,
  MAPPERS_RECEIVED,
  COMMAND_STATUSES_RECEIVED,
  FETCHING_COMMAND_STATUSES,
  RECEIVED_PROJECT_VERSIONS,
  CHANGED_VALUATION_DATE,
  CHECKING_PROJECT_FOR_UNIQUENESS,
  CHECKED_PROJECT_FOR_UNIQUENESS,
  RECEIVED_COPIED_PROJECT_ID,
  RECEIVED_VALUATION_DATES,
  FETCHING_VALUATION_DATES,
  FETCHING_EXTRACT_DEFINITIONS,
  RECEIVED_EXTRACT_DEFINITION_NAMES,
  FETCHING_REPORTING_DEFINITIONS,
  RECEIVED_REPORTING_DEFINITIONS,
  INITIATING_EXTRACT,
  EXTRACT_INITIATED,
  RETRIEVING_EXPORTS_BY_LIST_TYPE,
  RETRIEVED_EXPORTS_BY_LIST_TYPE,
  RETRIEVING_EXCEL_BY_LIST_TYPE,
  RETRIEVED_EXCEL_BY_LIST_TYPE,
  RETRIEVING_PRINTS_BY_LIST_TYPE,
  RETRIEVED_PRINTS_BY_LIST_TYPE,
  RETRIEVING_COLLECTION_SET_BY_LIST_TYPE,
  RETRIEVED_COLLECTION_SET_BY_LIST_TYPE,
  CLEAR_SCRIPTS,
  RECEIVED_REPORTING_CSV_FILE,
  FETCHING_REPORTING_WIDE_CSV,
  FETCHING_REPORTING_NARROW_CSV,
  FETCHING_CSV,
  RECEIVED_BACKUPRESTORE_LOG_FILE,

  GET_PROJECT_REVIEW_STATE_TYPE,
  GET_PROJECT_REVIEW_HISTORY_TYPE,
  SEND_PROJECT_REVIEW_COMMMAND_TYPE,
  CLEAN_PROJECT_REVIEW_TYPE,

  FETCHING_DOWNLOADS,
    RECEIVED_DOWNLOADS,
    FETCHING_ANALYSIS_TASKS,
  RECEIVED_ANALYSIS_TASKS,
  RECEIVED_DATABASE_DOWNLOADS,
  RECEIVED_DOWNLOAD_TYPES,
  CONFIRM_ZIP_DOWNLOAD,
  RECEIVED_ZIP_DOWNLOAD,
  DOWNLOAD_CHANGE_DB,
  DOWNLOAD_CHANGE_TYPE,
  CLEAR_WORKSPACE_RELATED,
} from '../actions/analysis/analysis.actionTypes';

import analysisTasksReducer from './analysis/analysisTasks.reducer';
import downloadsReducer from './analysis/downloads.reducer';
import workspacesReducer from './analysis/workspaces.reducer';
import projectsReducer from './analysis/projects.reducer';
import segmentsReducer from './analysis/segments.reducer';
import cubeReducer from './analysis/cube.reducer';
import reportsReducer from './analysis/reports.reducer';
import automationReducer from './analysis/automation.reducer';
import reportingReducer from './analysis/reporting.reducer';

const analysis = (state = {
  workspaces: {
    isFetching: false,
    isSaving: false,
    items: [],
    reviewLevels:[],
    reviewLevelMembers:[],
    backups: [],
    archived: [],
    current: null,
    modal: null,
    databaseUdoVersions: [],
    UdoLibrarylockstatus:false,
    currentAEVersion: null,
    isExtractToCsvProcessing: false,
    isFetchingReviewList:false,
    csvExtractFile: null,
	txtFile: null,
  },
  projects: {
    workspaceId: 0,
    isFetching: false,
    items: [],
    current: null,
    batchProjects: [],
    longRunningOperationsStatus: [],
    currentUdoVersionNbr: 0,
    copyingProject: {},
    projectVersions: [],
    valuationDate: '',
    checkingUniqueness: false,
    copiedProjectId: null,
    valuationDates: [],
    exportList: [],
    printList: [],
    collectionSet: [],
    isFetchingExportList: false,
    isFetchingPrintList: false,
    isFetchingCollectionSet: false,
    excelList: [],
    isFetchingExcelList: false,
    projectReviewState: null,
    projectReviewHistory: null,
    projectReviewRefresh: null,
  },
  segments: { workspaceId: 0, projectId: 0, isFetchingSegments: false, items: [] },
  reports: { isFetching: false, current: null, accessToken: null },
  cube: { isFetching: false },
  automation: { 
    isFetching: false,
    isFetchingCommandStatuses: false,
    scripts: [],
    mappers: [],
    scriptCommands: [],
    commandStatuses: [],
    selectedScript: null,
    isCreatingScript: false,
  },
  reporting: {
    isFetching: false,
    extractDefinitions: [],
    extractKickingOff: false,
    isFetchingWideCsv: false,
    isFetchingNarrowCsv: false,
    csv: null
  },
  downloads: {},
  analysistasks: {}
}, action) => {
  switch (action.type) {
    case RECEIVED_WORKSPACE:
    case RECEIVED_WORKSPACES:
    case FETCHING_WORKSPACES_FROM_SERVER:
    case SELECTING_WORKSPACE:
    case RECEIVED_ARCHIVED:
    case RECEIVED_ARCHIVED_FOR_BACKUP:
    case RECEIVED_BACKUPS:
    case RECEIVED_PIT_RESTORE_RANGE:
    case FETCHING_BACKUPS:
    case RECEIVED_DATABASE_UDO_VERSIONS:
    case RECEIVED_DATABASE_UDOLIBRARY_LOCKSTATUS:
    case LOCK_UDO_LIBRARY:
    case UNLOCK_UDO_LIBRARY:
    case FETCHING_REVIEWLEVEL:
    case RECEIVED_REVIEWLEVEL:
    case RECEIVED_REVIEWLEVELS:
    case RECEIVED_AE_DB_SCRIPT_VERSION:
    case REQUESTING_EXTRACT_DATA_CSV:
    case RECEIVED_EXTRACT_DATA_CSV:
    case FAILED_EXTRACT:
	  case RECEIVED_BACKUPRESTORE_LOG_FILE:
      return Object.assign({}, state, { workspaces: workspacesReducer(state.workspaces, action) });

// TODO: why separatly? it should be part of workspace
  case CHANGE_REVIEWLEVEL_STATUS:
    return Object.assign({}, state, { isFetchingReviewList:false});


    case RECEIVED_PROJECT:
    case RECEIVE_PROJECTS:
    case FETCHING_PROJECTS_FROM_SERVER:
    case CHECKING_OUT_PROJECT:
    case SELECTING_PROJECT:
    case INITIATING_BATCH_PROCESS:
    case RECEIVED_LONG_RUNNING_OPERATIONS_STATUS:
    case FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER:
    case RECEIVED_CURRENT_UDO_VERSION:
    case COPYING_PROJECT:
    case CHANGED_VALUATION_DATE:
    case RECEIVED_PROJECT_VERSIONS:
    case CHECKED_PROJECT_FOR_UNIQUENESS:
    case CHECKING_PROJECT_FOR_UNIQUENESS:
    case RECEIVED_COPIED_PROJECT_ID:
    case RECEIVED_VALUATION_DATES:
    case FETCHING_VALUATION_DATES:
    case RETRIEVED_EXPORTS_BY_LIST_TYPE:
    case RETRIEVING_EXPORTS_BY_LIST_TYPE:
    case RETRIEVED_PRINTS_BY_LIST_TYPE:
    case RETRIEVING_PRINTS_BY_LIST_TYPE:
    case RETRIEVED_COLLECTION_SET_BY_LIST_TYPE:
    case RETRIEVING_COLLECTION_SET_BY_LIST_TYPE:
    case RETRIEVED_EXCEL_BY_LIST_TYPE:
    case RETRIEVING_EXCEL_BY_LIST_TYPE:
    case GET_PROJECT_REVIEW_STATE_TYPE:
    case GET_PROJECT_REVIEW_HISTORY_TYPE:
    case CLEAN_PROJECT_REVIEW_TYPE:
    case SEND_PROJECT_REVIEW_COMMMAND_TYPE:
    case CLEAR_WORKSPACE_RELATED: 
      return Object.assign({}, state, { projects: projectsReducer(state.projects, action) });
    case RECEIVE_SEGMENTS:
    case FETCHING_SEGMENTS_FROM_SERVER:
    case CLEAR_SEGMENTS:
      return Object.assign({}, state, { segments: segmentsReducer(state.segments, action) });
    case CUBE_REFRESHED:
    case REFRESHING_CUBE:
      return Object.assign({}, state, { cube: cubeReducer(state.cube, action) });
    case FETCHING_SCRIPTS:
    case SCRIPTS_RECEIVED:
    case FETCHING_SCRIPT:
    case SCRIPT_RECEIVED:
    case RECEIVED_SCRIPT_COMMANDS:
    case FETCHING_SCRIPT_COMMANDS:
    case CREATING_SCRIPT:
    case SCRIPT_CREATED:
    case MAPPERS_RECEIVED:
    case COMMAND_STATUSES_RECEIVED:
    case FETCHING_COMMAND_STATUSES:
    case CLEAR_SCRIPTS:
      return Object.assign({}, state, { automation: automationReducer(state.automation, action) });
    case FETCHING_EXTRACT_DEFINITIONS:
    case RECEIVED_EXTRACT_DEFINITION_NAMES:
    case FETCHING_REPORTING_DEFINITIONS:
    case RECEIVED_REPORTING_DEFINITIONS:
    case EXTRACT_INITIATED:
    case INITIATING_EXTRACT:
    case RECEIVED_REPORTING_CSV_FILE:
    case FETCHING_REPORTING_WIDE_CSV:
    case FETCHING_REPORTING_NARROW_CSV:
    case FETCHING_CSV:
    case RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION:
      return Object.assign({}, state, { reporting: reportingReducer(state.reporting, action) });
    case FETCHING_REPORT_FROM_SERVER:
    case FETCHING_REPORTS_FROM_SERVER:
    case FETCHING_REPORT_ACCESS_TOKEN:
    case RECEIVED_REPORT_ACCESS_TOKEN:
    case SELECTING_REPORT:
    case RECEIVED_REPORTS:
      return Object.assign({}, state, { reports: reportsReducer(state.reports, action) });
    case FETCHING_DOWNLOADS:
    case RECEIVED_DOWNLOADS:
    case RECEIVED_DATABASE_DOWNLOADS:
    case RECEIVED_DOWNLOAD_TYPES:
    case CONFIRM_ZIP_DOWNLOAD:
    case RECEIVED_ZIP_DOWNLOAD:
    case DOWNLOAD_CHANGE_DB:
    case DOWNLOAD_CHANGE_TYPE:
      return Object.assign({}, state, { downloads: downloadsReducer(state.downloads, action) });
    case FETCHING_ANALYSIS_TASKS:
    case RECEIVED_ANALYSIS_TASKS:
      return Object.assign({}, state, { analysistasks: analysisTasksReducer(state.analysistasks, action) });
    default:
      return state;
  }
};

export default analysis;
