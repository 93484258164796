// import { receivedMessageFromServer } from '../actions/serverNotification.actions';
// import { store } from '../store';
import { getAppSettings } from '../config';
// import $ from 'jquery';
 
var jQuery = require('jquery');
window.jQuery = jQuery; // hack to get signalr working
// TODO - WDD disabling signalR for now - var signalR = require('@app/signalr/jquery.signalR-2.4.0.min.js');

// const _client = {};

function initializeServerNotifications(userKey) {
    
  getAppSettings().then(settings => {
      return;
      // TODO WDD disabling
    // const connection = $.hubConnection(`https://${settings.baseServiceUrl}`);
    // connection.qs = { 'X-AE-USERKEY': userKey };
    // connection.logging = true;
    // const proxy = connection.createHubProxy('clientNotificationHub');
    // proxy.on('Notify', (message) => {
    //     //console.error('Notify fired: ', message);
    //     store.dispatch(receivedMessageFromServer(message));
    // });

    // connection.start({ withCredentials: false })
    //     .done(() => console.error('signalr connected...'))
    //     .fail((error) => console.error('signalr FAIL: ', error)); 

    // _client.connection = connection;
    // _client.proxy = proxy;
  });


    // NEW CODE PROVIDED BY NIKITA AT MICROSOFT
    // async function start() {
    //     try {
    //         await connection.start();
    //         console.log('connected');
    //     } catch (err) {
    //         //console.log(err);
    //         setTimeout(() => start(), 20000);
    //     }
    // };

    // connection.onclose(async () => {
    //     await start();
    // });
    // END NEW CODE
  
}

export {
  initializeServerNotifications,
};
