import React, { Component } from 'react';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import * as Colors from '@app/utilities/colors';
import {
  getAvailableDownloads,
  getDatabasesWithAvailableDownloads,
  getAvailableDownloadTypes,
  initiateDownload,
  deleteExportFile,
} from '@app/actions/analysis/download.actions';
import Loader from '@app/shared/presentational/loader';
import NoResults from '@app/components/shared/presentational/noResults';
import {
    RECEIVED_DOWNLOADS,
    DOWNLOAD_CHANGE_DB,
    DOWNLOAD_CHANGE_TYPE,
    CONFIRM_ZIP_DOWNLOAD,
    RECEIVED_ZIP_DOWNLOAD,
} from '@app/actions/analysis/analysis.actionTypes';
import { Button, Modal} from 'react-bootstrap';

import { Grid, Button as AButton } from '@arius';
import { showModal } from '@app/actions/modal.actions';

class DownloadsList extends Component {
    static propTypes = {};
    static defaultProps = {
        isFetching: false,
        downloadss: [],
        userKey: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            selectedDownloadType: 0,
            selectedDatabaseId: 0,
            displayWaitingIcon: false,
            columnSelected: '',
            isDescending: false,
        };
        this.handleDatabaseChange = this.handleDatabaseChange.bind(this);
        this.handleDownloadTypeChange = this.handleDownloadTypeChange.bind(this);
        this.getGrid = this.getGrid.bind(this);
    }

    componentDidMount() {
        const { dispatch, userKey, selectedDatabaseId, selectedDownloadType} = this.props;

        dispatch(getAvailableDownloadTypes(userKey));
        dispatch(getDatabasesWithAvailableDownloads(userKey));
        this.getDownloads(selectedDatabaseId, selectedDownloadType);
    }

    componentWillReceiveProps(nextProps) {
        
        if(nextProps.availableDatabases !== this.props.availableDatabases) {
            if (!this.props.selectedDatabaseId) {
                if (nextProps.availableDatabases && nextProps.availableDatabases.length > 0) {
                    this.selectDatabase(nextProps.availableDatabases[0].databaseID);
                }
            }
            else {
                const index = nextProps.availableDatabases.findIndex(x => x.databaseID === this.props.selectedDatabaseId);
                if (index === -1) {
                    if (nextProps.availableDatabases && nextProps.availableDatabases.length > 0) {
                        this.selectDatabase(nextProps.availableDatabases[0].databaseID);
                    }
                }
            }
        }

        if(nextProps.availableDownloadTypes !== this.props.availableDownloadTypes) {
            if (!this.props.selectedDownloadType) {
                if (nextProps.availableDownloadTypes && nextProps.availableDownloadTypes.length > 0) {
                    this.selectDownloadType(nextProps.availableDownloadTypes[0].id);
                }
            }
        }

        if(nextProps.selectedDatabaseId !== this.props.selectedDatabaseId ||
           nextProps.selectedDownloadType !== this.props.selectedDownloadType) {
                this.getDownloads(nextProps.selectedDatabaseId, nextProps.selectedDownloadType);
        }
    }

    handleDatabaseChange(e){
        this.selectDatabase(e.target.value);
    }

    selectDatabase = (databaseId) => {
        const { dispatch } = this.props;
        dispatch({ type: DOWNLOAD_CHANGE_DB, databaseId: databaseId });
    }

    getDownloads = (selectedDatabaseId, selectedDownloadType) => {
        const { dispatch, userKey} = this.props;
        if (selectedDatabaseId > 0 && selectedDownloadType > 0) {
            dispatch(getAvailableDownloads(userKey, selectedDownloadType, selectedDatabaseId));
        }
        else {
            dispatch({ type: RECEIVED_DOWNLOADS, data : [] });
        }
    }

    handleDownloadTypeChange(e){
        this.selectDownloadType(e.target.value);
    }

    selectDownloadType = (typeId) => {
        const { dispatch } = this.props;
        dispatch({ type: DOWNLOAD_CHANGE_TYPE, downloadType : typeId });
    }

    ///////////////////////////////////////////////////////////
    // from downloadItem
    
    launchModal = (item) => {
        const { dispatch } = this.props;
        dispatch({ type: CONFIRM_ZIP_DOWNLOAD, item });
    }

    handleOpen = () => {
        this.setState({ displayWaitingIcon: false });
    }

    handleClose = () => {
        const { dispatch } = this.props;
        this.setState({ displayWaitingIcon: false });
        dispatch({ type: RECEIVED_ZIP_DOWNLOAD, data : [] });
    }

    save = () => {
        const { dispatch, userKey, selectedDownloadZipFileItem:item } = this.props;

        this.setState({ displayWaitingIcon: true });

        dispatch(initiateDownload(userKey, item.downloadType, item.databaseID, item.id, item.fileName));
    }

    promptToDelete = (item) => {
        const { dispatch, userKey } = this.props;

        let deleteMessageItems = [
            <li key={item.id} style={{ fontWeight: 800, listStyle: 'none' }}>
                {item.fileName}
            </li>,
        ];
    
        deleteMessageItems.unshift(
            'Are you sure you want to delete the following exported file: '
        );
        deleteMessageItems.push(
            'This action cannot be undone.'
        );

        const yesClickHandler = () => {
            dispatch(deleteExportFile(userKey, item.downloadType, item.databaseID, item.id)); 
        };
        const noClickHandler = () => {},
        action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);       
    }

    // end from downloadItem
    //////////////////////////////////////////////////////////////

    getActionItems = (row) => {

        const actionButtons = (
            <div>
                <AButton key={'btn-download-' + row.id} 
                        toolTip={'Download'} 
                        placement="left" 
                        iconName="arrow_downward" 
                        onClick={(e) => this.launchModal(row)} 
                        />

                <AButton key={'btn-delete-' + row.id} 
                        toolTip={'Delete'} 
                        placement="left" 
                        iconName="delete" 
                        onClick={(e) => this.promptToDelete(row)} />
            </div>
        );

        return  (actionButtons);
    }

    getGrid(data) {

        const columns = [
            { field: 'fileName', headerText: 'File (created on YYYYMMDD-hhmmss)', width: '35%'},
            { field: 'createdBy', headerText: 'Created By', width: '15%'},
            { field: 'createdDate', headerText: 'Created Date', type: 'datetime', width: '20%'},
            { field: 'numberOfFiles', headerText: '# of APJ files', width: '10%'},
            { field: 'fileSizeFormatted', headerText: 'Approx size', width: '10%'},
            { headerText: 'Actions', width: '10%', template: this.getActionItems},
        ];

        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    render() {
        const { 
            isFetching,
            availableDownloads,
            availableDatabases,
            availableDownloadTypes, 
            selectedDatabaseId,
            selectedDownloadType,
            showDownloadZipFileDialog,
            selectedDownloadZipFileItem,
        } = this.props;

        const { displayWaitingIcon } = this.state;

        let alreadyFetched = true;
        let data = availableDownloads ? availableDownloads : [];
        let dbs = availableDatabases ? availableDatabases : [];
        let dts = availableDownloadTypes ? availableDownloadTypes : [];
        let toolbar = (
            <div className='row form form-inline' style={{
                paddingTop: '15px', paddingBottom: '15px',
                backgroundColor: '#EAEAEA'}}>
                <div className="form-group col-sm-6">
                    <label style={{marginRight: '15px'}} htmlFor="selectedDatabaseId">Database:  </label>
                    <select className="form-control" style={{width: '60%'}} 
                            id="selectedDatabaseId" 
                            value={selectedDatabaseId} 
                            onChange={this.handleDatabaseChange}>
                        <option value="">
                        -- Select a Database --
                        </option>
                        {dbs.map(col => (
                        <option
                            value={col.databaseID}
                            key={`${col.databaseName}`}
                            >
                            {col.displayName}
                        </option>
                        ))
                        }
                    </select>
                </div>
                <div className="form-group  col-sm-6">
                    <label style={{marginRight: '15px'}} htmlFor="selectedDatabaseId">Download Type:  </label>
                    <select style={{width: '60%'}} className="form-control"
                            id="selectedDownloadType"
                            value={selectedDownloadType}
                            onChange={this.handleDownloadTypeChange}>
                        <option value="">
                        -- Select a Download Type --
                        </option>
                        {dts.map(col => (
                        <option
                            value={col.id}
                            key={`${col.fileType}`}
                            >
                            {col.displayName}
                        </option>
                        ))
                        }
                    </select>
                </div> 
            </div>
        );

        let headerMarkup = (
            <div className="list-header-arius">
                <h4>Downloads</h4> 
            </div>
        );

        let contentMarkup = (
            <div style={{ width: '75vw', marginTop: '5vh auto' }}>
            <Loader loadingItem="Downloads" />
            </div>
        );

        if (!isFetching && alreadyFetched) {
            if (data && (data.error || data.length === 0)) {
                contentMarkup = (
                    <div style={{ width: '75vw', marginTop: '5vh auto' }}>
                    <NoResults />
                    </div>
                );
            } else {
                contentMarkup = this.getGrid(data);
            }
        }


        const item = selectedDownloadZipFileItem;

        let waitControl = displayWaitingIcon? (
                    <i style={ { color: Colors.purple, marginLeft: 10 } } className="fa fa-spinner fa-spin"></i>
                    ) : (<div></div>);

        let modalButtons = item? (              
            <span>
                <Button key="confirmButton"
                    variant="arius"
                    style={{marginRight:'10px'}}
                    onClick={this.save}>Yes</Button>
                <Button key="nobutton"
                    variant="arius"
                    onClick={this.handleClose}>No</Button>
            </span>):('');

        let modalBody = item? (
            <span style={{ textAlign: 'center', fontSize: 20, margin: 10 }}>
                <div>
                    Are you sure you want to download the following exported file:
                    <li
                        key={item.id}
                        style={{ fontWeight: 800, listStyle: 'none' }}
                    >
                        {item.fileName}
                        {waitControl}
                    </li>
                </div>
            </span>):('');

        return (
            <div className="list-container-arius">
                {toolbar}
                {headerMarkup}
                {contentMarkup}
                {item && showDownloadZipFileDialog ?
                    <div>
                        <Modal show={showDownloadZipFileDialog} 
                            onHide={this.handleClose} 
                            onEnter={this.handleOpen} 
                            dialogClassName="download-confirmation-modal">
                            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
                                <Modal.Title>Are you sure?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{modalBody}</Modal.Body>
                            <Modal.Footer>{modalButtons}</Modal.Footer>
                        </Modal>
                    </div>:('')
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    availableDownloadTypes: state.analysis.downloads.availableDownloadTypes,
    availableDownloads: state.analysis.downloads.availableDownloads,
    availableDatabases: state.analysis.downloads.availableDatabases,
    isFetching: state.analysis.downloads.isFetchingDownloads,
    selectedDatabaseId: state.analysis.downloads.selectedDatabaseId,
    selectedDownloadType: state.analysis.downloads.selectedDownloadType,
    showDownloadZipFileDialog: state.analysis.downloads.showDownloadZipFileDialog,
    selectedDownloadZipFileItem: state.analysis.downloads.selectedDownloadZipFileItem,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(DownloadsList));

