import React, { Component } from 'react';
import Feature from '@app/utilities/routing/routeSafeFeature';
import { Link } from 'react-router-dom';

class Home extends Component {
    render() {
        return (
        <div className="tile-container">
            <img className="arius-logo" src="assets/arius_logo.svg" alt="arius enterprise"/>
            <h2>Welcome to Arius Enterprise</h2>
            <div className="tile-row">
                <Link className="tile" to="trianglesOnDemand">
                    <div>
                        <img src="assets/ToD_logo_white.svg" alt="TOD"/>                    
                    </div>
                    Triangles On Demand
                </Link>
                <Feature flag="ariusAnalysis">
                    <Link className="tile" to="analysis">
                        <div>
                            <img src="assets/white_ariusCurve.svg" alt="analysis"/>                    
                        </div>
                        Arius Analysis Tool
                    </Link>
                </Feature>
            </div>
            <div className="tile-row">
                <Link className="tile" to="portalManagement">
                    <div>
                        <img src="assets/portal_management_white.svg" alt="portalmanagement"/>                    
                    </div>
                    Portal Management
                </Link>
                <Link className="tile" to="utilities">
                    <i className="fa fa-cog"/>
                    Utilities
                </Link>
            </div>
        </div>
        );
    }
}

export default Home;
