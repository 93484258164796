import {
  RECEIVED_USER,
  FETCHING_USER_FROM_SERVER,
  UNAUTHORIZED_USER,
} from './actionTypes';
import * as userServiceApi from '../serviceApi/users.serviceApi';
import {
  initializeServerNotifications,
} from '../utilities/initializeServerNotifications';
import { addFeatureFlags } from 'react-redux-feature-flags';

function fetchingFromServer() {
  return {
    type: FETCHING_USER_FROM_SERVER,
  };
}

function receivedUser(json) {
  initializeServerNotifications(json.userKey);
  return {
    type: RECEIVED_USER,
    userId: json.userId,
    userKey: json.userKey,
    userPermissions: json.sitePermissions,
    siteSettings: json.siteSettings,
    receivedAt: Date.now(),
  };
}

function unauthorized() {
  return {
    type: UNAUTHORIZED_USER,
    receivedAt: Date.now(),
  };
}

function fetchCurrentUser(accessToken) {
  return (dispatch, getState) => {
    dispatch(fetchingFromServer());
    return userServiceApi.retrieveCurrentUser(accessToken).then(json => {
      if (!json) {
        dispatch(unauthorized());
      } else {
        dispatch(addFeatureFlags(json.features));
        dispatch(receivedUser(json, getState()));
      }
    });
  };
}

export { fetchCurrentUser, unauthorized };
