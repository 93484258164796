import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import {
    OverlayTrigger,
    Popover
} from 'react-bootstrap';
import {
    DATA_DELETETODDATABASE,
    DATA_BACKUP,
    DATA_MANAGESECURITY,
    DATA_MODIFY,
    SITE_MANAGESECURITY,
} from '@app/utilities/permissions';
import { Button } from '@arius';
import * as style from '@app/utilities/globalStyles';
import { notAuthorized } from '@app/shared/containers/conditionalLink';
import { createPermissionChecker } from '@app/utilities/permissions';
import $ from 'jquery';

class TodDatabaseActions extends Component {
    static propTypes = {
        database: PropTypes.object,
        isSelected: PropTypes.bool,
        onDatabaseClick: PropTypes.func,
        deleteClickHandler: PropTypes.func,
        openBackupModalHandler: PropTypes.func,
        viewInfoHandler: PropTypes.func,
        index: PropTypes.number,
        description: PropTypes.string,
        columns: PropTypes.array,
        isFetching: PropTypes.bool,
        columnTypes: PropTypes.array,
    };
    constructor(props) {
        super(props);
        this.state = {
            showActions: false,
        };
        this.toggleActions = this.toggleActions.bind(this);
        this.getActionLink = this.getActionLink.bind(this);
        this.viewInfo = this.viewInfo.bind(this);
        this.openBackupModal = this.openBackupModal.bind(this);
        this.delete = this.delete.bind(this);
        this.closeActions = this.closeActions.bind(this);
        this.getPermissionChecker = this.getPermissionChecker.bind(this);
    }

    toggleActions(e) {
        e.stopPropagation();
        this.setState({ showActions: !this.state.showActions });
    }

    getActionLink(path, isAllowed, toolTip, iconName){
        const { databaseId, browserHistory } = this.props;
        return (
            <Button
                id={`${toolTip}-${databaseId}`}
                onClick={() => {
                    this.closeActions();
                    if (isAllowed) {
                        browserHistory.push(path);
                    } else {
                        notAuthorized();
                    }
                }}
                toolTip={toolTip}
                iconName={iconName}
            />
        )
    }

    closeActions(){
        const { database } = this.props;
        let databaseId = database ? database.workspaceId : 0;
        $(`#action-overlay-${databaseId}`).click();  // make action menu close.  TODO find better solution...
    }

    viewInfo(e) {
        const { database, viewInfoHandler } = this.props;
        this.closeActions();
        viewInfoHandler(e, database);
    }

    openBackupModal(e) {
        const { database, openBackupModalHandler,} = this.props;
        let databaseId = database.workspaceId;
        let verifyPermission = this.getPermissionChecker();
        if (verifyPermission(DATA_BACKUP)){
            openBackupModalHandler(databaseId, e);
        } else {
            notAuthorized();
        }
        this.closeActions();
    }

    delete(e) {
        const {database, deleteHandler } = this.props;
        let verifyPermission = this.getPermissionChecker();

        let databaseId = database.workspaceId;

        if (verifyPermission(DATA_DELETETODDATABASE)){
            deleteHandler(databaseId, e);
        } else {
            notAuthorized();
        }
        e.stopPropagation();  // don't let the row expand fire...
        $(`#action-overlay-${databaseId}`).click();  // make action menu close.  TODO find better solution...
    }

    getPermissionChecker() {
        const { database, userPermissions } = this.props;
        return createPermissionChecker(
            database ? [...userPermissions, ...database.permissions] : userPermissions);
    }

    render() {
        const { database,} = this.props;
        let verifyPermission = this.getPermissionChecker();
        let databaseId = database.workspaceId;
        const popover = (
            <Popover id="todListItemActions">
                {this.getActionLink(
                    `/trianglesOnDemand/databases/${databaseId}/security`, 
                    verifyPermission(SITE_MANAGESECURITY) || verifyPermission(DATA_MANAGESECURITY),
                    "Security", 
                    "lock"
                )}
                {this.getActionLink(
                    `/trianglesOnDemand/databases/${databaseId}/edit`, 
                    verifyPermission(DATA_MODIFY),
                    "Edit", 
                    "edit"
                )}
                {this.getActionLink(
                    `/trianglesOnDemand/databases/${databaseId}/mappings`, 
                    verifyPermission(DATA_MODIFY),
                    "Map Data", 
                    "fa-exchange",
                )}
                {this.getActionLink(
                    `/trianglesOnDemand/databases/${databaseId}/loader`, 
                    verifyPermission(DATA_MODIFY),
                    "Load Data", 
                    "file_upload",
                )}
                <Button toolTip="View Info" iconName="pageview" onClick={this.viewInfo}/>
                <Button toolTip="Database Backup" iconName="backup" onClick={this.openBackupModal}/>
                <Button toolTip="Delete" iconName="delete" onClick={this.delete}/>
            </Popover>
        );

        return (
        <OverlayTrigger  trigger="click" placement="auto" overlay={popover} rootClose={true} >
            <i
                id={`action-overlay-${databaseId}`}
                key={`action-items-${databaseId}`}
                onClick={(e) => e.stopPropagation()}
                style={[style.menuIcon, style.menuIconHover]}
                className="material-icons"
            >
                more_horiz
            </i>
        </OverlayTrigger>
        )
    }
}

export default radium(TodDatabaseActions);
