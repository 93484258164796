import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from '@app/utilities/routing';
import {
  fetchDatabases,
  selectDatabase,
  fetchDatabase,
} from '@app/actions/tod/todDatabases.actions';
import {
  fetchTotals,
  fetchTotalsTable,
  exportToCsv,
  exportToExcel,
  clearTotalsTable,
  clearDataSource,
} from '@app/actions/tod/totals.actions';
import TotalsTabContent from '@app/tod/totalsTabContent';

class TotalsTab extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    currentDb: PropTypes.object,
    getDatabase: PropTypes.func,
    getTotals: PropTypes.func,
    isFetchingDatabases: PropTypes.bool,
    isFetching: PropTypes.bool,
    application: PropTypes.object.isRequired,
    getDatabases: PropTypes.func,
    allDatabases: PropTypes.array,
    changeDatabase: PropTypes.func,
    dataSource: PropTypes.object,
    getTotalsTable: PropTypes.func,
    clearTotalsTable: PropTypes.func,
    exportCsv: PropTypes.func,
    exportExcel: PropTypes.func,
    totalsTable: PropTypes.object,
    isFetchingTable: PropTypes.bool,
    clearDataSourceHandler: PropTypes.func,
    clearTableHandler: PropTypes.func,
    csvFile: PropTypes.object,
    excelFile: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      databases: [],
    };
  }
  componentDidMount() {
    const { userKey, getDatabases } = this.props;
    getDatabases(userKey);
    this.filterDatabases(this.props.allDatabases.slice());
  }

  componentDidUpdate(prevProps) {
    const {
      currentDb,
      userKey,
      match,
      getDatabase,
      getTotals,
      isFetchingDatabases,
      isFetching,
      allDatabases,
    } = this.props;

    let params = match.params;

    if (currentDb && prevProps.currentDb !== currentDb && !isFetching) {
      getTotals(userKey, currentDb.id);
    }

    if (isFetchingDatabases) {
      return;
    }

    if (params.databaseId) {
      const databaseId = parseInt(params.databaseId, 10);
      if (databaseId) {
        if (!currentDb || currentDb.id !== databaseId) {
          getDatabase(userKey, databaseId);
        }
      }
    }
    if (prevProps.allDatabases !== allDatabases) {
      this.filterDatabases(allDatabases.slice());
    }
  }

  filterDatabases(allDatabases) {
    // TODO: Fix TOD Permissions
    let databases = allDatabases.filter(db => db.hasDataLoaded);
    databases = databases.sort((a, b) =>
      a.workspaceName.toLowerCase().localeCompare(b.workspaceName.toLowerCase())
    );
    this.setState({ databases });
  }

  render() {
    const {
      userKey,
      dispatch,
      changeDatabase,
      currentDb,
      isFetching,
      dataSource,
      getTotalsTable,
      exportCsv,
      exportExcel,
      totalsTable,
      isFetchingTable,
      clearDataSourceHandler,
      clearTableHandler,
      csvFile,
      excelFile,
    } = this.props;
    const { databases } = this.state;

    let childProps = {
        userKey,
        dispatch,
        databases,
        changeDatabase,
        currentDb,
        isFetching,
        dataSource,
        getTotalsTable,
        exportCsv,
        exportExcel,
        totalsTable,
        isFetchingTable,
        clearDataSourceHandler,
        clearTableHandler,
        csvFile,
        excelFile,
    };

    return (
        <Switch >
          <Route path="/trianglesOnDemand/totals" render={props => (<TotalsTabContent {...childProps}/>)}/>
      </Switch>   
    )
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  application: state.application,
  allDatabases: state.tod.databases.items,
  currentDb: state.tod.databases.current,
  isFetchingDatabases: state.tod.databases.isFetching,
  isFetching: state.tod.totals.isFetching,
  isFetchingTable: state.tod.totals.isFetchingTable,
  dataSource: state.tod.totals.dataSource,
  totalsTable: state.tod.totals.table,
  csvFile: state.tod.totals.csvFile,
  excelFile: state.tod.totals.excelFile,
});

const mapDispatchToProps = dispatch => ({
  getDatabases: userKey => {
    dispatch(fetchDatabases(userKey));
  },
  getDatabase: (userKey, databaseId) => {
    dispatch(fetchDatabase(userKey, databaseId));
  },
  changeDatabase: db => {
    dispatch(selectDatabase(db));
  },
  getTotals: (userKey, databaseId) => {
    dispatch(fetchTotals(userKey, databaseId));
  },
  getTotalsTable: ({
    userKey,
    databaseId,
    valuationDate,
    columns,
    filters,
    measures,
    rows,
  }) => {
    dispatch(
      fetchTotalsTable({
        userKey,
        databaseId,
        valuationDate,
        columns,
        filters,
        measures,
        rows,
      })
    );
  },
  exportCsv: ({
    userKey,
    databaseId,
    valuationDate,
    columns,
    filters,
    measures,
    rows,
  }) => {
    dispatch(
      exportToCsv({
        userKey,
        databaseId,
        valuationDate,
        columns,
        filters,
        measures,
        rows,
      })
    );
  },
  exportExcel: ({
    userKey,
    databaseId,
    valuationDate,
    columns,
    filters,
    measures,
    rows,
  }) => {
    dispatch(
      exportToExcel({
        userKey,
        databaseId,
        valuationDate,
        columns,
        filters,
        measures,
        rows,
      })
    );
  },
  clearDataSourceHandler: () => {
    dispatch(clearDataSource());
  },
  clearTableHandler: () => {
    dispatch(clearTotalsTable());
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalsTab);
