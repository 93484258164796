import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { createExtractDefinition, editExtractDefinition } from '../../../actions/analysis/reporting.actions';
import * as GlobalStyles from '../../../utilities/globalStyles';
import * as Colors from '../../../utilities/colors';
import { notifyError } from '../../../utilities/notifier';
import {
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
} from 'react-bootstrap';
import { retrieveExportsByListType } from '../../../actions/analysis/project.actions';
import MasterViewComponent from './masterViewComponent';
import ValuationDatesView from './valuationDatesView';
import ProjectStatusView from './projectStatusView';
import LastUpdatedByView from './lastUpdatedByView';
import { validateName } from '../../../utilities/validators';
const style = {
  mainContainer: {
    width: '85vw',
    height: 'calc(100vh - 106px)',
    maxHeight: 'calc(100vh - 106px)',
    overflowY: 'auto',
  },
  backIcon: {
    color: Colors.darkGrey,
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '4.5vmin',
    ':hover': {
      backgroundColor: Colors.purple,
      color: 'white',
      transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
    },
    marginRight: '20px',
  },
  button: {
    borderRadius: '4px',
    backgroundColor: Colors.blue,
    color: 'white',
    // margin: '10px',
    fontSize: '40px',
    cursor: 'pointer',
    textAlign: 'center',
    // boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    ':hover': {
      // boxShadow: '0px 14px 28px rgba(0,0,0,0.25), 0px 10px 10px rgba(0,0,0,0.22)',
      backgroundColor: Colors.purple,
    },
  },
  masterCustomStyle: {
    display: 'flex',
    minHeight: '20vh',
    width: '100%',
    margin: '0px 0px 20px 0px',
    border: '1px solid #ccc',
    backgroundColor: 'white',
  },
  selectItem: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
  },
  radioDouble: { 
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    marginTop: '6px',
  },
  radioRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    paddingRight: '3px',
    justifyContent: 'space-between',
  },
  radioColumn: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    paddingRight: '3px',
    justifyContent: 'space-between',
    minHeight: '203px',
  },
  selectRow: {
    display: 'flex',
    flexFlow: 'nowrap',
    justifyContent: 'space-between',
  },
  saveCancelButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

class CreateExtract extends Component {
  static propTypes = {
    selectSideNavOption: PropTypes.func,
    dispatch: PropTypes.func,
    userKey: PropTypes.string,
    databaseId: PropTypes.number,
    extractTableDefinition: PropTypes.object,
    params: PropTypes.object,
    extractDefinitions: PropTypes.array,
    clearExtractTableDefinition: PropTypes.func,
    exportList: PropTypes.array,
    isFetchingExportList: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      tableName: '',
      tableDescription: '',
      editingMode: false,
      nameError: '',
      softErrorMode: true,
      selectedExport: 'select',
      valuationDatesState: {
        databaseId: -1,
        selectedValuationDate: '',
        latestValue: 4,
        selectedRadioOption: 'all',
        label: 'All',
        field: 'ValuationDate',
      },
      projectStatusState: {
        checked: ['All'],
        label: 'All',
        field: 'ProjectStatus',
      },
      lastUpdatedByState: {
        selectedUserNameId: -1,
        selectedRadioOption: 'all',
        label: 'All',
        field: 'LastUpdatedBy',
      },
      lastDiagonalOnly: true,
      includeCumulative: true,
      childStateNames: ['valuationDatesState', 'projectStatusState', 'lastUpdatedByState'],
    };
    this.handleTableNameChange = this.handleTableNameChange.bind(this);
    this.handleTableDescriptionChange = this.handleTableDescriptionChange.bind(this);
    this.save = this.save.bind(this);
    this.validateName = this.validateName.bind(this);
    this.selectExportOption = this.selectExportOption.bind(this);
    this.updateValuationDatesState = this.updateValuationDatesState.bind(this);
    this.setValuationDatesState = this.setValuationDatesState.bind(this);
    this.updateProjectStatusState = this.updateProjectStatusState.bind(this);
    this.setProjectStatusState = this.setProjectStatusState.bind(this);
    this.updateLastUpdatedByState = this.updateLastUpdatedByState.bind(this);
    this.setLastUpdatedByState = this.setLastUpdatedByState.bind(this);
    this.selectBuildWith = this.selectBuildWith.bind(this);
    this.setDataMode = this.setDataMode.bind(this);
  }
  componentWillMount() {
    const { extractTableDefinition, params, databaseId, userKey, dispatch } = this.props,
      definitionId = !params.definitionId || params.definitionId === 'create' ? null : Number(params.definitionId);
    if (extractTableDefinition || definitionId) {
      let valuationDatesState, projectStatusState, lastUpdatedByState;
      extractTableDefinition.filters.forEach((f) => {
        if (f.field === 'ValuationDate') {
          valuationDatesState = this.setValuationDatesState(f);
          valuationDatesState.databaseId = databaseId;
        } else if (f.field === 'ProjectStatus') {
          projectStatusState = this.setProjectStatusState(f);
        } else if (f.field === 'LastUpdatedBy') {
          lastUpdatedByState = this.setLastUpdatedByState(f);
        }
      });
      const tableName = (extractTableDefinition && extractTableDefinition.name) || '',
        tableDescription = (extractTableDefinition && extractTableDefinition.description) || '',
        selectedExport = (extractTableDefinition && extractTableDefinition.exportListId) || 'select',
        { includeCumulative, includeIncremental, lastDiagonalOnly } = extractTableDefinition;
      this.setState({
        editingMode: true,
        tableName,
        tableDescription,
        selectedExport,
        valuationDatesState,
        projectStatusState,
        lastUpdatedByState,
        includeCumulative,
        includeIncremental,
        lastDiagonalOnly,
      });
    }
    if (databaseId) {
      dispatch(
        retrieveExportsByListType({ 
          userKey, 
          databaseId,
          listType: 'ExtractTable',
        })
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const { extractTableDefinition, userKey, dispatch, databaseId } = this.props,
      { extractTableDefinition: nextExtractTableDefinition, databaseId: nextDatabaseId } = nextProps,
      { editingMode } = this.state;
    if (nextExtractTableDefinition && extractTableDefinition !== nextExtractTableDefinition && editingMode) {
      this.setState({
        tableName: nextExtractTableDefinition.name,
        tableDescription: nextExtractTableDefinition.description,
        selectedExport: nextExtractTableDefinition.exportListId || 'select',
      });
    }
    
    if (nextDatabaseId && databaseId !== nextDatabaseId) {
      dispatch(
        retrieveExportsByListType({ 
          userKey, 
          databaseId: nextDatabaseId,
          listType: 'ExtractTable',
        })
      );
    }
  }

  componentWillUnmount() {
    const { clearExtractTableDefinition } = this.props;
    clearExtractTableDefinition();
  }

  setValuationDatesState(f) {
    let selectedRadioOption, latestValue, selectedValuationDate;
    if (f.values.length > 0) {
      if (f.values[0] === 'selectedValuationDate') {
        latestValue = 4;
        selectedValuationDate = f.values[1];
        selectedRadioOption = 'selectedValuationDate';
      }
      else if (f.values[1] > 1) {
        latestValue = f.values[1];
        selectedValuationDate = '';
        selectedRadioOption = 'lastFewByAmount';
      } else {
        latestValue = 4;
        selectedValuationDate = '';
        selectedRadioOption = 'latest';
      }
    } else {
      latestValue = 4;
      selectedValuationDate = '';
      selectedRadioOption = 'all';
    }
    let label;
    switch (selectedRadioOption) {
      case 'all': 
        label = 'All';
        break;
      case 'latest': 
        label = 'Most Recent';
        break;
      case 'lastFewByAmount': 
        label = `Last ${latestValue}`;
        break;
      case 'selectedValuationDate':
        label = selectedValuationDate;
        break;
      default: label = 'All';
    }
    return {
      latestValue,
      selectedValuationDate,
      selectedRadioOption,
      label,
      field: 'ValuationDate',
    };
  }

  setProjectStatusState(f) {
    let checked = [];
    if (!f.values.length) {
      checked.push('All');
    } else {
      checked = f.values;
    }
    const labelArray = [];
    if (checked.indexOf('All') !== -1) {
      labelArray.push('All');
    } else {
      if (checked.indexOf('Not Reviewed') !== -1) {
        labelArray.push('NR');
      }
      if (checked.indexOf('Not Started') !== -1) {
        labelArray.push('NS');
      }
      if (checked.indexOf('In Progress') !== -1) {
        labelArray.push('IP');
      }
      if (checked.indexOf('Complete') !== -1) {
        labelArray.push('C');
      }
      if (checked.indexOf('Level 1 Review') !== -1) {
         labelArray.push('L1');
      }
      if (checked.indexOf('Level 2 Review') !== -1) {
         labelArray.push('L2');
      }
      if (checked.indexOf('Level 3 Review') !== -1) {
         labelArray.push('L3');
      }
      if (checked.indexOf('Level 4 Review') !== -1) {
         labelArray.push('L4');
      }
      if (checked.indexOf('Level 5 Review') !== -1) {
         labelArray.push('L5');
      }
    }
    const label = labelArray.join(', ');
    return {
      checked,
      label,
      field: 'ProjectStatus',
    };
  }

  setLastUpdatedByState(f) {
    let selectedRadioOption, selectedUserNameId;
    if (f.values.length === 0) {
      selectedRadioOption = 'all';
      selectedUserNameId = -1;
    } else {
      selectedUserNameId = f.values[1];
      selectedRadioOption = 'lastUpdatedBy';
    }
    let label;
    switch (selectedRadioOption) {
      case 'all': 
        label = 'All';
        break;
      case 'lastUpdatedBy': 
        label = '';
        break;
      default: label = 'All';
    }
    return {
      selectedUserNameId,
      selectedRadioOption,
      label,
      field: 'LastUpdatedBy',
    };
  }

  setDataMode(bool) {
    this.setState({ includeCumulative: bool });
  }

  getValidationState() {
    const { tableName, editingMode } = this.state, 
      { extractDefinitions, extractTableDefinition } = this.props,
      comparingName = tableName ? tableName.toLowerCase().trim() : '',
      found = extractDefinitions.find(ed => ed.name.toLowerCase() === comparingName);
      let error = validateName(comparingName, this.state.softErrorMode);
      if (error) return error;
      error = found ? 'Name must be unique' : '';
      if (!editingMode) return error;
      if (extractTableDefinition && comparingName === extractTableDefinition.name.toLowerCase().trim()) error = '';
      return error;
  }

  validateName(name) {
    const { extractDefinitions, extractTableDefinition } = this.props,
      { editingMode } = this.state;
    let nameIsDirty = false;
    if (extractDefinitions.find(ed => ed.name.toLowerCase().trim() === name.toLowerCase().trim())) nameIsDirty = true;
    if (editingMode && name.toLowerCase().trim() === extractTableDefinition.name.toLowerCase().trim()) nameIsDirty = false;
    return nameIsDirty;
  }

  handleTableNameChange(e) {
    this.setState({ tableName: e.target.value });
  }

  handleTableDescriptionChange(e) {
    this.setState({ tableDescription: e.target.value });
  }

  selectExportOption(e) {
    const val = e.target.value,
      selectedExport = val === 'select' ? val : Number(val);
    this.setState({ selectedExport });
  }

  updateValuationDatesState(childState) {
    const { latestValue, selectedRadioOption, selectedValuationDate } = childState,
      valuationDatesState = {
        latestValue,
        selectedValuationDate,
        selectedRadioOption,
        field: 'ValuationDate',
      };
    let label;
    switch (selectedRadioOption) {
      case 'all': 
        label = 'All';
        break;
      case 'latest': 
        label = 'Most Recent';
        break;
      case 'lastFewByAmount': 
        label = `Last ${latestValue}`;
        break;
      case 'selectedValuationDate':
        label = selectedValuationDate;
        break;
      default: label = 'All';
    }
    valuationDatesState.label = label;
    this.setState({ valuationDatesState });
  }

  updateProjectStatusState(childState) {
    const { checked } = childState,
      projectStatusState = {
        checked,
        field: 'ProjectStatus',
      },
      labelArray = [];
    if (checked.indexOf('All') !== -1) {
      labelArray.push('All');
    } else {
      if (checked.indexOf('Ad Hoc') !== -1) {
        labelArray.push('AH');
      }
      if (checked.indexOf('Not Reviewed') !== -1) {
        labelArray.push('NR');
      }
      if (checked.indexOf('Not Started') !== -1) {
        labelArray.push('NS');
      }
      if (checked.indexOf('In Progress') !== -1) {
        labelArray.push('IP');
      }
      if (checked.indexOf('Complete') !== -1) {
        labelArray.push('C');
      }
      if (checked.indexOf('Level 1 Review') !== -1) {
        labelArray.push('L1');
      }
      if (checked.indexOf('Level 2 Review') !== -1) {
        labelArray.push('L2');
      }
      if (checked.indexOf('Level 3 Review') !== -1) {
        labelArray.push('L3');
      }
      if (checked.indexOf('Level 4 Review') !== -1) {
        labelArray.push('L4');
      }
      if (checked.indexOf('Level 5 Review') !== -1) {
        labelArray.push('L5');
      }
    }
    projectStatusState.label = labelArray.join(', ');
    this.setState({ projectStatusState });
  }

  updateLastUpdatedByState(childState) {
    const { selectedRadioOption, selectedUserNameId } = childState,
      lastUpdatedByState = {
        selectedUserNameId,
        selectedRadioOption,
        field: 'LastUpdatedBy',
      };
    let label;
    switch (selectedRadioOption) {
      case 'all': 
        label = 'All';
        lastUpdatedByState.selectedUserNameId = -1;
        break;
      case 'lastUpdatedBy': 
        label = '';
        break;
      default: label = 'All';
    }
    lastUpdatedByState.label = label;
    this.setState({ lastUpdatedByState });
  }

  save() {
    const { 
      tableName,
      tableDescription,
      editingMode,
      selectedExport: exportListId,
      childStateNames,
      lastDiagonalOnly,
      includeCumulative,
    } = this.state,
      { 
        userKey,
        dispatch,
        databaseId,
        extractTableDefinition,
        selectSideNavOption,
      } = this.props,
      req = {
        name: tableName.trim(),
        description: tableDescription,
        filters: [],
        includeCumulative, 
        includeIncremental: !includeCumulative, 
        lastDiagonalOnly, 
      };
    if (!req.name) {
      notifyError('Name cannot be blank');
      return;
    }
    if (exportListId !== 'select') req.exportListId = exportListId;
    
    childStateNames.forEach((cs) => {
      const filter = {
        field: this.state[cs].field,
        values: [],
      };
      if (cs === 'valuationDatesState') {

        if (this.state[cs].selectedRadioOption === 'selectedValuationDate') {
          filter.values.push('selectedValuationDate');
          filter.values.push(this.state[cs].selectedValuationDate); 
        }
        else if (this.state[cs].selectedRadioOption !== 'all') {
          filter.values.push('latest');
          if (this.state[cs].selectedRadioOption === 'lastFewByAmount') {
            filter.values.push(this.state[cs].latestValue); 
          } else {
            filter.values.push(1);
          }
        }

      } else if (cs === 'projectStatusState') {
        const isAny = this.state[cs].checked.indexOf('All') !== -1 || !this.state[cs].checked.length;
        filter.values = isAny ? [] : this.state[cs].checked;
      } else {
        if (this.state[cs].selectedRadioOption !== 'all') {
          filter.values.push('lastUpdatedBy');
          filter.values.push(this.state[cs].selectedUserNameId);
        }
      }
      req.filters.push(filter);
    });
    if (this.validateName(req.name)) {
      const error = 'Name must be unique';
      notifyError(error);
      this.setState({ nameError: error });
      return;
    } 
    if(validateName(req.name.toLowerCase().trim(), this.state.softErrorMode)){
      const error=validateName(req.name.toLowerCase().trim(), this.state.softErrorMode);
      notifyError(error);
      this.setState({ nameError:error})
      return;
    }
    if (!editingMode) {
      dispatch(createExtractDefinition({ userKey, databaseId, req }));
    } else {
      dispatch(editExtractDefinition({ userKey, databaseId, definitionId: extractTableDefinition.id, req }));
    }
    selectSideNavOption('extract_list');
  }

  selectBuildWith(bool) {
    this.setState({ lastDiagonalOnly: bool });
  }

  render() {
    const { tableName,
      tableDescription,
      editingMode,
      selectedExport,
      selectedView,
      valuationDatesState,
      lastDiagonalOnly,
      includeCumulative,
      projectStatusState,
      lastUpdatedByState } = this.state,
      nameError = this.getValidationState(),
      { selectSideNavOption, exportList, databaseId } = this.props,
      exportListOptions = exportList && exportList.map((el, idx) => 
        <option key={`export-list-option-${idx}`} value={el.exportListId}>{el.listName}</option>
      ),
      editTitle = editingMode ? <span>Editing </span> : '',
      views = [
        {
          name: 'Valuation_Dates',
          displayName: 'Valuation Dates',
          component: (
            <ValuationDatesView
              updateParent={this.updateValuationDatesState}
              state={valuationDatesState} />
          ),
          viewState: valuationDatesState,
        },
        {
          name: 'Project_Status',
          displayName: 'Project Status',
          component: (
            <ProjectStatusView
              updateParent={this.updateProjectStatusState}
              state={projectStatusState} />
          ),
          viewState: projectStatusState,
        },
        {
          name: 'Last_Updated_By',
          displayName: 'Last Updated By',
          component: (
            <LastUpdatedByView
              updateParent={this.updateLastUpdatedByState}
              state={lastUpdatedByState} />
          ),
          viewState: lastUpdatedByState,
        },
      ],
      mainContent = (
        <div>
          <div
            style={{
              borderBottom: '2px solid #BDBDBD',
              margin: 'initial',
              height: '75px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ margin: 'initial', paddingLeft: 15 }}>
              <h3>{editTitle}Extract Table Definition</h3>
            </div>
            <div>
              <OverlayTrigger
                placement="left"
                focus
                overlay={
                  <Tooltip id="addNewScriptTooltip">Extract Table List</Tooltip>
                }
              >
                <i
                  style={style.backIcon}
                  className="material-icons"
                  onClick={() => selectSideNavOption('extract_list')}
                >
                  arrow_back
                </i>
              </OverlayTrigger>
            </div>
          </div>
          <div style={[
            GlobalStyles.card,
            { marginTop: '0px',
              marginRight: '0px',
              marginBottom: '0px',
              marginLeft: '0px',
              padding: '20px',
            },
          ]}>
            <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flex: '1 1', marginRight: '10px' }}>
                <Form.Group
                  style={{ width: '100%', paddingRight: '3px' }}
                  controlId="tableFormName">
                  <Form.Label>
                    Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={nameError}
                    style={{ width: '100%' }}
                    value={tableName}
                    placeholder="enter name"
                    onChange={e => this.handleTableNameChange(e)}
                  />
                  <Form.Control.Feedback type='invalid'>{nameError}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div style={{ display: 'flex', flex: '1 1', marginLeft: '10px' }}>
                <Form.Group 
                  controlId="tableFormDesc"
                  style={{ width: '100%', paddingLeft: '3px' }}>
                  <Form.Label>
                    Description
                  </Form.Label>
                  <Form.Control
                    style={{ width: '100%', resize: 'vertical' }}
                    as="textarea"
                    rows="1"
                    placeholder="enter description"
                    value={tableDescription}
                    onChange={e => this.handleTableDescriptionChange(e)}
                  />
                </Form.Group>
              </div>
            </div>
            <div style={style.selectRow}>
              <Form.Group controlId="formControlsSelect" style={{ ...style.selectItem, ...{ marginRight: '10px' } }}>
                <Form.Label>Extract Table List</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="select"
                  onChange={this.selectExportOption}
                  value={selectedExport}>
                  <option value="select">Default List: All Tables</option>
                  {exportListOptions}
                </Form.Control>
              </Form.Group>
              <Form.Group
                controlId="formControlsSelect"
                style={{ ...style.selectItem, ...{ marginLeft: '10px', visibility: 'hidden', pointerEvents: 'none' } }}>
                <Form.Label>Extract Table List</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="select"
                  onChange={this.selectExportOption}
                  value={selectedExport}>
                  <option value="select">Default List: All Tables</option>
                  {exportListOptions}
                </Form.Control>
              </Form.Group>
            </div>
            <Form.Label style={{ marginTop: '20px', fontWeight: 'bold' }}>
              Export Filtering
            </Form.Label>
            <MasterViewComponent
              selectedView={selectedView}
              views={views}
              customStyle={style.masterCustomStyle} />
            <div style={style.radioColumn}>
              <Form.Label style={{fontWeight: 'bold'}}>Options</Form.Label>
              <Form.Group controlId="formControlsSelect" style={{ ...style.selectItem, ...{ marginLeft: '10px' } }}>
                <div>Triangle Data</div>
                <div style={style.radioDouble}> 
                  <Form.Check type="radio"
                    id="build-rad-one"
                    checked={lastDiagonalOnly}
                    onChange={() => this.selectBuildWith(true)}
                    inline
                    label="Last Diagonal Only" />
                  <span style={{ height: '8px' }}></span>
                  <Form.Check type="radio"
                    id="build-rad-two"
                    checked={!lastDiagonalOnly}
                    onChange={() => this.selectBuildWith(false)}
                    inline
                    label="Full triangle" />
                </div> 
              </Form.Group>
              <Form.Group controlId="formControlsSelect" style={{ ...style.selectItem, ...{ marginLeft: '10px' } }}>
                <div>Mode</div>
                <div style={style.radioDouble}>
                  <Form.Check type="radio"
                    id="set-rad-one"
                    checked={includeCumulative}
                    onChange={() => this.setDataMode(true)}
                    inline
                    label="Cumulative" />
                  <span style={{ height: '8px' }}></span>
                  <Form.Check type="radio"
                    id="set-rad-two"
                    checked={!includeCumulative}
                    onChange={() => this.setDataMode(false)}
                    inline
                    label="Incremental" />
                </div>
              </Form.Group>
            </div>
            <div style={style.saveCancelButtons}>
              <div>
                <Button variant="primary"
                  key="save-script-button"
                  onClick={() => this.save()}
                  aria-hidden="true"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      );

      if (!editingMode) {
        valuationDatesState.databaseId = databaseId;
      }
    return (
      <div style={style.mainContainer}>{mainContent}</div>
    );
  }
}

export default radium(CreateExtract);
