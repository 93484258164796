import {
  FETCHING_QUERIES,
  RECEIVED_QUERIES,
  RECEIVED_QUERY,
  SAVING_TOD_QUERY,
  FETCHING_DATAGROUPS,
  FETCHING_DATAGROUP,
  RECEIVED_DATAGROUPS,
  RECEIVED_DATAGROUP,
  RECEIVED_ROLLUPS,
  FETCHING_FACTCOLUMNS,
  FETCHING_OPERATORS,
  RECEIVED_FACTCOLUMNS,
  RECEIVED_OPERATORS,
  FETCHING_QUERY_VALIDATION,
  RECEIVED_QUERY_VALIDATION,
  FETCHING_VALUES,
  RECEIVED_VALUES,
  FETCHING_QUERY_SEGMENTS,
  RECEIVED_QUERY_SEGMENTS,
} from './tod.actionTypes';
import * as queriesServiceApi from '@app/api/advancedQueries.serviceApi';
import { notifySuccess, notifyError } from '@app/utilities/notifier';

function fetchingFromServer() {
  return {
    type: FETCHING_QUERIES,
  };
}

function savingToServer() {
  return {
    type: SAVING_TOD_QUERY,
  };
}

function receivedQueries(json) {
  return {
    type: RECEIVED_QUERIES,
    items: json,
  };
}

function receivedQuery(json) {
  return {
    type: RECEIVED_QUERY,
    current: json,
  };
}

function fetchFactColumns(userKey, databaseId) {
  return dispatch => {
    dispatch({type: FETCHING_FACTCOLUMNS});
    return queriesServiceApi
      .getFactColumns(userKey, databaseId)
      .then(json => dispatch(
        {
          type: RECEIVED_FACTCOLUMNS,
          data: json,
        }
      ));
  };
}

function fetchValues(userKey, databaseId, factColumnName, search, skip, take, desc) {
    return dispatch => {
      dispatch({type: FETCHING_VALUES});
      return queriesServiceApi
        .getValues(userKey, databaseId, factColumnName, search, skip, take, desc)
        .then(pagingResult => dispatch(
            { type: RECEIVED_VALUES, data: pagingResult}
        ));
    };
}

function fetchFactValues(userKey, databaseId, factColumnName, search, skip, take, desc) {
    return dispatch => {
      dispatch({type: FETCHING_VALUES});
      return queriesServiceApi
        .getFactValues(userKey, databaseId, factColumnName, search, skip, take, desc)
        .then(pagingResult => dispatch(
            { type: RECEIVED_VALUES, data: pagingResult}
        ));
    };
}

function fetchOperators(userKey, databaseId) {
  return dispatch => {
    dispatch({type: FETCHING_OPERATORS});
    return queriesServiceApi
      .getOperators(userKey, databaseId)
      .then(json => dispatch(
        {
          type: RECEIVED_OPERATORS,
          data: json,
        }
      ));
  };
}

function fetchDataGroups(userKey, databaseId) {
  return dispatch => {
    dispatch({type: FETCHING_DATAGROUPS});
    return queriesServiceApi
      .getDataGroups(userKey, databaseId)
      .then(json => dispatch(
        {
          type: RECEIVED_DATAGROUPS,
          data: json,
        }
      ));
  };
}

function fetchRollups(userKey, databaseId) {
    return dispatch => {
      return queriesServiceApi
        .getRollups(userKey, databaseId)
        .then(json => dispatch(
          {
            type: RECEIVED_ROLLUPS,
            data: json,
          }
        ));
    };
  }

function fetchDataGroup(userKey, databaseId, dataGroupId) {
  return dispatch => {
    dispatch({type: FETCHING_DATAGROUP});
    return queriesServiceApi
      .getDataGroup(userKey, databaseId, dataGroupId)
      .then(json => dispatch(
        {
          type: RECEIVED_DATAGROUP,
          data: json,
        }
      ));
  };
}

function fetchQueriesForDataGroup(userKey, databaseId, dataGroupId, successCallback = (queryId)=>{}) {
    return dispatch => {
      return queriesServiceApi
        .getQueriesForDataGroup(userKey, databaseId, dataGroupId)
        .then(data => successCallback(data));
    };
  }

function createDataGroup(userKey, databaseId, dataGroup) {
  return dispatch => {
    dispatch({type: FETCHING_DATAGROUP});
    dispatch({type: FETCHING_DATAGROUPS});
    return queriesServiceApi
      .createDataGroup(userKey, databaseId, dataGroup)
      .then(() => dispatch(fetchDataGroups(userKey, databaseId)));
  };
}

function updateDataGroup(userKey, databaseId, dataGroup) {
  return dispatch => {
    dispatch({type: FETCHING_DATAGROUP});
    dispatch({type: FETCHING_DATAGROUPS});
    return queriesServiceApi
      .updateDataGroup(userKey, databaseId, dataGroup)
      .then(() => dispatch(fetchDataGroups(userKey, databaseId)))
  };
}

function deleteDataGroup(userKey, databaseId, dataGroupId) {
  return dispatch => {
    dispatch({type: FETCHING_DATAGROUP});
    return queriesServiceApi
      .deleteDataGroup(userKey, databaseId, dataGroupId)
      .then(() => dispatch(fetchDataGroups(userKey, databaseId)));
  };
}

function copyDataGroup(userKey, databaseId, dataGroupId) {
    function _validateAndSave(dispatch, n, origName, o){
        o.name = `${origName} Copy${n}`;
        queriesServiceApi
            .validateDataGroup(userKey, databaseId, o)
            .then((v) => {
                if (v && v.isValid){
                    dispatch(createDataGroup(userKey, databaseId, o));
                }
                else {
                    _validateAndSave(dispatch, n+1, origName, o);
                }
            });
    }

    return dispatch => {
      dispatch(fetchingFromServer());
      return queriesServiceApi
        .getDataGroup(userKey, databaseId, dataGroupId)
        .then(data => {
                let q = JSON.parse(JSON.stringify(data));
                if (q.id > 0){
                    q.id = -1;
                    _validateAndSave(dispatch, 1, q.name, q);
                } else {
                    notifyError('Issue copying data group');
                }
            }
        );
    };
}

function fetchQueries(userKey, databaseId) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return queriesServiceApi
      .retrieveQueries(userKey, databaseId)
      .then(json => dispatch(receivedQueries(json)));
  };
}

function fetchQuery(userKey, databaseId, queryId) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return queriesServiceApi
      .retrieveQuery(userKey, databaseId, queryId)
      .then(json => {
            dispatch(validateSyntax(userKey, databaseId, json));
            dispatch(receivedQuery(json));
      }
    );
  };
}

function fetchQuerySQL(userKey, databaseId, query) {
    return dispatch => {
        dispatch(fetchingFromServer());
        return queriesServiceApi
        .retrieveQuerySQL(userKey, databaseId, query)
        .then(d => dispatch({type: RECEIVED_QUERY_VALIDATION, data: d}))
      };
}

function fetchingQuerySegments() {
  return {
    type: FETCHING_QUERY_SEGMENTS,
  };
}

function fetchQuerySegments(userKey, databaseId, queryId) {
  return dispatch => {
    dispatch(fetchingQuerySegments());
    return queriesServiceApi
      .retrieveQuerySegments(userKey, databaseId, queryId)
      .then(json => 
        dispatch({type: RECEIVED_QUERY_SEGMENTS, stringRecordSet: json})
      );
  };
}

function fetchWhereStatement(userKey, databaseId, query) {
  return dispatch => {
    return queriesServiceApi
      .retrieveQueryWhereStatement(userKey, databaseId, query)
      .then(data => 
          console.error(data)
      );
  };
}

function createQuery(userKey, databaseId, query, successCallback = (queryId)=>{}) {
  return dispatch => {
    dispatch(savingToServer());
    return queriesServiceApi
        .validateQuery(userKey, databaseId, query)
            .then((data) => {
                if (data && data.isValid){
                    queriesServiceApi
                        .createQuery(userKey, databaseId, query)
                        .then((data) => {
                            if (data && data.queryId > 0){
                                notifySuccess('Query Saved!');
                                successCallback(data.queryId);
                            }
                            else {
                                notifyError('Issue saving query');
                            }
                            dispatch(fetchQueries(userKey, databaseId));
                        });
                }
                else {
                    notifyError(data.validationMessage);
                    return;
                }
            });
  };
}

function updateQuery(userKey, databaseId, query, successCallback = ()=>{}) {
  return dispatch => {
    dispatch(savingToServer());
    return queriesServiceApi
    .validateQuery(userKey, databaseId, query)
    .then((data) => {
        if (data && data.isValid){
            queriesServiceApi
                .updateQuery(userKey, databaseId, query)
                .then((data) => {
                    if (data && data.queryId > 0){
                        notifySuccess('Query Saved!');
                        successCallback();
                    }
                    else {
                        notifyError('Issue saving query');
                    }
                    dispatch(fetchQueries(userKey, databaseId));
                });
        }
        else {
            notifyError(data.validationMessage);
            return;
        }
    });
  };
}

function copyQuery(userKey, databaseId, queryId, successCallback = ()=>{}) {
    function _validateAndSave(dispatch, n, origName, q){
        q.name = `${origName} Copy${n}`;
        queriesServiceApi
            .validateQuery(userKey, databaseId, q)
            .then((v) => {
                if (v && v.isValid){
                    dispatch(createQuery(userKey, databaseId, q, successCallback));
                }
                else {
                    _validateAndSave(dispatch, n+1, origName, q);
                }
            });
    }

    return dispatch => {
      dispatch(fetchingFromServer());
      return queriesServiceApi
        .retrieveQuery(userKey, databaseId, queryId)
        .then(data => {
                let q = JSON.parse(JSON.stringify(data));
                if (q.queryId > 0){
                    q.queryId = -1;
                    _validateAndSave(dispatch, 1, q.name, q);
                } else {
                    notifyError('Issue copying query');
                }
            }
        );
    };
}

function validateSyntax(userKey, databaseId, query) {
  return dispatch => {
    dispatch({type: FETCHING_QUERY_VALIDATION});
    return queriesServiceApi
      .validateSyntax(userKey, databaseId, query)
      .then(data => dispatch({type: RECEIVED_QUERY_VALIDATION, data}));
  };
}

function deleteQuery(userKey, databaseId, queryId) {
  return dispatch => {
    dispatch(savingToServer());
    return queriesServiceApi
      .deleteQuery(userKey, databaseId, queryId)
      .then(() => dispatch(fetchQueries(userKey, databaseId)));
  };
}

export {
    fetchFactColumns,
    fetchOperators,
    fetchDataGroups,
    fetchDataGroup,
    fetchRollups,
    fetchQueriesForDataGroup,
    fetchQueries,
    fetchQuery,
    fetchQuerySQL,
    fetchQuerySegments,
    fetchWhereStatement,
    createQuery,
    deleteQuery,
    updateQuery,
    copyQuery,
    validateSyntax,
    createDataGroup,
    updateDataGroup,
    deleteDataGroup,
    copyDataGroup,
    receivedQuery,
    fetchValues,
    fetchFactValues
};
