import {
  FETCHING_GROUPS_FROM_SERVER,
  FETCHING_GROUP_USERS_FROM_SERVER,
  RECEIVED_GROUP_USERS,
  RECEIVED_GROUPS,
  RECEIVED_GROUP,
  UPDATED_GROUP,
  SAVING_GROUP,
} from './portalManagement.actionTypes';
import * as groupServiceApi from '../../serviceApi/groups.serviceApi';
import { MEMBER_TYPE_ID } from '../../utilities/constants';

// TODO - WDD - remove all these actions?
function savingToServer() {
  return {
    type: SAVING_GROUP,
  };
}

function updatedGroup() {
  return {
    type: UPDATED_GROUP,
  };
}

function receivedGroups(json) {
  return {
    type: RECEIVED_GROUPS,
    groups: json,
    receivedAt: Date.now(),
  };
}

function receivedUsers(json) {
  return {
    type: RECEIVED_GROUP_USERS,
    users: json,
    receivedAt: Date.now(),
  };
}

function receivedGroup(json) {
  return {
    type: RECEIVED_GROUP,
    group: json,
    receivedAt: Date.now(),
  };
}

function fetchingGroupsFromServer() {
  return {
    type: FETCHING_GROUPS_FROM_SERVER,
  };
}

function fetchingUsersFromServer() {
  return {
    type: FETCHING_GROUP_USERS_FROM_SERVER,
  };
}

function fetchGroups(userKey) {
  return (dispatch) => {
    dispatch(fetchingGroupsFromServer());
    return groupServiceApi
            .retrieveGroups(userKey)
            .then(json => dispatch(receivedGroups(json)));
  };
}

function fetchGroup(userKey, groupId) {
  return (dispatch) => {
    dispatch(fetchingGroupsFromServer());
    return groupServiceApi
            .retrieveGroup(userKey, groupId)
            .then(json => dispatch(receivedGroup(json)));
  };
}

function deleteGroup(userKey, groupId) {
  return (dispatch) =>
    groupServiceApi.deleteUserGroup(userKey, groupId)
      .then(() => dispatch(fetchGroups(userKey)));
}


function fetchUsersForGroup(userKey, groupId) {
  return (dispatch) => {
    dispatch(fetchingUsersFromServer());
    return groupServiceApi
            .retrieveUsersForGroup(userKey, groupId)
            .then(json => dispatch(receivedUsers(json)));
  };
}

function updateUsersForGroup(userKey, groupId, userIds) {
  return (dispatch) => {
    dispatch(savingToServer());
    return groupServiceApi
            .updateUsersForGroup(userKey, groupId, userIds)
            .then(() => dispatch(updatedGroup()))
            .then(() => dispatch(fetchUsersForGroup(userKey, groupId)));
  };
}

function updateGroup({ userKey, groupId, description, name, roles, groups, users }) {
  return (dispatch) => {
    dispatch(savingToServer());
    return groupServiceApi
             .updateGroup({
               userKey,
               groupId,
               description,
               name,
               roles,
               groups,
               memberTypeId: MEMBER_TYPE_ID.group,
             })
             .then(() => dispatch(updatedGroup()))
             .then(() => {
               if (users) {
                 dispatch(updateUsersForGroup(userKey, groupId, users));
               }
             })
             .then(() => dispatch(fetchGroups(userKey)));
  };
}

// TODO - WDD callers of this need to be modified to include browserHistory
function createGroup({ userKey, description, name, roles, groups, users, browserHistory }) {
  return (dispatch) => {
    dispatch(savingToServer());
    return groupServiceApi
             .createGroup({
               userKey,
               description,
               name,
               roles,
               groups,
               memberTypeId: MEMBER_TYPE_ID.group,
             })
             .then((json) => dispatch(updateUsersForGroup(userKey, json.groupId, users)))
             .then(() => dispatch(fetchGroups()))
             .then(() => browserHistory.push('/portalManagement/groups'));
  };
}

export {
  createGroup,
  deleteGroup,
  fetchGroup,
  fetchGroups,
  updateGroup,
  fetchUsersForGroup,
  updateUsersForGroup,
};
