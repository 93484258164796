import React, { Component } from 'react';
import { Route, Switch} from 'react-router-dom';
import { logoff } from '@app/utilities/adalAdapter';
import Feature from '@app/utilities/routing/routeSafeFeature';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Button } from '@arius';
import { NavRoutes } from '@app/routes';

class AriusNavbar extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {};
        this.navigateTo = this.navigateTo.bind(this);
    }

    getLabels() {
        let iconStyle = { marginRight: '5px'};
        return {
            analysis: (
                <small>
                    <img
                        style={iconStyle}
                        src="/assets/portal_pulldown_Arius_icon_16px_USE_flat.png"
                        alt="AA"
                    />
                    Arius Analysis Tool
                </small>
            ),
            tod: (
                <small>
                    <img
                    style={iconStyle}
                    src="/assets/portal_pulldown_TOD_icon_16px_USE_flat.png"
                    alt="TOD"
                    />
                    Triangles On Demand
                </small>
            ), 
            management: (
                <small>
                    <img
                    style={iconStyle}
                    src="/assets/portal_pulldown_portalManagement_icon_16px_USE_flat.png"
                    alt="portal mgmt"
                    />
                    Portal Management
                </small>
            ),
            utilities: (
                <small>
                    <i
                        className="fa fa-cog"
                        aria-hidden="true"
                        style={{ ...iconStyle, ...{ fontSize: '14px' } }}>
                    </i>
                    Utilities
                </small>
            )
        }
    }

    getTitle() {
        const labels = this.getLabels();
        return (
        <Switch>
            <Route path="/analysis" render={props => <Feature flag="ariusAnalysis">{labels.analysis}</Feature>} />
            <Route path="/portalManagement" render={props => labels.management} />
            <Route path="/trianglesOnDemand" render={props => labels.tod} />
            <Route path="/utilities" render={props => labels.utilities} />
            <Route path="/" render={props => <span>Select Your Application</span>} />
        </Switch>
        )
    }

    navigateTo(path){
        this.props.history.push(path);
    }

    render() {
        const {
            showSettingsHandler,
            versionData,
            history,
        } = this.props;
        const labels = this.getLabels();

        const {
            user,
            apiVersion,
            ariusVersion,
            lockDataFromTOD,
            currentTodDbVersion,
            currentAEDbScriptVersion,
        } = versionData;
        
        return (
        <Navbar collapseOnSelect expand="md" bg="light" variant="light">
            <Navbar.Brand onClick={()=> history.push("/home")} 
                 style={{ height: '40px', display: 'flex', alignItems: 'center'}}>
                <img src="/assets/arius_logo.svg" alt="arius enterprise" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <NavDropdown title={this.getTitle()} id="basic-nav-dropdown">
                        <NavDropdown.Item onClick={()=> this.navigateTo("/trianglesOnDemand")}>{labels.tod}</NavDropdown.Item>
                        <NavDropdown.Item onClick={()=> this.navigateTo("/analysis")}>{labels.analysis}</NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item onClick={()=> this.navigateTo("/portalManagement")}>{labels.management}</NavDropdown.Item>
                        <NavDropdown.Item onClick={()=> this.navigateTo("/utilities")}>{labels.utilities}</NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Item className='d-none d-lg-block'>
                        <NavRoutes/>
                    </Nav.Item> */}
                    <NavRoutes/>
                </Nav>
                <Nav>
                <Nav.Item style={{display: 'flex', alignItems: 'center'}}>
                    <Button
                        iconName="vpn_key" 
                        toolTip="API key"
                        onClick={showSettingsHandler}/>
                </Nav.Item>
                <NavDropdown className="no-caret" title={user ? <i className="fa fa-user"></i> : ''} alignRight>
                    <NavDropdown.Item href="">{user ? user.userId : ''}</NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item disabled>Lock Data from TOD: {lockDataFromTOD ? 'YES' : 'NO'}</NavDropdown.Item>
                    <NavDropdown.Item disabled>Enterprise 2020a</NavDropdown.Item>
                    <NavDropdown.Item disabled>Web API version {apiVersion}</NavDropdown.Item>
                    {currentTodDbVersion && (typeof currentTodDbVersion === 'string' || typeof currentTodDbVersion === 'number')
                        ? <NavDropdown.Item disabled>TOD DB script related version {currentTodDbVersion}</NavDropdown.Item>: ''}
                    {currentAEDbScriptVersion && (typeof currentAEDbScriptVersion === 'string' || typeof currentAEDbScriptVersion === 'number')
                        ? <NavDropdown.Item disabled>AE DB script related version{" "}{currentAEDbScriptVersion}</NavDropdown.Item>: ''}
                    {ariusVersion && typeof ariusVersion === 'string'
                        ? <NavDropdown.Item disabled>Arius Application{" "}{ariusVersion}</NavDropdown.Item>: ''}
                    <NavDropdown.Divider/>
                    {/* <NavDropdown className="no-caret" title={'Site Settings'} alignRight>
                        {siteSettings.map((x, idx) => (
                            <NavDropdown.Item key={`s${idx}`} disabled>{x.name}  
                                : {x.value === 'False' ? 'NO' : x.value === 'True' ? 'YES' : x.value}</NavDropdown.Item>
                        ))}
                    </NavDropdown>
                    <NavDropdown.Divider/> */}
                    <NavDropdown.Item href="" onClick={logoff}>Log Out</NavDropdown.Item>
                </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        )
    }
}

export default AriusNavbar;
