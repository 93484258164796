import { callApi } from '../utilities/callApi';

export function retrieveUploads(userKey) {
  return callApi(
    '/rawdata',
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrieveGroupedUploads(userKey) {
    return callApi(
      '/uploads',
      { 'X-AE-USERKEY': userKey }
    );
  }

export function retrieveColumnsForFile(userKey, fileId) {
  return callApi(
    `/rawdata/${fileId}/columns`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function deleteUpload(userKey, uploadId) {
  return callApi(
    `/rawdata/${uploadId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function uploadFile(userKey, file) {
  return callApi(
    '/rawdata/datafile',
    { 'Content-Type': 'multipart/form-data', 'X-AE-USERKEY': userKey },
    'POST',
    file
  );
}

