import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import Utils from './queryUtils';

class Operator extends Component {
    static propTypes = {};
    constructor(props) {
        super(props);
        this.state = {};
        this.handleClick = this.handleClick.bind(this);
    }

    onDrop = (ev) => {
        const {parentSerialNumber, order} = this.props.data;
        //console.error('dropped in operator');
        let o = Utils.parseDragObject(ev);
        Utils.handleOnDragLeave.call(this, ev);

        // add to parent
        this.props.insert(o, parentSerialNumber, order);
    }

    handleClick = (e) => {
        const {operators, data, insert} = this.props;
        let items = operators.filter((item, index) => item.type === 'and' || item.type === 'or');
        let ndx = items.findIndex((item)=> item.queryFragmentOperatorId === data.queryFragmentOperatorId) +1;
        ndx = ndx === items.length ? 0 : ndx;
        let obj = {
            ...data, 
            queryFragmentOperatorId: items[ndx].queryFragmentOperatorId,
            type: items[ndx].type,
            sql: items[ndx].sql 
        };
        insert(obj, data.parentSerialNumber, data.order-1);
    }

    render() {
        const {key, data, remove} = this.props;
        return (


        <span 
            className='operator'
            key={key}
            onDragStart={(e)=> Utils.handleOnDragStart(e, data)} 
            onDrop={this.onDrop}    
            draggable
        >
            <span onClick={this.handleClick}>{data.sql}</span>
            <span className='remove' onClick={()=> {remove(data.serialNumber)}}>
                <span className='serial-number'>{data.serialNumber}</span>
            </span>
        </span>
        
        );
    } 
}

const mapStateToProps = state => ({
    operators: state.tod.queries.operators,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Operator);

