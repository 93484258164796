import React, { Component } from 'react';
import { connect } from '@app/utilities/routing';
import { Modal, Form } from 'react-bootstrap';
import * as Colors from '@app/utilities/colors';
import { showModal } from '@app/actions/modal.actions';
import { 
    updateElasticPool,
    fetchElasticPoolOptions,
} from '@app/actions/utils/performance.actions';

class ModalEditElasticPool extends Component {
    constructor(props) {
        super(props);
        this.state = {tierName: '', levelName: ''};
        this.monthFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0, 
        }).format;
        this.hourFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2, 
        }).format;
        this.getControls = this.getControls.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.promptToSave = this.promptToSave.bind(this);
        this.save = this.save.bind(this);
    }

    getOptions() {
        const {userKey, dispatch} = this.props;
        dispatch(fetchElasticPoolOptions(userKey));
    }

    getControls() {
        const { options, canEdit, levelsWarningMessage } = this.props;
        const { tierName, levelName } = this.state;
        let tiers = options ? options : [];
        let levels = tierName && tiers.length > 0 ? tiers.find((x) => x.name === tierName).items : [];
        
        return (
        <Form>
            <fieldset disabled={!canEdit}>
                <Form.Group controlId="tier">
                    <Form.Label>Tier</Form.Label>
                    {tiers.map((o) => (
                        <Form.Check 
                            type="radio"
                            key={`checkboxtier${o.name}`}
                            checked={o.name === tierName}
                            onChange={() => this.setState({tierName: o.name, levelName: o.items[0].name})}
                            label={o.name} />
                    ))}
                </Form.Group>
                <Form.Group controlId="level">
                    <Form.Label>Level</Form.Label>
                    <div className='levelsWarningMessage'>{levelsWarningMessage}</div>
                    {levels.map((o) => {
                        let hourly = o.hourlyCostEstimate ? this.hourFormatter(o.hourlyCostEstimate) : 'unknown';
                        let monthly = o.monthlyCostEstimate ? this.monthFormatter(o.monthlyCostEstimate): 'unknown';
                        
                        return (
                        <Form.Check 
                            type="radio"
                            key={`checkboxlevel${o.name}`}
                            checked={o.name === levelName}
                            onChange={() => this.setState({levelName: o.name})}
                            label={`${o.description} (est ${hourly}/hr, est ${monthly}/mo)`}/>
                    )})}
                </Form.Group>                
            </fieldset>
        </Form>
        )
    }

    handleOpen(){
        const { data } = this.props;
        this.setState({tierName: data.edition, levelName: data.dtu.toString(), elasticPoolName: data.name});
        this.getOptions();
    }

    handleClose(){
        this.props.closeCallback();
    }

    promptToSave() {
        const { dispatch, options } = this.props;
        const { tierName, levelName } = this.state;
        let tiers = options ? options : [];
        let levels = tierName && tiers.length > 0 ? tiers.find((x) => x.name === tierName).items : [];
        let level = levels.find((o) => o.name === levelName);
        let hourly = level.hourlyCostEstimate ? this.hourFormatter(level.hourlyCostEstimate): 'unknown';
        let monthly = level.monthlyCostEstimate ? this.monthFormatter(level.monthlyCostEstimate) : 'unknown';
        let desc = `${level.description} (est ${hourly}/hr, est ${monthly}/mo)`;

        let messageItems = [
          <li key={`${tierName}-1`} style={{ fontWeight: 800 }}>{desc}</li>,
        ];

        messageItems.unshift(<div key={`${tierName}-2`}>Are you sure you want to change level?</div>);
        messageItems.unshift(<div key={`${tierName}-3`}>{`This level has an estimated monthly cost of ${monthly}`}</div>);

        const yesClickHandler = () => {this.save()}
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            messageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
      }

    save() {
        const { dispatch, userKey, data } = this.props;
        const { tierName, levelName } = this.state;
        let request = JSON.parse(JSON.stringify(data));
        request.edition = tierName;
        request.dtu = levelName;
        dispatch(updateElasticPool(userKey, request));
        this.handleClose();
    }

    render() {
        const { show, canEdit } = this.props;
        const { elasticPoolName } = this.state;
        return (  
 
        <Modal show={show} onHide={this.handleClose} onEnter={this.handleOpen} size='lg' dialogClassName="review-confirmation-modal">
            <Modal.Header closeButton style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }}>
            <Modal.Title>Elastic Pool levels:  {elasticPoolName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container form" style={{width:'100%'}}>
                <fieldset className="row">
                    <div className="col-sm-12" style={{}}>
                        <div className="form-group">
                            {this.getControls()}
                        </div>
                    </div>
                </fieldset> 
            </div>
            </Modal.Body>
            <Modal.Footer>
                <button key="confirmButton"
                    className="btn btn-info dialog-button"
                    onClick={this.promptToSave}
                    disabled={!canEdit}
                >
                    Save
                </button>
                <button key="nobutton"
                    className="btn btn-info dialog-button"
                    onClick={this.handleClose}>Cancel</button>
            </Modal.Footer>
        </Modal>
        );
    }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    fetchingOptions: state.utils.performance.fetchingPerformanceOptions,
    options: state.utils.performance.elasticPoolOptions
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditElasticPool);
