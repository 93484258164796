import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';
import { notifyError } from '../../../utilities/notifier';

const style = {
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  selectLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50,
    marginBottom: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  workspaceSelects: {
    backgroundColor: '#EAEAEA',
    minHeight: '54px',
  },
};

class AnalysisToolbar extends Component {
  static propTypes = {
    currentWorkspace: PropTypes.object,
    workspaces: PropTypes.array,
    dispatch: PropTypes.func,
    userKey: PropTypes.string,
    changeWorkspaceHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    valuationDates: PropTypes.array,
    changeValuationDateHandler: PropTypes.func,
    valuationDate: PropTypes.string,
    selectedSideNavOption: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedWorkspace: null,
      selectedWorkspaceId: null,
      workspaces: [],
    };
    this.workspaceChanged = this.workspaceChanged.bind(this);
  }

  componentWillMount() {
    const { currentWorkspace, workspaces } = this.props;
    if (currentWorkspace && currentWorkspace.isOnline) {
      this.setState({
        selectedWorkspace: currentWorkspace,
        selectedWorkspaceId: currentWorkspace.id,
      });
    }
    if (workspaces) {
      this.setState({ workspaces: this.alphabetizeItems(workspaces) });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { currentWorkspace, workspaces } = this.props;
    if (currentWorkspace !== nextProps.currentWorkspace && nextProps.currentWorkspace) {
      this.setState({
        selectedWorkspace: nextProps.currentWorkspace,
        selectedWorkspaceId: nextProps.currentWorkspace.id,
      });
    }
    if (workspaces && workspaces !== nextProps.workspaces) {
      this.setState({ workspaces: this.alphabetizeItems(nextProps.workspaces) });
    }
  }

  alphabetizeItems(items) {
    if (items && Array.isArray(items)) {
      return items.sort((a, b) => {
        if (
          a.workspaceName.toString().toLowerCase() <
          b.workspaceName.toString().toLowerCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      });
    } else {
      return [];
    }
  }

  workspaceChanged(e) {
    const { workspaces } = this.props, selectedWorkspaceId = e.target.value;
    if (selectedWorkspaceId !== 'default') {
      const id = parseInt(selectedWorkspaceId, 10);
      const selectedWorkspace =
        workspaces &&
        workspaces.length &&
        workspaces.find(w => w.workspaceId === id);
      if (selectedWorkspace.isOnline) {
        this.props.changeWorkspaceHandler(id);
        this.setState({selectedWorkspace, selectedWorkspaceId: id});
      } else {
        notifyError('Database is currently offline or being created.');
      }
    }
  }

  render() {
    const { disabled, dataStructures, dataStructure, valuationDates, changeValuationDateHandler, valuationDate, selectedSideNavOption } = this.props,
      { selectedWorkspaceId, workspaces } = this.state;
    return (
      <div style={[style.row, style.workspaceSelects]} id="analysisToolbar">
        <div style={style.row}>
          <div style={style.selectLabel}>
            Database
          </div>
          <Form.Group controlId="workspaceSelect" style={style.select}>
            <Form.Control
              as="select"
              disabled={disabled}
              placeholder="Select Workspace"
              value={selectedWorkspaceId || 0}
              onChange={this.workspaceChanged}
            >
              <option value="default">Select Database</option>
              {workspaces && workspaces.length
                ? workspaces.map(workspace => {
                  if (workspace.isOnline) {
                    return (
                        <option
                          key={workspace.workspaceId}
                          value={workspace.workspaceId}
                        >
                          {workspace.workspaceName}
                        </option>
                      );
                  } else {
                    return (
                        <option
                          key={workspace.workspaceId}
                          value={workspace.workspaceId}
                        >
                          {workspace.workspaceName}(Offline)
                        </option>
                      );
                  }
                })
                : <option>You have no databases</option>}
            </Form.Control>
          </Form.Group>
        </div>
        {changeValuationDateHandler && selectedSideNavOption === 'projects' && selectedWorkspaceId ?
          (<div style={style.row}>
            <div style={style.selectLabel}>
              Valuation Date
            </div>
            <Form.Group controlId="accountingDateSelect"
              style={style.select}>
              <Form.Control onChange={this.props.changeValuationDateHandler} as="select" placeholder="Select Accounting Date" value={valuationDate || 'all'}>
                <option value="all">All</option>
                {valuationDates.map((date, idx) =>
                  (<option key={`valuationDate-${idx}`}
                    value={date}>{date}</option>)
                )}
              </Form.Control>
            </Form.Group>
            <div style={style.selectLabel}>
                Data Structure
            </div>
            <Form.Group controlId="dataStructureSelect"
              style={style.select}>
              <Form.Control 
                onChange={this.props.changeDataStructureHandler} 
                as="select" placeholder="Select Data Structure" 
                value={dataStructure || 'all'}>
                <option value="all">All</option>
                {dataStructures.map((s, idx) =>
                  (<option key={`dataStructure-${idx}`}
                    value={s}>{s}</option>)
                )}
              </Form.Control>
            </Form.Group>
          </div>) : (<span></span>)}
      </div>
    );
  }
}

export default radium(AnalysisToolbar);
