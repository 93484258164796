export const
  CLEARING_SERVER_NOTIFICATIONS = 'CLEARING_SERVER_NOTIFICATIONS',
  RECEIVED_APP_SETTINGS = 'RECEIVED_APP_SETTINGS',
  RECEIVED_INTEGRATION_SETTINGS = 'RECEIVED_INTEGRATION_SETTINGS',
  RECEIVED_API_VERSION = 'RECEIVED_API_VERSION',
  RECEIVED_ARIUS_VERSION = 'RECEIVED_ARIUS_VERSION',
  RECEIVED_MESSAGE_FROM_SERVER = 'RECEIVED_MESSAGE_FROM_SERVER',
  FETCHING_FROM_SERVER = 'FETCHING_FROM_SERVER',
  SAVING_TO_SERVER = 'SAVING_TO_SERVER',
  RECEIVED_USER = 'RECEIVED_USER',
  FETCHING_USER_FROM_SERVER = 'FETCHING_USER_FROM_SERVER',
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
  UNAUTHORIZED_USER = 'UNAUTHORIZED_USER',
  RECEIVED_ERROR_FROM_SERVER = 'RECEIVED_ERROR_FROM_SERVER',
  CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE',
  RECEIVED_USER_PERMISSIONS = 'RECEIVED_USER_PERMISSIONS',
  FETCHING_PERMISSIONS_FROM_SERVER = 'FETCHING_PERMISSIONS_FROM_SERVER',
  SETTING_BACK_ROUTE = 'SETTING_BACK_ROUTE',
  CLEAR_BACK_ROUTE = 'CLEAR_BACK_ROUTE',
  // SETTINGS ACTIONS
  TOGGLED_SETTINGS_MENU = 'TOGGLED_SETTINGS_MENU',
  FETCHING_API_KEY = 'FETCHING_API_KEY',
  RECEIVED_API_KEY = 'RECEIVED_API_KEY';
