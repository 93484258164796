import {
  RECEIVED_INTEGRATION_SETTINGS,
  RECEIVED_API_VERSION,
  RECEIVED_ERROR_FROM_SERVER,
  CLEAR_ERROR_MESSAGE,
  RECEIVED_APP_SETTINGS,
  SETTING_BACK_ROUTE,
  CLEAR_BACK_ROUTE,
  RECEIVED_ARIUS_VERSION,
} from './actionTypes';
import * as applicationServiceApi from '../serviceApi/application.serviceApi';
import { getAppSettings } from '../config';

function receivedAppSettings(settings) {
  return {
    type: RECEIVED_APP_SETTINGS,
    settings,
  };
}

function receivedIntegrationSettings(settings) {
  return {
    type: RECEIVED_INTEGRATION_SETTINGS,
    settings,
  };
}

function receivedApiVersion(apiVersion) {
  return {
    type: RECEIVED_API_VERSION,
    apiVersion,
  };
}

function receivedAriusApplicationVersion(ariusVersion) {
  return {
    type: RECEIVED_ARIUS_VERSION,
    ariusVersion,
  };
}

function fetchIntegrationSettings() {
  return dispatch =>
    applicationServiceApi
      .retrieveIntegrationSettings()
      .then((json) => {
        if (json && !json.error) dispatch(receivedIntegrationSettings(json));
      });
}

function fetchApiVersion() {
  return dispatch =>
    applicationServiceApi
      .retrieveApiVersion()
      .then((json) => {
        if (json && !json.error) dispatch(receivedApiVersion(json));
      });
}

function fetchAriusApplicationVersion() {
  return dispatch =>
    applicationServiceApi
      .retrieveAriusApplicationVersion()
      .then((json) => {
        let version = typeof json == "string" ? json : "not found";
        dispatch(receivedAriusApplicationVersion(version))
      });
}

function fetchAppSettings() {
  return dispatch =>
    getAppSettings().then(settings => dispatch(receivedAppSettings(settings)));
}

function receivedErrorFromServer(errorMessage) {
  return {
    type: RECEIVED_ERROR_FROM_SERVER,
    errorMessage,
  };
}

function clearErrorMessage() {
  return {
    type: CLEAR_ERROR_MESSAGE,
  };
}

function setBackRoute(route) {
  return {
    type: SETTING_BACK_ROUTE,
    route,
  };
}

function clearBackRoute() {
  return {
    type: CLEAR_BACK_ROUTE,
  };
}

export {
  setBackRoute,
  clearBackRoute,
  fetchAppSettings,
  fetchIntegrationSettings,
  fetchApiVersion,
  receivedErrorFromServer,
  clearErrorMessage,
  fetchAriusApplicationVersion,
};
