import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import $ from 'jquery';

const style = {
  mainContainer: {
    boxShadow: '0 0 1px black',
    cursor: 'row-resize',
    position: 'relative',
    zIndex: '10',
    display: 'flex',
    flex: '1 1 auto',
  },
};

class Resizer extends Component {
  static propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
    parentContainer: PropTypes.string,
    containerOne: PropTypes.string,
    containerTwo: PropTypes.string,
    resizer: PropTypes.string,
    id: PropTypes.string,
    useContainerOne: PropTypes.bool,
  };
  static isResizing = false;
  static lastDownY = 0;
  constructor(props) {
    super(props);
    this.state = {
    };
    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.resize();
  }

  componentWillUnmount() {
    const { containerOne } = this.props,
      one = $(`#${containerOne}`);
    one.css('height', this.state.originalOneHeight);
    $(document).unbind('mousemove', this.move);
  }

  onMouseDown(e) {
    Resizer.isResizing = true;
    Resizer.lastDownY = e.clientX;
  }
  
  onMouseUp() {
    Resizer.isResizing = false;
  }

  resize() {
    const { parentContainer, containerOne, containerTwo, useContainerOne } = this.props,
      parent = $(`#${parentContainer}`),
      parentHeight = parent.height(),
      one = $(`#${containerOne}`),
      oneHeightFixed = one.height(),
      two = $(`#${containerTwo}`);
    this.setState({ originalOneHeight: oneHeightFixed });
    let temp;
    $(document).on('mousemove', function move(e) {
      temp = move;
      if (!Resizer.isResizing) {
        return;
      }
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      if ((one.height() < 5 || two.height() < 12) && (e.clientY < one.position().top || e.clientY > window.innerHeight - 12)) {
        return;
      }
      const offsetTop = parent.height() - (e.clientY - parent.offset().top),
        twoHeight = $(`#${containerTwo}`).height(),
        oneHeight = useContainerOne ? oneHeightFixed - twoHeight : parentHeight - twoHeight;
      one.css('height', oneHeight);
      two.css('height', offsetTop);
    }).on('mouseup', this.onMouseUp);
    this.move = temp;
  }

  render() {
    const { width, backgroundColor, id } = this.props;
    return (
      <div 
        id={id}
        onMouseDown={(e) => this.onMouseDown(e)}
        style={[style.mainContainer, { minHeight: '10px', maxHeight: '10px', width, backgroundColor }]}>
      </div>
    );
  }
}

export default radium(Resizer);
