import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    getOperationDescription,
    getStatusDescription,
    getInfoDescription,
} from '../../../utilities/longRunningOperationsDescriptionHelper';
import { Grid } from '@arius';
import StatusTaskDetail from './statusTaskDetail';

class StatusGrid extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        currentWorkspace: PropTypes.object,
        dispatch: PropTypes.func,
        params: PropTypes.object,
        projects: PropTypes.array,
        height: PropTypes.string,
    };
    constructor(props) {
        super(props);
        this.state = {
            expandedJobs: [],
            projects: [],
            fetching: false,
            cancelledJobs: [],
            jobs: [],
            statusFilter: [],
        };
        this.getGrid = this.getGrid.bind(this);
        this.getTasksGrid = this.getTasksGrid.bind(this);
    }

    getProjectNameAndId(opParams) {
        const { projects } = this.state;
        let projectNameAndId = '';
        if (projects && projects.length) {
            const project = projects.find(p => p.projectId === opParams.projectId);
            if (project) {
                projectNameAndId = (
                <div style={{ display: 'flex', justifyContent: 'space-between' }} />
                );
            }
        }
        return projectNameAndId;
    }

    toggleAllTasks(jobs, action) {
        if (action === 'expandAll') {
            const jobsToExpand = jobs.reduce((prev, curr) => {
                prev.push(curr.operationId);
                return prev;
            }, []);
            this.setState({ expandedJobs: jobsToExpand });

        } else {
            this.setState({ expandedJobs: [] });
        }
    }
    
    getGrid() {
        const { jobs, getCancelIcon } = this.props;

        let columns = [
            { headerText: 'Job', template: (r) => (
                <div style={{margin: 8, justifyContent: 'space-between'}}>
                    <span>
                        {getOperationDescription(r.operationCode, r.operationParams, true)}
                    </span>
                    <span style={{ float: 'right' }}>{getCancelIcon(r)}</span>
                </div>
        )},
            { headerText: 'Submitted by',width: '120px', template: (r) => (
                <span>{r.requestor.name ? r.requestor.name : r.requestor.email}</span>
            )},
            { headerText: 'Submitted at', field: 'requestTime',  type:'datetime'},
            { headerText: 'Status', width: '80px', template: (r) => (
                <span>{getStatusDescription(r.status)}</span>
            )},
            { headerText: 'Started', field: 'startTime',  type:'datetime'},
            { headerText: 'Info', width: '80px', template: (r) => (
                <span>{getInfoDescription(r.statusCode, r.statusParams)}</span>
            )},
            { headerText: 'Last Updated', field: 'timestamp',  type:'datetime'},
        ];

        return <Grid 
            columns={columns}
            data={jobs}
            height='calc(100vh - 200px)' 
            detailTemplate={this.getTasksGrid}
            allowSorting={false}
        />
    }

    getTasksGrid(job) {
        const {
            project,
            userKey,
            currentWorkspace,
        } = this.props;
        const { operations:data } = job;
        let isExtract = job.operations.find((x)=> x.operationParams.isExtract) != null;

        let columns = [
            { headerText: 'Job',  template: (r) => (
                <div 
                    style={{
                        margin: 8,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                }}
                >
                <div>{r.operationParams.projectName}</div>
                <div>
                    <span style={{ color: 'black', fontWeight: 'bold' }}>id:</span>
                    &nbsp;
                    {r.operationParams.projectId}
                </div>
                </div>
            )},
            { headerText: 'Submitted by', width: '120px', template: (r) => (
                <span>{r.requestor.name ? r.requestor.name : r.requestor.email}</span>
            )},
            { headerText: 'Submitted at', field: 'requestTime', type:'datetime'},
            { headerText: 'Status', width: '80px', headerTemplate: () => null, template: (r) => (
                <span>{getStatusDescription(r.status)}</span>
            )},
            { headerText: 'Started', field: 'startTime', type:'datetime'},
            { headerText: 'Info', width: '80px', template: (r) => (
                r.statusParams ? 
                    <span>
                        <OverlayTrigger
                        placement="top"
                        focus
                        overlay={
                            <Tooltip id="info-tip">Version:&nbsp;{r.statusParams.version}</Tooltip>
                        }
                        >
                        <span>{getInfoDescription(r.statusCode, r.statusParams)}</span>
                        </OverlayTrigger>
                    </span> :
                    <span>
                        {getInfoDescription(r.statusCode, r.statusParams)}
                    </span>
            )},
            { headerText: 'Last Updated', field: 'timestamp',  type:'datetime'},
        ];
// TEST PUSH
        let grid = <Grid 
            columns={columns} 
            data={data}     
            height='auto'
            detailTemplate={isExtract ? null : (c) => (<StatusTaskDetail
                job={c}
                project={project}
                userKey={userKey}
                currentWorkspace={currentWorkspace}
            />)}
        />;

        return <span className="task-grid">{grid}</span>
    }

    render() {
        return this.getGrid();
    }
}

export default radium(StatusGrid);
