import React, { Component } from 'react';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import moment from 'moment';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ProgressBar, Dropdown, Button, Form, Col } from 'react-bootstrap';
import PITRGrid from './pitrGrid';



class PITRTab extends Component {
	static propTypes = {};
	static defaultProps = {};
	constructor(props) {
		super(props);
		this.state = {};
        this.restorePointInTime = this.restorePointInTime.bind(this);
        this.undoPointInTime = this.undoPointInTime.bind(this);
        this.downloadLogFile = this.downloadLogFile.bind(this);
        this.deleteLogFile = this.deleteLogFile.bind(this);
	}

	componentDidMount() {
        console.log('pitrTab componentDidMount');
	}

	componentDidUpdate(prevProps) {
        const {latestRestorePoint} = this.props;
        const { targetRestorePoint } = this.state;
        console.log('pitrTab componentDidUpdate');
		if (!targetRestorePoint || prevProps.latestRestorePoint !== latestRestorePoint) {
            console.log('pitrTab componentDidUpdate. set targetRestorePoint');
			this.setState({targetRestorePoint: moment(latestRestorePoint).local()});
		}
	}

    restorePointInTime() {
        const { userKey, database, earliestRestorePoint, latestRestorePoint, modalHandler, restorePointInTimeHandler } = this.props;
        const { targetRestorePoint } = this.state,
            databaseId = database.id || database.workspaceId;
        console.log('pitrTab restorePointInTime');
        if (targetRestorePoint) {
            const rp = targetRestorePoint;
            const min = moment(earliestRestorePoint);
            const max = moment(latestRestorePoint);
            let displayDate = rp.local().format('MM/DD/YYYY HH:mm:ss');

            if (!this.isValidTargetRestorePoint) {
              this.setState({ showPITValidationModal: true, targetRestorePointOutOfRange: (rp <= min || rp >= max)  });
            } else {
              const yesHandler = () => {
                  restorePointInTimeHandler(userKey, databaseId, rp.utc().format('MM/DD/YYYY HH:mm:ss'));
                  modalHandler({ isRestoringPointInTimeBackup: true, showDeleteModal: false, additionalDeleteMesssage: '', areYouSureMessage: '' });
              };
              modalHandler({
                  showDeleteModal: true,
                  deleteModalHandler: yesHandler,
                  areYouSureMessage: `Are you sure you wish to restore this database from ${displayDate}?`,
                  additionalDeleteMesssage: 'Note that this will overwrite your existing database with all data from this backup. If previous Point-In-Time Restore UNDO database exists it will be overwritten.',
              });
            }
        }
    }

    undoPointInTime(backupId) {
        const { userKey, database, modalHandler, undoPITRHandler} = this.props;
        const databaseId = database.id || database.workspaceId;
        const yesHandler = () => {
            undoPITRHandler(userKey, databaseId, backupId);
            modalHandler({ isRestoringPointInTimeBackup: true, showDeleteModal: false, additionalDeleteMesssage: '', areYouSureMessage: '' });
        };
        modalHandler({
            showDeleteModal: true,
            deleteModalHandler: yesHandler,
            areYouSureMessage: `Are you sure you wish to UNDO this point-in-time restore?`,
            additionalDeleteMesssage: `Note that this will return the database to the same state the database was 
                in before the PITR was done.`,
        });
    }

    downloadLogFile() {
        const { userKey, database, retrieveLogFile } = this.props;
        retrieveLogFile(userKey, database.id || database.workspaceId);
    }

    deleteLogFile() {
        const { deleteLogFileFromServer, modalHandler, userKey, database } = this.props;
        console.error('delete...', database);
        const yesHandler = () => {
            console.error('delete handler ...', database);
            deleteLogFileFromServer(userKey, database.id || database.workspaceId);
            modalHandler({ showDeleteModal: false });
        };
        modalHandler({
          showDeleteModal: true,
          deleteModalHandler: yesHandler,
          areYouSureMessage: 'Are you sure you want to delete the log file?',
        });
    }

    get isValidTargetRestorePoint() {
        const { earliestRestorePoint, latestRestorePoint } = this.props;
        const { targetRestorePoint } = this.state;

        console.log('pitrTab isValidTargetRestorePoint');
        console.log(targetRestorePoint);

        if (!earliestRestorePoint || !latestRestorePoint || !targetRestorePoint) {return false;}

        const rp = moment(targetRestorePoint);
        const min = moment(earliestRestorePoint);
        const max = moment(latestRestorePoint);

        const res = rp.isValid() && rp >= min && rp <= max;
        console.log(res);
        return res;
    }

    handleChange = (e) => {
        const { targetRestorePoint } = this.state;
        console.log('handleChange');

        if (!e || !e.value) { return; }

        const mdate = moment(e.value);
        if (targetRestorePoint && mdate === targetRestorePoint) { return; }

        this.setState({targetRestorePoint: mdate})
    }

	render() {
		const {
            earliestRestorePoint,
            latestRestorePoint,
            restorePointInTimeMessage,
            undoPointInTimeMessage,
            isRestoringPointInTimeBackup,
            deleteBackupHandler,
            backups
        } = this.props;

        let isValidRestoreRange = earliestRestorePoint && latestRestorePoint;
        let restoreRangeMessage = 'No valid restore points exist for this database. Try again later.';
        let dateFormat = 'MM/DD/YYYY HH:mm:ss';
        let statusMessage = restorePointInTimeMessage ? restorePointInTimeMessage : undoPointInTimeMessage;

        let earliest = moment().local();
        let latest = moment().local();
        if (isValidRestoreRange) {
          earliest = moment(earliestRestorePoint).local();
          latest = moment(latestRestorePoint).local();
          restoreRangeMessage = 'Enter a restore point between ' + earliest.format(dateFormat) + ' and ' + latest.format(dateFormat);
        }

    let targetDate = moment(this.state.targetRestorePoint).local().toDate();
    let minDate = earliest.toDate();
    let maxDate = latest.toDate();
    let format = 'MM/dd/yyyy HH:mm:ss';

    const isActive = isRestoringPointInTimeBackup;
    const isComplete = restorePointInTimeMessage.indexOf('Completed') > -1;
    const isError = restorePointInTimeMessage.indexOf('Error') > -1;
    const msgColor = isComplete ? 'green' : isError ? 'red' : 'purple';

    const restoreDisabled = (isActive && !isComplete);

    return (
        <Form style={{padding: 15}}>
            <Form.Group>
                <Form.Row>
                    <Col>
                        Target Restore Point
                        <span style={{fontWeight: 500 }}>&nbsp;(local time):</span>
                        {/* {displayDate} */}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        <DateTimePickerComponent id="datetimepicker"
                            value={targetDate}
                            format={format}
                            strictMode
                            min={minDate}
                            max={maxDate}
                            change={this.handleChange}
                            disabled = {isActive && !isComplete}
                        />
                    </Col>
                    <Col>
                        <Button variant="arius"
                            key="create point in time restore" 
                            disabled={restoreDisabled} 
                            onClick={this.restorePointInTime}
                            
                        >Restore</Button>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>{restoreRangeMessage}
                    </Col>
                    </Form.Row>
                {/* <Form.Text>{restoreRangeMessage}</Form.Text> */}
            </Form.Group>
            <Form.Group>
                {(isActive && !isComplete) ? (<ProgressBar animated now={100} />) : ('')}
                <Form.Text style={{color: msgColor, paddingLeft: 12, height: 19}}>{statusMessage}</Form.Text> 
            </Form.Group>

            <Form.Group>
                
                <PITRGrid
                    backups={backups}
                    undoPointInTime={this.undoPointInTime}
                    deleteBackupHandler={deleteBackupHandler}
                />

                <div className="col-sm-12" style={{marginBottom: 15}}>
                    <Dropdown style={{marginLeft: 10 }} className="pull-right">
                        <Dropdown.Toggle variant="arius">
                            Log File
                        </Dropdown.Toggle >
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.downloadLogFile}>
                                Download
                            </Dropdown.Item>
                            <Dropdown.Item onClick={this.deleteLogFile}>
                                Delete
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown >
                </div>
            </Form.Group>
        </Form>
    );
	}
}

const mapStateToProps = state => ({
	queries: state.tod.queries.items,
	isFetching: state.tod.queries.isFetchingQueries,
});

const mapDispatchToProps = dispatch => ({
	dispatch,
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(radium(PITRTab));
