import { connect } from '@app/utilities/routing';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';
import { getValuationDates } from '@app/actions/analysis/project.actions';

const style = {
  radio: {
    margin: '8.5px 2.5px 2.5px 2.5px',
    height:'32px',
  },
  radio2: {
    margin: '8.5px 2.5px 2.5px 2.5px',
    width: '50px',
    height:'32px',
  },
  latestInput: {
    position: 'relative',
    width: '50px',
    bottom: '0px',
    margin: '0px 50px 10px 40px',
    top: '3px',
  },
  valuationDateSelector: {
    position: 'relative',
    width: '150px',
    bottom: '0px',
    margin: '0px 50px 10px 40px',
    top: '3px',
  },
};

class ValuationDatesView extends Component {
  static propTypes ={
    updateParent: PropTypes.func,
    state: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      latestValue: 4,
      selectedRadioOption: 'all',
      label: '',
    };
    this.handleValuationDateChange = this.handleValuationDateChange.bind(this);
    this.handleLatestValueChange = this.handleLatestValueChange.bind(this);
    this.handleSelectRadioOption = this.handleSelectRadioOption.bind(this);
    this.handleInputExit = this.handleInputExit.bind(this);
    this.updateTheParent = this.updateTheParent.bind(this);
  }

  componentDidMount() {
    const { dispatch, userKey, state } = this.props;
    const { valuationDates } = this.props;

    if (!valuationDates || valuationDates.length === 0) {
      dispatch(getValuationDates(userKey, state.databaseId));
    } 
  }

  componentDidUpdate(prevProps) {
    this.setValuationDateToDefault();
  }

  componentWillMount() {
    const { state } = this.props;
    this.setState(state);
  }

  setValuationDateToDefault() {
    const { valuationDates } = this.props;
    const { selectedValuationDate } = this.state;

    if (valuationDates && valuationDates.length === 0 && selectedValuationDate === '') {
      this.setState({ selectedValuationDate: valuationDates[0] }, () => this.updateTheParent());
    }
  }

  handleValuationDateChange(e) {
    this.setState({ selectedValuationDate: e.target.value }, () => this.updateTheParent());
  }

  handleLatestValueChange(e) {
    let latestValue = e.target.value;
    latestValue = Number(latestValue.replace(/[^0-9\.]/g, '')) || '';
    if (latestValue > 999) return;
    this.setState({ latestValue }, () => this.updateTheParent());
  }

  handleSelectRadioOption(option) {
    const { valuationDates } = this.props;
    const { selectedRadioOption, selectedValuationDate  } = this.state;
    if (selectedRadioOption !== option) {
      this.setState({ selectedRadioOption: option }, () => this.updateTheParent());
      if (option === 'selectedValuationDate' && selectedValuationDate === '') {
        this.setState({ selectedValuationDate: valuationDates[0] }, () => this.updateTheParent());
      }
    }
  }

  handleInputExit() {
    if (!this.state.latestValue) {
      this.setState({ latestValue: 4 }, () => this.updateTheParent());
    }
  }

  updateTheParent() {
    const { updateParent } = this.props;
    updateParent(this.state);
  }

  render() {    
    const { valuationDates } = this.props;
    const { latestValue, selectedRadioOption,} = this.state;

    let vdfed = valuationDates ? valuationDates : [];
    
    var isDisabled = selectedRadioOption === 'selectedValuationDate' ? false : true;

    return (
      <Form style={{marginLeft: '20px', marginBottom: '15px'}}>
        <Form.Row>
          <Form.Check type="radio"
              id="vd1"
              style={style.radio}
              checked={selectedRadioOption === 'all'}
              onChange={() => this.handleSelectRadioOption('all') }
              label="All" />
        </Form.Row>
        <Form.Row>
          <Form.Check type="radio"
              id="vd2"
              style={style.radio}
              checked={selectedRadioOption === 'latest'}
              onChange={() => this.handleSelectRadioOption('latest') }
              label="Most Recent" />
        </Form.Row>
        <Form.Row>
          <Form.Check type="radio"
              id="vd3"
              style={style.radio2}
              checked={selectedRadioOption === 'lastFewByAmount'}
              onChange={() => this.handleSelectRadioOption('lastFewByAmount') }
              label="Last" />
          <Form.Control
              style={style.latestInput}
              type="text"
              value={latestValue}
              onChange={(e) => this.handleLatestValueChange(e)}
              onBlur={() => this.handleInputExit()} />
        </Form.Row>
        <Form.Row>
          <Form.Check type="radio"
              id="vd4"
              style={style.radio2}
              checked={selectedRadioOption === 'selectedValuationDate'}
              onChange={ () => this.handleSelectRadioOption('selectedValuationDate') }
              label="Select:" />
          <select disabled={isDisabled} className="form-control" style={style.valuationDateSelector} id="valuationDatesForExtract" value={this.state.selectedValuationDate} onChange={this.handleValuationDateChange}>
                {vdfed.map((date, idx) => (
                   <option
                      value={date}
                      key={date}
                    >
                    {date}
                   </option>
                ))}
          </select>
        </Form.Row>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  userKey: state.user.userKey,
  valuationDates: state.analysis.projects.valuationDates,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(ValuationDatesView));
