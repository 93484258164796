import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';
import { showModal } from '@app/actions/modal.actions';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import { setBackRoute } from '@app/actions/application.actions';
import { notifyError } from '@app/utilities/notifier';
import Loader from '@app/shared/presentational/loader';
import AdxQueryWarning from '@app/shared/presentational/adxQueryWarning';
import NoResults from '@app/shared/presentational/noResults';
import { Grid, Button } from '@arius';

class QueriesList extends Component {
    static propTypes = {
        queries: PropTypes.array.isRequired,
        databases: PropTypes.array,
        changeDatabase: PropTypes.func,
        deleteQueryHandler: PropTypes.func,
        dispatch: PropTypes.func,
        userKey: PropTypes.string,
        currentDb: PropTypes.object,
        isFetching: PropTypes.bool,
        params: PropTypes.object,
        verifyPermission: PropTypes.func,
        getDatasetColumns: PropTypes.func,
    };
    static defaultProps = {
        queries: [],
    };
    constructor(props) {
        super(props);
        this.state = {};
        this.changeDatabase = this.changeDatabase.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
    }

    changeDatabase(e) {
        const {
            databases,
            changeDatabase,
            userKey,
            getDatasetColumns,
        } = this.props,
        database = databases.find(
            d => d.workspaceId.toString() === e.target.value
        );

        getDatasetColumns({ userKey, databaseId: database.workspaceId });
        changeDatabase(database);
    }

    deleteQuery(id) {
        const {
            queries,
            currentDb,
            verifyPermission,
            deleteQueryHandler,
            userKey,
            dispatch,
        } = this.props;
    
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;
                            
        if (!hasWriteAccess) {
            notifyError('You are not authorized to access this functionality');
            return;
        }

        if (id && queries && queries.length > 0) {
            const selectedQuery = queries.find(q => q.queryId === id),
                deleteMessageItems = [
                <li
                    key={selectedQuery.queryId}
                    style={{ fontWeight: 800, listStyle: 'none' }}
                >
                    {selectedQuery.queryName}
                </li>,
                ];

            deleteMessageItems.unshift(
                'Are you sure you want to delete the following query: '
            );
            deleteMessageItems.push(
                'This could affect Arius projects for segments that reference this query.'
            );

            const yesClickHandler = () => {
                deleteQueryHandler(userKey, currentDb.id, id);
            };
            const noClickHandler = () => {};
            const action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
            );
            dispatch(action);
        }
    }

    rollupNav() {
        const { dispatch, browserHistory } = this.props;
        dispatch(setBackRoute('/trianglesOnDemand/queries'));
        browserHistory.push('/trianglesOnDemand/queries/rollupDefinitionsList');
    }

    getGrid() {
        const { queries } = this.props;

        let columns = [
            { field: 'queryName', headerText: 'Name', width: '40%'},
            { field: 'queryName', headerText: 'Description', width:'60%',
                template: (r) => {
                    return (
                        <span>
                            {r.isAvailableForUse ? r.queryDescription : '[PREPARING]  ' + r.queryDescription}
                        </span>
                    )
                }
            },
            { field: 'auditUser', headerText: 'Last Modified By', width: '130px'},
            { field: 'auditDatetime', headerText: 'Last Modified', type: 'datetime'},
            { headerText: 'Actions', width: '100px', template: this.getActionItems}
        ];

        return <Grid columns={columns} data={queries} height='calc(100vh - 280px)'/>
    }

    getActionItems(r) {
        const {
            browserHistory,
            currentDb,
            verifyPermission,
        } = this.props;
    
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;

        return (
            <span>
                <Button
                    id={`edit-${r.queryId}`}
                    onClick={()=> {browserHistory.push(`/trianglesOnDemand/queries/${currentDb.id}/${r.queryId}`);}}
                    toolTip={hasWriteAccess ? 'View/Edit' : 'View'}
                    iconName='edit'
                />
                <Button
                    id={`delete-${r.queryId}`}
                    onClick={e => this.deleteQuery(r.queryId, e)}
                    toolTip='Delete Query'
                    iconName='delete'
                />
            </span>
        )
    }

    render() {
        const {
            databases,
            queries,
            isFetching,
            currentDb,
            verifyPermission,
            browserHistory,
        } = this.props;

        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;
        const accessErrorMessage = !currentDb ? 'Must first select a database' :
                                                'You are not authorized to access this functionality';

        let tableMarkup = <div />;

        if (currentDb) {
            tableMarkup = (
                <div style={{ margin: '5vh auto' }}>
                <NoResults />
                </div>
            );
            if (currentDb.useADX){
                tableMarkup = <AdxQueryWarning/>
            }
            else if (isFetching) {
                tableMarkup = (
                <div style={{ margin: '5vh auto' }}>
                    <Loader loadingItem="Queries" />
                </div>
                );
            } else if (queries && queries.length > 0) {
                tableMarkup = this.getGrid();
            }
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>Create and edit queries</h4>
                <div style={{display: 'flex'}}>
                    <Button variant="arius" style={{marginRight: '20px'}} onClick={e => this.rollupNav()}>Rollup Definitions</Button>
                    <Button
                        toolTip="Create New Query"
                        mode="add"
                        onClick={
                            hasWriteAccess
                            ? () => browserHistory.push(`/trianglesOnDemand/queries/${currentDb.id}/new`)
                            : () => notifyError(accessErrorMessage)
                        }/>
                </div> 
            </div>
            <Form.Group
            controlId="selectDatabaseControl"
            style={{ width: '25vw', marginTop: 10 }}
            >
            <Form.Label>Select Database</Form.Label>
            <Form.Control
                as="select"
                placeholder="select"
                value={currentDb ? currentDb.id : 'default'}
                onChange={this.changeDatabase}
            >
                <option value="default">Select a database</option>
                {databases.map(db => (
                <option key={`${db.workspaceId}`} value={db.workspaceId}>
                    {db.workspaceName}
                </option>
                ))}
            </Form.Control>
            </Form.Group>
            {tableMarkup}
        </div>
        );
    }
}

export default radium(QueriesList);
