
import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { ConditionalLink } from '@app/shared/containers/conditionalLink';

let button = (props) => {
    const { iconName, id:key, isAllowed, path, toolTip, placement, onClick, mode, size } = props;
    let toolTipPlacement = placement ? placement : 'left';
    let body = <span>{props.text}</span>;
    
    // Icon button using Font Awesome
    if (iconName && iconName.includes('fa-')){
        body = <i key={key} className={`fa ${iconName} menu-icon`} style={{...props.style }}/>;
    }
        
    // Icon button using Material Icons
    if (iconName && !iconName.includes('fa-')){
        body = <i key={key} className="material-icons menu-icon" style={{...props.style }}>{iconName}</i>;
    }

    // Small cancel button
    if (mode === 'cancel'){
        body = <i key={key} className="menu-icon cancel-icon fa fa-ban" style={{...props.style }}/>;
    }
    if (mode === 'cancel-disabled'){
        body = <i key={key} className="menu-icon cancel-icon-disabled fa fa-ban" style={{...props.style }}/>;
    }

    // Large back button
    if (mode === 'back'){
        body = <i className="material-icons menu-icon add-icon">arrow_back</i>;
        toolTipPlacement= "left";
    }

    // Large add button
    if (mode === 'add'){
        body = <i className="material-icons menu-icon add-icon">add</i>;
        toolTipPlacement= "left";
    }

    // Large red delete button
    if (mode === 'delete'){
        body = <i className="fa fa-minus btn btn-danger" style={{fontSize: 14, padding: '5px', lineHeight: '14px'}}/>;
    }

    // Red warning icon
    if (mode === 'error'){
        body = <i className="custom-project-list-icon material-icons red">error</i>
    }

    // Standard react-bootstrap button
    if (props.variant){
        return <Button 
            style={{ display: 'flex', alignItems: 'center', ...props.style }}
            variant={props.variant} 
            onClick={onClick}
            size={size ? size : ''}
        >{props.children}</Button>;
    }

    body = toolTip ? (
        <OverlayTrigger
            placement={toolTipPlacement}
            rootClose={true}
            overlay={<Tooltip className='tool-tip-fix'>{toolTip}</Tooltip>}
        >
            {body}
        </OverlayTrigger>
     ) : body;

    if (path) {
        let allow = isAllowed === false ? false : true;
        return (
            <ConditionalLink to={path} allow={allow}>{body}</ConditionalLink>
        )
    } else {
        return (<span onClick={onClick}>{body}</span>)
    }
}

export default button;