import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Grid, Button } from '@arius';
import { notifyError } from '../../../utilities/notifier';
import { showModal } from '../../../actions/modal.actions';
import { SITE_MANAGEROLES } from '../../../utilities/permissions';
import Loader from '../../shared/presentational/loader';
import NoResults from '../../shared/presentational/noResults';

const style = {
    row: {
        display: 'flex',
        flexFlow: 'row nowrap',
    },
    inputLabel: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 50,
        marginBottom: 10,
        marginLeft: 10,
        marginTop: 10,
    },
    userToolbar: {
        backgroundColor: '#EAEAEA',
    },
};

class ViewRoles extends Component {
    static propTypes = {
        application: PropTypes.object,
        userKey: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        dispatch: PropTypes.func,
        assignedRoles: PropTypes.array,
        roleDescriptions: PropTypes.array,
        assignedRoleHandler: PropTypes.func,
        roleDescriptionHandler: PropTypes.func,
        deleteRoleHandler: PropTypes.func,
        getCurrentUserPermissions: PropTypes.func,
        userPermissions: PropTypes.array,
        verifyPermission: PropTypes.func,
        fetchRolePermissions: PropTypes.func,
        selectedRole: PropTypes.object,
    };
    static defaultProps = { organizationId: 0, isFetching: false, userKey: '' };
    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: null,
            currentRoles: [],
            isUpdated: false,
            sortBy: 'name',
            selectedRole: null,
            alreadyFetched: false,
            permissions: [],
            searchText: ''
        };
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.searchRoles = this.searchRoles.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
    }

    componentDidMount() {
        const {
            roleDescriptionHandler,
            userKey,
            roleDescriptions,
        } = this.props;

        roleDescriptionHandler(userKey);
        if (roleDescriptions.length) {
            this.setState({ alreadyFetched: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { permissions } = this.state;
        if (
            permissions &&
            nextProps.selectedRole &&
            nextProps.selectedRole.permissions !== permissions
        ) {
            this.setState({ permissions: nextProps.selectedRole.permissions });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { roleDescriptions } = this.props;
        if (
            (!prevProps.roleDescriptions.length && roleDescriptions.length) ||
            (roleDescriptions.length &&
                roleDescriptions.length !== prevProps.roleDescriptions.length)
        ) {
            this.setState({ alreadyFetched: true });
        }
    }

    deleteClickHandler(e, id, name) {
        e.stopPropagation();

        const { dispatch, userKey, deleteRoleHandler } = this.props,
            deleteMessageItems = [
                (<li key={`${id}-delete-role`} style={{ fontWeight: 800 }}>{name}</li>),
            ];

        deleteMessageItems.unshift(
            'Are you sure you want to delete the following role?'
        );

        const yesClickHandler = () => {
            deleteRoleHandler(userKey, id);
        },
            noClickHandler = () => { },
            action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
            );
        dispatch(action);
    }

    getAdjustedRoles() {
        const { roleDescriptions } = this.props;
        const { searchText } = this.state;
        let roles =  roleDescriptions.map(r => { return {
                 ...r, 
                updatedBy: r.updatedBy ? r.updatedBy : r.createdBy,
                updatedDate:  r.updatedDate ? r.updatedDate : r.createdDate
            }});

        if (searchText.trim() !== '') {
                roles = roles.filter(rd => {
                    return  this.contains(rd.name, searchText) ||
                        this.contains(rd.description, searchText) ||
                        this.contains(rd.application, searchText) ||
                        this.contains(rd.updatedBy, searchText) ||
                        this.contains(rd.updatedDate, searchText);
                });
            }
        return roles;
    }

    contains(str, search) {
        return typeof(str)=='string' ? str.toLowerCase().indexOf(search) !== -1 : false;
    }

    searchRoles(e) {
        this.setState({searchText: e.target.value.toLowerCase()});
    }

    getActionItems(row) {
        const { browserHistory, readOnly ,verifyPermission} = this.props;
        let canEdit = !row.isSystemRole && verifyPermission(SITE_MANAGEROLES);
        return  (
        <span>
            <Button 
                iconName="edit" 
                onClick={() => {
                    readOnly ? 
                    notifyError('You are not authorized to access this functionality') : 
                    browserHistory.push(`/portalManagement/roles/defineRoles/${row.roleId}`)
                }}
                toolTip={canEdit ? 'Edit' : 'View'}/>
            {
                row.isSystemRole ? (<span>&nbsp; &nbsp;</span>) : (
                    <Button 
                        iconName="delete" 
                        onClick={(e) => {
                            canEdit ? this.deleteClickHandler(e, row.roleId, row.name) :
                            notifyError('You are not authorized to access this functionality')
                        }}
                        toolTip='Delete'/>     
                )
            }
        </span>
        )
    }

    getGrid() {
        let columns = [
            { field: 'name', headerText: 'Name', width:'15%'},
            { field: 'description', headerText: 'Description', width:'30%'},
            { field: 'application', headerText: 'Application', width:'15%'},
            { field: 'updatedBy', headerText: 'Last Modified By', width:'15%'},
            { field: 'updatedDate', headerText: 'Last Modified Date', type:'datetime', width:'15%'},
            { headerText: 'Actions', width: '100px', template: this.getActionItems},
        ];
    
        return <Grid 
            columns={columns}
            data={this.getAdjustedRoles()}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    render() {
        const { isFetching, verifyPermission, browserHistory, roleDescriptions } = this.props,
            { alreadyFetched } = this.state;
        // permissions based variables
        let addButton = (
            <Button 
                mode='add' 
                onClick={() => {
                    if (verifyPermission(SITE_MANAGEROLES)){
                        browserHistory.push('/portalManagement/roles/defineRoles');
                    } else {
                        notifyError('You are not authorized to access this functionality')
                    }
                }}
                toolTip='Add new role'/>
        );

        let contentMarkup = (
            <div style={{ margin: '5vh auto' }}>
                <Loader loadingItem="Roles" />
            </div>
        );

        if (alreadyFetched || !isFetching) {
            if (roleDescriptions.length !== 0) {
                contentMarkup = this.getGrid();
            } else {
                contentMarkup = (
                    <div style={{ margin: '5vh auto' }}>
                        <NoResults />
                    </div>
                );
            }
        }

        return (
            <div className="list-container-arius" style={{maxHeight: 'calc(100vh - 106px)', overflowY: 'auto'}}>
                <div style={[style.row, style.userToolbar]}>
                    <div style={style.row}>
                        <div style={style.inputLabel}>
                        Quick Search
                        </div>
                        <input style={style.input} type="text" onChange={this.searchRoles} />
                    </div>
                </div>
                <div className="list-header-arius">
                    <h4>Roles</h4>
                    {addButton}
                </div>
                {contentMarkup}
            </div>
        )
    }
}

export default radium(ViewRoles);
