import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import { 
    Form,
    Card,
    Collapse,
    InputGroup,
} from 'react-bootstrap';
import Feature from '@app/utilities/routing/routeSafeFeature';
import * as Colors from '../../../utilities/colors';
import * as Helpers from '../../../utilities/helpers';
import { fetchSegments } from '../../../actions/analysis/segment.actions';
import {
    fetchTasks,
    initiateTask,
} from '../../../actions/analysis/analysisTasks.actions';
import {
    deleteProject,
    checkOutProject,
    selectProject,
    fetchProjects,
    undoProjectCheckout,
    exportSignoffReport
} from '../../../actions/analysis/project.actions';
import { fetchWorkspace } from '../../../actions/analysis/workspace.actions';
import { showModal } from '../../../actions/modal.actions';
import {
    notifyError,
    notify,
    alertCustom,
    notifySuccess,
} from '../../../utilities/notifier';
import {
    WORKSPACE_MANAGEJOBS,
    WORKSPACE_DIRECTIMPORT,
    WORKSPACE_MODIFYTABLELIBRARY,
    createPermissionChecker,
    WORKSPACE_READPROJECT,
    WORKSPACE_MODIFYPROJECT,
    WORKSPACE_CREATEPROJECT,
    WORKSPACE_DELETEPROJECT,
} from '../../../utilities/permissions';
import { getAppSettings } from '../../../config';
import DetailCommentsModal from '../../shared/presentational/detailCommentsModal';
import UniversalFilter, { universalFilter } from '../../shared/presentational/universalFilter';
import DbUpdateModal from './dbUpdateModal';
import { databaseUpdate } from '../../../actions/analysis/workspace.actions';
import PlaceHolderMessage from '../../shared/presentational/placeHolderMessage';
import NoResults from '../../shared/presentational/noResults';
import PurpleButton from '@app/shared/presentational/purpleButton';
import ProjectsGrid from './projects/projectsGrid';
import { Button } from '@arius';
import ProjectBulkDeleteEditor from './projectBulkDeleteEditor';

const style = {
    projectLink: {
        display: 'flex',
        alignSelf: 'inherit',
        margin: '0px 0px 0px 20px',
        color: '#337AB7',
        fontWeight: 'lighter',
        textDecoration: 'underline',
        fontSize: 'small',
    },
    clickable: {
        cursor: 'pointer',
    },
    inputHanger: {
        position: 'relative',
        zIndex: -5000,
        right: '500px',
        width: '0px',
        height: '0px',
    },
};

const ProjectNameRestrictedChars = '\\ / : * ? " < > |';
function projectNameContainsRestrictedChars(str) {
    var reg = new RegExp('[\\\\/:\*\?\"<>\|]');
    return reg.test(str);
}

class AnalysisProjectList extends Component {
  static propTypes = {
    workspaces: PropTypes.array,
    currentWorkspace: PropTypes.object,
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
    isFetching: PropTypes.bool,
    workspaceId: PropTypes.number,
    params: PropTypes.object,
    projectsHaveDataShape: PropTypes.bool,
    longRunningOperationsStatus: PropTypes.array,
    getLongRunningOperationsStatus: PropTypes.func,
    clearLongRunningOperationsStatus: PropTypes.func,
    initiateBatchProcessHandler: PropTypes.func,
    apiUrl: PropTypes.object,
    getCurrentUdoVersion: PropTypes.func,
    cloneProject: PropTypes.func,
    currentUdoVersionNbr: PropTypes.number,
    runRollForward: PropTypes.func,
    fetchScriptsHandler: PropTypes.func,
    scripts: PropTypes.array,
    currentProjects: PropTypes.array,
    segments: PropTypes.array,
    isFetchingWorkspaces: PropTypes.bool,
    alreadyFetched: PropTypes.bool,
    checkingUniqueness: PropTypes.bool,
    verifyPermission: PropTypes.func,
    tableHeight: PropTypes.string,
    getProjectVersionsHandler: PropTypes.func,
    getCheckoutStatusHandler: PropTypes.func,
    updateProjectVersionHandler: PropTypes.func,
    projectVersions: PropTypes.array,
    update: PropTypes.func,
    remainingHeight: PropTypes.number,
    valuationDate: PropTypes.string,
    allProjects: PropTypes.array,
    copyingProject: PropTypes.object,
    copiedProjectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  static columnHeader = (
    <span style={{color: 'red'}}>
      Hey Now
    </span>
  )
  static filterDetailsConfig = {
    projectId: {
      displayName: 'Project ID',
      placeholder: 'enter project id',
    },
    projectName: {
      displayName: 'Project Name',
      placeholder: 'enter project name',
    },
    valuationDate: {
      displayName: 'Valuation Date',
      placeholder: 'enter valuation date',
    },
    dataShape: {
      displayName: 'Data Structure',
      placeholder: 'enter data structure',
    },
    checkedOutUser: {
      displayName: 'Checkout Out By',
      placeholder: 'enter name',
    },
    checkedOutTimestamp: {
      displayName: 'Checked Out Date',
      placeholder: 'enter date',
    },
    updatedBy: {
      displayName: 'Last Updated By',
      placeholder: 'enter name',
    },
    updatedDate: {
      displayName: 'Last Updated Date',
      placeholder: 'enter date',
    },
    status: {
      displayName: 'Status',
      placeholder: 'enter status',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      randomNumber: (Math.floor(Math.random() * 9) + 1) * 10,
      currentProjects: [],
      allProjects: [],
      columnSelected: null,
      shouldRenderCopyProjectMsg: false,
      isDescending: true,
      valuationDates: [],
      scrolledToProject: false,
      selectedFilters: [],
      longRunningOperationsStatusModalOpen: false,
      selectedScript: 'select',
      selectedTask: 'select',
      openPanel: null,
      currentSegments: [],
      projectedItem: {},
      searchFilterOpen: false,
      projectSearchFilter: {
        projectName: '',
        valuationDate: '',
        dataShape: '',
        checkedOutBy: '',
        checkedOutTimestamp: '',
        updatedBy: '',
        updatedDate: '',
        status: '',
      },
      projectFilterPanelHeight: 0,
      projectFilter: {
        projectId: '',
        projectName: '',
        valuationDate: '',
        dataShape: '',
        checkedOutUser: '',
        checkedOutTimestamp: '',
        updatedBy: '',
        updatedDate: '',
        status: '',
      },
      filteredProjects: [],
      useFilteredProjects: false,
      currentWorkspace: PropTypes.object,
      render: 0,
      noFilteredMatches: false,
      selectedField: null,
      dbToUpdateId: null,
      dbModalOpen: false,
      selectedProjectIds: []
    };
    // if (props && props.currentWorkspace && props.currentWorkspace.id) {
    //   if (!(props && props.params && props.params.workspaceId)) {
    //     browserHistory.push(
    //       `/analysis/analysisTab/workspaces/${props.currentWorkspace.id}/projects`
    //     );
    //   }
    // }

    this.quickSearchHandler = this.quickSearchHandler.bind(this);
    this.updateProjectName = this.updateProjectName.bind(this);
    this.deleteProjectHandler = this.deleteProjectHandler.bind(this);
    this.viewProjectSegments = this.viewProjectSegments.bind(this);
    this.createNewProject = this.createNewProject.bind(this);
    this.copyProjectHandler = this.copyProjectHandler.bind(this);
    this.runScript = this.runScript.bind(this);
    this.runTask = this.runTask.bind(this);
    this.toggleSegmentPanel = this.toggleSegmentPanel.bind(this);
    this.undoCheckoutHandler = this.undoCheckoutHandler.bind(this);
    this.checkOutHandler = this.checkOutHandler.bind(this);
    this.openReadOnlyHandler = this.openReadOnlyHandler.bind(this);
    this.seeVersions = this.seeVersions.bind(this);
    this.toggleVersionModal = this.toggleVersionModal.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
    this.toggleSearchFilter = this.toggleSearchFilter.bind(this);
    this.setParentState = this.setParentState.bind(this);
    this.validateText = this.validateText.bind(this);
    this.selectField = this.selectField.bind(this);
    this.openDatabaseUpdateModal = this.openDatabaseUpdateModal.bind(this);
    this.closeDbUpdateModal = this.closeDbUpdateModal.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updateDb = this.updateDb.bind(this);
    this.renderCopyProjectMsg = this.renderCopyProjectMsg.bind(this);
    this.reviewProjectHandler = this.reviewProjectHandler.bind(this);
    this.exportSignoffReport = this.exportSignoffReport.bind(this);
    this.selectProjects = this.selectProjects.bind(this);
  }

  componentWillMount() {
    const {
      userKey,
      workspaceId,
      getCurrentUdoVersion,
      fetchScriptsHandler,
      currentWorkspace,
      verifyPermission,
      currentProjects,
    } = this.props;
    let databaseId = null;

    if (workspaceId) {
      getCurrentUdoVersion(userKey, workspaceId);
      databaseId = workspaceId;
    }

    if (currentProjects) {
        this.setState({ 
            currentProjects,
            alreadyFetched: currentProjects.length ? true : this.state.alreadyFetched 
        });
    }

    if (currentWorkspace && !databaseId) {
      databaseId = currentWorkspace.id;
      getCurrentUdoVersion(userKey, databaseId);
    }

    if (databaseId) {
      if (verifyPermission(WORKSPACE_MANAGEJOBS)) {
        fetchScriptsHandler({ userKey, databaseId });
      }
    }
  }

  componentDidMount() {
    const {
      dispatch,
      userKey,
      currentWorkspace,
      currentProjects,
      workspaceId,
      params,
      getCurrentUdoVersion,
    } = this.props;
    
    if (workspaceId) {
      getCurrentUdoVersion(userKey, workspaceId);
      dispatch(fetchWorkspace(userKey, workspaceId));
    } else if (
      currentWorkspace && currentWorkspace.id && currentWorkspace.isOnline
    ) {
      getCurrentUdoVersion(userKey, currentWorkspace.id);
      // dispatch(tryFetchProjects(userKey, currentWorkspace.id));
    } else if (params.workspaceId && !currentWorkspace) {
      getCurrentUdoVersion(userKey, currentWorkspace.id);
      dispatch(fetchWorkspace(userKey, workspaceId));
      // dispatch(tryFetchProjects(userKey, currentWorkspace.id));
    }

    if (currentProjects) {
      this.setState({ currentProjects, });
    }

    dispatch(fetchTasks(userKey));
  }

  componentWillReceiveProps(nextProps) {
    const {
      userKey,
      currentProjects,
      currentWorkspace,
      projectVersions,
      valuationDate,
      fetchScriptsHandler,
      copiedProjectId,
    } = this.props;

    if (currentProjects !== nextProps.currentProjects) {
      this.setState({
        currentProjects: nextProps.currentProjects, selectedProjectIds: [], },
        () => this.renderCopyProjectMsg(nextProps.copiedProjectId),
      );
    }

    if (nextProps.copiedProjectId && copiedProjectId !== nextProps.copiedProjectId) {
      this.setState({ shouldRenderCopyProjectMsg: true });
    }

    if (currentWorkspace !== nextProps.currentWorkspace && nextProps.currentWorkspace) {
      fetchScriptsHandler({ userKey, databaseId: nextProps.currentWorkspace.id });
    }

    if (valuationDate !== nextProps.valuationDate) {
      Helpers.resetScrollTop('projects-table-analysis');
    }

    if (nextProps.projectVersions && projectVersions !== nextProps.projectVersions) {
      const newProjectVersions = nextProps.projectVersions.sort(this.numericalSorter);
      this.setState({ projectedItem: this.projectItem(newProjectVersions) });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { projectFilterPanelHeight, projectFilter, filteredProjects } = this.state;
    if (filteredProjects !== nextState.filteredProjects) return true;
    if (projectFilter !== nextState.projectFilter || projectFilterPanelHeight !== nextState.projectFilterPanelHeight) {
      return false;
    } else {
      return true;
    }
  }

  componentDidUpdate(prevProps) {
    const { getCurrentUdoVersion, currentProjects } = this.props;

    if (currentProjects !== prevProps.currentProjects) {
      this.filterProjects();
    }

    if (
      prevProps.currentWorkspace !== this.props.currentWorkspace &&
      this.props.currentWorkspace &&
      this.props.currentWorkspace.isOnline
    ) {
      const { userKey, currentWorkspace } = this.props;
      getCurrentUdoVersion(userKey, currentWorkspace.id);
      // dispatch(tryFetchProjects(userKey, currentWorkspace.id));
    }

    // if (
    //   prevState.currentProjects !== this.state.currentProjects &&
    //   this.state.projectsHaveDataShape
    // ) {
    //   if (params.projectId && !this.state.scrolledToProject) {
    //     const project = document.getElementById(
    //       `projectListItem${params.projectId}`
    //     );
    //     if (project) {
    //       project.scrollIntoView(true);
    //       this.setState({ scrolledToProject: true });
    //     }
    //   }
    // }
  }

  getAvailableValuesForColumn(column) {
    const { currentProjects } = this.state;
    const values = [];
    currentProjects.forEach(project => {
      if (!values.includes(project[column])) {
        values.push(project[column]);
      }
    });
    return values;
  }

  setParentState(newState) {
    const newStateMap = {
      projectFilter: newState.filter,
      filteredProjects: newState.filteredMatches,
      useFilteredProjects: newState.useFilteredMatches,
      noFilteredMatches: newState.noFilteredMatches,
    };
    this.setState(newStateMap);
  }

  viewProjectSegments(projectId, e) {
    e.stopPropagation();
    const { dispatch, currentProjects, currentWorkspace, browserHistory } = this.props,
      project = currentProjects.find(p => p.projectId === projectId);
    dispatch(selectProject(project));
    browserHistory.push(
      `/analysis/workspaces/${currentWorkspace.id}/projects/${projectId}/segments`
    );
  }

  numericalSorter(a, b) {// TODO remove now that syncfusion is used?
    if (a.versionId > b.versionId) return -1;
    if (a.versionId < b.versionId) return 1;
    return 0;
  }

  quickSearchHandler(e) {
    const searchText = e.target.value.toLowerCase(),
      { allProjects } = this.state;
    let currentProjects = [];

    if (searchText.trim() === '') {
      currentProjects = allProjects;
    } else {
      currentProjects = allProjects.filter(project => {
        if (
          project.projectName.toLowerCase().includes(searchText) ||
          project.projectId.toString().includes(searchText) ||
          project.exposureType.toLowerCase().includes(searchText)
        ) {
          return true;
        } else {
          return false;
        }
      });
    }
    this.setState({ currentProjects, selectedProjectIds: [], });
  }

    deleteProjectHandler(project) {
        const { dispatch, currentProjects, currentWorkspace, verifyPermission } = this.props;
        let authorized = verifyPermission(WORKSPACE_DELETEPROJECT);
        let id = project.projectId;
        let isCheckedOut = !!project.checkedOutUser;

        if (!authorized) {
            notifyError('You are not authorized to access this functionality');
            return;
        }

        if (isCheckedOut) {
            notifyError('You cannot delete a project that is currently checked out');
            return;
        }

        if (project.locked) {
            notifyError('You cannot delete this project because it is locked');
            return;
        }

        if (currentProjects.length !== 0) {
        const selectedProject = currentProjects.find(
            project => project.projectId === id
        ),
            deleteMessageItems = [
            <li key={selectedProject.projectId} style={{ fontWeight: 800 }}>
                {selectedProject.projectName}
            </li>,
            ];

        deleteMessageItems.unshift(
            'Are you sure you want to delete the following projects?'
        );

        const yesClickHandler = () => {
            const { userKey } = this.props;
            dispatch(deleteProject(userKey, currentWorkspace.id, id));
        };
        const noClickHandler = () => {};
        const action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
        }
    }

    reviewProjectHandler(project) {
        const { dispatch, workspaceId, browserHistory } = this.props;
        dispatch(selectProject(project));
        browserHistory.push(
        `/analysis/analysisTab/workspaces/${workspaceId}/projects/${project.projectId}/review`
        );
    }

  toggleSegmentPanel(e, panel) {
    e.stopPropagation();
    const { openPanel } = this.state,
      { dispatch, userKey, currentWorkspace } = this.props;
    if (openPanel === panel) {
      this.setState({ openPanel: null });
    } else {
      dispatch(fetchSegments(userKey, currentWorkspace.id, panel));
      this.setState({ openPanel: panel });
    }
  }

    selectProjects(selected) {
        this.setState({selectedProjectIds: selected.map((p)=>p.projectId)});
    }

  createNewProject() {
    const wsId = this.props.currentWorkspace.id;
    if (!wsId) {
      return;
    }
    getAppSettings().then(settings =>
      window.open(
        `arius:${settings.baseServiceUrl}/integration/projects/${wsId}`
      )
    );
  }

    lowerCaseFirstLetter(string) {
        return string[0].toLowerCase() + string.slice(1);
    }

    copyProjectHandler(project) {
        const { copyingProject, verifyPermission } = this.props;

        let authorized = verifyPermission(WORKSPACE_CREATEPROJECT);

        if (!authorized) {
            notifyError('You are not authorized to access this functionality');
            return;
        }

        let id = project.projectId;
        let projectSettings = JSON.parse(project.projectSettings);
        let keys = Object.keys(projectSettings);
        let newobj = {};
        let length = keys.length,key;

        while (length--) {
            key = keys[length];
            newobj[this.lowerCaseFirstLetter(key)] = projectSettings[key];
        } 

        const { 
            projectName,
            numberOfExposurePeriods,
            numberOfDevelopmentPeriods,
            lengthOfExposurePeriods,
            lengthOfDevelopmentPeriods,
            yearOfFirstExposurePeriod,
            endingMonthOfFirstExposurePeriod,
            firstDevelopmentAgeInMonths,
            lengthOfLastCalendarPeriodInMonths,
            exposurePeriodType,
            firstExposurePeriodIncludesAllPrior,
        } = newobj,
        body = {
            projectId: id,
            projectName,
            numberOfExposurePeriods,
            numberOfDevelopmentPeriods,
            lengthOfExposurePeriods,
            lengthOfDevelopmentPeriods,
            yearOfFirstExposurePeriod,
            endingMonthOfFirstExposurePeriod,
            firstDevelopmentAgeInMonths,
            lengthOfLastCalendarPeriodInMonths,
            exposurePeriodType,
            firstExposurePeriodIncludesAllPrior,
        };

        if (copyingProject === project) {
            notifyError('A new project is already being created from that copy');
        } else {
            notify('A new project is being created from copy');
            this.props.cloneProject(this.props.userKey, project, body);
        }
    }

    runScript() {
        const { userKey, runRollForward, currentWorkspace } = this.props;
        const { currentProjects, selectedScript, selectedProjectIds } = this.state;
        const scriptId = selectedScript;
        let { workspaceId } = this.props;
        if (!workspaceId) {
            workspaceId = currentWorkspace.id;
        }
        if (currentProjects.length) {
            const filteredProjects = currentProjects.filter(cp => selectedProjectIds.includes(cp.projectId));
            const projectsIds = filteredProjects.map(cp => cp.projectId);
            if (projectsIds.length) {
                runRollForward({
                    userKey,
                    databaseId: workspaceId,
                    scriptId: Number(scriptId),
                    projects: projectsIds,
                });
                this.setState({ selectedScript: 'select', selectedProjectIds: []});
            } else {
                notifyError('Must select at least one project before running script');
            }
        } else {
            notifyError('Must select at least one project before running script');
        }
    }

    runTask() {
        const { userKey, currentWorkspace, dispatch } = this.props;
        const { currentProjects, selectedTask, selectedProjectIds } = this.state;
        let { workspaceId } = this.props;

        if (!workspaceId) {
        workspaceId = currentWorkspace.id;
        }
        if (currentProjects.length) {
        const filteredProjects = currentProjects.filter(cp => selectedProjectIds.includes(cp.projectId));
        const projectIds = filteredProjects.map(cp => cp.projectId);
        if (projectIds.length) {
            dispatch(initiateTask(userKey, workspaceId, {
                projectIds: projectIds,
                databaseId: workspaceId,
                taskId: selectedTask.taskId
            }));
            notifySuccess('Task initiated');
            this.setState({ selectedTask: 'select', selectedProjectIds: [] });
        } else {
            notifyError('Must select at least one project before running task');
        }
        } else {
            notifyError('Must select at least one project before running task');
        }
    }


  selectScript(e) {
    this.setState({ selectedScript: e.target.value });
  }

  selectTask(e) {
    this.setState({ selectedTask: e.target.value });
  }

  navToStatus() {
    const { currentWorkspace, browserHistory} = this.props;
    if (currentWorkspace) {
      browserHistory.push(`/analysis/automation/${currentWorkspace.id}/status`);
    }
  }

  undoCheckoutHandler(project) {
    const { userKey, dispatch, currentWorkspace, verifyPermission } = this.props;

    let authorized = verifyPermission(WORKSPACE_MODIFYTABLELIBRARY) ||
        verifyPermission(WORKSPACE_MODIFYPROJECT)||
        verifyPermission(WORKSPACE_READPROJECT);

    if (!authorized) {
        notifyError('You are not authorized to access this functionality');
        return;
    }


    const undoCheckoutMessageItems = [
      'Are you sure you want to undo the checkout of the following project?',
      <li key={project.projectId} style={{ fontWeight: 800 }}>
        {project.projectName}
      </li>,
      <br />,
      'Note: this action will prevent ',
      <span style={{ fontWeight: 800}}>{project.checkedOutUser}</span>,
      <br />,
      'from saving any changes they made to this project back to the database.',
    ];

    const yesClickHandler = () => {
      dispatch(undoProjectCheckout(userKey, currentWorkspace.id, project.projectId));
    };
    const noClickHandler = () => {};
    const action = showModal(
      'confirmation',
      undoCheckoutMessageItems,
      yesClickHandler,
      noClickHandler
    );
    dispatch(action);
  }
  

    openReadOnlyHandler(project) {
        const { currentWorkspace, verifyPermission } = this.props;

        let authorized = verifyPermission(WORKSPACE_MODIFYTABLELIBRARY) ||
            verifyPermission(WORKSPACE_MODIFYPROJECT)||
            verifyPermission(WORKSPACE_READPROJECT);

        if (!authorized) {
            notifyError('You are not authorized to access this functionality');
            return;
        }

        window.location.href = `arius:/integration/projects/${currentWorkspace.id}/${project.projectId}/0`
    }


    checkOutHandler(project) {
        const { userKey, dispatch, currentWorkspace, verifyPermission} = this.props;

        let authorized = verifyPermission(WORKSPACE_MODIFYTABLELIBRARY) ||
            verifyPermission(WORKSPACE_MODIFYPROJECT)||
            verifyPermission(WORKSPACE_READPROJECT);

        if (!authorized) {
            notifyError('You are not authorized to access this functionality');
            return;
        }

        if (project.locked) {
            notifyError('You cannot checkout this project because it is locked');
            return;
        }

        if (project.checkoutStatus === 'Owned') {
            window.location.href = `arius:/integration/projects/${currentWorkspace.id}/${project.projectId}/2`;
            dispatch(fetchProjects(userKey, currentWorkspace.id));
        } else {
            dispatch(checkOutProject(userKey, currentWorkspace.id, project.projectId));
        }
    }

    seeVersions(project) {
        const { getProjectVersionsHandler, userKey, workspaceId } = this.props;
        getProjectVersionsHandler(userKey, workspaceId, project.projectId);
        this.toggleVersionModal(project);
    }

  updateHandler(comment, versionId) {
    const { userKey, workspaceId, updateProjectVersionHandler } = this.props,
      projectForVersions = { ...this.state.projectForVersions },
      projectedItem = JSON.parse(JSON.stringify(this.state.projectedItem)),
      projectId = projectForVersions.projectId,
      req = {
        workspaceId,
        projectId,
        versionId,
        comment,
      },
      found = projectedItem.data.find(d => d.Version === versionId);
    if (found) {
      found.Comment = comment;
      projectedItem.data.forEach((d, index) => {
        d.Actions = this.state.projectedItem.data[index].Actions;
      });
    }
    this.setState({ projectedItem });
    updateProjectVersionHandler(userKey, workspaceId, projectId, req);
  }

  toggleVersionModal(project) {
    const { projectVersionsModalOpen } = this.state;
    if (project) {
      this.setState({ projectForVersions: project, projectVersionsModalOpen: !projectVersionsModalOpen });
    } else {
      this.setState({ projectVersionsModalOpen: !projectVersionsModalOpen });
    }
  }

  alphabetizeItems(items) {
    if (items && Array.isArray(items)) {
      return items.sort((a, b) => {
        if (
          a.scriptName.toString().toLowerCase() <
          b.scriptName.toString().toLowerCase()
        ) {
          return -1;
        } else {
          return 1;
        }
      });
    } else {
      return [];
    }
  }

  projectItem(item) {
    const data = item.map((d, idx) => { // eslint-disable-line
      return {
        Version: d.versionId,
        'Updated By': d.updatedBy,
        'Updated Date': d.updatedDate,
        Comment: d.comment,
        // Actions: actions,
        id: d.versionId,
      };
    });
    let name;
    if (item[0]) name = item[0].projectName;
    return {
      name,
      data,
      commentFieldEditable: true,
    };
  }

  toggleSearchFilter() {
    const { searchFilterOpen } = this.state;
    this.setState({ searchFilterOpen: !searchFilterOpen });
  }

  searchProjects() {
  }

  filterProjects(e, field) {
    const input = e || '',
      { projectFilter } = this.state,
      { currentProjects } = this.props,
      newState = {};
    newState.projectFilter = { ...projectFilter };
    newState.selectedProjectIds = [];
    const localFilter = newState.projectFilter,
      filteredProjects = universalFilter(currentProjects, projectFilter, field, input);
    if (field) localFilter[field] = input;
    const filterValues = Object.values(localFilter),
      foundFilterInput = filterValues.find(fv => fv.length);
    newState.useFilteredProjects = !!foundFilterInput || false;
    newState.filteredProjects = filteredProjects;
    newState.noFilteredMatches = !filteredProjects.length || false;
    this.setState(newState);
  }
  
  validateText(projectName, projectId) {
    const { currentProjects } = this.props,
      foundProject = currentProjects.find(ap => ap.projectId === projectId),
      filteredProjects = currentProjects.filter(ap => ap.dataShape === foundProject.dataShape && ap.projectId !== projectId),
      nameAlreadyExists = filteredProjects.find(fp => fp.projectName === projectName),
      checkedOut = foundProject.checkedOutUser;
    if (nameAlreadyExists) {
      notifyError('Project with that name, valuation date, and data structure already exists');
      return false;
    } else if (projectName === '') {
      notifyError(`Name cannot be blank`);
    } else if (checkedOut) {
      notifyError(`Cannot change name when project is checkout out.  Checked out by: ${checkedOut}`);
      return false;
    } else if (projectNameContainsRestrictedChars(projectName)) {
      notifyError("Project name can't contain any of the following characters: " + ProjectNameRestrictedChars);
      return false;
    } else return true;
  }

    updateProjectName(projectId, newProjectName) {
        const { userKey, getCheckoutStatusHandler, currentProjects, currentWorkspace } = this.props;
        let project = currentProjects.find(p => p.projectId === projectId);
        let id = project.projectId;
        let projectSettings = JSON.parse(project.projectSettings);
        let keys = Object.keys(projectSettings);
        let newobj = {};
        let length = keys.length;
        let key;
        let lowerCaseFirstLetter = (s) => s[0].toLowerCase() + s.slice(1);

        while (length--) {
            key = keys[length];
            newobj[lowerCaseFirstLetter(key)] = projectSettings[key];
        } 

        const { 
            numberOfExposurePeriods,
            numberOfDevelopmentPeriods,
            lengthOfExposurePeriods,
            lengthOfDevelopmentPeriods,
            yearOfFirstExposurePeriod,
            endingMonthOfFirstExposurePeriod,
            firstDevelopmentAgeInMonths,
            lengthOfLastCalendarPeriodInMonths,
            exposurePeriodType,
            firstExposurePeriodIncludesAllPrior,
        } = newobj,
        body = {
            projectId: id,
            projectName: newProjectName,
            numberOfExposurePeriods,
            numberOfDevelopmentPeriods,
            lengthOfExposurePeriods,
            lengthOfDevelopmentPeriods,
            yearOfFirstExposurePeriod,
            endingMonthOfFirstExposurePeriod,
            firstDevelopmentAgeInMonths,
            lengthOfLastCalendarPeriodInMonths,
            exposurePeriodType,
            firstExposurePeriodIncludesAllPrior,
        };
        getCheckoutStatusHandler(userKey, currentWorkspace.id, projectId, body);
    }

  selectField(id) {
    this.setState({ selectedField: id });
  }

  evalInput() {
    const input = document.getElementById('db-update-input-file');
    if (input) {
      input.click();
      clearInterval(this.watchForIt);
    } 
  }

  watchForInput() {
    this.watchForIt = setInterval(() => this.evalInput(), 200);
  }

  uploadFile() {
    if (this.props.currentWorkspace) {
      let input = document.getElementById('db-update-input-file');
      const inputHanger = document.getElementById('input-hanger');
      if (!input) {
        input = document.createElement('input');
        input.id = 'db-update-input-file';
        input.style.height = '0px';
        input.style.width = '0px'; 
        input.setAttribute('type', 'file');
        input.addEventListener('change', () => this.updateDb());
        inputHanger.appendChild(input);
      }
      input.click();
    } else {
      notifyError('Must have database selected');
    }
  }

  openDatabaseUpdateModal(id) {
    this.setState({ dbModalOpen: true, dbToUpdateId: id }, () => this.watchForInput());
  }

  closeDbUpdateModal() {
    this.setState({ dbModalOpen: false, dbToUpdateId: null });
  }

  updateDb() {
    const input = document.getElementById('db-update-input-file'),
      { dispatch, currentWorkspace, userKey } = this.props;
    let file;
    if (input) file = input.files[0];
    if (file) {
      const data = new FormData();
      data.append('file', file);
      dispatch(databaseUpdate(userKey, currentWorkspace.id || currentWorkspace.workspaceId, data));
    }
    input.parentNode.removeChild(input);
  }

  renderCopyProjectMsg(id) {
    const { shouldRenderCopyProjectMsg } = this.state;
    if (!shouldRenderCopyProjectMsg) return;
    const {
      filteredProjects,
      currentProjects,
      useFilteredProjects,
    } = this.state,
      projects = useFilteredProjects ? filteredProjects : currentProjects,
      project = projects.find(p => p.projectId === id);
    if (project) {
      alertCustom( 
        `<a href="#projectListItem${id}">Scroll to ${project.projectName}</a>`,
        'custom'
      );
    }
    this.setState({ shouldRenderCopyProjectMsg: false });
  }

  exportSignoffReport() {
    const {
      currentWorkspace,
      valuationDate,
      dispatch,
      userKey,
    } = this.props;
    const databaseId = currentWorkspace ? currentWorkspace.id : null;

    if (!databaseId) {
        notifyError('Please select a database');
        return;
    } 
    dispatch(exportSignoffReport(userKey, databaseId, valuationDate));
  }

  getSelectedProjects(){
      
  }

  getBatchButtons() {
    const {
        currentWorkspace,
        analysisTasks,
      } = this.props,
      scripts = this.alphabetizeItems(this.props.scripts) || [],
      permissions = currentWorkspace ? currentWorkspace.permissions : [],
      verifyPermission = createPermissionChecker(permissions),
      {
        selectedScript,
        selectedTask,
      } = this.state;
    let tasks = analysisTasks ? analysisTasks : [];

      return (
        <Feature flag="batchButtons">
            <div style={{ display: 'flex', marginLeft: '10px' }}>
            <Form.Group>
            <InputGroup>
                <InputGroup.Prepend>
                <Button
                    // bsclass="btn btn-default custom-analysis-button"
                    variant='outline-secondary'
                    onClick={this.runScript}
                    //style={{ display: 'flex', alignItems: 'center', height: '2.5em', whiteSpace: 'nowrap'}}
                    style={{ whiteSpace: 'nowrap'}}
                    disabled={selectedScript === 'select'}
                >
                    Run Script
                </Button>
                </InputGroup.Prepend>
                <Form.Control
                disabled={!verifyPermission(WORKSPACE_MANAGEJOBS)}
                bsclass="form-control custom-analysis-select"
                as="select"
                placeholder="select"
                onChange={e => this.selectScript(e)}
                value={selectedScript}
                >
                <option value="select">select a script to run</option>
                {scripts.map((s, idx) => (
                    <option key={`o_${idx}`} value={s.batchScriptId}>{s.scriptName}</option>
                ))}
                </Form.Control>
            </InputGroup>
            </Form.Group>
            <Form.Group style={{marginLeft:'10px'}}>
            <InputGroup>
                <InputGroup.Prepend>
                <Button
                    // bsclass="btn btn-default custom-analysis-button"
                    variant='outline-secondary'
                    onClick={verifyPermission(WORKSPACE_MANAGEJOBS) ? this.runTask : () => notifyError('You are not authorized to access this functionality')}
                    //style={{ display: 'flex', alignItems: 'center', height: '2.5em', whiteSpace: 'nowrap' }}
                    style={{ whiteSpace: 'nowrap'}}
                    disabled={selectedTask === 'select'}
                >
                    Run Task
                </Button>
                </InputGroup.Prepend>
                <Form.Control
                disabled={!verifyPermission(WORKSPACE_MANAGEJOBS)}
                bsclass="form-control custom-analysis-select"
                as="select"
                placeholder="select"
                onChange={e => this.selectTask(e)}
                value={selectedTask}
                >
                <option value="select">select a task to run</option>
                {tasks.map((s, idx) => (
                    <option key={`o_${idx}`} value={s.actionId}>{s.name}</option>
                ))}
                </Form.Control>
            </InputGroup>
            </Form.Group>
            </div>
        </Feature>
      )
  }

  getStatusLink() {
      return (
        <div style={style.projectLink}>
            <span style={style.clickable} onClick={() => this.navToStatus()}>
            See status reports
            </span>
         </div>
      )
  }

    getPurpleButtons() {
        const {
            currentWorkspace,
            isFetchingCsv,
            userKey,
            refreshProjectsHandler
        } = this.props,

        { selectedProjectIds } = this.state,
        permissions = currentWorkspace ? currentWorkspace.permissions : [],
        verifyPermission = createPermissionChecker(permissions),
        errorMSG = 'You are not authorized to access this functionality';
        let addProject = verifyPermission(WORKSPACE_CREATEPROJECT)
        ? this.createNewProject
        : () => notifyError(errorMSG);

        return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
            <ProjectBulkDeleteEditor
              selectedProjectIds= {selectedProjectIds}
              userKey={userKey}
              canDelete={verifyPermission(WORKSPACE_DELETEPROJECT)}
              refreshProjectsHandler={refreshProjectsHandler}
              currentWorkspace={currentWorkspace}
              verifyPermission={verifyPermission} />
            <PurpleButton 
                id="downloadReport" 
                clickHandler={this.exportSignoffReport} 
                message="Signoff Report" 
                type="download"
                waiting={isFetchingCsv}>
            </PurpleButton>
            <PurpleButton 
                id="directImport" 
                clickHandler={verifyPermission(WORKSPACE_DIRECTIMPORT) ? this.uploadFile : () => notifyError('You are not authorized to access this functionality')}
                message="Direct Import" 
                type="import">
            <input id="file-input" type="file" name="name" style={{ display: 'none' }} />
            </PurpleButton>        
            <PurpleButton 
                id="addNewProject" 
                clickHandler={addProject}
                message="Add Project" 
                type="add">
            </PurpleButton>
        </div>
        )
    }

    getProjectFilterPanel() {
        const { searchFilterOpen, projectFilter } = this.state;
        const projectSearchFilterJSX = (
            <UniversalFilter
                filter={projectFilter}
                arrayToFilter={this.props.currentProjects}
                setParentState={this.setParentState}
                filterDetailsConfig={AnalysisProjectList.filterDetailsConfig} />
        );

        return (
        <Collapse id="projectFilterPanel" in={searchFilterOpen}>
            <Card
            style={{ 
                marginBottom: '0px !important',
                borderBottom: searchFilterOpen ? '2px solid #BDBDBD' : 'none',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                color: Colors.purple,
                width: '100%',
                paddingRight: '0em 2em',
            }}
            className="panel noborder">
            {projectSearchFilterJSX}
            </Card>
        </Collapse>
        )
    }

    getGrid() {
        const {
            currentWorkspace,
            params,
            userKey,
            valuationDate,
            currentUdoVersionNbr,
            refreshProjectsHandler,
            verifyPermission
          } = this.props,
          {
            searchFilterOpen,
            filteredProjects,
            useFilteredProjects,
            selectedProjectIds
          } = this.state,
          currentProjects = useFilteredProjects ? filteredProjects : this.state.currentProjects;

        return <ProjectsGrid 
            valuationDate={valuationDate}
            userKey={userKey}
            data={currentProjects}
            currentWorkspace={currentWorkspace}
            params={params}
            currentUdoVersionNbr={currentUdoVersionNbr}
            selectHandler={this.selectProjects}
            searchFilterOpen={searchFilterOpen}
            checkOutHandler={this.checkOutHandler}
            openReadOnlyHandler={this.openReadOnlyHandler}
            copyProjectHandler={this.copyProjectHandler}
            deleteProjectHandler={this.deleteProjectHandler}
            canModifyStatus={verifyPermission(WORKSPACE_READPROJECT)}
            seeVersions={this.seeVersions}
            reviewProjectHandler={this.reviewProjectHandler}
            undoCheckoutHandler={this.undoCheckoutHandler}
            refreshProjectsHandler={refreshProjectsHandler}
            selectedProjectIds={selectedProjectIds}
            validateText={this.validateText}
            updateProjectName={this.updateProjectName}
        />
    }

  render() {
    const {
        dispatch,
        isFetchingWorkspaces,
        isFetchingProjects,
        alreadyFetched,
        userKey,
      } = this.props,
      {
        projectVersionsModalOpen,
        projectedItem,
        filteredProjects,
        useFilteredProjects,
        dbModalOpen,
        dbToUpdateId,
        noFilteredMatches
      } = this.state,
          currentProjects = useFilteredProjects ? filteredProjects : this.state.currentProjects;

        
    let projectHeaderStyle = !alreadyFetched || 
        (!currentProjects.length && (isFetchingWorkspaces || isFetchingProjects)) ?
        {display: 'none'} : {}; 
    let projectsHeaderJSX = (
        <div className="list-header-arius" style={projectHeaderStyle}>
            <span style={{display:'flex'}}>
                <Button
                    toolTip = "Open search filter"
                    iconName="fa-search"
                    onClick={(e) => this.toggleSearchFilter(e)}/>
                <h4>Projects</h4>
                {this.getBatchButtons()}
                {this.getStatusLink()}
            </span>
            {this.getPurpleButtons()}
        </div>
    )

    let body = <div></div>;

    if (!currentProjects.length) {
        body = <div style={{ width: '85vw', margin: '5vh auto' }}><NoResults /></div>;
    }

    if (noFilteredMatches) {
        body = <div style={{ width: '85vw', margin: '5vh auto' }}><PlaceHolderMessage message="No Matches" /></div>;
    }

    return (
        <div className="list-container-arius">
            {projectsHeaderJSX}
            {this.getProjectFilterPanel()}
            {body}
            <div style={currentProjects.length > 0 ? {} : {display: 'none'}}>
                {/* <span>{this.state.selectedProjectIds.length ? JSON.stringify(this.state.selectedProjectIds) : 'none selected'}</span> */}
                {this.getGrid()}
            </div>
            <DetailCommentsModal
                userKey={userKey}
                dispatch={dispatch}
                selectedItem={projectedItem}
                modalId={'project-versions-modal'}
                showModal={projectVersionsModalOpen}
                closeHandler={this.toggleVersionModal}
                updateHandler={this.updateHandler}
            />
            <DbUpdateModal
                userKey={userKey}
                modalId="db-update-modal"
                showModal={dbModalOpen}
                dispatch={dispatch}
                closeHandler={this.closeDbUpdateModal}
                dbToUpdateId={dbToUpdateId}
            />
            <div style={style.inputHanger} id="input-hanger"></div>
        </div>
        )
  }
}

const mapStateToProps = state => ({
    isFetchingCsv: state.analysis.reporting.isFetchingCsv,
    analysisTasks: state.analysis.analysistasks.tasks
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default  radium(connect(mapStateToProps, mapDispatchToProps)(AnalysisProjectList));
