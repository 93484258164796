import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import ReviewListTableRow from './row';
import { Table } from '@arius';

class ReviewLevelTable extends Component {
    static propTypes = {
        reviewLevels: PropTypes.arrayOf(
            PropTypes.shape({
                levelId: PropTypes.number,
                level: PropTypes.number,
                description: PropTypes.string,
            })
            ),
            isFetchingReviewLevels: PropTypes.bool,
        sortList: PropTypes.func,
        deleteItem: PropTypes.func,
        sortedBy: PropTypes.string,
        ascending: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {
        const {
            data,
            editHandler,
            deleteHandler
        } = this.props;

        const {tr:Tr, th:Th, tbody:Tbody, thead:Thead, responsiveTable:Tbl} = Table;

        let renderTableBody = data.map((cs, idx) => 
            <ReviewListTableRow data={cs} key={idx} editHandler={editHandler} deleteHandler={deleteHandler}/>
        );

        return (
            <div style={{ paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                <Tbl>
                    <Thead >
                        <Tr>
                            <Th width='60px'>Level</Th>
                            <Th>Description</Th>
                            <Th width='100px'>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {renderTableBody}
                    </Tbody>
                </Tbl>
            </div>
        );
    }
}

export default radium(ReviewLevelTable);
