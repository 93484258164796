import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import * as Colors from '@app/utilities/colors';
import { notAuthorized } from '@app/shared/containers/conditionalLink';
import { DATA_MODIFY, createPermissionChecker } from '@app/utilities/permissions';
import Loader from '@app/shared/presentational/loader';
import { Table, Grid, Button } from '@arius';

const style = {
    backIcon: {
        color: Colors.darkGrey,
        borderRadius: '5px',
        marginRight: 5,
        cursor: 'pointer',
        fontSize: '4.5vmin',
        ':hover': {
            backgroundColor: Colors.purple,
            color: 'white',
            transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
        },
    },
};

class TodDatabaseInfoModal extends Component {
    static propTypes = {
        modalId: PropTypes.string.isRequired,
        closeHandler: PropTypes.func.isRequired,
        deleteValuationDateHandler: PropTypes.func.isRequired,
        showModal: PropTypes.bool,
        database: PropTypes.object,
        userKey: PropTypes.string,
        currentDatabase: PropTypes.array,
        permissions: PropTypes.array,
        deleteDataLoadHandler: PropTypes.func,
        dataLoadDeletesInProgress: PropTypes.array,
        valuationDateDeletesInProgress: PropTypes.array,
    };
    constructor(props) {
        super(props);
        this.state = {
            valuationDateSelected: null,
            modalDeleteHandler: null,
            showDeleteModal: false,
            deleteMSG: '',
        };
        this.close = this.close.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteValuationDate = this.deleteValuationDate.bind(this);
        this.deleteDataLoad = this.deleteDataLoad.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getValSelectedGrid = this.getValSelectedGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.getValSelectedActionItems = this.getValSelectedActionItems.bind(this);
        this.getValSelectedDetailGrid = this.getValSelectedDetailGrid.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { database } = this.props,
        { valuationDateSelected } = this.state;
        if (database !== nextProps.database) {
            if (valuationDateSelected
                && valuationDateSelected.valuationDate
                && nextProps.database
                && nextProps.database.info
                && nextProps.database.info.valuationStatistics) {
                    const date = valuationDateSelected.valuationDate,
                    valDates = nextProps.database.info.valuationStatistics,
                    foundDate = valDates.find(vd => vd.valuationDate === date);
                    if (foundDate) {
                        this.setState({ valuationDateSelected: foundDate });
                    } else if (!valDates.length) {
                        this.setState({ valuationDateSelected: null });
                    }
            }
        }
    }

    close() {
        this.setState({ valuationDateSelected: null });
        this.props.closeHandler();
    }

    open() {
        this.setState({ showModal: true });
    }

    selectValuationDate(valuationDateSelected) {
        this.setState({ valuationDateSelected });
    }

    deleteValuationDate(valuationDate) {
        const { deleteValuationDateHandler, database, userKey } = this.props,
        databaseId = database.id || database.workspaceId;
        const modalDeleteHandler = () => {
            // const foundValuationDate = database.info.valuationStatistics.find(vs => vs.valuationDate === valuationDate);
            // database.info.valuationStatistics.splice(database.info.valuationStatistics.indexOf(foundValuationDate), 1);
            deleteValuationDateHandler(userKey, databaseId, database.factTableGuid, moment(valuationDate).format('YYYY-MM-DD'));
            this.closeDeleteModal();
        };
        this.setState({ showDeleteModal: true, deleteMSG: 'valuation date', modalDeleteHandler });
    }
    
    deleteDataLoad(file) {
        const { deleteDataLoadHandler, database, userKey } = this.props,
        databaseId = database.id || database.workspaceId;
        const modalDeleteHandler = () => {
            // const foundFile = valuationDateSelected.importFiles.find(f => f === file);
            // if (foundFile) {
            //   valuationDateSelected.importFiles.splice(valuationDateSelected.importFiles.indexOf(file), 1);
            // }
            deleteDataLoadHandler(userKey, databaseId, file.importFileId, database.factTableGuid);
            this.closeDeleteModal();
        };
        this.setState({ showDeleteModal: true, deleteMSG: 'file', modalDeleteHandler });
    }

    closeDeleteModal() {
        this.setState({ showDeleteModal: false });
    }

    getActionItems(vd) {
        const { permissions, valuationDateDeletesInProgress} = this.props;
        const canDeleteData = createPermissionChecker(permissions)(DATA_MODIFY);
        return <div>
            <Button 
                iconName='pageview'
                onClick={(e) => this.selectValuationDate(vd, e)}
                toolTip='View Info for Valuation Date'/>
                {valuationDateDeletesInProgress.indexOf(moment(vd.valuationDate).format('YYYY-MM-DD')) === -1
                    ? <Button 
                        iconName='delete'
                        onClick={(e) => (canDeleteData ? this.deleteValuationDate : notAuthorized)(vd.valuationDate, e)}
                        toolTip='Delete ALL data for this valuation date'/>
                    :  
                    <Button 
                        iconName='fa-cog fa-spin'
                        onClick={(e) => (canDeleteData ? this.deleteValuationDate : notAuthorized)(vd.valuationDate, e)}
                        toolTip='Valuation Date Delete In Progress'/>
                }
        </div>
    }

    getGrid(info) {
        const {database} = this.props;
        const columns = [
            { headerText: 'Valuation Date', field: 'valuationDate'},
            { headerText: 'Exposure Period Range', field: 'exposurePeriodStartDate', 
                headerTextAlign: 'center', textAlign: 'center', template: (vd)=> {
                return <div>
                    {moment.utc(vd.exposurePeriodStartDate).local().format('MMM YYYY')} -
                    {moment.utc(vd.exposurePeriodEndDate).local().format('MMM YYYY')}
                </div>
            }},
            { headerText: 'Development Period Range', field: 'developmentPeriodStartDate', 
                headerTextAlign: 'center', textAlign: 'center', template: (vd)=> {
                return <div>
                    {moment.utc(vd.developmentPeriodStartDate).local().format('MMM YYYY')} -
                    {moment.utc(vd.developmentPeriodEndDate).local().format('MMM YYYY')}
                </div>
            }},
            { headerText: 'Number of Records', field: 'recordCount', headerTextAlign: 'center', textAlign: 'center', width: 180},
            { headerText: '', width: '100', template: this.getActionItems},
        ];

        return <Grid 
                key = {`val_dates_grid_${database.factTableGuid}`}
                columns={columns}
                data={info.valuationStatistics}
                height='auto'
        />
    }

    getValSelectedGrid() {
        const {database} = this.props;
        const { valuationDateSelected } = this.state;

        const columns = [
            { headerText: 'File Name', field: 'displayName'},
            { headerText: 'Number of Records', field: 'totalRecordCount', headerTextAlign: 'center', textAlign: 'center', width: 180},
            { headerText: 'Loaded By', field: 'loadedBy'},
            { headerText: 'Loaded Date', field: 'lastLoadedDate', type: 'datetime'},
            { headerText: '', width: '100', template: this.getValSelectedActionItems},
        ];

        return <Grid 
                key = {`val_selected_grid_${database.factTableGuid}`}
                columns={columns}
                data={valuationDateSelected.importFilesGrouped}
                detailTemplate={this.getValSelectedDetailGrid}
                height='auto'
        />
    }

    getValSelectedActionItems(file){
        const { dataLoadDeletesInProgress, permissions } = this.props;
        const canDeleteData = createPermissionChecker(permissions)(DATA_MODIFY);

        return <div>
            {dataLoadDeletesInProgress.indexOf(file.importFileId) === -1
            ? <Button 
                iconName='delete'
                onClick={(e) => (canDeleteData ? this.deleteDataLoad : notAuthorized)(file, e)}
                toolTip='Delete data loaded from this file for this valuation date'/>
            : <Button 
                iconName='fa-cog fa-spin'
                onClick={(e) => (canDeleteData ? this.deleteValuationDate : notAuthorized)(file.valuationDate, e)}
                toolTip='Data Load Delete In Progress'/>
            }
        </div>
    }

    getValSelectedDetailGrid(file){
        const columns = [
            { headerText: 'File Name', field: 'importFileName'},
            { headerText: 'Number of Records', field: 'recordCount', headerTextAlign: 'center', textAlign: 'center', width: 180},
            { headerText: 'Loaded By', field: 'loadedBy'},
            { headerText: 'Loaded Date', field: 'loadedDate', type: 'datetime'},
        ];

        if (file.importFiles.length === 1) {
            return <div>[ Nothing to expand ]</div>;
        }

        return <Grid 
                columns={columns}
                data={file.importFiles}
                height='auto'
        />
    }

    getValSelectedGridOLD() {
        const { dataLoadDeletesInProgress, permissions } = this.props;
        const { valuationDateSelected } = this.state;
        const canDeleteData = createPermissionChecker(permissions)(DATA_MODIFY);
        let {tr:Tr, td:Td, th:Th, tbody:Tbody, thead:Thead, responsiveTable:Tbl} = Table;

        let rows = valuationDateSelected.importFiles.map((file, i) => (
            <Tr key={`${file.importFileId}`}>
            <Td>{file.importFileName}</Td>
            <Td style={{textAlign: 'right', paddingRight: 20 }}>
                {file.recordCount.toLocaleString().toLocaleString()}
            </Td>
            <Td style={{ paddingLeft: 20 }}>{file.loadedBy}</Td>
            <Td>
                {moment.utc(file.loadedDate).local().format('L LT')}
            </Td>
            <Td>
                <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end' }}>
                    {dataLoadDeletesInProgress.indexOf(file.importFileId) === -1
                    ? <Button 
                        iconName='delete'
                        onClick={(e) => (canDeleteData ? this.deleteDataLoad : notAuthorized)(file, e)}
                        toolTip='Delete Info for Valuation Date'/>
                    : <Button 
                        iconName='fa-cog fa-spin'
                        onClick={(e) => (canDeleteData ? this.deleteValuationDate : notAuthorized)(file.valuationDate, e)}
                        toolTip='Data Load Delete In Progress'/>
                    }
                </div>
            </Td>
            </Tr>
        ))

        return (
            <Tbl>
                <Thead>
                    <Tr>
                        <Th>File Name</Th>
                        <Th style={{textAlign: 'right', paddingRight: 20 }}>Num. Records</Th>
                        <Th style={{paddingLeft: 20 }}>Loaded By</Th>
                        <Th>Date Loaded</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>{rows}</Tbody>
            </Tbl>
        )
    }

    render() {
        const { database } = this.props;
        const { valuationDateSelected, modalDeleteHandler, showDeleteModal, deleteMSG } = this.state;
        let bodyMarkup = (<div></div>);

        if (database) {
        const info = database.info;
        if (database.isFetching) {
            bodyMarkup = (
            <div style={{ margin: '5vh auto' }}>
                <Loader loadingItem="Database Info" />
            </div>
            );
        } else if (info && !database.isFetching) {
            bodyMarkup = (
            <div>
                {valuationDateSelected ? (
                    <div style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    padding: 5,
                    marginBottom: 10,
                    backgroundColor: '#EAEAEA' }}>
                    <span onClick={() => this.setState({ valuationDateSelected: null })}>
                        <OverlayTrigger placement="left" focus overlay={<Tooltip id="backToList">Back</Tooltip>}>
                        <i key={`back-${valuationDateSelected.valuationDate}`} style={[style.backIcon]} className="material-icons">arrow_back</i>
                        </OverlayTrigger>
                    </span>
                    <h6><smaller>ssssDisplaying Information for valuation date {valuationDateSelected.valuationDate}</smaller></h6>
                    </div>
                    ) : (<span></span>)
                }
                <div className="row">
                    <div className="col-md-3" style={{textAlign: "right"}}><strong>Created By</strong></div>
                    <div className="col-md-3" style={{marginLeft: 0}}>{database.createdBy}</div>
                    <div className="col-md-3"style={{textAlign: "right"}}><strong>Number of Records</strong></div>
                    <div className="col-md-3">{info.recordCount.toLocaleString()}</div>
                </div>
                <div className="row">
                    <div className="col-md-3" style={{textAlign: "right"}}><strong>Exposure Period Range</strong></div>
                    <div className="col-md-3">
                        {info.recordCount > 0 ? (
                            <span>
                            {`${moment.utc(info.exposurePeriodStartDate).local().format('MMM YYYY')} -
                            ${moment.utc(info.exposurePeriodEndDate).local().format('MMM YYYY')}`}
                            </span>
                        ) : (<span></span>)}
                    </div>
                    <div className="col-md-3"style={{textAlign: "right"}}><strong>Development Period Range</strong></div>
                    <div className="col-md-3">
                        {info.recordCount > 0 ? (
                            <span>
                                {`${moment.utc(info.developmentPeriodStartDate).local().format('MMM YYYY')} - 
                                ${moment.utc(info.developmentPeriodEndDate).local().format('MMM YYYY')}`}
                            </span>
                        ) : (<span></span>)}
                    </div>
                </div>
                <div className="row"  style={{marginTop: '20px'}}>
                   {this.getGrid(info)}
                </div>
                
            </div>
            );
            if (valuationDateSelected) {
            bodyMarkup = (
                <div>
                    <div style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    padding: 5,
                    marginBottom: 10,
                    backgroundColor: '#EAEAEA' }}>
                    <span onClick={() => this.setState({ valuationDateSelected: null })}>
                        <OverlayTrigger placement="left" focus overlay={<Tooltip id="backToList">Back</Tooltip>}>
                        <i key={`back-${valuationDateSelected.valuationDate}`} style={[style.backIcon]} className="material-icons">arrow_back</i>
                        </OverlayTrigger>
                    </span>
                    <h6>Displaying Information for valuation date {valuationDateSelected.valuationDate}</h6>
                    </div>
                    <div className="row">
                        <div className="col-md-3" style={{textAlign: "right"}}><strong>Created By</strong></div>
                        <div className="col-md-3" style={{marginLeft: 0}}>{database.createdBy}</div>
                        <div className="col-md-3"style={{textAlign: "right"}}><strong>Number of Records</strong></div>
                        <div className="col-md-3">{valuationDateSelected.recordCount.toLocaleString()}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3" style={{textAlign: "right"}}><strong>Exposure Period Range</strong></div>
                        <div className="col-md-3">
                            {info.recordCount > 0 ? (
                                <span>
                                {`${moment.utc(valuationDateSelected.exposurePeriodStartDate).local().format('MMM YYYY')} -
                                ${moment.utc(valuationDateSelected.exposurePeriodEndDate).local().format('MMM YYYY')}`}
                                </span>
                            ) : (<span></span>)}
                        </div>
                        <div className="col-md-3"style={{textAlign: "right"}}><strong>Development Period Range</strong></div>
                        <div className="col-md-3">
                            {info.recordCount > 0 ? (
                                <span>
                                    {`${moment.utc(valuationDateSelected.developmentPeriodStartDate).local().format('MMM YYYY')} - 
                                    ${moment.utc(valuationDateSelected.developmentPeriodEndDate).local().format('MMM YYYY')}`}
                                </span>
                            ) : (<span></span>)}
                        </div>
                    </div>
                    <div className="row"  style={{marginTop: '20px'}}>
                        {this.getValSelectedGrid()}
                    </div>
                </div>
            );
            }
        } else {
            bodyMarkup = (<div>An Error Occured</div>);
        }
        }
        const dbName = database && database.info && database.info.name ? database.info.name : '';
        return (
        <Modal id={this.props.modalId} show={this.props.showModal} onHide={this.close} dialogClassName="modal-90w" enforceFocus>
            <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
            {database ? (<Modal.Title><small>Database Info for {database.name ? database.name : dbName }</small></Modal.Title>) : (<span></span>)}
            </Modal.Header>
            <Modal.Body>
            {bodyMarkup}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="outline-dark" onClick={this.close}>Close</Button>
            </Modal.Footer>
            <Modal style={{ marginTop: 50 }} show={showDeleteModal} onHide={this.closeDeleteModal}>
            <Modal.Header style={{ backgroundColor: '#b15555', color: '#FFFFFF' }}>Are you sure?</Modal.Header>
            <Modal.Body>{`Are you sure you wish to delete all data that was loaded for the selected ${deleteMSG}? This cannot be undone.`}</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={modalDeleteHandler}>Yes</Button>
                <Button variant="outline-dark" onClick={this.closeDeleteModal}>No</Button>
            </Modal.Footer>
            </Modal>
        </Modal>
        );
    }
}

export default radium(TodDatabaseInfoModal);
