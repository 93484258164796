import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TodDashboard extends Component {
    render() {
        const dbLink = 'trianglesOnDemand/databases',
        uploadsLink = 'trianglesOnDemand/uploads',
        queriesLink = 'trianglesOnDemand/queries',
        totalsLink = 'trianglesOnDemand/totals';

        return (
        <div className="tile-container">
            <h2>Triangles On Demand</h2>
            <div className="tile-row">
                <Link className="tile" to={dbLink}>
                    <div>
                        <img src="assets/database_white.svg" alt="database"/>                    
                    </div>
                    Databases
                </Link>
                <Link className="tile" to={uploadsLink}>
                    <i className="material-icons">file_upload</i>
                    Uploads
                </Link>
            </div>
            <div className="tile-row">
                <Link className="tile" to={queriesLink}>
                    <div>
                        <img src="assets/query_white.svg" alt="queries"/>                    
                    </div>
                    Queries
                </Link>
                <Link className="tile" to={totalsLink}>
                    <i className="material-icons">account_balance</i>
                    Totals
                </Link>
            </div>
        </div>
        );
    }
}

export default TodDashboard;