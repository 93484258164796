import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Feature from '@app/utilities/routing/routeSafeFeature';

class AriusAnalysisDashboard extends Component {
    render() {
        return (
        <Feature flag="ariusAnalysis">
            <div className="tile-container">
                <h2>Arius Analysis Tool</h2>
                <div className="tile-row">
                    <Link className="tile" to="analysis/workspaces">
                        <i className="fa fa-database" />
                        Databases
                    </Link>
                    <Link className="tile" to="analysis/analysisTab">
                        <i className="fa fa-table" />
                        Analysis
                    </Link>
                </div>
                <div className="tile-row">
                    <Link className="tile" to="analysis/automation">
                        <div>
                            <img src="assets/automate_white.svg" alt="automate"/>                    
                        </div>
                        Automation
                    </Link>
                    <Link className="tile" to="analysis/reporting">
                        <div>
                            <img src="assets/reports_white.svg" alt="reports"/>                    
                        </div>
                        Reporting
                    </Link>
                </div>
                <div className="tile-row">
                    <Feature flag="powerBiReports">
                        <Link className="tile" to="analysis/powerbi">
                            <i className="fa fa-bar-chart" />
                            Power BI
                        </Link> 
                    </Feature>
                </div>
            </div>           
        </Feature>
        );
    }
}

export default AriusAnalysisDashboard;