import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { retrieveExportsByListType, deleteExportListItem } from '@app/actions/analysis/project.actions';
import { showModal } from '@app/actions/modal.actions';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';
import Loader from '@app/shared/presentational/loader';
import { Grid, Button } from '@arius';

class GenericList extends Component {
    static propTypes = {
        data: PropTypes.array,
        userKey: PropTypes.string,
        dispatch: PropTypes.func,
        currentWorkspace: PropTypes.object,
        isFetching: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
        this.listTypes = {
            collections: 'CollectionLibrary',
            prints: 'PrintToPdf',
            excel: 'ExportToExcel'
        }
        this.deleteItem = this.deleteItem.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
    }

    componentWillMount() {
        const { currentWorkspace, data } = this.props;
        if (currentWorkspace) {
            this.fetchRecords();
        }

        if (data) {
            this.setState({data});
        }
    }

    componentDidUpdate(prevProps) {
        const { currentWorkspace, data, isFetching} = this.props;
        if (currentWorkspace && !isFetching && (!data || this.databaseChanged(this.props, prevProps))) {
            this.fetchRecords();
            return;
        }

        if (JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState({ data });
        }
    }

    databaseChanged(props, prevProps) {
        const { currentWorkspace } = props;
        const { currentWorkspace:prev } = prevProps;
        let id = currentWorkspace ? currentWorkspace.id || currentWorkspace.workspaceId : '';
        let prevId = prev ? prev.id || prev.workspaceId : '';
        return id !== prevId;
    }

    get label() {
        const { listType } = this.props;
        switch(listType) {
            case this.listTypes.collections:
                return 'Collection Sets';
            case this.listTypes.prints:
                return 'Print Lists';
            case this.listTypes.excel:
                return 'Excel Lists';
            default:
                return '';
        }
    }

    fetchRecords() {
        const { userKey, dispatch, currentWorkspace, listType, isFetching } = this.props;
        if (isFetching) { return;}
        dispatch(
            retrieveExportsByListType({ 
                userKey, 
                databaseId: currentWorkspace.id || currentWorkspace.workspaceId,
                listType,
            })
        );
    }

    getActionItems(row) {
        return  (
            <Button 
                iconName="delete" 
                onClick={() => this.deleteItem(row.exportListId)}
                toolTip='Delete'/>
        )
    }

    getGrid() {
        const { data } = this.state;
        let columns = [
            { field: 'listName', headerText: 'Name'},
            { field: 'lastModifiedBy', headerText: 'Last Modified By', width:'130px'},
            { field: 'lastModifiedDate', headerText: 'Last Modified Date', type: 'datetime'},
            { headerText: 'Actions', width: '100px', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    deleteItem(exportListId) {
        const { dispatch, userKey, currentWorkspace, data, listType } = this.props;

        if (data.length !== 0) {
            const selectedExport = data.find(
                def => def.exportListId === exportListId
            );
            const deleteMessageItems = [
                <li key={exportListId} style={{ fontWeight: 800 }}>
                    {selectedExport.listName}
                </li>,
                ];

            deleteMessageItems.unshift(
                'Are you sure you want to delete the following item?'
            );

            const yesClickHandler = () => {
                dispatch(deleteExportListItem({ userKey, exportListId, databaseId: currentWorkspace.id || currentWorkspace.workspaceId, listType }));
            };
            const noClickHandler = () => {},
                action = showModal(
                'confirmation',
                deleteMessageItems,
                yesClickHandler,
                noClickHandler
                );
            dispatch(action);
        }
    }

    render() {
        const { currentWorkspace, isFetching } = this.props;
        let body = <div></div>;

        if (isFetching) {
            body = (
                <div style={{ width: '85vw', margin: '5vh auto' }}>
                    <Loader loadingItem={this.label} />
                </div>
            );
        } else if (!currentWorkspace) {
            body = (
                <div
                style={{ width: '85vw', margin: '5vh auto' }}>
                <PlaceHolderMessage message={'Please select a database'} />
                </div>
            );
        }

        return (
        <div className="list-container-arius">
            <div className="list-header-arius">
                <h4>{this.label}</h4>
            </div>
            {body}
            <div style={currentWorkspace && !isFetching ? {} : {display: 'none'}}>
                {this.getGrid()}
            </div>
        </div>
        )
    }
}

export default radium(GenericList);
