import { callApi } from '../utilities/callApi';

export function retrieveCurrentDbVersion(userKey, applicationId) {
  return callApi(
    `/version/${applicationId}/database`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrieveIntegrationSettings() {
  return callApi(
    '/integration/settings/0/0/0',
  );
}

export function retrieveApiVersion() {
  return callApi(
    '/version',
  );
}

export function retrieveAriusApplicationVersion() {
  return callApi(
    '/version/batchprocessor',
  );
}

export function retrieveAuditLogs(query) {
  return callApi({ url: '/auditquery', query });
}

export function exportAuditLogsToCSV({ userKey, query }) {
  const queryStringArray = [];
  if (query.ds) {
    queryStringArray.push(`ds=${encodeURI(query.ds)}`);
  }
  if (query.de) {
    queryStringArray.push(`de=${encodeURI(query.de)}`);
  }
  if (query.q) {
    queryStringArray.push(`q=${encodeURI(query.q)}`);
  }
  let url = '/auditcsv';
  if (queryStringArray.length) {
    const queryString = queryStringArray.join('&');
    url = `/auditcsv?${queryString}`;
  }
  return callApi(
    url,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function retrieveTodDataLoadLogFile(userKey) {
  return callApi('/dataLoadLogFile',
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function deleteTodDataLoadLogFile(userKey) {
  return callApi('/dataLoadLogFile',
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

