import {
  FETCHING_EXTRACT_DEFINITIONS,
  RECEIVED_EXTRACT_DEFINITION_NAMES,
  FETCHING_REPORTING_DEFINITIONS,
  RECEIVED_REPORTING_DEFINITIONS,
  INITIATING_EXTRACT,
  EXTRACT_INITIATED,
  RECEIVED_REPORTING_CSV_FILE,
  FETCHING_REPORTING_WIDE_CSV,
  FETCHING_REPORTING_NARROW_CSV,
  FETCHING_CSV,
  RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION,
} from '../../actions/analysis/analysis.actionTypes';

const reportingReducer = (
  state = {
    isFetching: false,
    extractKickingOff: false,
    extractDefinitions: [],
    csv: null
  },
  action
) => {
  switch (action.type) {
    case INITIATING_EXTRACT:
      return Object.assign({}, state, {
        extractKickingOff: true,
      });
    case EXTRACT_INITIATED:
      return Object.assign({}, state, {
        extractKickingOff: false,
      });
    case FETCHING_EXTRACT_DEFINITIONS:
    case FETCHING_REPORTING_DEFINITIONS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVED_EXTRACT_DEFINITION_NAMES:
      return Object.assign({}, state, {
        isFetching: false,
        extractDefinitions: action.extractDefinitions,
      });
    case RECEIVED_REPORTING_DEFINITIONS:
        return Object.assign({}, state, {
          isFetching: false,
          reportingDefinitions: action.reportingDefinitions,
        });
    case FETCHING_REPORTING_WIDE_CSV:
      return Object.assign({}, state, {
        isFetchingWideCsv: true,
        isFetchingNarrowCsv: false,
        csv: null
      });
    case FETCHING_REPORTING_NARROW_CSV:
      return Object.assign({}, state, {
        isFetchingWideCsv: false,
        isFetchingNarrowCsv: true,
        csv: null
      });
    case FETCHING_CSV:
      return Object.assign({}, state, {
        isFetchingCsv: true,
        csv: null
      });
    case RECEIVED_REPORTING_CSV_FILE:
      return Object.assign({}, state, {
        isFetchingWideCsv: false,
        isFetchingNarrowCsv: false,
        isFetchingCsv: false,
        csv: action.csv
      });
    case RECEIVED_USER_NAMES_FOR_EXTRACT_DEFINITION:
      return Object.assign({}, state, {
        userNamesForExtractDefinition: action.userNamesForExtractDefinition,
      });
  default:
      return state;
  }
};

export default reportingReducer;
