import {
  RECEIVED_PROJECT,
  RECEIVE_PROJECTS,
  FETCHING_PROJECTS_FROM_SERVER,
  SELECTING_PROJECT,
  INITIATING_BATCH_PROCESS,
  RECEIVED_LONG_RUNNING_OPERATIONS_STATUS,
  FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER,
  RECEIVED_CURRENT_UDO_VERSION,
  COPYING_PROJECT,
  RECEIVED_PROJECT_VERSIONS,
  CHANGED_VALUATION_DATE,
  CHECKING_PROJECT_FOR_UNIQUENESS,
  CHECKED_PROJECT_FOR_UNIQUENESS,
  RECEIVED_COPIED_PROJECT_ID,
  RECEIVED_VALUATION_DATES,
  FETCHING_VALUATION_DATES,
  RETRIEVING_EXPORTS_BY_LIST_TYPE,
  RETRIEVED_EXPORTS_BY_LIST_TYPE,
  RETRIEVING_EXCEL_BY_LIST_TYPE,
  RETRIEVED_EXCEL_BY_LIST_TYPE,
  RETRIEVING_PRINTS_BY_LIST_TYPE,
  RETRIEVED_PRINTS_BY_LIST_TYPE,
  RETRIEVING_COLLECTION_SET_BY_LIST_TYPE,
  RETRIEVED_COLLECTION_SET_BY_LIST_TYPE,
  CLEAR_WORKSPACE_RELATED,

  GET_PROJECT_REVIEW_STATE_TYPE,
  GET_PROJECT_REVIEW_HISTORY_TYPE,
  SEND_PROJECT_REVIEW_COMMMAND_TYPE,
  CLEAN_PROJECT_REVIEW_TYPE,

} from '../../actions/analysis/analysis.actionTypes';

const projectsReducer = (
  state = {
    workspaceId: 0,
    isFetching: false,
    items: [],
    current: null,
    batchProjects: [],
    longRunningOperationsStatus: [],
    currentUdoVersionNbr: 0,
    copyingProject: {},
    projectVersions: [],
    valuationDate: '',
    checkingUniqueness: false,
    copiedProjectId: null,
    valuationDates: [],
    exportList: [],
    printList: [],
    excelList: [],
    isFetchingExcelList: false,
    collectionSet: [],
    isFetchingExportList: false,
    isFetchingPrintList: false,
    isFetchingCollectionSet: false,
    projectReviewState: null,
    projectReviewHistory: null,
    projectReviewRefresh: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCHING_LONG_RUNNING_OPERATIONS_FROM_SERVER:
        return Object.assign({}, state, { 
            isFetching: true
        });
    case FETCHING_PROJECTS_FROM_SERVER:
        return Object.assign({}, state, { 
            isFetching: true,
            isFetchingProjects: true
        });
    case RECEIVE_PROJECTS:
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingProjects: false,
        items: action.projects ? action.projects : [],
        receivedAt: action.receivedAt,
      });
    case RECEIVED_COPIED_PROJECT_ID:
      return Object.assign({}, state, {
        copiedProjectId: action.copiedProjectId,
      });
    case RECEIVED_VALUATION_DATES:
      return Object.assign({}, state, {
        valuationDates: action.valuationDates,
        isFetchingValuationDates: false,
      });
    case FETCHING_VALUATION_DATES: 
      return Object.assign({}, state, {
        isFetchingValuationDates: true,
      });
    case RECEIVED_PROJECT:
    case SELECTING_PROJECT:
      return Object.assign({}, state, {
        isFetching: false,
        current: action.current,
        receivedAt: action.receivedAt,
      });
    case CHANGED_VALUATION_DATE:
      return Object.assign({}, state, {
        valuationDate: action.valuationDate,
        shape: action.shape
      });
    case INITIATING_BATCH_PROCESS:
      return Object.assign({}, state, {
        isFetching: false,
        batchProjects: action.batchProjects,
      });
    case CHECKED_PROJECT_FOR_UNIQUENESS:
      return Object.assign({}, state, {
        checkingUniqueness: false,
      });
    case CHECKING_PROJECT_FOR_UNIQUENESS:
      return Object.assign({}, state, {
        checkingUniqueness: true,
      });
    case RECEIVED_LONG_RUNNING_OPERATIONS_STATUS:
      return Object.assign({}, state, {
        isFetching: false,
        longRunningOperationsStatus: action.longRunningOperationsStatus,
      });
    case RECEIVED_CURRENT_UDO_VERSION:
      return Object.assign({}, state, {
        isFetching: false,
        currentUdoVersionNbr: action.currentUdoVersionNbr,
      });
    case CLEAR_WORKSPACE_RELATED: 
      return Object.assign({}, state, {
        isFetching: false,
        currentUdoVersionNbr: 0,
        valuationDates: [],
        isFetchingValuationDates: false,
        projects: [],
        longRunningOperationsStatus: [],
        valuationDate: '',
        shape: null
      });
    case RECEIVED_PROJECT_VERSIONS:
      return Object.assign({}, state, {
        isFetching: false,
        projectVersions: action.projectVersions,
      });
    case COPYING_PROJECT:
      return Object.assign({}, state, {
        isFetching: false,
        copyingProject: action.copyingProject,
      });
    case RETRIEVING_EXPORTS_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingExportList: true,
      });
    case RETRIEVED_EXPORTS_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingExportList: false,
        exportList: action.exportList,
      });
    case RETRIEVING_EXCEL_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingExcelList: true,
      });
    case RETRIEVED_EXCEL_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingExcelList: false,
        excelList: action.excelList,
      });
    case RETRIEVING_PRINTS_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingPrintList: true,
      });
    case RETRIEVED_PRINTS_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingPrintList: false,
        printList: action.printList,
      });
    case RETRIEVING_COLLECTION_SET_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingCollectionSet: true,
      });
    case RETRIEVED_COLLECTION_SET_BY_LIST_TYPE:
      return Object.assign({}, state, {
        isFetchingCollectionSet: false,
        collectionSet: action.collectionSet,
      });

    case GET_PROJECT_REVIEW_STATE_TYPE:
      return {
        ...state,
        projectReviewState: action.projectReviewState,
      };

      case GET_PROJECT_REVIEW_HISTORY_TYPE:
      return {
        ...state,
        projectReviewHistory: action.projectReviewHistory,
        projectReviewRefresh: false,
      };

      case SEND_PROJECT_REVIEW_COMMMAND_TYPE:
      return {
        ...state,
        projectReviewRefresh: true,
      };
      
      case CLEAN_PROJECT_REVIEW_TYPE:
      return {
        ...state,
        projectReviewState: null,
        projectReviewHistory: null,
      };

    default:
      return state;
  }
};

export default projectsReducer;
