import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { Switch, Route } from 'react-router-dom';
import {
  fetchWorkspaces,
  fetchCurrentAEDbScriptVersion,
} from '../../../actions/analysis/workspace.actions';
import {
  initiateBatchProcess,
} from '../../../actions/analysis/project.actions';
import {
  fetchLongRunningOperationsStatus,
  emptyLongRunningOperationsStatus,
  fetchCurrentUdoVersion,
  copyProject,
  getProjectVersions,
  updateProjectVersion,
  getCheckoutStatus,
  getProjectsByValuationDate,
  getValuationDates,
} from '../../../actions/analysis/project.actions';
import { runRollForwardBatchProcess, fetchScripts } from '../../../actions/analysis/automation.actions';
import AnalysisTabMain from '../presentational/analysisTabMain';
import ProjectReview from '../presentational/projectReview/projectReview';

class AnalysisTab extends Component {
  static propTypes = {
    currentWorkspace: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        projectId: PropTypes.number,
        projectName: PropTypes.string,
        projectDescription: PropTypes.string,
      })
    ).isRequired,
    dispatch: PropTypes.func.isRequired,
    userKey: PropTypes.string.isRequired,
    application: PropTypes.object,
    workspaces: PropTypes.array,
    initiateBatchProcessHandler: PropTypes.func,
    batchProjects: PropTypes.array,
    longRunningOperationsStatus: PropTypes.array,
    getLongRunningOperationsStatus: PropTypes.func,
    clearLongRunningOperationsStatus: PropTypes.func,
    getCurrentAEDbScriptVersion: PropTypes.func,
    currentAEDbScriptVersion: PropTypes.number,
    currentUdoVersionNbr: PropTypes.number,
    getCurrentUdoVersion: PropTypes.func,
    copyingProject: PropTypes.object,
    cloneProject: PropTypes.func,
    runRollForward: PropTypes.func,
    fetchScriptsHandler: PropTypes.func,
    scripts: PropTypes.array,
    segments: PropTypes.array,
    isFetchingSegments: PropTypes.bool,
    isFetchingWorkspaces: PropTypes.bool,
    userPermissions: PropTypes.array,
    getProjectVersionsHandler: PropTypes.func,
    updateProjectVersionHandler: PropTypes.func,
    getCheckoutStatusHandler: PropTypes.func,
    projectVersions: PropTypes.array,
    valuationDate: PropTypes.string,
    checkingUniqueness: PropTypes.bool,
    copiedProjectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    getProjectsByValuationDateHandler: PropTypes.func,
    getValuationDatesHandler: PropTypes.func,
    valuationDates: PropTypes.array,
    exportList: PropTypes.array,
    isFetchingExportList: PropTypes.bool,
    excelList: PropTypes.array,
    isFetchingExcelList: PropTypes.bool,
    printList: PropTypes.array,
    isFetchingPrintList: PropTypes.bool,
    collectionSet: PropTypes.array,
    isFetchingCollectionSet: PropTypes.bool,

    current: PropTypes.object,
    projectReviewState: PropTypes.object,
    projectReviewHistory: PropTypes.array,
  };

  componentWillMount() {
    const {
      userKey,
      getCurrentAEDbScriptVersion,
      currentAEDbScriptVersion,
    } = this.props;
    if (!currentAEDbScriptVersion) {
      getCurrentAEDbScriptVersion(userKey);
    }
  }

  componentDidMount() {
    const { dispatch, userKey } = this.props;
    dispatch(fetchWorkspaces(userKey));
  }

  render() {
    const {
      currentWorkspace,
      projects,
      isFetching,
      isFetchingProjects,
      isFetchingValuationDates,
      match,
      history,
      userKey,
      dispatch,
      workspaces,
      initiateBatchProcessHandler,
      batchProjects,
      longRunningOperationsStatus,
      getLongRunningOperationsStatus,
      clearLongRunningOperationsStatus,
      currentUdoVersionNbr,
      getCurrentUdoVersion,
      copyingProject,
      cloneProject,
      runRollForward,
      scripts,
      fetchScriptsHandler,
      segments,
      isFetchingSegments,
      isFetchingWorkspaces,
      userPermissions,
      getProjectVersionsHandler,
      updateProjectVersionHandler,
      projectVersions,
      valuationDate,
      shape,
      checkingUniqueness,
      getCheckoutStatusHandler,
      copiedProjectId,
      getValuationDatesHandler,
      getProjectsByValuationDateHandler,
      valuationDates,
      exportList,
      isFetchingExportList,
      excelList,
      isFetchingExcelList,
      printList,
      isFetchingPrintList,
      collectionSet,
      isFetchingCollectionSet,

      current,
      projectReviewState,
      projectReviewHistory,
      jobStatus,
    } = this.props;
    let { params } = match;
    const workspaceId = parseInt(params.workspaceId,10);

    let childProps = {
        browserHistory: history,
        currentWorkspace,
        workspaceId,
        isFetching,
        isFetchingProjects,
        isFetchingValuationDates,
        projects,
        userKey,
        dispatch,
        workspaces,
        params,
        initiateBatchProcessHandler,
        batchProjects,
        longRunningOperationsStatus,
        getLongRunningOperationsStatus,
        clearLongRunningOperationsStatus,
        currentUdoVersionNbr,
        getCurrentUdoVersion,
        copyingProject,
        cloneProject,
        runRollForward,
        scripts,
        fetchScriptsHandler,
        segments,
        isFetchingSegments,
        isFetchingWorkspaces,
        userPermissions,
        getProjectVersionsHandler,
        updateProjectVersionHandler,
        projectVersions,
        valuationDate,
        shape,
        checkingUniqueness,
        getCheckoutStatusHandler,
        copiedProjectId,
        getValuationDatesHandler,
        getProjectsByValuationDateHandler,
        valuationDates,
        exportList,
        isFetchingExportList,
        excelList,
        isFetchingExcelList,
        printList,
        isFetchingPrintList,
        collectionSet,
        isFetchingCollectionSet,
        current,
        projectReviewState,
        projectReviewHistory,
        jobStatus,
      };

    return (
      <Switch>
          <Route path="/analysis/analysisTab/workspaces/:workspaceId/projects/:projectId/review" render={props => <ProjectReview {...{...childProps, params: props.match.params }}/>}/> />
          <Route path="/analysis/analysisTab/workspaces/:workspaceId/projects/date/:date/:shape" render={props => <AnalysisTabMain {...{...childProps, params: props.match.params }}/>}/> />
          <Route path="/analysis/analysisTab/workspaces/:workspaceId/projects" render={props => <AnalysisTabMain {...{...childProps, params: props.match.params }}/>}/> />
          <Route path="/analysis" render={props => <AnalysisTabMain {...{...childProps, params: props.match.params }}/>}/> />
      </Switch>
    )};
}

const mapStateToProps = state => ({
  currentWorkspace: state.analysis.workspaces.current,
  segments: state.analysis.segments.items,
  workspaces: state.analysis.workspaces.items,
  projects: state.analysis.projects.items,
  isFetching: state.analysis.projects.isFetching,
  isFetchingProjects: state.analysis.projects.isFetchingProjects,
  isFetchingValuationDates: state.analysis.projects.isFetchingValuationDates,
  isFetchingWorkspaces: state.analysis.workspaces.isFetching,
  isFetchingSegments: state.analysis.segments.isFetchingSegments,
  userKey: state.user.userKey,
  application: state.application,
  batchProjects: state.analysis.projects.batchProjects,
  longRunningOperationsStatus: state.analysis.projects
    .longRunningOperationsStatus,
  currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
  currentUdoVersionNbr: state.analysis.projects.currentUdoVersionNbr,
  copyingProject: state.analysis.projects.copyingProject,
  projectVersions: state.analysis.projects.projectVersions,
  scripts: state.analysis.automation.scripts,
  userPermissions: state.user.userPermissions,
  valuationDate: state.analysis.projects.valuationDate,
  shape: state.analysis.projects.shape,
  checkingUniqueness: state.analysis.projects.checkingUniqueness,
  copiedProjectId: state.analysis.projects.copiedProjectId,
  valuationDates: state.analysis.projects.valuationDates,
  exportList: state.analysis.projects.exportList,
  isFetchingExportList: state.analysis.projects.isFetchingExportList,
  excelList: state.analysis.projects.excelList,
  isFetchingExcelList: state.analysis.projects.isFetchingExcelList,
  printList: state.analysis.projects.printList,
  isFetchingPrintList: state.analysis.projects.isFetchingPrintList,
  collectionSet: state.analysis.projects.collectionSet,
  isFetchingCollectionSet: state.analysis.projects.isFetchingCollectionSet,

  current: state.analysis.projects.current,
  projectReviewState: state.analysis.projects.projectReviewState,
  projectReviewHistory: state.analysis.projects.projectReviewHistory,
  jobStatus: state.jobStatus,
});

const mapDispatchToProps = dispatch => ({
  initiateBatchProcessHandler: (userKey, projects, operation) => {
    dispatch(initiateBatchProcess(userKey, projects, operation));
  },
  getLongRunningOperationsStatus: (userKey, databaseId) => {
    dispatch(fetchLongRunningOperationsStatus(userKey, databaseId));
  },
  getCheckoutStatusHandler: (userKey, databaseId, projectId, body) => {
    dispatch(getCheckoutStatus(userKey, databaseId, projectId, body));
  },
  getValuationDatesHandler: (userKey, databaseId) => {
    dispatch(getValuationDates(userKey, databaseId));
  },
  getProjectsByValuationDateHandler: (userKey, databaseId, valuationDate) => {
    dispatch(getProjectsByValuationDate(userKey, databaseId, valuationDate));
  },
  runRollForward: ({ userKey, databaseId, scriptId, projects }) => {
    dispatch(runRollForwardBatchProcess({ userKey, databaseId, scriptId, projects }));
  },
  clearLongRunningOperationsStatus: () => {
    dispatch(emptyLongRunningOperationsStatus());
  },
  getCurrentAEDbScriptVersion: userKey => {
    dispatch(fetchCurrentAEDbScriptVersion(userKey));
  },
  getCurrentUdoVersion: (userKey, databaseId) => {
    dispatch(fetchCurrentUdoVersion(userKey, databaseId));
  },
  cloneProject: (userKey, project, body) => {
    dispatch(copyProject(userKey, project, body));
  },
  fetchScriptsHandler: ({ databaseId, userKey }) => {
    dispatch(fetchScripts({ databaseId, userKey }));
  },
  getProjectVersionsHandler: (userKey, databaseId, projectId) => {
    dispatch(getProjectVersions(userKey, databaseId, projectId));
  },
  updateProjectVersionHandler: (userKey, databaseId, projectId, req) => {
    dispatch(updateProjectVersion(userKey, databaseId, projectId, req));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisTab);
