import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Link } from 'react-router-dom';
import * as GlobalStyles from '../../../utilities/globalStyles';
import * as Colors from '../../../utilities/colors';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

const style = {
  container: {
    ...GlobalStyles.sidebar,
    height: 'calc(100vh - 54px)',
  },
  active: {
    padding: '10px 10px 10px 20px',
    color: '#555',
    cursor: 'pointer',
    borderRadius: '0px',
    backgroundColor: '#f5f5f5',
    borderRight: 'none',

    // textAlign: 'center',
  },
  inactive: {
    padding: '10px 10px 10px 20px',
    color: Colors.blue,
    cursor: 'pointer',
    borderRadius: '0px',
    borderRight: 'none',

    // textAlign: 'center',
  },
  sidebarItem: {
    marginTop: 20,
  },
  sectionHeader: { color: Colors.purple, padding: '20px' },
};

class AnalysisTabSidebar extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    selectSideNavOption: PropTypes.func,
    selectedSideNavOption: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { selectedSideNavOption, selectSideNavOption } = this.props;
    return (
      <div style={style.container}>
        <div className="analysis-home-link" style={[style.sidebarItem, { padding: '0px 20px' }]}>
          <Link to={"/analysis"}>
            <i
              className="fa fa-arrow-circle-o-left"
              style={{ paddingRight: 2 }}
              aria-hidden="true"
            />
            Analysis Home
          </Link>
        </div>
        <ListGroup className="nav nav-sidebar" style={style.sidebarItem}>
          <ListGroupItem
            style={
              selectedSideNavOption === 'projects'
                ? style.active
                : style.inactive
            }
            onClick={() => selectSideNavOption('projects')}
          >
            Projects
          </ListGroupItem>
          <ListGroupItem
            style={
              selectedSideNavOption === 'collection sets'
                ? style.active
                : style.inactive
            }
            onClick={() => selectSideNavOption('collection sets')}
          >
            Collection Sets
          </ListGroupItem> 
          <ListGroupItem
            style={
              selectedSideNavOption === 'print lists'
                ? style.active
                : style.inactive
            }
            onClick={() => selectSideNavOption('print lists')}
          >
            Print Lists
          </ListGroupItem>
          <ListGroupItem
            style={
              selectedSideNavOption === 'excel lists'
                ? style.active
                : style.inactive
            }
            onClick={() => selectSideNavOption('excel lists')}
          >
            Excel Lists
          </ListGroupItem>
          <ListGroupItem
            style={
              selectedSideNavOption === 'review levels'
                ? style.active
                : style.inactive
            }
            onClick={() => selectSideNavOption('review levels')}
          >
            Review Levels
          </ListGroupItem>
        </ListGroup>
      </div>
    );
  }
}

export default radium(AnalysisTabSidebar);
