import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import radium from 'radium';
import { Button } from '@arius';
import * as Colors from '@app/utilities/colors';
import * as style from '@app/utilities/globalStyles';

import { notifySuccess, notifyError } from '@app/utilities/notifier';
import MappingRow from './mappingRow';
import SaveButtons from '@app/shared/presentational/saveButtons';
import { validateName } from '@app/utilities/validators';

class CreateMapper extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    userKey: PropTypes.string.isRequired,
    application: PropTypes.object,
    getDatabaseMappings: PropTypes.func,
    getDatabase: PropTypes.func,
    getDateFormats: PropTypes.func,
    getDateFormatDelimiters: PropTypes.func,
    getColumnTypes: PropTypes.func,
    columnTypes: PropTypes.array,
    currentDb: PropTypes.object,
    mappings: PropTypes.object,
    dataMappers: PropTypes.array,
    isFetching: PropTypes.bool,
    deleteMapper: PropTypes.func,
    getUploads: PropTypes.func,
    getColumnsForCsvFile: PropTypes.func,
    dateFormats: PropTypes.array,
    dateFormatDelimiters: PropTypes.array,
    currentMapper: PropTypes.object,
    uploads: PropTypes.array,
    fileColumns: PropTypes.array,
    createNewMapper: PropTypes.func,
    getDatabaseColumns: PropTypes.func,
    getMapper: PropTypes.func,
    updateMapper: PropTypes.func,
    isSaving: PropTypes.bool,
    errorMessage: PropTypes.string,
    clearApplicationError: PropTypes.func,
    isUserDbAdmin: PropTypes.bool,
    selectMapperHandler: PropTypes.func,
  };
  static defaultProps = {
    uploads: [],
    currentDb: {},
    userKey: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      mappingColumns: [],
      mappingDesc: '',
      mappingName: '',
      isEditing: false,
      isPristine: true,
      selectedFile: null,
      softErrorMode: true,
      uploads: [],
    };
    this.descChangeHandler = this.descChangeHandler.bind(this);
    this.nameChangeHandler = this.nameChangeHandler.bind(this);
    this.createMapper = this.createMapper.bind(this);
    this.populateColumnsFromCsv = this.populateColumnsFromCsv.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.changeDbColumn = this.changeDbColumn.bind(this);
    this.trimName = this.trimName.bind(this);
    this.changeDateFormat = this.changeDateFormat.bind(this);
    this.changeDateFormatDelimiter = this.changeDateFormatDelimiter.bind(this);
  }

  componentDidMount() {
    const { 
        params, 
        currentDb, 
        userKey, 
        getUploads, 
        currentMapper, 
        getDatabaseColumns, 
        getMapper, 
        isUserDbAdmin,
        browserHistory
    } = this.props;
    
    if (!isUserDbAdmin) {
      browserHistory.push('/trianglesOnDemand/databases');
    }

    if (params.mappingId && params.databaseId) {
      this.setState({
        isEditing: true,
        mappingDesc: currentMapper ? currentMapper.description : '',
        mappingName: currentMapper ? currentMapper.name : '',
      });

      if (currentDb && currentDb.id === parseInt(params.databaseId, 10)) {
        getMapper(userKey, currentDb.id, params.mappingId);
      }
    }

    if (currentDb && !currentDb.withColumns) {
      getDatabaseColumns(userKey, currentDb.id, currentDb.factTableGuid);
    }

    getUploads(userKey);
  }

  componentDidUpdate(prevProps) {
    const {
      params,
      currentDb,
      fileColumns,
      currentMapper,
      userKey,
      getMapper,
      isSaving,
      errorMessage,
      getDatabaseColumns,
      browserHistory
    } = this.props;

    const { isEditing, isPristine } = this.state;

    if (currentDb && prevProps.currentDb !== currentDb) {
      if (params.mappingId) {
        getMapper(userKey, currentDb.id, params.mappingId);
      }
      if (currentDb.isFetching) {
        return;
      }
      if (!currentDb.withColumns) {
        getDatabaseColumns(userKey, currentDb.id, currentDb.factTableGuid);
      }
    }

    if (currentMapper && prevProps.currentMapper !== currentMapper) {
      this.setState({
        mappingColumns: this.populateColumnsFromMapper(currentMapper.columns),
        mappingName: currentMapper.mapperName,
        mappingDesc: currentMapper.mapperDescription,
        isPristine: false,
      });
    }

    if (prevProps.uploads !== this.props.uploads) {
      const uploads = this.props.uploads.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      this.setState({ uploads });
    }

    if (prevProps.errorMessage !== errorMessage) {
      // clearApplicationError();
      notifyError(errorMessage);
      if (isPristine) {
        this.setState({ isPristine: true });
      }
    }

    if (prevProps.fileColumns !== fileColumns) {
      this.populateColumnsFromCsv(fileColumns);
    }

    if (prevProps.isSaving && !isSaving && !errorMessage && !isPristine) {
      notifySuccess('Mapper saved!');
      if (!isEditing) {
        browserHistory.push(`/trianglesOnDemand/databases/${currentDb.id}/mappings`);
      }
      this.setState({ isPristine: true });
    }
  }

  componentWillUnmount() {
    const { currentDb } = this.props;
    const databaseId = currentDb ? currentDb.id : 0;
    this.props.selectMapperHandler(null, databaseId);
  }

  getNameValidationError(soft) {
    let { mappingName } = this.state;
    const { isEditing, isPristine } = this.state;
    const { dataMappers, currentMapper } = this.props;
    let error = validateName(mappingName, soft);
    if (!error && !isPristine) {
      const otherMappers = isEditing && currentMapper ? dataMappers.filter((m) => m.mapperId !== currentMapper.mapperId) : dataMappers;

      mappingName = (mappingName || '').trim();
      if (otherMappers.find((m) => m.mapperName.toLowerCase() === mappingName.toLowerCase())) {
        error = 'Already exists';
      }
    }

    return error;
  }

  changeDateFormat(index, e) {
    let { mappingColumns } = this.state;
    const dateFormat = e.target.value;
    mappingColumns = mappingColumns.map((c, idx) => {
      const col = c;
      if (idx === index) {
        col.dateFormat = dateFormat;
      }
      return col;
    });
    this.setState({ mappingColumns });
  }

  changeDateFormatDelimiter(index, e) {
    let { mappingColumns } = this.state;
    const { dateFormatDelimiters } = this.props;
    const dateFormatDelimiter = dateFormatDelimiters.find(dfd => dfd.value === e.target.value);
    mappingColumns = mappingColumns.map((c, idx) => {
      const col = c;
      if (idx === index) {
        col.dateFormatDelimiter = dateFormatDelimiter.value;
      }
      return col;
    });
    this.setState({ mappingColumns });
  }

  selectFile(e) {
    const
      { uploads, userKey, getColumnsForCsvFile } = this.props,
      selectedFile = uploads.find(u => u.id === e.target.value);

    this.setState({ selectedFile });
    getColumnsForCsvFile(userKey, selectedFile.id);
  }

  populateColumnsFromMapper(columns) {
    const { columnTypes } = this.props;
    return columns.map(mapperColumn => {
      const columnType = columnTypes.find(ct => ct.columnTypeId === mapperColumn.columnTypeId);
      return {
        mapperColumnId: mapperColumn.mapperColumnId,
        mapperId: mapperColumn.mapperId,
        externalColumnName: mapperColumn.externalColumnName,
        factColumnGuid: mapperColumn.factColumnGuid,
        factColumnName: mapperColumn.factColumnName,
        isDateType: columnType ? columnType.isDate : false,
        dateFormat: mapperColumn.dateFormat,
        dateFormatDelimiter: mapperColumn.dateFormatDelimiter,
      };
    });
  }

  populateColumnsFromCsv(columns) {
    const { columnTypes } = this.props;
    const defaultDelimiter = this.props.dateFormatDelimiters.find(dfd => dfd.value === '');
    const mappingColumns = columns.map(csvColumn => {
      const matchingColumn = this.props.currentDb.columns.find(c => c.factColumnDisplayName === csvColumn);
      return {
        externalColumnName: csvColumn,
        factColumnGuid: matchingColumn ? matchingColumn.factColumnGuid : 'default',
        factColumnName: matchingColumn ? matchingColumn.factColumnDisplayName : '',
        isDateType: matchingColumn ? (columnTypes.find(ct => ct.columnTypeId === matchingColumn.columnTypeId).isDate) : false,
        dateFormat: '',
        dateFormatDelimiter: defaultDelimiter.value,
      };
    });
    this.setState({ mappingColumns });
  }

  isRequiredColumnType(columnType) {
    return columnType !== 'measure' && columnType !== 'calc_measure' && columnType !== 'other';
  }

  nameChangeHandler(e) {
    const { currentMapper } = this.props;
    const mappingName = e.target.value;
    this.setState({ mappingName, isPristine: (currentMapper && currentMapper.mapperName === mappingName) });
  }

  trimName() {
    const { currentMapper } = this.props;
    const mappingName = (this.state.mappingName || '').trim();

    this.setState({ mappingName, isPristine: (currentMapper && currentMapper.mapperName === mappingName) });
  }

  changeDbColumn(index, e) {
    let { mappingColumns } = this.state;
    if (index < 0 || index >= mappingColumns.length) {
      return;
    }

    const
      { currentDb, columnTypes } = this.props,
      dbColumn = currentDb.columns.find(c => c.factColumnGuid === e.target.value),
      newMapping = dbColumn ? dbColumn.factColumnGuid : 'default',
      oldMapping = mappingColumns[index].factColumnGuid,
      isDateColumn = dbColumn ? columnTypes.find(ct => ct.columnTypeId === dbColumn.columnTypeId).isDate : false,
      mapCounts = mappingColumns.reduce(
        (previous, c) => {
          const prev = previous;
          if (c.factColumnGuid !== 'default' && prev[c.factColumnGuid]) {
            prev[c.factColumnGuid] += 1;
          }
          return prev;
        },
        { [oldMapping]: -1, [newMapping]: 1 });

    mappingColumns = mappingColumns.map((c, idx) => {
      if (idx === index) {
        const duplicate = mapCounts[newMapping] > 1;
        return Object.assign({}, c,
          dbColumn
            ? { factColumnGuid: newMapping, factColumnName: dbColumn.name, factColumnType: dbColumn.type, isDateType: isDateColumn, duplicate }
            : { factColumnGuid: 'default', factColumnName: '', factColumnType: '', isDateType: false, duplicate });
      } else if (mapCounts[c.factColumnGuid] !== undefined) {
        return Object.assign({}, c, { duplicate: mapCounts[c.factColumnGuid] > 1 });
      } else {
        return c;
      }
    });
    this.setState({ mappingColumns });
  }

  descChangeHandler(e) {
    const { currentMapper } = this.props;
    const mappingDesc = e.target.value;
    this.setState({ mappingDesc, isPristine: (currentMapper && currentMapper.mapperDescription === mappingDesc) });
  }

  validate() {
    const { mappingColumns } = this.state;
    const { currentDb } = this.props;

    const nameError = this.getNameValidationError(false);
    if (nameError) {
      notifyError(`Mapping name error: ${nameError}`);
      return false;
    }

    if (mappingColumns.length === 0) {
      notifyError('Mapping needs at least one column');
      return false;
    }

    const map = mappingColumns.reduce(
      (mapper, c) => {
        const m = mapper;
        if (c.factColumnGuid !== 'default') {
          m[c.factColumnGuid] = c;
        }
        return m;
      }, {}
    );

    const unmapped = currentDb.columns.filter(c => !map[c.factColumnGuid] && this.isRequiredColumnType(c.columnType));
    if (unmapped.length > 0) {
      notifyError('Every database column that is not a Measure or Other must be included in the mapping');
      return false;
    }

    if (mappingColumns.filter(c => c.isDateType && !c.dateFormat).length > 0) {
      notifyError('Date format and delimiter are required for all date columns');
      return false;
    }

    return true;
  }

  createMapper() {
    const { userKey, createNewMapper, updateMapper, currentDb, currentMapper, browserHistory } = this.props;
    const { mappingDesc, mappingName, mappingColumns, isEditing } = this.state;

    if (!this.validate(false)) {
      this.setState({ softErrorMode: false });
      return;
    }

    let onlyMappedColumns = mappingColumns.slice();
    onlyMappedColumns = onlyMappedColumns.filter(col => col.factColumnGuid !== 'default');

    if (isEditing && currentMapper) {
      const columns = onlyMappedColumns.map(column => {
        const col = {
          mapperColumnId: column.mapperColumnId,
          mapperId: column.mapperId,
          dateFormatDelimiter: column.dateFormatDelimiter,
          dateFormat: column.dateFormat,
          factColumnGuid: column.factColumnGuid,
          externalColumnName: column.externalColumnName,
        };
        return col;
      });
      updateMapper({
        userKey,
        browserHistory,
        databaseId: currentDb.id,
        mapperId: currentMapper.mapperId,
        mapperName: mappingName,
        mapperDescription: mappingDesc,
        factTableGuid: currentDb.factTableGuid,
        columns,
      });
    } else {
      const columns = onlyMappedColumns.map(column => {
        const col = {
          dateFormatDelimiter: column.dateFormatDelimiter,
          dateFormat: column.dateFormat,
          factColumnGuid: column.factColumnGuid,
          externalColumnName: column.externalColumnName,
        };
        return col;
      });
      createNewMapper({
        userKey,
        browserHistory,
        databaseId: currentDb.id,
        mapperName: mappingName,
        mapperDescription: mappingDesc,
        factTableGuid: currentDb.factTableGuid,
        columns,
      });
    }
  }

  render() {
    const { mappingName, mappingColumns, isEditing, uploads, softErrorMode } = this.state;
    const { currentDb, params, dateFormats, dateFormatDelimiters, currentMapper, errorMessage, browserHistory } = this.props;

    const isNew = !(isEditing && currentMapper);
    const nameError = this.getNameValidationError(softErrorMode);
    let fileSelect = '';
    if (isNew) {
      fileSelect = (
            <Form.Group controlId="fileSelect">
              <Form.Label>Select CSV File to Map</Form.Label>
              <Form.Control as="select" placeholder="Select a CSV File" onChange={this.selectFile}>
                <option value="select">-- Select a CSV file to map --</option>
                {uploads.map(u => (
                  <option key={`${u.id}`} value={u.id}>{u.name}</option>
                ))}
              </Form.Control>
            </Form.Group>);
    }

    let contentMarkup = (<div></div>);
    if (currentDb) {
      contentMarkup = (
        <div>
          <div style={{ marginBottom: 125 }}>
            <div style={{
              borderBottom: '2px solid #BDBDBD',
              margin: 'initial',
              height: 75,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center' }}>
            <h2 style={{ margin: 'initial', paddingLeft: 15 }}>{isEditing ? 'Edit Mapping' : 'Create Mapping'}</h2>
            <Button 
              mode='back' 
              onClick={() => browserHistory.push(`/trianglesOnDemand/databases/${params.databaseId}/mappings`)}
              toolTip='Back to Mappings List'/>
            </div>
            <div style={style.card}>
              <strong>Selected Database:</strong>
              <h3>{currentDb.name}</h3>
              {errorMessage ? (<h5 style={{ color: Colors.red }}>Error while saving mapper: {errorMessage}</h5>) : null}
              <div style={{ fontWeight: 800, display: 'flex', flexDirection: 'row' }}>Name:</div>
              <Row>
                <Col md={9}>
                  <Form.Group>
                    <Form.Control
                      className="form-control"
                      type="text" maxLength="255"
                      id="mappingName"
                      placeholder="Enter Mapping Name"
                      value={mappingName}
                      onChange={this.nameChangeHandler}
                      onBlur={this.trimName}
                      isInvalid={nameError}
                    />
                    <Form.Control.Feedback type='invalid'>{nameError}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div style={{ fontWeight: 800, display: 'flex', flexDirection: 'row' }}>Description:</div>
              <div className="row form-group">
                  <div className="col-md-9">
                      <textarea
                        className="form-control"
                        type="text" maxLength="4000"
                        id="mappingDesc"
                        placeholder="Enter Mapping Description"
                        value={this.state.mappingDesc}
                        onChange={this.descChangeHandler}
                      />
                  </div>
              </div>
              {fileSelect}
            </div><div><h6>CSV File Mapping</h6></div>
            <div style={[{ display: 'flex', flexDirection: 'row' }, style.card]}>
              
              <div style={{ display: 'flex' }}>
                <div className="table-responsive" style={{ paddingTop: 10, paddingLeft: 15, paddingRight: 15, minWidth: '65vw', margin: 'auto' }}>
                  <table className="table table-striped">
                    <thead style={style.tableRow} >
                      <tr>
                        <th style={style.cellPositioning}>CSV Column</th>
                        <th style={style.cellPositioning}>Database Column</th>
                        <th style={style.cellPositioning}>Date Format</th>
                        <th style={style.cellPositioning}>Date Format Delimiter</th>
                        <th style={style.cellPositioning}></th>
                      </tr>
                    </thead>
                    <tbody style={style.tableBody}>
                      {mappingColumns.map((column, idx) => (
                        <MappingRow key={`mappingRow-${idx}`} column={{ ...column }} idx={idx}
                          dbColumns={currentDb.columns}
                          dateFormats={dateFormats}
                          dateDelimiters={dateFormatDelimiters}
                          validateDates={!softErrorMode}
                          disabled={!isNew}
                          /* canEditDateFormats={!(isNew || (currentMapper && !currentMapper.hasDataLoaded))}*/ 
                          changeDbColumn={this.changeDbColumn}
                          changeDateFormat={this.changeDateFormat}
                          changeDateFormatDelimiter={this.changeDateFormatDelimiter} />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div 
          style={{ width: '100vw',
            padding: '0vh 10vw',
            overflowY: 'auto',
            height: 'calc(100vh - 106px)',
            position: 'relative' }}>
          {contentMarkup}
        </div>
        <SaveButtons
          saveHandler={this.createMapper}
          backButtonHander={() => browserHistory.push(`/trianglesOnDemand/databases/${currentDb.id}/mappings`)}
          backButtonText="Back to Mappers" />
      </div>
    );
  }
}

export default radium(CreateMapper);
