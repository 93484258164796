import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import {
    Form,
    ListGroup,
    ListGroupItem,
} from 'react-bootstrap';
import { Grid, Button} from '@arius';
import { showModal } from '../../../actions/modal.actions';
import SaveButtons from '../../shared/presentational/saveButtons';
import {
    clearRollupDefinitionsSnapshot,
    createRollupDefinitionsSnapshot,
} from '../../../actions/tod/queries.actions';
import { clearBackRoute } from '../../../actions/application.actions';
import { notifyError, alertMessage } from '../../../utilities/notifier';
import { alphabetize as sort } from '../../../utilities/helpers';
import { clearQuery } from '../../../actions/tod/queries.actions';
import Loader from '../../shared/presentational/loader';
import NoResults from '../../shared/presentational/noResults';
import PlaceHolderMessage from '../../shared/presentational/placeHolderMessage';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import AdxQueryWarning from '@app/shared/presentational/adxQueryWarning';

function alphabetize(list) {
  return list.sort((a, b) => {
    const dimensionA = a.rollupName
      .slice(1, a.rollupName.length - 1)
      .toUpperCase(),
      dimensionB = b.rollupName.slice(1, b.rollupName.length - 1).toUpperCase();
    if (dimensionA < dimensionB) {
      return -1;
    }
    if (dimensionA > dimensionB) {
      return 1;
    }
    return 0;
  });
}

function sortAllRollupDefinitions(rollupDefinitions, dimensions) {
  return dimensions.reduce((prev, curr) => {
    const definitions = prev,
      filteredAndOrdered = alphabetize(
        rollupDefinitions.filter(rd => rd.attributeName === curr)
      );
    return [...definitions, ...filteredAndOrdered];
  }, []);
}

class RollupDefinitionsList extends Component {
  static propTypes = {
    queries: PropTypes.array,
    dispatch: PropTypes.func,
    userKey: PropTypes.string,
    currentDb: PropTypes.object,
    isFetching: PropTypes.bool,
    isDataSourceFetching: PropTypes.bool,
    params: PropTypes.object,
    datasetSpecificRollupDefinitions: PropTypes.array,
    rollupDefinition: PropTypes.object,
    getRollupDefinitionsForDataSet: PropTypes.func,
    getRollupDefinition: PropTypes.func,
    createRollupDefinitionHandler: PropTypes.func,
    updateRollupDefinitionHandler: PropTypes.func,
    deleteRollupDefinitionHandler: PropTypes.func,
    datasetColumns: PropTypes.array,
    getDatasetColumns: PropTypes.func,
    databases: PropTypes.array,
    changeDatabase: PropTypes.func,
    getQueryDataSource: PropTypes.func,
    rollupDefinitionsComponentSnapshot: PropTypes.object,
    isProcessingNewRollupDefinition: PropTypes.bool,
    backRoute: PropTypes.object,
    queryInfo: PropTypes.object,
    currentQuery: PropTypes.object,
    getAllRollupDefinitions: PropTypes.func,
    allRollupDefinitions: PropTypes.array,
    dataSource: PropTypes.object,
    queriesUsingRollup: PropTypes.array,
    verifyPermission: PropTypes.func,
  };
  static defaultProps = {};
  constructor(props) {
    super(props);
    this.state = {
      databaseId: null,
      dimensionName: null,
      selectedDimension: null,
      dimensions: [],
      dataSource: PropTypes.object,
      dataSetColumnId: null,
      freeDataBaseMode: false,
      allRollupsReceived: false,
      dataSourceReceived: false,
      selectedRollupDefinition: null,
      alreadyFetched: false,
    };
    if (props.params) {
      if (props.params.databaseId) {
        this.state.databaseId = props.params.databaseId;
      } else {
        this.state.freeDataBaseMode = true;
      }

      if (props.params.dimensionName) {
        this.state.dimensionName = props.params.dimensionName;
      }
    }
    this.openCreateRollupDefinitions = this.openCreateRollupDefinitions.bind(
      this
    );
    this.backNav = this.backNav.bind(this);
    this.deleteRollupDefinition = this.deleteRollupDefinition.bind(this);
    this.openEditRollupDefinitions = this.openEditRollupDefinitions.bind(this);
    this.changeDatabase = this.changeDatabase.bind(this);
    this.getGrid = this.getGrid.bind(this);
    this.getActionItems = this.getActionItems.bind(this);
    this.getDetailTemplate = this.getDetailTemplate.bind(this);
  }

  componentDidMount() {
    const {
      currentDb,
      dispatch,
      dataSource,
      userKey,
      datasetColumns,
      getDatasetColumns,
      rollupDefinitionsComponentSnapshot,
      backRoute,
      getQueryDataSource,
      getAllRollupDefinitions,
      isProcessingNewRollupDefinition,
    } = this.props,
      { dimensionName, databaseId, freeDataBaseMode } = this.state;

    if (!this.props.rollupDefinitionsComponentSnapshot.loaded) {
      if (backRoute && backRoute.path && !freeDataBaseMode) {
        this.setState({ freeDataBaseMode: true });
      }
      if (currentDb && !isProcessingNewRollupDefinition) {
        getAllRollupDefinitions(userKey, currentDb.id);
        this.setState({ currentDb, databaseId: currentDb.id });
      }

      if (datasetColumns && !datasetColumns.length && databaseId) {
        getDatasetColumns({
          userKey,
          databaseId,
        });
      }

      if (currentDb && !dataSource && !currentDb.useADX) {
        getQueryDataSource(userKey, currentDb.id);
      }

      if (dataSource && dataSource.values) {
        const dimensions = dataSource.values.map(ds => ds.name),
          selectedDimension = dataSource.values.find(
            ds => ds.name === dimensionName
          );

        this.setState({
          dimensions,
          selectedDimension,
          dataSourceReceived: true,
        });
      }

      let dataSetColumnId;

      if (dimensionName) {
        dataSetColumnId = (datasetColumns.find(
          dmra => dmra.attributeName === dimensionName
        ) || {}).dataSetColumnId;
      }

      if (dataSetColumnId) {
        this.setState({ dataSetColumnId });
      }
    } else {
      const stateSnapshot = rollupDefinitionsComponentSnapshot.state;
      stateSnapshot._radiumStyleState = this.state._radiumStyleState;
      this.setState(stateSnapshot);
      dispatch(clearRollupDefinitionsSnapshot());
    }
  }

  componentWillReceiveProps(nextProps) {
    const { queriesUsingRollup, queries } = this.props,
      { queriesUsingRollup: nextQueriesUsingRollup } = nextProps;
    if (queriesUsingRollup !== nextQueriesUsingRollup && nextQueriesUsingRollup.length) {
      const filteredQueries = queries.filter((q) => 
        nextQueriesUsingRollup.indexOf(q.queryId) !== -1
      );
      let queryNames = sort(filteredQueries, 'queryName', true);
      queryNames = filteredQueries.map(fq => 
        `<span>${fq.queryName}</span>`
      );
      const displayString = queryNames.join('<br>');
      alertMessage('Cannot delete this rollup definition while in use by the following queries:', displayString);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      currentDb,
      dataSource,
      userKey,
      getRollupDefinitionsForDataSet,
      datasetColumns,
      getQueryDataSource,
      allRollupDefinitions,
      getAllRollupDefinitions,
    } = this.props,
      {
        dimensionName,
        databaseId,
        dataSourceReceived,
        allRollupsReceived,
        dimensions,
        allRollupDefinitions: state_all
      } = this.state;

    if (currentDb && currentDb.useADX && (state_all ? state_all.length : 0) > 0){
        this.setState({allRollupDefinitions: [] });
        return;
    }
    if (currentDb && currentDb !== prevProps.currentDb && !currentDb.useADX) {
      getAllRollupDefinitions(userKey, currentDb.id);
      this.setState({ currentDb, databaseId: currentDb.id });
      getQueryDataSource(userKey, currentDb.id);
    }

    if (
      ((allRollupDefinitions &&
      allRollupDefinitions.length) || 
      (prevProps.allRollupDefinitions.length && 
      !allRollupDefinitions.length)) &&
      allRollupDefinitions !== prevProps.allRollupDefinitions
    ) {
      this.setState({ allRollupsReceived: true });
    }

    if (
      dataSource &&
      dataSource.values &&
      (!prevProps.dataSource ||
        (prevProps.dataSource &&
          prevProps.dataSource.values &&
          dataSource.values !== prevProps.dataSource.values))
    ) {
      const mappedDimensions = dataSource.values.map(ds => ds.name),
        selectedDimension = dataSource.values.find(
          ds => ds.name === dimensionName
        );
      this.setState({
        dimensions: mappedDimensions,
        selectedDimension,
        dataSourceReceived: true,
      });
    }

    if (dataSourceReceived && allRollupsReceived) {
      const sortedRollupDefinitions = sortAllRollupDefinitions(
        allRollupDefinitions,
        dimensions
      );
      this.setState({
        dataSourceReceived: false,
        allRollupsReceived: false,
        allRollupDefinitions: sortedRollupDefinitions,
        alreadyFetched: true,
      });
    }

    let dataSetColumnId;

    if (
      dimensionName &&
      (dimensionName !== prevState.dimensionName ||
        (datasetColumns.length && !prevProps.datasetColumns.length))
    ) {
      dataSetColumnId = (datasetColumns.find(
        dmra => dmra.attributeName === dimensionName
      ) || {}).dataSetColumnId;
    }

    if (
      (databaseId && dataSetColumnId && databaseId !== prevProps.databaseId) ||
      dataSetColumnId !== prevProps.dataSetColumnId
    ) {
      getRollupDefinitionsForDataSet({
        userKey,
        factDatabaseId: databaseId,
        dataSetColumnId,
      });
    }

    if (dataSetColumnId) {
      this.setState({ dataSetColumnId });
    }
  }

  openCreateRollupDefinitions() {
    const { databaseId, dimensionName, freeDataBaseMode } = this.state,
      snapshot = {
        loaded: true,
        state: this.state,
        props: {},
      },
      { dispatch, browserHistory, } = this.props;

    if (!databaseId) {
      notifyError('You must first select a database');
    } else {
      if (freeDataBaseMode) {
        dispatch(createRollupDefinitionsSnapshot(snapshot));
      }

      if (databaseId && dimensionName) {
        browserHistory.push(
          `/trianglesOnDemand/queries/createEditRollupDefinitions/${databaseId}/${dimensionName}`
        );
      } else {
        browserHistory.push(
          '/trianglesOnDemand/queries/createEditRollupDefinitions'
        );
      }
    }
  }

  openEditRollupDefinitions(e, rollupDefinitionId, attributeName) {
    e.stopPropagation();
    const { databaseId, freeDataBaseMode } = this.state,
      snapshot = {
        loaded: true,
        state: this.state,
        props: {},
      },
      { dispatch, browserHistory, } = this.props;

    if (!databaseId) {
      notifyError('You must first select a database');
    } else {
      if (freeDataBaseMode) {
        dispatch(createRollupDefinitionsSnapshot(snapshot));
      }

      browserHistory.push(
        `/trianglesOnDemand/queries/createEditRollupDefinitions/${databaseId}/${attributeName}/${rollupDefinitionId}/edit`
      );
    }
  }

  backNav() {
    const { databaseId, freeDataBaseMode } = this.state,
      {
        backRoute,
        dispatch,
        changeDatabase,
        queryInfo,
        queries,
        currentQuery,
        browserHistory,
      } = this.props;

    if (databaseId && !freeDataBaseMode) {
      let route, queryId;

      if (queryInfo && queryInfo.queryName) {
        queryId = currentQuery
          ? currentQuery.queryId
          : (queries.find(q => q.queryName === queryInfo.queryName) || {})
              .queryId;
      }
      if (queryId) {
        route = `/trianglesOnDemand/databases/${databaseId}/queries/${queryId}/edit`;
        dispatch(clearQuery());
      } else {
        route = `/trianglesOnDemand/databases/${databaseId}/queries/new`;
      }
      browserHistory.push(route);
    } else if (backRoute && backRoute.path) {
      const path = backRoute.path;
      dispatch(clearBackRoute());
      changeDatabase(null);
      browserHistory.push(path);
    }
  }

  changeDatabase(e) {
    const {
      databases,
      changeDatabase,
      userKey,
      getDatasetColumns,
      getAllRollupDefinitions,
    } = this.props,
      database = databases.find(
        d => d.workspaceId.toString() === e.target.value
      );
    if (database) {
      getDatasetColumns({ userKey, databaseId: database.workspaceId });
      this.setState({ dataSourceReceived: false, allRollupsReceived: false });
      getAllRollupDefinitions(userKey, database.workspaceId);
      changeDatabase(database);
    }
  }

  deleteRollupDefinition(e, rollupDefinitionId, rollupName) {
    e.stopPropagation();
    this.setState({ dataSourceReceived: true });
    const { userKey, dispatch, deleteRollupDefinitionHandler } = this.props,
      { databaseId, dataSetColumnId } = this.state;

    const deleteMessageItems = [
      <li key={`${rollupName}-delete`} style={{ fontWeight: 800 }}>
        {rollupName}
      </li>,
    ];

    deleteMessageItems.unshift(
      'Are you sure you want to delete the following rollup definition?'
    );

    const yesClickHandler = () => {
        deleteRollupDefinitionHandler({
          userKey,
          factDatabaseId: databaseId,
          rollupDefinitionId,
          dataSetColumnId,
        });
      },
      noClickHandler = () => {},
      action = showModal(
        'confirmation',
        deleteMessageItems,
        yesClickHandler,
        noClickHandler
      );
    dispatch(action);
  }

    getGrid() {
        const { allRollupDefinitions:data } = this.state;

        let columns = [
            { field: 'attributeName', headerText: 'Dimension', width: '30%'},
            { field: 'rollupName', headerText: 'Name', width:'60%'},
            { field: 'rollupDescription', headerText: 'Description', width:'60%'},
            { field: 'auditUser', headerText: 'Last Modified By', width: '130px'},
            { field: 'auditDatetime', headerText: 'Last Modified Date', type: 'datetime'},
            { headerText: 'Actions', width: '100px', template: this.getActionItems}
        ];
        return <Grid columns={columns} data={data} height='calc(100vh - 330px)' 
            detailTemplate={this.getDetailTemplate}/>
    }

    getDetailTemplate(r) {
        let rData = [];
        if (r.rollupData) {
            rData = JSON.parse(r.rollupData);
        }
        if (!rData.length) {
            rData.push('All');
        }
        return (
        <small>
            <div>
                <span style={{ fontWeight: 'bold' }}>Description: </span>
                {r.rollupDescription}
            </div>
            <ListGroup style={{border: '0px'}}>
                {rData.map((data, i) => (
                <ListGroupItem style={{ border: '0px', padding: '2px 20px 2px 20px' }} key={`${data}-${i}`}>
                    {data}
                </ListGroupItem>
                ))}
            </ListGroup>
        </small>
        )
    }

    getActionItems(r) {
        const { currentDb, verifyPermission,} = this.props;
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        const hasWriteAccess = currentDb && hasPermissions;

        return (
            <span>
                <Button
                    id={`edit-${r.queryId}`}
                    onClick={e =>
                        this.openEditRollupDefinitions(e, r.rollupDefinitionId, r.attributeName)}
                    toolTip={hasWriteAccess ? 'View/Edit' : 'View'}
                    iconName='edit'
                />
                <Button
                    id={`delete-${r.queryId}`}
                    onClick={(e) =>{
                        if (hasWriteAccess){
                            this.deleteRollupDefinition(e,r.rollupDefinitionId,r.rollupName)
                        } else {
                            e.stopPropagation();
                            notifyError('You are not authorized to access this functionality');
                        }
                    }}
                    toolTip='Delete Query'
                    iconName='delete'
                />
            </span>
        )
    }



  render() {
    const { isFetching, databases, isDataSourceFetching, currentDb, verifyPermission, } = this.props,
      {
        freeDataBaseMode,
        allRollupDefinitions,
      } = this.state;
    const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
    const hasWriteAccess = currentDb && hasPermissions;
    
    let dimensionSelectJSX = <div />,
      databaseHeaderJSX = <h4>{currentDb ? currentDb.name : ''}</h4>,
      tableMarkup = <div />;
    
    if (freeDataBaseMode) {
      databaseHeaderJSX = (
        <div style={{ display: 'flex' }}>
          <Form.Group
            controlId="selectDatabaseControl"
            style={{ width: '25vw', marginTop: 10 }}
          >
            <Form.Label>Select Database</Form.Label>
            <Form.Control
              as="select"
              placeholder="select"
              value={currentDb ? currentDb.id : 'default'}
              onChange={this.changeDatabase}
            >
              <option value="default">Select a database</option>
              {databases.map(db => (
                <option key={`${db.workspaceId}`} value={db.workspaceId}>
                  {db.workspaceName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      );
    }

    if (allRollupDefinitions && allRollupDefinitions.length) {
        tableMarkup = this.getGrid();
    } else if (!currentDb) {
      tableMarkup = (
        <div style={{ margin: '5vh auto' }}>
          <PlaceHolderMessage message="Please select a database" />
        </div>
      );
    } else if (currentDb.useADX){
        tableMarkup = <AdxQueryWarning/>
    }
    else if (isFetching || isDataSourceFetching || !databases.length) {
      tableMarkup = <div style={{ margin: '5vh auto' }}><Loader loadingItem="Rollup Definitions" /></div>;
    } else {
      tableMarkup = (
        <div style={{ margin: '5vh auto' }}>
          <NoResults />
        </div>
      );
    }

    return (
      <div className="list-container-arius" style={{ overflowY: 'auto', height: 'calc(100vh - 107px)' }}>
        <div className="list-header-arius">
          <h4>Rollup Definitions</h4>
          <span>
          <Button 
              mode='add' 
              onClick={(e) =>{
                if (hasWriteAccess){
                  this.openCreateRollupDefinitions()
                } else {
                  notifyError('You are not authorized to access this functionality');
                }
              }}
              toolTip='Create New Rollup Definition'/>
          </span>
        </div>
        {databaseHeaderJSX}
        {dimensionSelectJSX}
        {tableMarkup}
        <SaveButtons
          backButtonHander={() => this.backNav()}
          backButtonText="Back"
        />
      </div>
    );
  }
}

export default radium(RollupDefinitionsList);
