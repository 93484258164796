import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Grid, Button } from '@arius';
import { notifySuccess } from '@app/utilities/notifier';
import { showModal } from '@app/actions/modal.actions';
import { deleteSubscriptionUser } from '@app/actions/portalManagement/subscriptionUser.actions';
import Loader from '@app/shared/presentational/loader';
import NoResults from '@app/shared/presentational/noResults';
import { notifyError } from '../../../utilities/notifier';
import { notAuthorized } from '@app/shared/containers/conditionalLink';

const style = {
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50,
    marginBottom: 10,
    marginLeft: 10,
    marginTop: 10,
  },
  userToolbar: {
    backgroundColor: '#EAEAEA',
  },
};

class UsersList extends Component {
    static propTypes = {
        organizationId: PropTypes.number.isRequired,
        userKey: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        users: PropTypes.object,
        dispatch: PropTypes.func,
        isUserUpdated: PropTypes.bool,
        readOnly: PropTypes.bool,
    };
    static defaultProps = { organizationId: 0, isFetching: false, userKey: '', users: {} };
    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: null,
            currentUsers: [],
            isUpdated: false,
        };

        this.userCheckClickHandler = this.userCheckClickHandler.bind(this);
        this.deleteClickHandler = this.deleteClickHandler.bind(this);
        this.getGrid = this.getGrid.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
    }

    componentDidMount() {
        if (this.props.users && this.props.users.subscriptionUsers.length > 0) {
            this.setState({currentUsers: this.props.users.subscriptionUsers});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.users !== this.props.users) {
            this.setState({currentUsers: this.props.users.subscriptionUsers});
        }
        if (prevProps.isUserUpdated !== this.props.isUserUpdated) {
            this.setState({ isUpdated: this.props.isUserUpdated });
        }

        if (prevState.isUpdated !== this.state.isUpdated && this.state.isUpdated) {
            this.setState({ isUpdated: false });
            notifySuccess('User Updated!');
        }
    }

    userCheckClickHandler(id, e) {
        e.stopPropagation();
        if (!e.target.classList.contains('more-menu')) {
            let { selectedUserId } = this.state;
            selectedUserId = selectedUserId === id ? null : id;
            this.setState({ selectedUserId });
        }
    }

    deleteClickHandler(id, e) {
        e.stopPropagation();

        const { dispatch, users, userKey } = this.props;

        if (users.subscriptionUsers.length !== 0) {
            const
                selectedUser = users.subscriptionUsers.find(u => u.userId === id),
                deleteMessageItems = [(
                <li key={`${selectedUser.id}-delete`} style={{ fontWeight: 800 }}>{selectedUser.name}</li>
            )];

            deleteMessageItems.unshift('Are you sure you want to delete the following users?');

            const yesClickHandler = () => {
                dispatch(deleteSubscriptionUser({ userKey, userId: id }));
                this.setState({ selectedUserId: null });
            };
            const noClickHandler = () => {};
            const action = showModal('confirmation', deleteMessageItems, yesClickHandler, noClickHandler);
            dispatch(action);
        }
    }

    searchUsers(e) {
        const
        searchText = e.target.value.toLowerCase(),
        { users } = this.props,
        subscriptionUsers = users.subscriptionUsers ? users.subscriptionUsers : [];
        let currentUsers;

        if (searchText.trim() === '') {
            currentUsers = subscriptionUsers;
        } else {
            currentUsers = subscriptionUsers.filter(user => {
                if (user.name.toLowerCase().includes(searchText) || user.email.toLowerCase().includes(searchText)) {
                    return true;
                } else {
                    return false;
                }
            });
        }
        this.setState({ currentUsers });
    }

    getActionItems(row) {
        const { browserHistory, readOnly } = this.props;
        return  (
            <span>
            <Button 
                iconName="edit" 
                onClick={() => {
                    readOnly ? 
                    notifyError('You are not authorized to access this functionality') : 
                    browserHistory.push(`/portalManagement/users/edit/${row.userId}`)
                }}
                toolTip='View/Edit'/>
            <Button 
                iconName="delete" 
                onClick={(e) => (readOnly ? notAuthorized : this.deleteClickHandler)(row.userId, e)}
                toolTip='Delete'/>                
            </span>
        )
    }

    getGrid(data) {
        let columns = [
            { field: 'name', headerText: 'Name'},
            { field: 'email', headerText: 'Email'},
            { headerText: 'Actions', width: '100px', template: this.getActionItems},
        ];
        
        return <Grid 
            columns={columns}
            data={data}
            height='calc(100vh - 240px)' 
            allowPaging={false}/>
    }

    render() {
        const {browserHistory } = this.props;
        const {
            isFetching,
            readOnly,
        } = this.props,
        { currentUsers } = this.state;

        let contentMarkup = (
        <div style={{ margin: '5vh auto' }}>
            <Loader loadingItem="Users" />
        </div>
        );

        if (!isFetching) {
            contentMarkup = (
                <div style={{ margin: '5vh auto' }}>
                <NoResults />
                </div>
            );
            if (currentUsers.length !== 0) {
                contentMarkup = this.getGrid(currentUsers);
            }
        }

        return (
            <div className="list-container-arius" style={{maxHeight: 'calc(100vh - 106px)', overflowY: 'auto'}}>
                <div style={[style.row, style.userToolbar]}>
                    <div style={style.row}>
                        <div style={style.inputLabel}>
                        Quick Search
                        </div>
                        <input style={style.input} type="text" onChange={(e) => this.searchUsers(e)} />
                    </div>
                </div>
                <div className="list-header-arius">
                    <h4 style={{ margin: 'initial', paddingLeft: 15 }}>Users</h4>
                    <Button 
                    mode='add' 
                    onClick={() => {
                        readOnly ? 
                            notifyError('You are not authorized to access this functionality') :
                            browserHistory.push('/portalManagement/users/edit')
                    }}
                    toolTip='Add new user'/>
                </div>
                {contentMarkup}
            </div>
        )
    }
}

export default radium(UsersList);
