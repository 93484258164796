import React from 'react';
import { NavTabs, NavTab } from '@app/utilities/routing';
import Feature from '@app/utilities/routing/routeSafeFeature';

function AriusAnalysisNav() {
    return (
        <Feature flag="ariusAnalysis">
            <NavTabs>
                <NavTab label={'Databases'} to={'/analysis/workspaces'}/>
                <NavTab label={'Analysis'} to={'/analysis/analysisTab'}/>
                <NavTab label={'Automation'} to={'/analysis/automation'}/>
                <NavTab label={'Reporting'} to={'/analysis/reporting'}/>
                <Feature flag="powerBiReports">
                    <NavTab label={'Power BI'} to={'/analysis/powerbi'}/>
                </Feature>                
                <NavTab label={'Downloads'} to={'/analysis/downloads'}/>
            </NavTabs>
        </Feature>
    );
}   

export default AriusAnalysisNav;
