
import { saveAs } from 'file-saver';

import { callApi, getBaseServiceUrl } from '../utilities/callApi';
import { getToken } from '../utilities/adalAdapter';

export function getAvailableDownloadTypes(userKey) {
  return callApi(
    `downloads/fileTypes`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function getDatabasesWithAvailableDownloads(userKey) {
  return callApi(
    `downloads/databases`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

export function getAvailableDownloads(userKey, downloadType, databaseId) {
  return callApi(
    `downloads/${downloadType}/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'GET'
  );
}

//export function initiateDownload(userKey, downloadType, databaseId, downloadId) {
//  return callApi(
//    `downloads/${downloadType}/${databaseId}/${downloadId}`,
//    { 'X-AE-USERKEY': userKey, 'Accept': 'application/zip' },
//    'GET',
//  );
//}

export function initiateDownload(userKey, downloadType, databaseId, downloadId, zipFileName) {
  return getBaseServiceUrl().then(baseUrl =>
    getToken().then(
      token =>
        new Promise((resolve, reject) => {
          const http = new XMLHttpRequest();
          http.open(
            'GET',
            `${baseUrl}/downloads/${downloadType}/${databaseId}/${downloadId}`,
            true
          );
          http.setRequestHeader(
            'Content-Type',
            'application/zip'
          );
          http.setRequestHeader('Authorization', `Bearer ${token}`);
          http.setRequestHeader('X-AE-USERKEY', userKey);
          http.responseType = 'arraybuffer';
          http.send();
          http.onreadystatechange = () => {
            if (http.readyState === 4) {
              if (http.status === 200) {
                const blob = new Blob([http.response], {
                  type: 'application/zip;',
                });
                resolve(zipFileName);
                saveAs(blob, zipFileName);
              } else {
                reject(http.response);
              }
            }
          };
        })
    )
  );
}

export function deleteExportFile(userKey, downloadType, databaseId, downloadId) {
  return callApi(
    `downloads/${downloadType}/${databaseId}/${downloadId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}
