import {
  FETCHING_QUERIES,
  RECEIVED_QUERIES,
  RECEIVED_QUERY,
  RECEIVED_V2_QUERY,
  RECEIVED_QUERY_DATASOURCE,
  FETCHING_QUERY_DATA_SOURCE,
  SAVING_TOD_QUERY,
  RECEIVED_SEGMENT_PREVIEW,
  FETCHING_ROLLUPS,
  RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS,
  FETCHING_ROLLUP_DEFIINITION_FROM_SERVER,
  RECEIVED_ROLLUP_DEFINITION,
  RECEIVED_DATASET_COLUMNS,
  LOAD_ROLLUPDEFINITIONS_SNAPSHOT,
  CLEAR_ROLLUPDEFINITIONS_SNAPSHOT,
  CLEAR_ROLLUPDEFINITIONS,
  FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION,
  PROCESSING_NEW_ROLLUP_DEFINITION,
  STORE_QUERY,
  CLEAR_QUERY,
  FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER,
  RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER,
  RECEIVED_QUERIES_USING_ROLLUP,
} from './tod.actionTypes';
import * as queriesServiceApi from '../../serviceApi/queries.serviceApi';

function fetchingFromServer() {
  return {
    type: FETCHING_QUERIES,
  };
}

function fetchingRollupsFromServer() {
  return {
    type: FETCHING_ROLLUPS,
  };
}

function finishedProcessingNewRollupDefinition() {
  return {
    type: FINISHED_PROCESSING_NEW_ROLLUP_DEFINITION,
  };
}

function fetchingRollupDefinitionFromServer() {
  return {
    type: FETCHING_ROLLUP_DEFIINITION_FROM_SERVER,
  };
}

function fetchingAllRollupDefinitionsFromServer() {
  return {
    type: FETCHING_ALL_ROLLUP_DEFIINITIONS_FROM_SERVER,
  };
}

function savingToServer() {
  return {
    type: SAVING_TOD_QUERY,
  };
}

function fetchingQueryDataSource() {
  return {
    type: FETCHING_QUERY_DATA_SOURCE,
  };
}

function receivedQueries(json) {
  return {
    type: RECEIVED_QUERIES,
    items: json,
  };
}

function receivedV2Query(query) {
  return {
    type: RECEIVED_V2_QUERY,
    item: query,
  };
}

function receivedQueriesUsingRollup(queriesUsingRollup) {
  return {
    type: RECEIVED_QUERIES_USING_ROLLUP,
    queriesUsingRollup,
  };
}

function receivedDatasetSpecificRollupDefinitions(json) {
  return {
    type: RECEIVED_DATASET_SPECIFIC_ROLLUP_DEFINITIONS,
    items: json,
  };
}

function receivedRollupDefinition(json) {
  return {
    type: RECEIVED_ROLLUP_DEFINITION,
    items: json,
  };
}

function receivedAllRollupDefinitions(json) {
  return {
    type: RECEIVED_ALL_ROLLUP_DEFINITIONS_FROM_SERVER,
    items: json,
  };
}

function receivedQuery(json) {
  return {
    type: RECEIVED_QUERY,
    current: json,
  };
}

function receivedQueryDataSource(json) {
  return {
    type: RECEIVED_QUERY_DATASOURCE,
    dataSource: json,
  };
}

function receivedSegmentPreview(json) {
  return {
    type: RECEIVED_SEGMENT_PREVIEW,
    segmentPreview: json,
  };
}

function receivedDataSetColumns(json) {
  return {
    type: RECEIVED_DATASET_COLUMNS,
    columns: json,
  };
}

function fetchQueries(userKey, databaseId) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return queriesServiceApi
      .retrieveQueries(userKey, databaseId)
      .then(json => dispatch(receivedQueries(json)));
  };
}

function fetchQuery(userKey, databaseId, queryId) {
  return dispatch => {
    dispatch(fetchingFromServer());
    return queriesServiceApi
      .retrieveQuery(userKey, databaseId, queryId)
      .then(json => dispatch(receivedQuery(json)));
  };
}

// function fetchQueryDataSource(userKey, databaseId, filters = [], splits = []) {
//  const queryParams = `?filters=${encodeURIComponent(JSON.stringify(filters))}&splits=${encodeURIComponent(splits)}`;
//  return (dispatch) => {
//    dispatch(fetchingQueryDataSource());
//    return queriesServiceApi.retrieveQueryDataSource(userKey, databaseId, queryParams)
//            .then(json => dispatch(receivedQueryDataSource(json)));
//  };
// }
function fetchAllRollupDefinitions(userKey, databaseId) {
  return dispatch => {
    dispatch(fetchingAllRollupDefinitionsFromServer());
    return queriesServiceApi
      .retrieveRollupDefinitions(userKey, databaseId)
      .then(json => dispatch(receivedAllRollupDefinitions(json)));
  };
}

function fetchQueryDataSource(userKey, databaseId, filters = [], splits = []) {
  const queryParams = { filters, splits };
  return dispatch => {
    dispatch(fetchingQueryDataSource());
    return queriesServiceApi
      .retrieveQueryDataSource(userKey, databaseId, queryParams)
      .then(json => {
          if (null == json) {return;}
        queriesServiceApi
          .retrieveRollupDefinitions(userKey, databaseId)
          .then(() => {
            // map dimension values as objects with nullable rollupDefinitionId instead of array of strings
            const adjJson = {
              ...json,
              values: json.values.map(v => {
                const dimValues = {
                  ...v,
                  values: v.values.map(val => {
                    const dimVal = {
                      value: val,
                      rollupDefinitionId: null,
                      rollupData: [],
                    };
                    return dimVal;
                  }),
                };
                return dimValues;
              }),
              provisional: json.provisional.map(p => {
                const provisionalValues = {
                  ...p,
                  values: p.values.map(val => {
                    const proVal = {
                      value: val,
                      rollupDefinitionId: null,
                      rollupData: [],
                    };
                    return proVal;
                  }),
                };
                return provisionalValues;
              }),
            };
            // push rollupDefinition into values array
            // adjJson.values.forEach(v => {
            //   if (v.values.length) {
            //     rollupDefinitions.forEach(rd => {
            //       if (v.name === rd.attributeName) {
            //         v.values.push({
            //           value: rd.rollupName,
            //           rollupDefinitionId: rd.rollupDefinitionId,
            //           // rollupData: JSON.parse(rd.rollupData),
            //           rollupData: JSON.parse(rd.rollupData.replace(/'/g, '"')),
            //         });
            //       }
            //     });
            //   }
            // });
            dispatch(receivedQueryDataSource(adjJson));
          });
      });
  };
}

function fetchSegmentPreview(userKey, databaseId, filters = [], splits = []) {
  const queryParams = { filters, splits };
  return dispatch => {
    dispatch(fetchingFromServer());
    return queriesServiceApi
      .retrieveSegmentPreview(userKey, databaseId, queryParams)
      .then(json => dispatch(receivedSegmentPreview(json)));
  };
}

function fetchDatasetColumns({ userKey, databaseId }) {
  return dispatch => {
    dispatch(fetchingRollupsFromServer());
    return queriesServiceApi
      .retrieveDatasetColumns({ userKey, databaseId })
      .then(json => dispatch(receivedDataSetColumns(json)));
  };
}

function createQuery(
  { userKey, databaseId, queryName, queryDescription, columns, defaultDateY },
) {
  return dispatch => {
    dispatch(savingToServer());
    return queriesServiceApi
      .createQuery({
        userKey,
        databaseId,
        queryName,
        queryDescription,
        columns,
        defaultDateY,
      })
      .then(() => dispatch(fetchQueries(userKey, databaseId)));
  };
}

function updateQuery(
  {
    userKey,
    databaseId,
    queryId,
    queryName,
    queryDescription,
    columns,
    defaultDateY,
  },
) {
  return dispatch => {
    dispatch(savingToServer());
    return queriesServiceApi
      .updateQuery({
        userKey,
        databaseId,
        queryId,
        queryName,
        queryDescription,
        columns,
        defaultDateY,
      })
      .then(() => dispatch(fetchQuery(userKey, databaseId, queryId)))
      .then(() => dispatch(fetchQueries(userKey, databaseId)));
  };
}

function deleteQuery(userKey, databaseId, queryId) {
  return dispatch => {
    dispatch(savingToServer());
    return queriesServiceApi
      .deleteQuery(userKey, databaseId, queryId)
      .then(() => dispatch(fetchQueries(userKey, databaseId)));
  };
}

function fetchRollupDefinitionsForDataSet(
  { userKey, factDatabaseId, dataSetColumnId },
) {
  return dispatch => {
    dispatch(fetchingRollupsFromServer());
    return queriesServiceApi
      .retrieveRollupDefinitionsForDataSet({
        userKey,
        factDatabaseId,
        dataSetColumnId,
      })
      .then(json => dispatch(receivedDatasetSpecificRollupDefinitions(json)));
  };
}

function fetchRollupDefinition(
  { userKey, factDatabaseId, dataSetColumnId, rollupDefinitionId },
) {
  return dispatch => {
    dispatch(fetchingRollupDefinitionFromServer());
    return queriesServiceApi
      .retrieveRollupDefinition({
        userKey,
        factDatabaseId,
        dataSetColumnId,
        rollupDefinitionId,
      })
      .then(json => dispatch(receivedRollupDefinition(json)));
  };
}

function createRollupDefinition(
  {
    userKey,
    factDatabaseId,
    dataSetColumnId,
    rollupName,
    rollupDescription,
    rollupData,
    allSelected,
  },
) {
  return dispatch =>
    queriesServiceApi
      .createRollupDefinition({
        userKey,
        factDatabaseId,
        dataSetColumnId,
        rollupName,
        rollupDescription,
        rollupData,
        allSelected,
      })
      .then(() => dispatch(fetchAllRollupDefinitions(userKey, factDatabaseId)))
      .then(() => dispatch(finishedProcessingNewRollupDefinition()));
}

function updateRollupDefinition(
  {
    userKey,
    factDatabaseId,
    rollupDefinitionId,
    rollupName,
    rollupDescription,
    rollupData,
    allSelected,
  },
) {
  return dispatch =>
    queriesServiceApi
      .updateRollupDefinition({
        userKey,
        factDatabaseId,
        rollupDefinitionId,
        rollupName,
        rollupDescription,
        rollupData,
        allSelected,
      })
      .then(() => dispatch(fetchAllRollupDefinitions(userKey, factDatabaseId)))
      .then(() => dispatch(finishedProcessingNewRollupDefinition()));
}

function deleteRollupDefinition(
  { userKey, factDatabaseId, rollupDefinitionId },
) {
  return dispatch =>
    queriesServiceApi
      .deleteRollupDefinition({ userKey, factDatabaseId, rollupDefinitionId })
      .then((res) => {
        if (res.status === 409) {
          dispatch(receivedQueriesUsingRollup(res.data));
        } else dispatch(fetchAllRollupDefinitions(userKey, factDatabaseId));
      });
}

function createRollupDefinitionsSnapshot(snapshot) {
  return {
    type: LOAD_ROLLUPDEFINITIONS_SNAPSHOT,
    payload: snapshot,
  };
}

function clearRollupDefinitionsSnapshot() {
  return {
    type: CLEAR_ROLLUPDEFINITIONS_SNAPSHOT,
  };
}

function clearRollupDefinitions() {
  return {
    type: CLEAR_ROLLUPDEFINITIONS,
  };
}

function processNewRollupDefinition() {
  return {
    type: PROCESSING_NEW_ROLLUP_DEFINITION,
  };
}

function storeQuery({ queryName, databaseId }) {
  return {
    type: STORE_QUERY,
    query: {
      queryName,
      databaseId,
    },
  };
}

function clearQuery() {
  return {
    type: CLEAR_QUERY,
  };
}

export {
  storeQuery,
  clearQuery,
  processNewRollupDefinition,
  clearRollupDefinitions,
  clearRollupDefinitionsSnapshot,
  createRollupDefinitionsSnapshot,
  fetchQueries,
  fetchQuery,
  fetchQueryDataSource,
  fetchSegmentPreview,
  fetchDatasetColumns,
  createQuery,
  deleteQuery,
  updateQuery,
  fetchRollupDefinitionsForDataSet,
  fetchAllRollupDefinitions,
  fetchRollupDefinition,
  createRollupDefinition,
  updateRollupDefinition,
  deleteRollupDefinition,
  receivedV2Query,
};
