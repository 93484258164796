export function validateName(name, soft, dashAllowed, startWithALetter) {
  if (!name && !soft) {
    return 'Required';
  }

  if (typeof (name) !== 'string') {
    return 'Invalid value';
  }

  const trimmed = name.trim();
  if (dashAllowed) {
    if (!trimmed.match(/^[\w\s-]*$/) || trimmed.match(/^_/)) {
      return 'Only use Letters, Numbers, Underscores, Hyphens and Spaces. First character cannot be underscore.';
    }
  } else {
    if (startWithALetter === true){
      if (soft && trimmed === '') {
        return '';// allow empty string on soft compare
      }
      let re_FirstCharacterALetter = /^[A-Za-z].*$/;

      if (!trimmed.match(re_FirstCharacterALetter) || !trimmed.match(/^[\w\s]*$/) || trimmed.match(/^_/)) {
        return 'Only use Letters, Numbers, Underscores and Spaces.  First character must be a letter.';
      }
    }
    else if (!trimmed.match(/^[\w\s]*$/) || trimmed.match(/^_/)) {
      return 'Only use Letters, Numbers, Underscores and Spaces. First character cannot be underscore.';
    }
  }
  return '';
}
