import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import Resizer from '../../shared/presentational/resizer';
import Loader from '../../shared/presentational/loader';
import { COMMAND_STATUSES } from '../../../utilities/constants';
import * as Colors from '../../../utilities/colors';
import moment from 'moment';
import {
  ButtonGroup,
  Button,
} from 'react-bootstrap';

const style = {
  mainContainer: { 
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '85vw',
    background: 'white',
    position: 'fixed',
    zIndex: '50',
    bottom: '0vh',
    minHeight: '11px',
  },
  commandContainer: {
    // border: '1px solid #BDBDBD',
    overflowY: 'auto',
    borderTop: 'none',
  },
};

function prepareInitialized(cs) {
  const unrecognized = (cs.statusParams.unrecognized || []).map(f => <li>{f}</li>);
  const inputs = [];
  const sdfs = [];
  const isdfs = [];
  for (let i = 0; i < cs.statusParams.data.length; i++) {
    const t = cs.statusParams.data[i];
    switch (t.dataType) {
      case 1: 
        inputs.push(<li>{t.name}({t.id})</li>);
        break;
      case 2: 
        sdfs.push(<li>{t.name}({t.id})</li>);
        break;
      case 3: 
        isdfs.push(<li>{t.name}({t.id})</li>);
        break;
      default:
        break;
    }
  }
  const sUnrec = unrecognized.length === 0 ? null : <span>Unrecognized<ul>{unrecognized}</ul></span>;
  const sInputs = inputs.length === 0 ? null : <span>Inputs<ul>{inputs}</ul></span>;
  const sFactors = sdfs.length === 0 ? null : <span>Selected factors<ul>{sdfs}</ul></span>;
  const sInterp = isdfs.length === 0 ? null : <span>Interpolated factors<ul>{isdfs}</ul></span>;
  return { 
    ...cs,
    statusMessage: (<div>{sUnrec}{sInputs}{sFactors}{sInterp}</div>),
    status: unrecognized.length > 0 ? COMMAND_STATUSES.FAILED : cs.status,
  };
}

function prepareDataLoaded(cs) {
  return cs;
}

function fromJson(val) {
  try {
    return typeof val === 'string' ? JSON.parse(val) : val;
  } catch (ex) {
    return val;
  }
}

function prepareApplyToSegments(cs) {

  const selector = [];
  for (const p in cs.commandParams) {
    if (cs.commandParams.hasOwnProperty(p)) {
      selector.push(<li><span className="lbl">{p}: </span>{cs.commandParams[p]}</li>);
    }
  }

  const found = cs.statusParams.matched || fromJson(cs.statusParams.params) || [];
  const locked = cs.statusParams.locked || [];

  let msg1 = null;
  if (found.length > 0) {
    msg1 = (<span>Matching segments:<ul>{found.map(s => <li>{s}</li>)}</ul></span>);
  }
  
  let msg2 = null;
  if (locked.length > 0) {
    msg2 = <span>Locked tables:<ul>{locked.map(s => <li>{s}</li>)}</ul></span>;
  }

  let statusMessage = null;
    
  if (!msg1&!msg2) {
    statusMessage = <span className="errorStr">No segments found</span>
  } else {
    statusMessage = <div>{msg1}{msg2}</div>
  }

  
  return { 
    ...cs, 
    statusMessage: statusMessage, 
    commandMessage: <span>Apply to segments{selector.length > 0 ? <ul>{selector}</ul> : null}</span>,
    status: found.length > 0 ? cs.status : COMMAND_STATUSES.FAILED,
  };
}

class CommandStatus extends Component {
  static propTypes = {
    height: PropTypes.string,
    commandStatuses: PropTypes.array,
    isFetchingCommandStatuses: PropTypes.bool,
    job: PropTypes.object,
    project: PropTypes.object,
    fetchCommandStatuses: PropTypes.func,
    toggleCommandStatuses: PropTypes.func,
    userKey: PropTypes.string,
    currentWorkspace: PropTypes.object,
    maxHeight: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
    this.buildStatusMessage = this.buildStatusMessage.bind(this);
  }

  buildStatusMessage(cs) {
    try {
      if (cs.statusParams) {
        switch (cs.command) {
          case 'Initialize':
            return prepareInitialized(cs);
          case 'DataLoaded':
            return prepareDataLoaded(cs);
          case 'ApplyToSegments':
            return prepareApplyToSegments(cs);
          default:
            break;
        }
      }
      return cs;
    } catch (ex) {
      return cs;
    }
  }

  render() {
    const { 
      height,
      commandStatuses,
      isFetchingCommandStatuses,
      fetchCommandStatuses,
      job,
      project,
      userKey,
      currentWorkspace,
      toggleCommandStatuses,
      maxHeight,
    } = this.props,
      calculatedHeight = `${height}vh`;

    let commandContainer = <div></div>;

    if (isFetchingCommandStatuses) {
      commandContainer = (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: 'auto' }}><Loader loadingItem="Command Reports" /></div>
      );
    } else {
      const infoRowJSX = (
        <div>
          <span style={{ color: Colors.purple }}><b>{job.operationCode}</b>&nbsp;</span>
          <span>submitted by&nbsp;</span>
          <span style={{ color: Colors.purple }}><b>{job.requestor.name}</b>&nbsp;</span>
          <span>for&nbsp;</span>
          <span style={{ color: Colors.purple }}><b>{project}</b>&nbsp;</span>
          <span>at&nbsp;</span>
          <span style={{ color: Colors.purple }}><b>{moment.utc(job.requestTime).local().format('L LT')}</b>&nbsp;</span>
          <span style={{ float: 'right' }}>
            <ButtonGroup>
              <Button
                onClick={() => fetchCommandStatuses({ userKey, databaseId: currentWorkspace.id, jobId: job.operationId })}
                variant="arius">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </Button>
              <Button
                onClick={(e) => toggleCommandStatuses(e, job.operationId)}
                variant="arius">
                <i className="fa fa-times" aria-hidden="true"></i>
              </Button>
            </ButtonGroup>
          </span>
        </div>
      );
      if (!commandStatuses.length) {
        commandContainer = (
          <div style={{ display: 'flex', flexFlow: 'column nowrap', width: '100%' }}>
            <div style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{infoRowJSX}</div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: 'auto' }}>
              no data to show
            </div>
          </div>
        );
      } else {
        commandContainer = (
          <div className="table-responsive" style={{ width: '100%', overflow: 'visible' }}>
            <table className="table">
              <thead>
                <tr>
                  <td colSpan="4">{infoRowJSX}</td>
                </tr>
                <tr>
                  <th>Command</th>
                  <th>Last Updated</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {commandStatuses.map(this.buildStatusMessage).map((cs, i) => {
                  let statusIcon = '';
                  switch (cs.status) {
                    case COMMAND_STATUSES.NOT_EXECUTED:
                      break;
                    case COMMAND_STATUSES.FAILED:
                      statusIcon = <i className="fa fa-times" style={{ color: 'red' }} aria-hidden="true"></i>;
                      break;
                    case COMMAND_STATUSES.EXECUTING:
                      statusIcon = (
                        <span>
                          <i className="fa fa-cog fa-spin fa-fw" style={{ color: Colors.purple }}></i>
                          <span className="sr-only">Loading...</span>
                        </span>
                      );
                      break;
                    case COMMAND_STATUSES.COMPLETED:
                      statusIcon = <i className="fa fa-check" style={{ color: 'green' }} aria-hidden="true"></i>;
                      break;
                    case COMMAND_STATUSES.CANCELLED:
                      statusIcon = (
                        <span className="fa-stack fa-lg">
                          <i className="fa fa-cog fa-stack-1x"></i>
                          <i className="fa fa-ban fa-stack-2x text-danger"></i>
                        </span>
                      );
                      break;
                    
                    default:
                      break;
                  }
                  return (
                    <tr key={`commandsStatus-${i}`}>
                      <td>{cs.commandMessage || cs.command}</td>
                      <td>{moment.utc(cs.lastUpdated).local().format('L LT')}</td>
                      <td>{cs.statusMessage || cs.status}</td>
                      <td>{statusIcon}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      }
    } 
    
    return (
      <div id="commandStatusesContainer" style={[style.mainContainer, { height: calculatedHeight, maxHeight }]}>
        <Resizer
          useContainerOne
          containerTwo="commandStatusesContainer"
          containerOne="statusReportsContainer"
          parentContainer="mainStatusContainer"
          id="statusResizer"
          width={style.mainContainer.width}
          backgroundColor={'whitesmoke'} />
        <div style={[style.commandContainer, { display: 'flex', flex: '40 1 auto', overflowY: 'scroll' }]}>
          {commandContainer}
        </div>
      </div>
    );
  }
}

export default radium(CommandStatus);
