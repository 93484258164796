import { create } from 'apisauce';
import { receivedErrorFromServer } from '../actions/application.actions';
import { unauthorized } from '../actions/user.actions';
import { store } from '../store';
import { getToken } from './adalAdapter';
import { notifyError } from './notifier';
import { getAppSettings } from '../config';
import { conflictHelper } from './helpers';

function getBaseServiceUrl() {
  return getAppSettings().then(settings => {
    const { baseServiceUrl } = settings;
    return `https://${baseServiceUrl}`;
  });
}

function ServerError(code, data) {
  this.message = 'API Error';
  this.code = code;
  this.data = data;
  this.stack = (new Error()).stack;
}

ServerError.prototype = Object.create(Error.prototype);
ServerError.prototype.name = 'ServerError';

function makeApiCall(url, headers = null, method = 'GET', body = null, silentError = false) {
  return getBaseServiceUrl()
    .then(baseURL => {
      let api = create({
        baseURL,
        headers,
      });

      if (method === 'GET') {
        if (typeof url !== 'string') {
          api = api.get(url.url, url.query);
        } else {
          api = api.get(url);
        }
      } else if (method === 'POST') {
        api = api.post(url, body);
      } else if (method === 'PUT') {
        api = api.put(url, body);
      } else if (method === 'DELETE') {
        api = api.delete(url);
      }
      return api;
    })
    .then(response => {
      const errMSG = 'An unknown error occured';
      let data = response.data || { status: response.status };
      if (response.ok) {
        return data;
      }
      if (response.status === 401) {
        store.dispatch(unauthorized());
      } else if (response.status === 409) {
        if (conflictHelper(response)) {
          return {
            data,
            status: response.status,
          };
        } else {
          const localMSG = typeof data.message === 'string' ? data.message : 'An unknown error has occurred';
          notifyError(localMSG);
        }
      } else if (response.status >= 500) {
        data = {
          error: response.data || 'unknown error',
        };

        if (response.status === 503) {
          console.log('response 503');
          console.log(response);
          if (response.headers) {
            const retryAfter = response.headers['retry-after'];
            console.log(`retryAfter: ${retryAfter}`);
            if (retryAfter) {
              data = {
                ...data,
                retryAfter,
              };
              console.log(data);

              if (!silentError) {
                // don't notify error here, let calling code to make this decision
                notifyError('Resource temporary unavailable. Retry.');
              }
              return data;
            }
          }
          if (!silentError) {
            notifyError('Service Unavailable', data.error);
          }
        } else {
          if (!silentError) {
            notifyError(errMSG);
          }
        }
        return data;
      } else if (response.status === 413) {
        // RequestEntityTooLarge = 413
        // user friendly exception, notify user
        data = {
          error: response.data || 'unknown error',
        };
        notifyError(data.error.message);
        return data;
      }

      const defaultErrorMessage = typeof data === 'string' ||
        response.status >= 500 || response.status === 413
        ? data
        : errMSG;
      throw new ServerError(response.status, data.message ? data.message : defaultErrorMessage);
    });
}

function callApiPassingErrors(url, headers = null, method = 'GET', body = null, silentError = false) {
  if (headers && headers.Authorization) {
    return makeApiCall(url, headers, method, body, silentError);
  }

  return getToken().then(token =>
    makeApiCall(
      url,
      Object.assign(headers || {}, { Authorization: `Bearer ${token}` }),
      method,
      body,
      silentError,
    )
  );
}

function callApi(url, headers = null, method = 'GET', body = null, silentError = false) {
  return callApiPassingErrors(url, headers, method, body, silentError).catch(err => {
    if (err instanceof ServerError) {
      if (err.code === 403) {
          notifyError('You are not authorized to access this functionality');
      } 
      else if (err.code === 400) {
          notifyError(err.data);
      }
      else {
        store.dispatch(receivedErrorFromServer(err.message));
      }
      return null;
    } else {
      throw err;
    }
  });
}

export { callApi, makeApiCall, getBaseServiceUrl, callApiPassingErrors, ServerError };
