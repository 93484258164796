import React, { Component } from 'react';
import radium from 'radium';
import moment from 'moment';
import { ProgressBar, Form, Button } from 'react-bootstrap';
import { Table, Button as AButton } from '@arius';

class RestoreTab extends Component {
	static propTypes = {};
	static defaultProps = {};
	constructor(props) {
		super(props);
		this.state = {};
    }


    render() {
		const {
            restoreMessage,
            availableBackups,
            selectedBackup,
            isRestoringBackup,
            isArchivingBackup,
            restoreBackup,
            archiveBackup,
            deleteBackup,
            selectBackup,
        } = this.props;

        const {tr:Tr, td:Td, th:Th, tbody:Tbody, thead:Thead, responsiveTable:Tbl} = Table;
        const restoreBackups = availableBackups ? availableBackups.filter(x=> !x.isPitr) : [{description: 'No available databases to restore'}];

        const columnWidth = ['5%','20%','20%','15%','15%','15%','10%' ];
        const isActive = isRestoringBackup || isArchivingBackup;

        // shouldn't need it isRestoringBackup or isArchivingBackup already should be cleared
        const isComplete = restoreMessage.indexOf('Completed') > -1;
        const buttonsDisabled = !selectedBackup || (isActive && !isComplete);

        // console.log('restore tab render');
        // console.log(restoreMessage);
        // console.log(`isActive: ${isActive}; isComplete: ${isComplete}`);

        const restoreTab = availableBackups.length > 0 ? (
            <Form style={{padding: 15}}>
                <Form.Group controlId="restoreActions">
                    <Button variant="arius" style={{marginRight: '5vw'}} onClick={restoreBackup} disabled={buttonsDisabled}>Restore Backup</Button>
                    <Button variant="arius" onClick={archiveBackup} disabled={buttonsDisabled}>Archive Backup</Button>
                </Form.Group>

                {(isActive && !isComplete) ? (<ProgressBar animated now={100} />) : ('')}
                <Form.Text style={{color: (isActive && !isComplete) ? 'purple' : 'green', paddingLeft: 12, height: 19}}>{restoreMessage}</Form.Text> 
                
                <Form.Group controlId="listBackups">
                    <Tbl>
                        {/* <colgroup>
                        {
                            columnWidth.map((w, i) =>
                                <col key={`col-${i}`} style={{width:`${w}`}} />
                            )
                        }
                        </colgroup> */}
                    <Thead>
                        <Tr>
                        <Th width={columnWidth[0]}></Th>
                        <Th width={columnWidth[1]}>Description</Th>
                        <Th width={columnWidth[2]}>Size</Th>
                        <Th width={columnWidth[3]}>Status</Th>
                        <Th width={columnWidth[4]}>Date Created</Th>
                        <Th width={columnWidth[5]}>Created By</Th>
                        <Th width={columnWidth[6]}></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {restoreBackups.map(backup =>
                        (<Tr key={`backup-${backup.id}`} onClick={() => selectBackup(backup.id)} style={{ cursor: 'pointer' }}>
                            <Td width={columnWidth[0]}>
                                <input onChange={() => {}} checked={selectedBackup === backup} type="radio" />
                            </Td>
                            <Td width={columnWidth[1]}>{backup.description}</Td>
                            <Td width={columnWidth[2]}>{backup.size}</Td>
                            <Td width={columnWidth[3]}>{backup.status}</Td>
                            <Td width={columnWidth[4]}>{moment.utc(backup.createdDate).local().format('L LT')}</Td>
                            <Td style={{width:columnWidth[5]}}>{backup.createdBy}</Td>
                            <Td style={{width:columnWidth[6]}}>
                                <AButton toolTip="Delete Backup" placement="left" iconName="delete" onClick={(e) => deleteBackup(backup.id)} />
                            </Td>
                        </Tr>)
                        )}
                    </Tbody>
                    </Tbl>
                </Form.Group>
            </Form>
          ) : (<div style={{ margin: 10 }}>You have no saved backups</div>);

          return restoreTab;
    };
    
}

export default radium(RestoreTab);