import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';

const style = {
  menuIconHover: {
    ':hover': {
      color: Colors.purple,
      transition: 'all 0.15s cubic-bezier(0.35, 0, 0.25, 1)',
    },
  },
  menuIcon: {
    fontSize: '20px',
    padding: '5px',
    color: Colors.darkGrey,
    cursor: 'pointer',
  },
  deleteIcon: {
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: 14,
    padding: 5,
  },
  tableRow: {
    display: 'table',
    width: '100%',
    tableLayout: 'fixed',
  },
  cellPositioning: {
    margin: 0,
    verticalAlign: 'middle',
  },
};

class ColumnRow extends Component {
  static propTypes = {
    onSelectChanged: PropTypes.func,
    onNameChanged: PropTypes.func,
    deleteColumn: PropTypes.func,
    column: PropTypes.object,
    validationError: PropTypes.string,
    idx: PropTypes.number,
    canEdit: PropTypes.bool,
    hasDataLoaded: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    const cCol = this.props.column;
    const nCol = nextProps.column;

    return this.props.idx !== nextProps.idx || this.props.validationError !== nextProps.validationError
      || nCol.factColumnDisplayName !== cCol.factColumnDisplayName
      || nCol.isSelected !== cCol.isSelected;
  }

  render() {
    const { column, validationError, canEdit, idx } = this.props;

    return (
      <tr style={style.tableRow} key={`newColumn-${idx}`}>
        <td style={[style.cellPositioning, { width: '5%' }]}>
          { canEdit ?
            <input type="checkbox"
              onChange={(e) => this.props.onSelectChanged(e)}
              checked={ column.isSelected }
            /> : ''}
        </td>
        <td style={[style.cellPositioning, { width: '20%' }]}>
          <Form.Group>
          <Form.Control
              id={`nameInput${idx}`}
              type="text" maxLength="255"
              disabled={!canEdit}
              value={column.factColumnDisplayName}
              placeholder="Enter column name"
              onChange={(e) => this.props.onNameChanged(e.target.value)}
              onBlur={(e) => this.props.onNameChanged(e.target.value.trim())}
              isInvalid={validationError}
            />
            { validationError ?
              <Form.Control.Feedback type='invalid' style={{ marginBottom: 0 }}>{validationError}</Form.Control.Feedback> : ''}
          </Form.Group>
        </td>
        <td style={[style.cellPositioning, { width: '10%' }]}>
          {canEdit ? (
                <OverlayTrigger
                  placement="right"
                  focus
                  overlay={<Tooltip id={`deleteColumn-tooltip-${idx}`}>Remove Column</Tooltip>}
                >
                  <i onClick={() => this.props.deleteColumn()}
                    key={`delete-column-${idx}`}
                    style={style.deleteIcon} className="fa fa-minus btn btn-danger"
                  >
                  </i>
                </OverlayTrigger>
              ) : ''}
        </td>
        <td style={[style.cellPositioning, { width: '20%' }]}>
        </td>
        <td style={[[style.cellPositioning, { width: '10%' }], { textAlign: 'center' }]}>
        </td>
        <td style={[style.cellPositioning, { width: '20%' }]}>
        </td>
        <td style={[style.cellPositioning, { width: '20%' }]}>
        </td>
      </tr>
    );
  }
}

export default radium(ColumnRow);
