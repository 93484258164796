import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Modal, Form, ButtonGroup, Button } from 'react-bootstrap';
import * as Colors from '../../../utilities/colors';
import DataRow from './dataRow';
import { Table } from '@arius'

class DetailCommentsModal extends Component {
  static propTypes = {
    modalId: PropTypes.string.isRequired,
    closeHandler: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    selectedItem: PropTypes.object,
    userKey: PropTypes.string,
    dispatch: PropTypes.func,
    updateHandler: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedVersion: null,
      editingComment: false,
      selectedItem: {
        name: '',
        data: [],
        commentFieldEditable: false,
      },
      currentComment: '',
      isMouseInside: false,
    };
    this.toggleCommentBox = this.toggleCommentBox.bind(this);
  }

  componentWillMount(nextProps) {
    const { selectedItem } = this.props;
    if (nextProps && nextProps.selectedItem !== selectedItem) {
      this.setState({ selectedItem: nextProps.selectedItem });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedItem, showModal } = this.props;
    if (nextProps && nextProps.selectedItem !== selectedItem) {
      this.setState({ selectedItem: nextProps.selectedItem });
    }
    if (nextProps.showModal !== showModal) {
      this.restoreInitialState();
    }
  }

  onCloseClick() {
    this.props.closeHandler();
    this.toggleEditMode();
  }

  restoreInitialState() {
    this.setState({
      selectedVersion: null,
      editingComment: false,
      isMouseInside: false,
    });
  }

  toggleCommentBox(selectedVersion) {
    if (this.state.selectedVersion === selectedVersion) {
      this.setState({ selectedVersion: null, editingComment: false, isMouseInside: false });
    } else {
      this.setState({ selectedVersion, isMouseInside: false, editingComment: true, currentComment: selectedVersion.Comment });
    }
  }

  toggleEditMode() {
    const { editingComment, selectedVersion } = this.state;
    this.setState({ editingComment: !editingComment,
      currentComment: selectedVersion ? selectedVersion.Comment : '',
      isMouseInside: false,
      selectedVersion: null,
    });
  }

  editComment(e) {
    this.setState({ currentComment: e.target.value });
  }

  updateComment() {
    const
      { currentComment } = this.state,
      selectedVersion = { ...this.state.selectedVersion },
      { updateHandler } = this.props; 
    selectedVersion.Comment = currentComment;
    updateHandler(currentComment, selectedVersion.id);
    this.toggleCommentBox(this.state.selectedVersion);
  }

  mouseEnter() {
    this.setState({ isMouseInside: true });
  }
  mouseExit() {
    this.setState({ isMouseInside: false });
  }

  render() {
    const { 
        selectedItem,
        selectedVersion,
        editingComment,
        currentComment,
        isMouseInside,
      } = this.state,
      showEditIconStyle = isMouseInside ? {} : { visibility: 'hidden' },
      pBorderStyle = isMouseInside ? {
        outline: 'none',
        borderColor: '#9ecaed',
        boxShadow: '0 0 10px #9ecaed',
      } : {};
    let dataRows = null, keys = [];
    if (selectedItem.data && selectedItem.data.length) {
      keys = Object.keys(selectedItem.data[0]);
      keys.splice(keys.indexOf('id'), 1);
      dataRows = selectedItem.data.map((data, idx) =>
          <DataRow
            key={`data-row-${idx}`}
            keys={keys}
            idx={idx}
            data={data}
            toggleCommentBox={this.toggleCommentBox}
            commentFieldEditable={selectedItem.commentFieldEditable} />
      );
    }
    let {tr:Tr, tbody:Tbody, thead:Thead, responsiveTable:Tbl} = Table;

    let detailCommentTable = (
      <div className="row">
        <Tbl>
          <Thead>
            <Tr>
              {keys.filter(x=> x !== 'Actions').map((key, index) =>
                <td key={`${index}-theader`}>{key}</td>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {dataRows}
          </Tbody>
        </Tbl>
      </div>
    );

    if (selectedVersion) {
      if (editingComment) {
        detailCommentTable = (
          <Form.Group controlId="editcommentTextarea">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonGroup
                style={{ borderBottom: 'none' }}>
                <Button
                  style={{ 
                    color: 'green',
                    borderBottomLeftRadius: '0',
                    position: 'relative',
                    top: '1.5px',
                  }}
                  onClick={() => this.updateComment()}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
                <Button
                  style={{
                    color: 'red',
                    borderBottomRightRadius: '0',
                    position: 'relative',
                    top: '1.5px',
                  }}
                  onClick={() => this.toggleEditMode()}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </Button>
              </ButtonGroup>
            </div>
            <Form.Control 
              as="textarea"
              placeholder="write comment"
              autoFocus
              value={currentComment}
              rows={5}
              onChange={(e) => this.editComment(e)}
              style={{
                resize: 'vertical',
                borderTopRightRadius: '0',
              }} />
          </Form.Group>
        );
      } else {
        detailCommentTable = (
          <div>
            <div style={[{ display: 'flex', justifyContent: 'flex-end' }, showEditIconStyle]}>
              <i
                style={{ marginBottom: '4px' }}
                className="fa fa-pencil"
                aria-hidden="true"></i>
            </div>
            <p
              style={[pBorderStyle, { cursor: 'text' }]}
              onClick={() => this.toggleEditMode()}
              onMouseEnter={() => this.mouseEnter()}
              onMouseLeave={() => this.mouseExit()}>
              {selectedVersion.Comment}
            </p>
          </div>
        );
      }
    }
    return (
      <Modal id={this.props.modalId} show={this.props.showModal} onHide={this.props.closeHandler} enforceFocus dialogClassName="modal-90w">
        <Modal.Header style={{ backgroundColor: Colors.blue, color: '#FFFFFF' }} closeButton>
          <Modal.Title><small>{selectedItem.name}</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small>{detailCommentTable}</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => this.onCloseClick()}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default radium(DetailCommentsModal);
