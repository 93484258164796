import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Button } from '@arius';
import moment from 'moment';
import * as Colors from '@app/utilities/colors';
import * as GlobalStyles from '@app/utilities/globalStyles';
import {
    Form, 
    Dropdown,
   } from 'react-bootstrap';
import Loader from '@app/shared/presentational/loader';
import {
  SERVER_NOTIFICATION_MESSAGES,
  SERVER_NOTIFICATION_SUBJECTS } from '@app/utilities/constants';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { showModal } from '@app/actions/modal.actions';
import { fetchJobStatus, clearJobStatus } from '../../../actions/jobStatus.actions';

class DbLoader extends Component {
  static propTypes = {
    userKey: PropTypes.string,
    uploads: PropTypes.array,
    mappings: PropTypes.array,
    csvColumns: PropTypes.array,
    currentMapper: PropTypes.object,
    csvIsFetching: PropTypes.bool,
    mapperIsFetching: PropTypes.bool,
    application: PropTypes.object,
    getDatabaseMappings: PropTypes.func,
    getDatabase: PropTypes.func,
    getUploads: PropTypes.func,
    currentDb: PropTypes.object,
    performDataLoad: PropTypes.func,
    serverNotification: PropTypes.object,
    clearNotificationHandler: PropTypes.func,
    cancelDataLoadHandler: PropTypes.func,
    errorMessage: PropTypes.string,
    getCsvColumns: PropTypes.func,
    getMapperColumns: PropTypes.func,
    clearErrorMessageHandler: PropTypes.func,
    getDataLoads: PropTypes.func,
    dataLoads: PropTypes.array,
    mapColumns: PropTypes.array,
    selectMapperHandler: PropTypes.func,
    isFetchingDataLoads: PropTypes.bool,
    retrieveLogFile: PropTypes.func,
    logFile: PropTypes.object,
    deleteLogFileFromServer: PropTypes.func,
  };

  static defaultProps = {
    mappings: [],
    currentDb: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      selectedMapping: null,
      selectedFile: null,
      valuationDate: '',
      isFinishedLoading: false,
      isPreparingLoad: false,
      isLoading: false,
      canCancelLoad: false,
      percentageComplete: 0,
      loadErrors: null,
      isCancelling: false,
      delayValidation: true,
      recordsProcessed: 0,
      dataLoadStats: null,
      previouslySelectedMapping: null,
      previouslySelectedFile: null,
      dataLoadComplete: false,
      timerId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.changeSelectedFile = this.changeSelectedFile.bind(this);
    this.changeSelectedMapping = this.changeSelectedMapping.bind(this);
    this.cancelLoad = this.cancelLoad.bind(this);
    this.changeValuationDate = this.changeValuationDate.bind(this);
    this.downloadLogFile = this.downloadLogFile.bind(this);
    this.deleteLogFile = this.deleteLogFile.bind(this);
    this.TAG_DATA_LOAD = 'DATA LOAD';
  }

  componentDidUpdate(prevProps) {
    const { currentDb, errorMessage, dataLoads, logFile, getDatabase, userKey, jobStatus } = this.props;
    console.log('componentDidUpdate');

    // const serverSubject = this.getServerNotificationSubjectForDatabase(currentDb);
    // if (prevProps.serverNotification !== serverNotification &&
    //   serverNotification.subject === serverSubject) {
    //   this.handleServerNotificationChange(serverNotification, () => getDatabase(userKey, currentDb.id));
    // }

    const tag = `${this.TAG_DATA_LOAD}`; // ${currentDb.id} or fact table id?

    if (prevProps.jobStatus !== jobStatus  &&  jobStatus.jobStatuses) {
      console.log(jobStatus);
      const job = jobStatus.jobStatuses[tag];
      if (job) {
        this.handleServerNotificationChange(job, () => getDatabase(userKey, currentDb.id));

        if ((this.state.isPreparingLoad ||
              this.state.isLoading ||
              this.state.isCancelling
            ) && 
            !this.state.timerId) {
          const timerId = this.startInterval();
          this.setState({ timerId });
        }
 
        if (!this.state.isPreparingLoad &&
            !this.state.isLoading &&
            !this.state.isCancelling &&
            this.state.timerId) {
          console.log('stop timer');
          clearInterval(this.state.timerId);
          this.setState({ timerId: null });
        }
      }

    }


    if (prevProps.dataLoads !== dataLoads && dataLoads.length > 0) {
      this.setState({
        dataLoadStats: dataLoads[dataLoads.length - 1],
      });
    }

    if (prevProps.errorMessage !== errorMessage) {
      this.setState({
        isFinishedLoading: false,
        percentageComplete: 0,
        isPreparingLoad: false,
        isLoading: false,
        isCancelling: false,
      });
    }

    if (prevProps.logFile !== logFile)
    {
      saveAs(logFile, 'DataLoadLogFile.txt');
    }


  }

  componentWillUnmount() {
    this.props.clearNotificationHandler();
    const { currentDb, dispatch } = this.props;
    const databaseId = currentDb ? currentDb.id : 0;
    this.props.selectMapperHandler(null, databaseId);

    if (this.state.timerId) {
      console.log('stop timer');
      clearInterval(this.state.timerId);
      this.setState({ timerId: null });
    }
    dispatch(clearJobStatus(this.TAG_DATA_LOAD));
  }

  startInterval = () => {
    console.log('start interval');
    const timerId = setInterval(() => {
        this.checkJobStatus(this.TAG_DATA_LOAD);
    }, 5000);

    return timerId;
  }

  checkJobStatus = (tag) => {
    const {userKey, dispatch, jobStatus} = this.props;
    console.log(`checkJobStatus: ${tag}`);

    const job = jobStatus.jobStatuses[tag];
    console.log(job);

    const jobId = job ? job.jobId : '';
    dispatch(fetchJobStatus(userKey, jobId, tag));
  }


  getServerNotificationSubjectForDatabase(currentDb){
    return SERVER_NOTIFICATION_SUBJECTS.TOD_DATABASE_LOAD +
      ((currentDb != null && currentDb.hasOwnProperty('factTableGuid')) ? (": " + currentDb.factTableGuid) : '');
  }

  handleServerNotificationChange = (job, doneCallback) => {
    console.log('handleServerNotificationChange');
    console.log(job);

    if (!this.state.isLoading)
    {
      console.log('1');

      this.setState({
        isPreparingLoad: true,
        isLoading: true,
        isFinishedLoading: false,
        loadErrors: null,
        canCancelLoad: true,
        dataLoadComplete: false,
      });
    }
    
    if (this.state.isPreparingLoad && 
        (job.status !== 'Completed' && job.status !== 'Error')) {
      console.log('2');
      this.setState({ isPreparingLoad: false });
    }
    
    if (job.message === SERVER_NOTIFICATION_MESSAGES.TOD_DB_LOAD_IMPORT_COMPLETE) {
      console.log('3');
      this.setState({
        canCancelLoad: false,
      });
    }
    
    if (job.message.includes('complete') ||
      job.message.includes('cancelled')) {
      console.log('4');
      this.setState({
        isFinishedLoading: false,
        percentageComplete: 0,
        isPreparingLoad: false,
        isLoading: false,
        isCancelling: false,
        loadErrors: this.state.loadErrors ? this.state.loadErrors : null,
      });
    }

    
    const data = job.data ? JSON.parse(job.data) : null;
    console.log(data);

    if (data) {
      if (data.loadErrors && job.message.includes('failed')) {
        console.log('5');
        this.setState({
          percentageComplete: data.percentComplete,
          isPreparingLoad: false,
          isLoading: false,
          loadErrors: this.isJson(data.loadErrors) ?
            JSON.parse(data.loadErrors) : data.loadErrors,
          isFinishedLoading: true,
        });
      } else {
        console.log('6');
        let loadErrors = '';
        if (data.loadErrors) {
          loadErrors = (this.isJson(data.loadErrors) ?
            JSON.parse(data.loadErrors) :
            data.loadErrors);
        }
        this.setState({
          percentageComplete: data.percentComplete,
          recordsProcessed: data.recordsProcessed,
          isPreparingLoad: false,
          loadErrors,
        });
      }
      if (job.message.includes('complete') ||
        job.message.includes('file(s) failed to load')) {
          console.log('7');
        this.props.getDataLoads(this.props.userKey, this.props.currentDb.id);

        if (this.state.timerId) {
          console.log('stop timer');
          clearInterval(this.state.timerId);
        }

        this.setState({
          isFinishedLoading: true,
          percentageComplete: 100,
          isPreparingLoad: false,
          isLoading: false,
          isCancelling: false,
          loadErrors: this.state.loadErrors ? this.state.loadErrors : null,
          selectedMapping: null,
          selectedFile: null,
          delayValidation: true,
          dataLoadComplete: true,
          recordsProcessed: data.recordsProcessed,
          timerId: null,
        });
        if (doneCallback) doneCallback();
      }
    } else if (job.message.includes('failed') && !data) {
      console.log('8');
      
      if (this.state.timerId) {
        console.log('stop timer');
        clearInterval(this.state.timerId);
      }
      this.setState({
        isPreparingLoad: false,
        isLoading: false,
        isFinishedLoading: true,
        loadErrors: 'An unknown error occured',
        timerId: null,
      });
    } else {
      if (this.state.percentageComplete !== 0) {
        console.log('9');
        this.setState({ percentageComplete: 0 });
      }
    }
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  changeSelectedFile(e) {
    const { uploads, getCsvColumns, userKey } = this.props;
    const selectedFile = uploads.find(file => file.id === e.target.value);
    getCsvColumns(userKey, selectedFile ? selectedFile.id : null);
    this.setState({ selectedFile });
  }

  changeSelectedMapping(e) {
    const mapperId = e.target.value;
    const { mappings, userKey, currentDb, getMapperColumns } = this.props;
    const selectedMapping = mappings.find(mapper => mapper.mapperId.toString() === mapperId);
    getMapperColumns(userKey, currentDb.id, selectedMapping ? selectedMapping.mapperId : null);
    this.setState({ selectedMapping });
  }

  changeValuationDate(e) {
    this.setState({ valuationDate: e.target.value });
  }

  validate() {
    const { valuationDate } = this.state;
    const parsedDate = moment(valuationDate, 'M/D/YYYY', true);

    if (!parsedDate.isValid()) {
      return false;
    }

    if (!this.isMapperMatching()) {
      return false;
    }

    return true;
  }

  loadData() {
    const { selectedMapping, selectedFile, valuationDate } = this.state;
    const { userKey, performDataLoad, currentDb, clearNotificationHandler, clearErrorMessageHandler } = this.props;
    clearNotificationHandler();
    clearErrorMessageHandler();

    if (!this.validate(true)) {
      this.setState({ delayValidation: false });
      return;
    }

    performDataLoad({
      userKey,
      databaseId: currentDb.id,
      mapperId: selectedMapping.mapperId,
      csvFileName: selectedFile.uniqueName,
      valuationDate: moment(valuationDate, 'M/D/YYYY', true).format('YYYY-MM-DD'),
    });
    this.setState({
      isPreparingLoad: true,
      isLoading: true,
      isFinishedLoading: false,
      loadErrors: null,
      canCancelLoad: true,
      dataLoadComplete: false,
      previouslySelectedMapping: selectedMapping,
      previouslySelectedFile: selectedFile,
    });
  }

  cancelLoad() {
    this.props.cancelDataLoadHandler(this.props.userKey, this.props.currentDb.id);
    this.setState({ isCancelling: true});
  }

  validateFile() {
    const { selectedFile } = this.state;
    const { csvIsFetching } = this.props;
    const hasSelectedFile = selectedFile ? undefined : 'error';
    return {
      state: csvIsFetching ? 'warning' : (hasSelectedFile),
      error: selectedFile ? '' : 'Error: Must select a file to load',
    };
  }

  isMapperMatching() {
    const { selectedMapping, selectedFile } = this.state;

    const { csvColumns, mapColumns, csvIsFetching, mapperIsFetching } = this.props;

    if (!selectedFile || !selectedMapping || csvIsFetching || mapperIsFetching) {
      return false;
    }

    const missingColumns = _.difference(mapColumns, csvColumns);
    return missingColumns.length === 0;
  }

  validateMapper() {
    const { selectedFile } = this.state;
    const { mapperIsFetching } = this.props;

    const matching = this.isMapperMatching();
    const hasMatching = matching ? undefined : 'error';
    const matchingError = mapperIsFetching || matching ? '' : 'Error: Selected mapping is not compatible with selected CSV file';
    return {
      state: mapperIsFetching ? 'warning' : (hasMatching),
      error: selectedFile ? matchingError : 'Error: Must select a file to load',
    };
  }

  downloadLogFile() {
    const { retrieveLogFile } = this.props;
    retrieveLogFile(this.props.userKey);
  }

  deleteLogFile() {
    const { dispatch, userKey, deleteLogFileFromServer } = this.props;
    const deleteMessageItems = ['Are you sure you want to delete the log file?'];
    const yesClickHandler = () => {
      deleteLogFileFromServer(userKey);
    };
    const noClickHandler = () => {};
    const action = showModal(
      'confirmation',
      deleteMessageItems,
      yesClickHandler,
      noClickHandler
    );
    dispatch(action);
  }

  render() {
    const { mappings, currentDb, uploads, errorMessage, isFetchingDataLoads, jobStatus } = this.props;
    const {
      valuationDate,
      isLoading, isFinishedLoading, isPreparingLoad, percentageComplete, loadErrors, isCancelling, canCancelLoad,
      delayValidation, recordsProcessed, dataLoadStats, previouslySelectedMapping, previouslySelectedFile, selectedFile,
      selectedMapping, dataLoadComplete } = this.state;

    const sortedUploads = uploads.sort((a, b) => {
      const aName = typeof(a.name) === 'string' ? a.name.toLowerCase() : '';
      const bName = typeof(b.name) === 'string' ? b.name.toLowerCase() : '';

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });

    const tag = `${this.TAG_DATA_LOAD}`; // ${currentDb.id} or fact table id?
    let job = null;
    if (jobStatus && jobStatus.jobStatuses) {
      job = jobStatus.jobStatuses[tag];
    }

    let contentMarkup = (<div></div>);
    let progressBar = isCancelling || isPreparingLoad ||
      (job && job.message &&
        job.message !== SERVER_NOTIFICATION_MESSAGES.TOD_DB_LOAD_CANCEL_COMPLETE &&
        job.message !== SERVER_NOTIFICATION_MESSAGES.TOD_DB_LOAD_CANCELLED) ? (<Loader />) : (<span></span>);
    let serverMessage = job ? job.message : '';

    serverMessage = job && job.message === SERVER_NOTIFICATION_MESSAGES.TOD_DB_LOAD_CREATING_DB_COPY ?
      'Creating database copy. This may take a few minutes' : serverMessage;
    serverMessage = isPreparingLoad ? 'Processing your request' : serverMessage;
    serverMessage = isCancelling ? 'Cancelling data load, please wait....' : serverMessage;
    let
      dateValidationError = null,
      fileValidation = { state: undefined, error: '' },
      mapperValidation = { state: undefined, error: '' };

    if (!delayValidation) {
      if (!valuationDate) {
        dateValidationError = 'Required';
      } else {
        const parsedDate = moment(valuationDate, 'M/D/YYYY', true);
        if (!parsedDate.isValid()) {
          dateValidationError = 'Invalid valuation date. Format must be MM/DD/YYYY';
        }
      }

      fileValidation = this.validateFile();
      mapperValidation = this.validateMapper();
    }

    if (currentDb) {
      if (isFinishedLoading) {
        if (loadErrors || (job && job.message.includes('file(s) failed to load'))) {
          progressBar = (
            <div><i className="fa fa-times" style={{ display: 'flex', justifyContent: 'center', fontSize: 50, color: Colors.red }}></i></div>
          );
        } else if (dataLoadComplete) {
          progressBar = (
            <div><i className="fa fa-check" style={{ display: 'flex', justifyContent: 'center', fontSize: 50, color: 'green' }}></i></div>
          );
        }
      }

      let btnStyle = {width: 150};
      contentMarkup = (
        <div>
          <div style={{
            borderBottom: '2px solid #BDBDBD',
            margin: 'initial',
            height: 75,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center' }}>
            <h2 style={{ margin: 'initial', paddingLeft: 15 }}>Loader</h2>
            <Button mode='back' path='/trianglesOnDemand/databases' toolTip='Back to Database List'/>
          </div>
          <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
            <div style={[GlobalStyles.card, { width: '50%' }]}>
              <h3>{currentDb.name}</h3>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="col-md-6">
                  <Form.Group controlId="dateSelect">
                    <Form.Label>Enter Valuation Date</Form.Label>
                    <Form.Control as="input" placeholder="MM/DD/YYYY"
                        isInvalid={dateValidationError}
                      value={this.state.valuationDate}
                      disabled={isLoading}
                      onChange={this.changeValuationDate} />
                    <Form.Control.Feedback type="invalid">{dateValidationError}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="col-md-6">
                  <Form.Group controlId="fileSelect">
                    <Form.Label>Select File to Load</Form.Label>
                    <Form.Control as="select" placeholder="select" 
                        isInvalid={fileValidation.state} onChange={this.changeSelectedFile}
                      disabled={isLoading}
                      value={selectedFile ? selectedFile.id : 'default'}>
                      <option value="default">-- Select a CSV file --</option>
                      {sortedUploads.map(upload => (
                        <option key={`${upload.id}`} value={upload.id}>{upload.name}</option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{fileValidation.error}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="col-md-6">
                  <Form.Group controlId="mappingSelect">
                    <Form.Label>Select Mapping</Form.Label>
                    <Form.Control as="select" placeholder="select" 
                        isInvalid={mapperValidation.state}
                        onChange={this.changeSelectedMapping}
                      disabled={isLoading}
                      value={selectedMapping ? selectedMapping.mapperId : 'default'}>
                      <option value="default">-- Select Mapping --</option>
                      {mappings.map(mapping => (
                        <option key={`${mapping.mapperId}`} value={mapping.mapperId}>{mapping.mapperName}</option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{mapperValidation.error}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div style={{ width: '50%' }}>
                {(() => {
                  if (loadErrors) {
                    if (loadErrors instanceof Array) {
                      return (
                        <div style={[GlobalStyles.card]}>
                          <h5>Load Errors</h5>
                          <div className="table-responsive" >
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Row</th>
                                  <th>Column</th>
                                  <th>Value</th>
                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loadErrors.map((error, idx) => (
                                  <tr key={`error-${idx}`}>
                                    <td>{error.Row}</td>
                                    <td>{error.Column}</td>
                                    <td>{error.Value}</td>
                                    <td>{error.Message}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    } else {
                      return (<div>{loadErrors.Message}</div>);
                    }
                  } else {
                    return '';
                  }
                })()}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {!isLoading ? (
              <Button variant="arius" onClick={this.loadData}>Load Data</Button>) : (
                <Button variant="arius" key="save"
                  disabled={isCancelling || !canCancelLoad} onClick={this.cancelLoad}>Cancel Load</Button>)}
            <Dropdown >
                <Dropdown.Toggle variant="arius" style={btnStyle}>Log File</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={this.downloadLogFile}>Download</Dropdown.Item>
                    <Dropdown.Item onClick={this.deleteLogFile}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div style={{ width: '55vw' }}>
              {progressBar}
              <div style={{ textAlign: 'center' }}>
                {(() => {
                  if (dataLoadComplete) {
                    return (
                      <div>
                        <div>{serverMessage}</div>
                        {dataLoadStats ? (
                          <div>
                            {(() => {
                              if (isFetchingDataLoads) {
                                return (
                                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Loader />
                                    Fetching data load statistics
                                  </div>
                                );
                              }

                              const
                                totalMinutes = moment(dataLoadStats.completeDateTime).diff(moment(dataLoadStats.queueInsertDateTime), 'minutes'),
                                totalSeconds = moment(dataLoadStats.completeDateTime).diff(moment(dataLoadStats.queueInsertDateTime), 'seconds'),
                                totalRemainderSeconds = totalMinutes > 0 ? totalSeconds % (totalMinutes * 60) : totalSeconds,
                                totalTime = totalMinutes > 0 ?
                                  `${totalMinutes} minutes and ${totalRemainderSeconds} seconds` : `${totalSeconds} seconds`,
                                minutes = moment(dataLoadStats.importEndDateTime).diff(moment(dataLoadStats.importBeginDateTime), 'minutes'),
                                seconds = moment(dataLoadStats.importEndDateTime).diff(moment(dataLoadStats.importBeginDateTime), 'seconds'),
                                remainderSeconds = minutes > 0 ? seconds % (minutes * 60) : seconds,
                                milliseconds = moment(dataLoadStats.importEndDateTime).diff(moment(dataLoadStats.importBeginDateTime));

                              let statistics = '';

                              if (minutes > 0 && seconds > 0) {
                                statistics = `Loaded ${recordsProcessed} records in
                                 ${minutes} minutes and ${remainderSeconds} seconds. Total time: ${totalTime}`;
                              } else if (minutes < 1 && seconds > 0) {
                                statistics = `Loaded ${recordsProcessed} records in ${seconds} seconds. Total time: ${totalTime}`;
                              } else {
                                statistics = `Loaded ${recordsProcessed} records in ${milliseconds} milliseconds. Total time: ${totalTime}`;
                              }
                              if (previouslySelectedFile && previouslySelectedMapping)
                                return (
                                  <div>
                                    <div>{statistics}</div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                      <div style={{ textAlign: 'left' }}>
                                        <div><strong>Valuation Date:</strong> {moment(dataLoadStats.valuationDate).format('L')}</div>
                                        <div><strong>File Loaded:</strong> {previouslySelectedFile.name}</div>
                                        <div><strong>Mapping:</strong> {previouslySelectedMapping.mapperName}</div>
                                      </div>
                                    </div>
                                  </div>
                                );
                                else
                                return (<div>Loaded {recordsProcessed} records</div>);
                            })()}
                          </div>
                        ) : (<div>Loaded {recordsProcessed} records</div>)}
                      </div>
                    );
                  } else if (serverMessage === SERVER_NOTIFICATION_MESSAGES.TOD_DB_LOAD_RECORDS_PROCESSING && job && job.data) {
                    //const currentRecordsProcessed = job.data.recordsProcessed ? job.data.recordsProcessed : 0;
                    const currentRecordsProcessed = recordsProcessed;
                    return (
                      <div>
                        <div>
                          {serverMessage}
                        </div>
                        <div>
                          {currentRecordsProcessed} records processsed
                        </div>
                        <div>
                          {percentageComplete} % complete.
                        </div>
                      </div>
                    );
                  } else {
                    return serverMessage;
                  }
                })()}
              </div>
              <div style={{ textAlign: 'center', color: Colors.red }}>
                {(() => {
                  if (errorMessage) {
                    return errorMessage;
                  } else if (typeof loadErrors === 'string') {
                    return loadErrors;
                  } else {
                    return '';
                  }
                })()}
              </div>

            </div>
          </div>

        </div>
      );
    }
    return (
      <div style={{ width: '100vw',
        padding: '0vh 10vw 15px',
        overflowY: 'auto',
        height: 'calc(100vh - 52px)' }}>
        {contentMarkup}
      </div>
    );
  }
}

export default radium(DbLoader);
