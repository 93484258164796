import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import {
    ButtonGroup,
   // Button,
    DropdownButton,
    Dropdown,
    OverlayTrigger,
    Tooltip,
    Form,
} from 'react-bootstrap';
import { Button } from '@arius';
import AnalysisToolbar from './analysisToolbar';
import { fetchWorkspace } from '../../../actions/analysis/workspace.actions';
import { notifyError } from '../../../utilities/notifier';
import { makeClone } from '../../../utilities/helpers';
import Loader from '../../shared/presentational/loader';
import PlaceHolderMessage from '../../shared/presentational/placeHolderMessage';
import NoResults from '../../shared/presentational/noResults';
import { clearScripts } from '../../../actions/analysis/automation.actions';
import CommandStatus from './commandStatus';
import StatusGrid from './statusGrid';

const style = {
    buttonsRow: {
            display: 'flex',
            alignSelf: 'inherit',
            margin: '10px 0px 0px 20px',
        },
    projectLink: {
        display: 'flex',
        alignSelf: 'inherit',
        margin: '10px 0px 0px 20px',
        color: '#337AB7',
        fontWeight: 'lighter',
        textDecoration: 'underline',
        fontSize: 'small',
    },
    mainContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%'
    },
    clickable: {
        cursor: 'pointer',
    },                                                     
};

class Status extends Component {
    static propTypes = {
        userKey: PropTypes.string,
        isFetching: PropTypes.bool,
        currentWorkspace: PropTypes.object,
        workspaces: PropTypes.array,
        dispatch: PropTypes.func,
        params: PropTypes.object,
        longRunningOperationsStatus: PropTypes.array,
        getLongRunningOperationsStatus: PropTypes.func,
        clearLongRunningOperationsStatus: PropTypes.func,
        statuses: PropTypes.array,
        projects: PropTypes.array,
        isFetchingWorkSpaces: PropTypes.bool,
        isFetchingProjects: PropTypes.bool,
        cancelBatch: PropTypes.func,
        height: PropTypes.string,
        tableHeight: PropTypes.string,
        toggleCommandStatuses: PropTypes.func,
        openedCommandStatus: PropTypes.string,
        fetchCommandStatuses: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {
            expandedJobs: [],
            projects: [],
            fetching: false,
            cancelledJobs: [],
            jobs: [],
            statusFilter: [],
        };
        this.changeWorkspaceHandler = this.changeWorkspaceHandler.bind(this);
        this.getCancelIcon = this.getCancelIcon.bind(this);
        this.toggleCommandStatuses = this.toggleCommandStatuses.bind(this);
        this.filterJobsByStatus = this.filterJobsByStatus.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
    }

    componentWillMount() {
        const {
            getLongRunningOperationsStatus,
            longRunningOperationsStatus,
            currentWorkspace,
            userKey,
            dispatch,
            params,
        } = this.props;

        if (currentWorkspace) {
            getLongRunningOperationsStatus(userKey, currentWorkspace.id);
            // dispatch(tryFetchProjects(userKey, currentWorkspace.id));
            this.setState({ fetching: true });
        }

        if (params.workspaceId && !currentWorkspace) {
          dispatch(fetchWorkspace(userKey, params.workspaceId));
        }

        if (longRunningOperationsStatus && longRunningOperationsStatus.length) {
            this.setState({ alreadyFetched: true, jobs: makeClone(longRunningOperationsStatus) });
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            getLongRunningOperationsStatus,
            longRunningOperationsStatus,
            currentWorkspace,
            userKey,
            projects,
            isFetching,
            isFetchingProjects,
            isFetchingWorkSpaces,
        } = this.props,
        stillFetching = isFetching || isFetchingProjects || isFetchingWorkSpaces;

        if (
            nextProps.currentWorkspace &&
            currentWorkspace !== nextProps.currentWorkspace
        ) {
            getLongRunningOperationsStatus(userKey, nextProps.currentWorkspace.id);
            // dispatch(tryFetchProjects(userKey, nextProps.currentWorkspace.id));
            this.setState({ fetching: true });
        }
        if (projects && nextProps.projects && projects !== nextProps.projects) {
                this.setState({ projects: nextProps.projects });
                if (!stillFetching) {
                    this.setState({ fetching: false });
                }
        }

        if (
            longRunningOperationsStatus &&
            longRunningOperationsStatus !== nextProps.longRunningOperationsStatus
        ) {
            const newState = { alreadyFetched: true, stillFetching: false, fetching: false };
            // if (!nextProps.longRunningOperationsStatus.length) newState.fetching = false;
            let { longRunningOperationsStatus: jobs } = nextProps;
            jobs = this.filterJobsByStatus(makeClone(jobs));
            newState.jobs = jobs;
            this.setState(newState);
        }
    }

    onFilterClick(e, menuOption) {
        if (e) e.stopPropagation();
        const statusFilter = this.state.statusFilter.slice(),
        { longRunningOperationsStatus: jobs } = this.props,
        index = statusFilter.indexOf(menuOption);
        if (index === -1) {
            statusFilter.push(menuOption);
        } else {
            statusFilter.splice(index, 1);
        }
        const filteredJobs = this.filterJobsByStatus(jobs, statusFilter);
        this.setState({ statusFilter, jobs: filteredJobs });
    }

    getProjectNameAndId(opParams) {
        const { projects } = this.state;
        let projectNameAndId = '';
        if (projects && projects.length) {
            const project = projects.find(p => p.projectId === opParams.projectId);
            if (project) {
                projectNameAndId = (
                <div style={{ display: 'flex', justifyContent: 'space-between' }} />
                );
            }
        }
        return projectNameAndId;
    }

    getCancelIcon(job) {
        const id = job.groupId,
        { userKey, currentWorkspace } = this.props,
        { cancelledJobs } = this.state;
        let message = 'Cancel Batch Process',
        icon = <span></span>;

        let disabled = cancelledJobs.indexOf(id) !== -1;
        
       if (job.status === 1 || job.status === 2) {
            icon = (
                <Button
                    mode={disabled ? 'cancel-disabled' : 'cancel'}
                    onClick={() => {
                        if (!disabled) {
                            this.cancelBatchProcess({ userKey, databaseId: currentWorkspace.id, batchJobId: id })
                    }}}
                    toolTip={message}
                    placement='left'
                />
            );
       } 
       
        return icon;
    }

    filterJobsByStatus(jobs, filter) {
        const statusFilter = filter || this.state.statusFilter,
        filteredJobs = !statusFilter.length ? jobs : jobs.filter(j =>
            statusFilter.indexOf(j.statusCode) !== -1);
        return filteredJobs;
    }

    toggleCommandStatuses(e, operationId, job, project) {
        const { toggleCommandStatuses } = this.props;
        toggleCommandStatuses(e, operationId, job, project);
    }

    cancelBatchProcess({ userKey, databaseId, batchJobId }) {
        const { cancelledJobs } = this.state;
        cancelledJobs.push(batchJobId);
        this.props.cancelBatch({ userKey, databaseId, batchJobId });
        this.setState({ cancelledJobs });
        this.refreshStatusReports();
    }

    changeWorkspaceHandler(workspaceId) {
        const {
            dispatch,
            userKey,
            clearLongRunningOperationsStatus,
            currentWorkspace,
            toggleCommandStatuses,
            browserHistory,
        } = this.props;
        if (currentWorkspace) {
            clearLongRunningOperationsStatus();
        }
        dispatch(fetchWorkspace(userKey, workspaceId));
        dispatch(clearScripts());
        browserHistory.push(`/analysis/automation/status/${workspaceId}`);
        this.setState({ projects: [] });
        toggleCommandStatuses(null, null);
    }

    navToProjects() {
        const { currentWorkspace, browserHistory } = this.props;
        if (currentWorkspace) {
            browserHistory.push(`/analysis/analysisTab/workspaces/${currentWorkspace.id}/projects`);
        } else {
            browserHistory.push(`/analysis/analysisTab`);
        }
    }
    
    navToExtract() {
        this.props.browserHistory.push('/analysis/reporting');
    }

    refreshStatusReports() {
        const {
            getLongRunningOperationsStatus,
            currentWorkspace,
            userKey,
            clearLongRunningOperationsStatus,
            toggleCommandStatuses,
        } = this.props;

        if (currentWorkspace) {
            getLongRunningOperationsStatus(userKey, currentWorkspace.id);
            clearLongRunningOperationsStatus();
            this.setState({ fetching: true });
        } else {
            notifyError('You must first select a database');
        }
        toggleCommandStatuses(null, null);
    }

    
    getButtons() {
        //return <div>THE BUTTONS</div>
        return (
            <ButtonGroup>
                <OverlayTrigger
                placement="left"
                focus
                overlay={
                    <Tooltip id="addNewScriptTooltip">Refresh status reports</Tooltip>
                }
                >
                    <Button onClick={() => this.refreshStatusReports()} variant='outline-secondary'>
                        <i
                        className="fa fa-refresh"
                        aria-hidden="true"
                        />
                    </Button>
                </OverlayTrigger>
                <DropdownButton title="Filter By" id="bg-nested-dropdown" variant='outline-secondary'>
                <Dropdown.Header>Status</Dropdown.Header>
                <Dropdown.Item eventKey="4">
                    <Form.Check
                        onClick={e => this.onFilterClick(e, 'Failed')}
                        inline
                        label='Failed'
                    />
                </Dropdown.Item>
                <Dropdown.Item eventKey="5">
                    <Form.Check
                        onClick={e => this.onFilterClick(e, 'Completed')}
                        inline
                        label='Completed'
                    />
                </Dropdown.Item>
                <Dropdown.Item eventKey="6">
                    <Form.Check
                        onClick={e => this.onFilterClick(e, 'Pending')}
                        inline
                        label='Pending'
                    />
                </Dropdown.Item>
                <Dropdown.Item eventKey="7">
                    <Form.Check
                        onClick={e => this.onFilterClick(e, 'Executing')}
                        inline
                        label='Executing'
                    />
                </Dropdown.Item>
                <Dropdown.Item eventKey="8">
                    <Form.Check
                        onClick={e => this.onFilterClick(e, 'Cancelled')}
                        inline
                        label='Cancelled'
                    />
                </Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>
        )
    }

    render() {
        const { jobs } = this.state,
        {
            currentWorkspace,
            workspaces,
            dispatch,
            longRunningOperationsStatus,
            isFetching,
            isFetchingProjects,
            isFetchingWorkSpaces,
            height,
            openedCommandStatus,
            maxHeight,
            commandStatuses,
            isFetchingCommandStatuses,
            job,
            project,
            fetchCommandStatuses,
            userKey,
            toggleCommandStatuses,
        } = this.props,
        { alreadyFetched, fetching } = this.state;
        let renderTable = (
            <div
            style={{ width: '85vw', margin: '5vh auto' }}>
            <PlaceHolderMessage message={'Please select a database'} />
            </div>
        );
        if (currentWorkspace) {
        renderTable = (
            <div
            style={{ width: '85vw', margin: '5vh auto' }}>
            <PlaceHolderMessage message="No matches" />
            </div>
        );
        }
        if (isFetching || isFetchingProjects || isFetchingWorkSpaces || fetching) {
            renderTable = <div style={{ width: '85vw', margin: '5vh auto' }}><Loader loadingItem="Operations Statuses" /></div>;
        } else if (alreadyFetched && longRunningOperationsStatus && !longRunningOperationsStatus.length) {
            renderTable = (
                renderTable = <div style={{ width: '85vw', margin: '5vh auto' }}><NoResults /></div>
            );
        }

        if (jobs && jobs.length) {
            renderTable = <StatusGrid 
                jobs={jobs}
                commandStatuses={commandStatuses}
                isFetchingCommandStatuses={isFetchingCommandStatuses}
                fetchCommandStatuses={fetchCommandStatuses}
                project={project}
                userKey={userKey}
                getCancelIcon={this.getCancelIcon}
                currentWorkspace={currentWorkspace}
            />
        }

        const buttons = this.getButtons(),
        projectLink = (
            <span style={[style.clickable]} onClick={() => this.navToProjects()}>
            See projects
            </span>
        ),
        extractLink = (
            <span style={[style.clickable]} onClick={() => this.navToExtract()}>
            See extract tables
            </span>
        );

        return (
        <div style={style.mainContainer}>
            <AnalysisToolbar
                currentWorkspace={currentWorkspace}
                workspaces={workspaces}
                dispatch={dispatch}
                changeWorkspaceHandler={this.changeWorkspaceHandler}
            />
            {openedCommandStatus ? <CommandStatus
                maxHeight={maxHeight}
                height={height}
                commandStatuses={commandStatuses}
                isFetchingCommandStatuses={isFetchingCommandStatuses}
                job={job}
                project={project}
                fetchCommandStatuses={fetchCommandStatuses}
                userKey={userKey}
                currentWorkspace={currentWorkspace}
                toggleCommandStatuses={toggleCommandStatuses}
            /> : ''}
            <div style={{ padding: '0', height }}>
            <div
                id="statusTitleRow"
                style={{
                borderBottom: '2px solid #BDBDBD',
                margin: 'initial',
                height: '75px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                }}
            >
                <div style={{ margin: 'initial', paddingLeft: 15 }}>
                <h2>Status Reports</h2>
                </div>
                <div style={style.buttonsRow}>
                {buttons}
                </div>
                <div style={style.projectLink}>
                {projectLink}
                </div>
                <div style={style.projectLink}>
                {extractLink}
                </div>
            </div>
            {renderTable}
            </div>
        </div>
        );
    }
}

export default radium(Status);
