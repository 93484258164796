import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';
import { Table, Button } from '@arius';

class ColumnRow extends Component {
  static propTypes = {
    onSelectChanged: PropTypes.func,
    onNameChanged: PropTypes.func,
    onTypeChanged: PropTypes.func,
    onCumulativeChanged: PropTypes.func,
    openFormulaModal: PropTypes.func,
    deleteColumn: PropTypes.func,
    column: PropTypes.object,
    validationError: PropTypes.string,
    idx: PropTypes.number,
    canEdit: PropTypes.bool,
    columnTypes: PropTypes.array,
    hasDataLoaded: PropTypes.bool,
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    const cCol = this.props.column;
    const nCol = nextProps.column;

    return this.props.idx !== nextProps.idx || this.props.validationError !== nextProps.validationError
      || nCol.columnType !== cCol.columnType
      || nCol.factColumnDisplayName !== cCol.factColumnDisplayName
      || nCol.formula !== cCol.formula
      || nCol.isCumulative !== cCol.isCumulative
      || nCol.isSelected !== cCol.isSelected;
  }

  render() {
    const { column, validationError, canEdit, columnTypes, idx, hasDataLoaded } = this.props;
    const isCalculated = column.columnType === 'calc_measure';
    let {tr:Tr, td:Td} = Table;
    return (
      <Tr key={`newColumn-${idx}`}>
        <Td style={{ width: '5%' }}>
          { canEdit ?
            <Form.Check type="checkbox"
              onChange={(e) => this.props.onSelectChanged(e)}
              checked={ column.isSelected }
            /> : ''}
        </Td>
        <Td style={{ width: '20%' }}>
          <Form.Group>
            <Form.Control
                id={`nameInput${idx}`}
                isInvalid={validationError}
                type="text" maxLength="255"
                disabled={!canEdit}
                value={column.factColumnDisplayName}
                placeholder="Enter column name"
                onChange={(e) => this.props.onNameChanged(e.target.value)}
                onBlur={(e) => this.props.onNameChanged(e.target.value.trim())}
            />
            <Form.Control.Feedback type='invalid' style={{ marginBottom: 0 }}>{validationError}</Form.Control.Feedback>
          </Form.Group>
        </Td>
        <Td style={{ width: '20%' }}>
          <Form.Control as="select"
            placeholder=""
            value={column.columnType}
            disabled={!(canEdit || isCalculated)}
            onChange={(e) => this.props.onTypeChanged(e)}>
            {(() => {
              if (hasDataLoaded) {
                if (column.factColumnGuid && !isCalculated) {
                  return columnTypes.map((col, index) => (
                    <option key={`${index}-${col.value}`} value={col.value}>{col.label}</option>
                  ));
                } else {
                  const filteredColumnTypes = columnTypes.filter(ct => ct.columnTypeId === 2 || ct.columnTypeId === 5);
                  return filteredColumnTypes.map((col, index) => (
                    <option key={`${index}-${col.value}`} value={col.value}>{col.label}</option>
                  ));
                }
              } else {
                return columnTypes.map((col, index) => (
                  <option key={`${index}-${col.value}`} value={col.value}>{col.label}</option>
                ));
              }
            })()}
          </Form.Control>
        </Td>
        <Td style={{ width: '10%' , textAlign: 'center' }}>
          {column.columnType === 'measure' || column.columnType === 'calc_measure' ?
          (<input type="checkbox"
            checked={column.isCumulative}
            disabled={!(canEdit || isCalculated)}
            onChange={(e) => this.props.onCumulativeChanged(e)}
            />) : ''
          }
        </Td>
        <Td style={{ width: '20%' }}>
          {isCalculated ? (
            <div >
              <Form.Group>
                <Form.Control 
                    type="text" 
                    isInvalid={!column.formula} 
                    value={column.formula} disabled />
                <Form.Control.Feedback type='invalid' style={{ marginBottom: 0 }}>Please enter a formula</Form.Control.Feedback>
              </Form.Group>
            </div>

          ) : ''}
        </Td>
        <Td style={{ width: '20%' }}>
          {isCalculated ? (
              <Button toolTip="Edit Formula" iconName="edit" onClick={this.props.openFormulaModal}/>
          ): ''}
        </Td>
        <Td style={{ width: '10%' }}>
          {canEdit ? (<Button toolTip="Remove Column" mode="delete" onClick={this.props.deleteColumn}/>
              ) : ''}
        </Td>
      </Tr>
    );
  }
}

export default radium(ColumnRow);
