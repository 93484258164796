import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { connect } from '@app/utilities/routing';
import { validateName } from '@app/utilities/validators';
import { notifyError } from '@app/utilities/notifier';
import { RECEIVED_QUERY_VALIDATION} from  '@app/actions/tod/tod.actionTypes';
import {
    receivedQuery,
    validateSyntax,
    fetchFactColumns,
    fetchDataGroups,
    fetchOperators,
    fetchQuery,
    fetchQueries,
    createQuery,
    updateQuery,
    deleteDataGroup,
} from '@app/actions/tod/advancedQueries.actions';
import {RECEIVED_QUERY} from '@app/actions/tod/tod.actionTypes';
import QueryContainer from './query';
import PreviewModal from './editors/previewModal';
import SegmentsModal from './editors/segmentsModal';
import * as GlobalStyles from '@app/utilities/globalStyles';
import * as Colors from '@app/utilities/colors';
import { showModal } from '@app/actions/modal.actions';
import Utils from './queryUtils';
import { DATA_MANAGEQUERIES } from '@app/utilities/permissions';
import {
    Card,
  } from 'react-bootstrap';

class CreateQuery extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        verifyPermission: PropTypes.func,
    };
    constructor(props) {
        super(props);
        this.state = {
            query: {},
            columns: [],
            groups: [],
            operators: [],
            clauses: [],
        }
        this.editGroup = this.editGroup.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleInsertAtBase = this.handleInsertAtBase.bind(this);
        this.saveQuery = this.saveQuery.bind(this);
        this.validate = this.validate.bind(this);
        this.validateName = this.validateName.bind(this);
    }
    
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch({type: RECEIVED_QUERY, current: null});
    }

    componentDidUpdate(prevProps) {
        const {query, isFetchingQuery, currentDb, params} = this.props;

        if (null == query && currentDb && currentDb.id === parseInt(params.databaseId, 10) && !isFetchingQuery) {
            // only now can we begin loading things - the state machine resets after currentDb loads from parent...
            this.refresh();
        }
    }

    refresh() {
        const { dispatch, params, userKey} = this.props;
        dispatch(fetchDataGroups(userKey, params.databaseId));
        dispatch(fetchFactColumns(userKey, params.databaseId));
        dispatch(fetchOperators(userKey, params.databaseId));
        dispatch(fetchQueries(userKey,  params.databaseId));
        dispatch({type: RECEIVED_QUERY_VALIDATION, data: null});
        if (!!params.queryId){
            dispatch(fetchQuery(userKey, params.databaseId, params.queryId));
        } else {
            dispatch(fetchQuery(userKey, params.databaseId, 0));  // get default query from server
        }
    }

    editGroup(group, ndx) {
        this.setState({showModal: true, groupBeingEdited: group});
    }

    promptToDelete(group, ndx) {
        const { dispatch, userKey, params } = this.props;
        let deleteMessageItems = [
            <li
              key={group.id}
              style={{ fontWeight: 800, listStyle: 'none' }}
            >
              {group.name}
            </li>,
          ];
  
        deleteMessageItems.unshift(
          'Are you sure you want to delete the following data group: '
        );
        deleteMessageItems.push(
          'This could affect Arius projects for segments that reference this data group in a query.'
        );

        const yesClickHandler = () => {dispatch(deleteDataGroup(userKey, params.databaseId, group.id))};
        const noClickHandler = () => {},
        action = showModal(
            'confirmation',
            deleteMessageItems,
            yesClickHandler,
            noClickHandler
        );
        dispatch(action);
    }

    handleClose = (e) => {
        this.setState({showModal: false});
        this.setState({showPreviewModal: false});
    }

    handleInsertAtBase = (o) => {
        const { dispatch, userKey, params } = this.props;
        let query = JSON.parse(JSON.stringify(this.props.query));
        let item = Utils.createFilterItem(this.props.operators, o);
        item.order = query.where.items.length;
        query.where.items.push(item);
        Utils.numberItems(query.where, 0, 0);
        dispatch(receivedQuery(query));
        dispatch(validateSyntax(userKey, params.databaseId, query));
    }

    validate() {
        let query = this.props.query;
        if (query.description && query.description.length>4000) {
          notifyError(`Query description must have at most 4000 characters.`);
          return false;
        }

        if (!query.exposureColumnName) {
          notifyError('You must select a column for the exposure date.');
          return false;
        }

        let msg = this.validateName(query.name);
        if (msg){
            notifyError(msg);
            return false;
        }
    
        return true;
    }

    validateName(name){
        const {queries, query} = this.props;
        let msg = validateName(name);
        if (msg) {return msg;}

        if (!name) {
            return `You must enter a name for this query.`;
        }
            
        if (name.length > 255) {
            return `Query name must have at most 255 characters.`;
        }

        // now check duplicate names
        const otherQueries = queries ? queries.filter(q => 
            q.queryId !== query.queryId
            && q.name.toLowerCase().trim() === name.toLowerCase().trim()) : [];

        if (otherQueries.length > 0){
            return `Query with the name '${name.trim()}' already exists!`;
        }

        return '';
    }

    saveQuery(){
        const { dispatch, userKey, params } = this.props;
        let query = this.props.query;
        if (!this.validate()){
            return;
        }
        let successCallback = this.goBack;
        if (query.queryId > 0){
            dispatch(updateQuery(userKey, params.databaseId, query, successCallback));
        } else {
            dispatch(createQuery(userKey, params.databaseId, query, successCallback));
        }
    }

    goBack = () => {
        const { browserHistory } = this.props;
        browserHistory.push('/trianglesOnDemand/advancedQueries')
    }

    getValidationElements() {
        const {queryValidationResponse} = this.props;
        let valid = queryValidationResponse ? queryValidationResponse.isValid : true;
        let msg = queryValidationResponse ? queryValidationResponse.validationMessage : "";
        if (valid){
            return (
                <div style={{color: 'green'}}>
                    VALID
                </div>
            )
        } else {
            return (
                <div >
                   <span style={{color: 'red'}}>INVALID or INCOMPLETE</span>  <span>{msg}</span>
                </div>
            ) 
        }
    }

    get includeOtherColumns() {
        const { currentDb } = this.props;
        return currentDb ? currentDb.useADX : false;
    }

    render() {
        const { factColumns, operators, params, verifyPermission} = this.props;
        const hasPermissions = verifyPermission(DATA_MANAGEQUERIES);
        let columns = factColumns ? factColumns.filter(
            col => col.isClaimId || col.columnType.toLowerCase() === 'dimension'
            || (this.includeOtherColumns && col.columnType.toLowerCase() === 'property')
        ) : [];
        let queryOperators = operators ? operators : [];
        let databaseExposureDates = factColumns ? factColumns.filter(
            col => col.columnType === 'ExposureDate',
        ) : [];

        let columnElements = columns.map((cs, idx) => {
            let style = {marginBottom: '3px', textAlign: 'left', fontSize: '10px', padding:'2px', borderRadius: '5px'};
            if (cs.columnType.toLowerCase() !== 'dimension'){
                style.backgroundColor = '#97BBDA';
                style.color = 'white'
            }
            return (<div className='col-sm-12 bg-info-old text-primary-old' 
            style={style}
            onDragStart={(e)=> Utils.handleOnDragStart(e, cs)} 
            onDoubleClick={(e) => {this.handleInsertAtBase(cs);}}
            key={'cole'+idx}       
            draggable>
            {cs.name}
        </div>)
        }
            
        )

        let elementStyle = {
            backgroundColor:'#555E66', //'#95A4B2' 
            color: 'white', 
            padding: '2px',
            paddingBottom: '5px',
            marginBottom: '3px', 
            textAlign: 'center', 
            borderRadius: '5px', 
            marginRight: '3px'};

        let operatorElements = queryOperators.map((cs, idx) => 
            <div className='col-sm-2' 
                style={{...elementStyle}}
                onDragStart={(e)=> Utils.handleOnDragStart(e, cs)} 
                onDoubleClick={(e) => {this.handleInsertAtBase(cs);}}
                key={'opre'+idx}     
                draggable>
                {cs.sql}
            </div>
        )

        let btnStyle = GlobalStyles.button;
        btnStyle.marginRight = '10px';

        return (
            <div className='container'
                style={{ border: '', width: '100%', margin:0, padding: '15px', maxWidth:'none'}}>

                <div className="row" style={{height: 'calc(100vh - 110px)'}}>
                    <div className="col-sm-2">
                        <Card style={{}}>
                            <Card.Header><b>Columns</b></Card.Header>
                            <Card.Body  style={{paddingLeft:'3px', paddingRight:'3px', 
                                height: 'calc(100vh - 186px)', overflowY: 'auto'}}>
                                {columnElements}
                            </Card.Body>
                        </Card>                 
                    </div>
                        <div className="col-sm-10" >
                            <Card style={{padding: 0, border: ''}} >
                                    <Card.Header>
                                        <b>Query</b>
                                        <span className="pull-right">
                                        {/* <PurpleButton 
                                            id="saveQueryBtn"
                                            clickHandler={this.saveQuery} 
                                            message="Save Query" 
                                            type="save"
                                            waiting={this.props.isSaving}>
                                        </PurpleButton> */}
                                        </span>
                                        <SegmentsModal 
                                            query={this.props.query}
                                            validator={this.validate}
                                            databaseId={params.databaseId}>
                                        </SegmentsModal>
                                        <PreviewModal 
                                            databaseId={params.databaseId} 
                                            query={this.props.query}
                                        />
                                    </Card.Header>
                                    <Card.Body className="" style={{height: 'calc(100vh - 240px)', overflowY: 'auto'}}>
                                        <QueryContainer
                                            databaseExposureDates ={databaseExposureDates}
                                            databaseId={params.databaseId}
                                            validateName={this.validateName}
                                            includeOtherColumns={this.includeOtherColumns}
                                            factColumns={factColumns}
                                        ></QueryContainer> 
                                    </Card.Body>
                                    <Card.Footer style={{border: ''}}>
                                        <div className="row small" style={{ border: ''}}>
                                            <div className="col-sm-6">
                                            <div className="row" style={{display:'flex', justifyContent: 'flex-end'}}>
                                                {operatorElements}
                                            </div>
                                            </div>
                                            <div className="col-sm-4" style={{display:'flex',justifyContent: 'flex-end'}}>
                                                {this.getValidationElements()}
                                            </div>
                                        </div>

                                    </Card.Footer>
                            </Card>
                    </div> 
 
                </div>
                <div style={{width: '100%', position: 'fixed',
                        bottom: 0,left: 0,display:'flex', alignItems:'center',justifyContent: 'flex-end',
                        backgroundColor: Colors.grey, padding: 10, height: '54px', paddingBottom: '15px'}}>
                    <button
                        key="save"
                        type="button"
                        style={btnStyle}
                        onClick={this.saveQuery}
                        disabled={!hasPermissions}
                        className="btn btn-default"
                        >
                            Save
                        </button>
                    <button
                        key="cancel"
                        type="button"
                        style={btnStyle}
                        className="btn btn-default"
                        onClick={this.goBack}
                    >
                        Back to Queries
                    </button>
                </div>
            </div>
            
            
            );
    }
}

const mapStateToProps = state => ({
    userKey: state.user.userKey,
    application: state.application,
    query: state.tod.queries.current,
    queries: state.tod.queries.items,
    isFetchingQuery: state.tod.queries.isFetchingQuery,
    isSaving: state.tod.queries.isSaving,
    dataGroups: state.tod.queries.dataGroups,
    rollupDefinitions: state.tod.queries.rollupDefinitions,
    operators: state.tod.queries.operators,
    factColumns: state.tod.queries.factColumns,
    isFetching: state.tod.queries.isFetchingQueries,
    queryValidationResponse: state.tod.queries.queryValidationResponse,
    querySegments: state.tod.queries.querySegments,
});

const mapDispatchToProps = dispatch => ({
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(radium(CreateQuery));
