import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import ExtractDefinitionTableRow from './extractDefinitionTableRow';
import Loader from '@app/shared/presentational/loader';
import NoResults from '@app/shared/presentational/noResults';
import PlaceHolderMessage from '@app/shared/presentational/placeHolderMessage';
import { Table } from '@arius';

const style = {
  actionMenuCell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  clickable: {
    cursor: 'pointer',
  },
  nonEditableInput: {
    position: 'absolute',
    visibility: 'hidden',
    top: '-50px',
    // pointerEvents: 'none',
  },
};

class ExtractDefinitionTable extends Component {
  static propTypes = {
    extractDefinitions: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingWideCsv: PropTypes.bool,
    isFetchingNarrowCsv: PropTypes.bool,
    firstSearchPerformed: PropTypes.bool,
    extractKickingOff: PropTypes.bool,
    extractWithSelectedExtractDefinition: PropTypes.func,
    deleteExtractTableDefinition: PropTypes.func,
    editClickHandler: PropTypes.func,
    apiKey: PropTypes.string,
    exportCsv: PropTypes.func,
    csv: PropTypes.object,
    sortList: PropTypes.func,
    sortedBy: PropTypes.string,
    ascending: PropTypes.bool,
    currentWorkspace: PropTypes.object,
    userKey: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      extractDefinitions,
      isFetching,
      firstSearchPerformed,
      extractWithSelectedExtractDefinition,
      editClickHandler,
      apiKey,
      exportCsv,
      csv,  
      deleteExtractTableDefinition,
      extractKickingOff,
      isFetchingWideCsv,
      isFetchingNarrowCsv,
      sortedBy,
      ascending,
      sortList,
      currentWorkspace,
      userKey
    } = this.props,
      sorterIcon = ascending
        ? <i className="fa fa-caret-up" aria-hidden="true"></i>
        : <i className="fa fa-caret-down" aria-hidden="true"></i>,
      tableHeight = { maxHeight: 'calc(100vh - 181px)' };
    let renderTableBody, renderTable;
    if (extractDefinitions && extractDefinitions.length) {
      renderTableBody = extractDefinitions.map((ex, idx) => 
        <ExtractDefinitionTableRow
          extractDefinition={ex}
          extractKickingOff={extractKickingOff}
          isFetchingWideCsv={isFetchingWideCsv}
          isFetchingNarrowCsv={isFetchingNarrowCsv}
          idx={idx}
          key={idx}
          actionMenuCell={style.actionMenuCell}
          extractWithSelectedExtractDefinition={extractWithSelectedExtractDefinition}
          deleteExtractTableDefinition={deleteExtractTableDefinition}
          apiKey={apiKey}
          exportCsv={exportCsv}
          csv={csv}      
          editClickHandler={editClickHandler}
          currentWorkspace={currentWorkspace}
          userKey={userKey}
        />
      );
      renderTable = (
        <div className="table-responsive">
          <input
            style={style.nonEditableInput}
            type="text"
            id="csv-url">
          </input>
          <Table.responsiveTable>
            <Table.thead>
              <Table.tr>
                <Table.th
                  width='40%'
                  onClick={() => sortList(extractDefinitions, 'name')}>
                  Name&nbsp;<span style={sortedBy === 'name' ? {} : { visibility: 'hidden' }}>{sorterIcon}</span>
                </Table.th>
                <Table.th
                  width='60%'
                  onClick={() => sortList(extractDefinitions, 'description')}>
                  Description&nbsp;<span style={sortedBy === 'description' ? {} : { visibility: 'hidden' }}>{sorterIcon}</span>
                </Table.th>
            
                <Table.th
                  width='130px'
                  onClick={() => sortList(extractDefinitions, 'lastModified')}>
                  Last Modified&nbsp;<span style={sortedBy === 'lastModified' ? {} : { visibility: 'hidden' }}>{sorterIcon}</span>
                </Table.th>
                <Table.th
                  width='130px'
                  onClick={() => sortList(extractDefinitions, 'lastModifiedBy')}>
                  Last Modified By&nbsp;<span style={sortedBy === 'lastModifiedBy' ? {} : { visibility: 'hidden' }}>{sorterIcon}</span>
                </Table.th>
                <Table.th
                  width='130px'
                  onClick={() => sortList(extractDefinitions, 'lastSubmitted')}>
                  Last Submitted&nbsp;<span style={sortedBy === 'lastSubmitted' ? {} : { visibility: 'hidden' }}>{sorterIcon}</span>
                </Table.th>
                <Table.th
                  width='130px'
                  onClick={() => sortList(extractDefinitions, 'lastSubmittedBy')}>
                  Last Submitted By&nbsp;<span style={sortedBy === 'lastSubmittedBy' ? {} : { visibility: 'hidden' }}>{sorterIcon}</span>
                </Table.th>
                <Table.th
                    width='120px'
                    style={{textAlign:'right'}}
                    onClick={() => sortList(extractDefinitions, 'count')}>
                     Record Count&nbsp;<span style={sortedBy === 'count' ? {} : { visibility: 'hidden' }}>{sorterIcon}</span>
                </Table.th>
                <Table.th width='100px'><span style={style.actionMenuCell}>Actions</span></Table.th>
              </Table.tr>
            </Table.thead>
            <Table.tbody style={{maxHeight: `calc(100vh - 235px)`}}>
              {renderTableBody}
            </Table.tbody>
          </Table.responsiveTable>
        </div>
      );
    }
    if (isFetching) {
      renderTable = (
        <div style={{ width: '85vw', margin: '5vh auto' }}>
          <Loader loadingItem="Extract Definitions" />
        </div>
      );
    } else if (extractDefinitions && !extractDefinitions.length && firstSearchPerformed) {
      renderTable = <div style={{ width: '85vw', margin: '5vh auto' }}><NoResults /></div>;
    } else if (!currentWorkspace) {
      renderTable = (
        <div
          style={{ width: '85vw', margin: '5vh auto' }}>
          <PlaceHolderMessage message={'Please select a database'} />
        </div>
      );
    }
    return (
      <div className="custom-table-overflow" style={tableHeight}> 
        {renderTable}
      </div>
    );
  }
}

export default radium(ExtractDefinitionTable);
