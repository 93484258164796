import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@app/utilities/routing';
import { fetchApiKey } from '../../../actions/settings.actions';
import { fetchCurrentAEDbScriptVersion, fetchWorkspaces } from '../../../actions/analysis/workspace.actions';
import { exportToCsv } from '../../../actions/analysis/reporting.actions';
import { Switch, Route } from 'react-router-dom';
import ReportingMain from '../presentational/reportingMain';

class ReportingTab extends Component {
  static propTypes = {
    userKey: PropTypes.string.isRequired,
    application: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.object,
    currentAEDbScriptVersion: PropTypes.number,
    getCurrentAEDbScriptVersion: PropTypes.func,
    currentWorkspace: PropTypes.object,
    workspaces: PropTypes.array,
    extractDefinitions: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingWideCsv: PropTypes.bool,
    isFetchingNarrowCsv: PropTypes.bool,
    extractKickingOff: PropTypes.bool,
    exportList: PropTypes.array,
    isFetchingExportList: PropTypes.bool,
    apiKey: PropTypes.string,
    exportCsv: PropTypes.func,
    csv: PropTypes.object,
    userPermissions: PropTypes.array,   
  };

  componentWillMount() {
    const {
      userKey,
      getCurrentAEDbScriptVersion,
      currentAEDbScriptVersion,
    } = this.props;
    
    if (!currentAEDbScriptVersion) {
      getCurrentAEDbScriptVersion(userKey);
    }
  }

  componentDidMount() {
    const { dispatch, userKey } = this.props;
    dispatch(fetchApiKey(userKey));
    dispatch(fetchWorkspaces(userKey));
  }

  render() {
    const {
      history,
      dispatch,
      userKey,
      currentWorkspace,
      workspaces,
      extractDefinitions,
      reportingDefinitions,
      isFetching,
      isFetchingWideCsv,
      isFetchingNarrowCsv,
      params,
      extractKickingOff,
      exportList,
      isFetchingExportList,
      apiKey,
      exportCsv,
      csv,
      userPermissions,
    } = this.props;

    let childProps = {
      browserHistory: history,
      dispatch,
      userKey,
      currentWorkspace,
      workspaces,
      extractDefinitions,
      reportingDefinitions,
      isFetching,
      isFetchingWideCsv,
      isFetchingNarrowCsv,
      params,
      extractKickingOff,
      exportList,
      isFetchingExportList, 
      apiKey,
      exportCsv,
      csv,
      userPermissions,
    };

    return (
      <Switch>
        <Route path="/analysis/reporting/workspaces/:workspaceId/definitions/:definitionId" render={props => <ReportingMain {...{...childProps, params: props.match.params }}/>}/> />
        <Route path="/analysis/reporting/workspaces/:workspaceId" render={props => <ReportingMain {...{...childProps, params: props.match.params }}/>}/> />
        <Route path="/analysis/reporting" render={props => <ReportingMain {...{...childProps, params: props.match.params }}/>}/> />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  application: state.application,
  userKey: state.user.userKey,
  currentAEDbScriptVersion: state.analysis.workspaces.currentAEVersion,
  currentWorkspace: state.analysis.workspaces.current,
  workspaces: state.analysis.workspaces.items,
  extractDefinitions: state.analysis.reporting.extractDefinitions,
  reportingDefinitions: state.analysis.reporting.reportingDefinitions,
  isFetching: state.analysis.reporting.isFetching,
  isFetchingWideCsv: state.analysis.reporting.isFetchingWideCsv,
  isFetchingNarrowCsv: state.analysis.reporting.isFetchingNarrowCsv,
  extractKickingOff: state.analysis.reporting.extractKickingOff,
  exportList: state.analysis.projects.exportList,
  isFetchingExportList: state.analysis.projects.isFetchingExportList,
  apiKey: state.settingsMenu.apiKey,
  csvFile: state.analysis.reporting.csvFile,
  csv: state.analysis.reporting.csv,
  userPermissions: state.user.userPermissions,
});

const mapDispatchToProps = dispatch => ({
  getCurrentAEDbScriptVersion: userKey => {
    dispatch(fetchCurrentAEDbScriptVersion(userKey));
  },
  exportCsv: (userKey, id, format, databaseName, extractDefinitionName, apiKey) => {
    dispatch(exportToCsv({
      userKey,
      id,
      format,
      databaseName,
      extractDefinitionName,
      apiKey
    }));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportingTab);
