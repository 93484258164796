import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Sort, Resize, Inject, DetailRow, Edit } from '@syncfusion/ej2-react-grids';
import { isArray } from 'util';
import { getSecureDataManager } from '@app/utilities/secureDataManager';



class AriusGrid extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.object),
        data: PropTypes.arrayOf(PropTypes.object),
    };
    static defaultProps = {    };
    constructor(props) {
        super(props);
        this.state = {dm: null};
        this.expand = this.expand.bind(this);

        // NOTE:  for whatever reason, complex grid properties need to be set at the component level
        //          before they can be used in the grid...  (per syncfusion guidance)
        this.defaults = {
            sortSettings: {allowUnsort: false},
            selectionSettings: { checkboxOnly: true },
            checkBoxChange: ((e) => {
                let ndx = e.selectedRowIndexes;
                let selected = [];
                this.grid.contentModule.rows.filter((r, i) => {
                    if (ndx.includes(i)){
                        selected.push(r.data);
                    }
                    return null;
                });
                this.props.checkBoxHandler(selected);
                return null;
            })
        };
    }

    componentDidMount() {
        if (this.allowPaging) {
            // NOTE - here i'm sending filter info in the url params by inserting it manually
            //  the datamanager supposedly uses query object but can't get this working.
            getSecureDataManager('c-david.daughtrey@milliman.com', 'auditlogs/search').then(dm => {
                this.setState({dm});
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.grid && this.props.checkBoxHandler){
            // Clear out the checked rows if grid data is changed (based on set of primary keys)
            let pk = this.primaryKey;
            let prevIds = JSON.stringify(prevProps.data.map((r) => r[pk]));
            let newIds = JSON.stringify(this.props.data.map((r) => r[pk]));
            if (prevIds !== newIds) {
                this.grid.clearSelection();
                this.props.checkBoxHandler([]);
            }
        }
    }

    get allowPaging() {
        return this.props.allowPaging === true ? true : false;  // opt-in
    }

    get hasSelectableRows() {
        return this.columns ? this.props.columns[0].type === 'checkbox' : false;
    }

    get hasTemplate() {
        const { columns } = this.props;
        if (columns && columns.filter(x=> x.template).length) {
            return true;
        }
        if (columns && columns.filter(x=> x.headerTemplate).length) {
            return true;
        }
        return false;
    }

    get config() {
        const { data, height, detailTemplate, allowResizing, allowPaging, editOptions, key } = this.props;
        let adjustedHeight = height ? height : 'calc(100vh - 240px)';
        let allowSorting = this.props.allowSorting === false ? false : true;
        let checkBoxHandler = this.props.checkBoxHandler ? this.props.checkBoxHandler : null;
        let config = {
            sortSettings: this.defaults.sortSettings,
            ref: (g) => this.grid = g,
            dataBound: this.dataBound.bind(this),
            dataSource: data,
            allowTextWrap:true, 
            textWrapSettings: {wrapMode: 'Content'},
            allowSorting, 
            allowPaging
        };

        if (editOptions) { 
            config.editSettings = editOptions;
            config.actionComplete = (args) => {
                if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
                    let dialog = args.dialog;
                    dialog.width = '80%';
                    dialog.height = '250px';
                    dialog.header = args.requestType === 'beginEdit' ? this.props.getEditMessage(args.rowData) : 'New Record';
                    if (this.props.beginEdit){
                        this.props.beginEdit(args.rowData);
                    }
                }
                if ((args.requestType === 'save')) {
                    if (this.props.onSave){
                        this.props.onSave(args.rowData);
                    }
                }
            }
        }
        if (adjustedHeight !== 'auto') { config.height = adjustedHeight }

        if (checkBoxHandler && this.hasSelectableRows && this.primaryKey) {
            config.selectionSettings = this.defaults.selectionSettings;
            config.checkBoxChange = this.defaults.checkBoxChange.bind(this);
        }
        if (allowResizing===true) { config.allowResizing = true;}
        if (detailTemplate) { config.detailTemplate = detailTemplate;}
        if ( this.allowPaging) {     
            config.dataSource = this.state.dm;
            config.pageSettings = { pageSize: 50, pageSizes: [10, 50, 100]}
            config.actionBegin = (e) => {
                let {dm} = this.state;
                if (dm) {
                    dm.setAdditionalPayload(this.props.params) 
                }
            }
        }

        config.key = key ? key : 'NOGRIDKEYDEFINED';

        return config;
    }

    get services() {
        const { detailTemplate, allowResizing, allowPaging, editOptions } = this.props;
        let allowSorting = this.props.allowSorting === false ? false : true;

        let services = [];
        if (allowSorting) { services.push(Sort)}
        if (allowPaging===true) { services.push(Page)}
        if (allowResizing===true) { services.push(Resize) }
        if (detailTemplate) { services.push(DetailRow) }
        if (editOptions) { services.push(Edit)}
        return services;
    }

    get columns() {
        const { columns } = this.props;
        return isArray(columns) ? columns.map((c) => this.getColumn(c)) : [];
    }

    get primaryKey() {
        const { columns } = this.props;
        let primaryColumn = isArray(columns) ? columns.find(x => x.isPrimaryKey) : null;
        return primaryColumn ? primaryColumn.field : '';
    }

    getColumn(o) {
        if (o.type === "datetime"){
            o.template = (r)=> {
                let val = r[o.field];
                return (
                    <span>{val ? moment.utc(val).local().format('L LT') : ''}</span>
            )};
            o.width = o.width ? o.width : '10%';
            o.minWidth = '130';
        }

        if (o.type === "date"){
            o.template = (r)=> {
                let val = r[o.field];
                return (
                    <span>{val ? moment.utc(val).local().format('L') : ''}</span>
            )};
        }

        if (o.type === "dateUTC"){
            o.template = (r)=> {
                let val = r[o.field];
                return (
                    <span>{val ? moment.utc(val).format('L') : ''}</span>
            )};
        }

        if (o.headerText){
            o.headerTextAlign = o.headerTextAlign ? o.headerTextAlign : 'left';
        }

        return <ColumnDirective key={`column ${JSON.stringify(o)}`} {...o}></ColumnDirective>;
    }

    getInjectedServices(o) {

    }

    expand(e) {
        let { expandedIndex } = this.props;
        if (this.Grid && expandedIndex) { 
            //console.error('fire EXPANDING......')
            this.Grid.detailRowModule.expand(expandedIndex);
        }
    }

    dataBound(){ 
        // Starting point for the code below is Grid.prototype.hideScroll
        let g = this.grid;
        var headerContent = g.headerModule.getPanel();
        var contentTable = g.contentModule.getPanel().querySelector('.e-content');
        if ((g.currentViewData.length * g.getRowHeight()) < g.element.scrollHeight){//this.height) {
            headerContent.style.paddingRight = '';
            contentTable.style.overflowY = 'auto';
        }
        else {
            headerContent.style.paddingRight = '16px';
            contentTable.style.overflowY = 'scroll';
        }
    } 

    render() {
        if (this.hasTemplate || this.props.detailTemplate){
            // Kludge to prevent improper rendering of the detail row icons or header template
            setTimeout(()=> {
                if (this.grid) {
                    this.grid.refresh();
                }
                return null;
            }, 100)
        }
        return (
            <GridComponent {...this.config}>
                <ColumnsDirective>{this.columns}</ColumnsDirective>
                <Inject services={this.services} />
            </GridComponent>
        )
    }
}

export default AriusGrid;