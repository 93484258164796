import { callApi } from '../utilities/callApi';

export function retrieveGroups(userKey) {
  return callApi(
    '/groups',
    { 'X-AE-USERKEY': userKey }
  );
}

export function retrieveGroup(userKey, groupId) {
  return callApi(
    `/groups/${groupId}`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function createGroup({ userKey, description, name, roles, groups, memberTypeId }) {
  return callApi(
    '/groups',
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { description, name, roles, groups, memberTypeId }
  );
}

export function updateGroup({ userKey, groupId, description, name, roles, groups, memberTypeId }) {
  return callApi(
    `/groups/${groupId}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    { description, name, roles, groups, memberTypeId }
  );
}

export function deleteUserGroup(userKey, groupId) {
  return callApi(
    `/groups/${groupId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE'
  );
}

export function retrieveUsersForGroup(userKey, groupId) {
  return callApi(
    `/groups/${groupId}/users`,
    { 'X-AE-USERKEY': userKey }
  );
}

export function updateUsersForGroup(userKey, groupId, userIds) {
  return callApi(
    `/groups/${groupId}/users`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    userIds
  );
}
