import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { getToken } from './adalAdapter';
import { getAppSettings } from '../config';


function getBaseServiceUrl() {
  return getAppSettings().then(settings => {
    const { baseServiceUrl } = settings;
    return `https://${baseServiceUrl}`;
  });
}

function getSecureDataManager(userKey, path) {
    var dm = null;
    return new Promise((resolve, reject) => {
        getBaseServiceUrl().then( base => {
            getToken().then(token =>{
                dm = new SecureDataManager(`${base}/${path}`, [{ Authorization: `Bearer ${token}`, 'X-AE-USERKEY': userKey }])
                resolve(dm);
            });
        })
    });
}


class SecureDataManager extends DataManager {
    constructor(url, headers) {
        
        let adaptor = new UrlAdaptor();
        super({url, adaptor, headers});
        this._additionalPayload = {};
        adaptor.beforeSend = (dm, request, settings) => {
            let data = JSON.parse(settings.data);
            data = {...data, ...this._additionalPayload };
            settings.data = JSON.stringify(data);
        }
        
    }

    setAdditionalPayload(val) {
        this._additionalPayload = val;
    }
}   

export { getSecureDataManager };