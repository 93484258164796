import {
  SELECTING_TOD_DATABASE,
  RECEIVED_TOD_DATABASE,
  RECEIVED_TOD_DATABASES,
  FETCHING_TOD_DATABASES,
  RECEIVED_DATABASE_MAPPINGS,
  RECEIVED_DATABASE_MAPPING,
  RECEIVED_DATE_FORMATS,
  RECEIVED_DATE_FORMAT_DELIMITERS,
  RECEIVED_COLUMN_TYPES,
  SAVING_DATA_MAPPINGS,
  RECEIVED_COLUMNS_FOR_TOD_DB,
  RECEIVED_TOD_DATABASE_INFO,
  FETCHING_TOD_DATABASE_INFO,
  RECEIVED_TOD_DATABASE_BACKUPS,
  RECEIVED_TOD_DATABASE_ARCHIVED,
  RECEIVED_ARCHIVED_FOR_TOD_DATABASE_BACKUP,
  SAVING_TOD_DATABASE,
  RECEIVED_TOD_DB_VERSION,
  RECEIVED_TOD_DATABASE_DATALOADS,
  FETCHING_TOD_DATA_LOADS,
  CLEAR_TOD_DATABASE,
  DELETED_DATA_LOADS,
  DELETED_VALUATION_DATE,
  DELETING_DATA_LOADS,
  DELETING_VALUATION_DATE,
  RECEIVED_TOD_DATA_LOAD_LOG_FILE,
  RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE,
  RECEIVED_BACKUPRESTORE_LOG_FILE,
} from '../../actions/tod/tod.actionTypes';

function getBackupUpdater(items) {
  const saved = (items || [])
    .filter(db => db && (db.archived || db.backups))
    .reduce((a, v) => { a[v.workspaceId] = { backups: v.backups, archived: v.archived }; return a; }, {});
  return db => {
    const savedItem = saved[db.workspaceId]; 
    if (savedItem) {
      db.backups = savedItem.backups;
      db.archived = savedItem.archived;
    }
    return db;
  };
}

const todDatabaseReducer = (state = {
  items: [], 
  current: null,
  receivedAt: null,
  isFetching: false,
  mappings: { items: [], isSaving: false, current: null },
  dateFormats: [],
  dateFormatDelimiters: [],
  columnTypes: [],
  backups: [],
  archived: [],
  currentVersion: null,
  dataLoads: [],
  isFetchingDataLoads: false,
  dataLoadDeletesInProgress: [],
  valuationDateDeletesInProgress: [],
  txtFile: null,
}, action) => {
  switch (action.type) {
    case FETCHING_TOD_DATABASES:
      return Object.assign({}, state, { isFetching: true });
    case SELECTING_TOD_DATABASE:
    case RECEIVED_TOD_DATABASE:
    case RECEIVED_TOD_DATABASE_INFO:
    case FETCHING_TOD_DATABASE_INFO:
    case RECEIVED_COLUMNS_FOR_TOD_DB:
      return Object.assign({}, state, {
        isFetching: false,
        current: action.current,
        receivedAt: action.receivedAt,
      });
    case CLEAR_TOD_DATABASE:
      return Object.assign({}, state, {
        isFetching: false,
        current: action.current,
        receivedAt: action.receivedAt,
      });
    case DELETED_DATA_LOADS:
      state.dataLoadDeletesInProgress.splice(state.dataLoadDeletesInProgress.indexOf(action.id), 1);
      return Object.assign({}, state, {
        dataLoadDeletesInProgress: state.dataLoadDeletesInProgress,
      });
    case DELETED_VALUATION_DATE:
      state.valuationDateDeletesInProgress.splice(state.valuationDateDeletesInProgress.indexOf(action.id), 1);
      return Object.assign({}, state, {
        valuationDateDeletesInProgress: state.valuationDateDeletesInProgress,
      });
    case DELETING_DATA_LOADS:
      state.dataLoadDeletesInProgress.push(action.id);
      return Object.assign({}, state, {
        dataLoadDeletesInProgress: state.dataLoadDeletesInProgress,
      });
    case DELETING_VALUATION_DATE:
      state.valuationDateDeletesInProgress.push(action.id);
      return Object.assign({}, state, {
        valuationDateDeletesInProgress: state.valuationDateDeletesInProgress,
      });
    case FETCHING_TOD_DATA_LOADS:
      return Object.assign({}, state, {
        isFetchingDataLoads: true,
      });
    case RECEIVED_TOD_DB_VERSION:
      return Object.assign({}, state, {
        isFetching: false,
        currentVersion: action.currentVersion,
      });
    case RECEIVED_TOD_DATABASES:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        items: action.databases.map(getBackupUpdater(state.items)),
        receivedAt: action.receivedAt,
      });
    case RECEIVED_DATABASE_MAPPINGS:
    case RECEIVED_DATABASE_MAPPING:
      return Object.assign({}, state, {
        isFetching: false,
        isSaving: false,
        mappings: action.mappings,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_DATE_FORMATS:
      return Object.assign({}, state, {
        isFetching: false,
        dateFormats: action.dateFormats,
        receivedAt: action.receivedAt,
      });
    case RECEIVED_DATE_FORMAT_DELIMITERS:
      return Object.assign({}, state, {
        isFetching: false,
        dateFormatDelimiters: action.dateFormatDelimiters,
        receivedAt: action.receivedAt,
      });
    case SAVING_DATA_MAPPINGS:
      return Object.assign({}, state, {
        mappings: action.mappings,
      });
    case RECEIVED_COLUMN_TYPES:
      return Object.assign({}, state, {
        isFetching: false,
        columnTypes: action.columnTypes,
      });
    case RECEIVED_TOD_DATABASE_PIT_RESTORE_RANGE:
      return Object.assign({}, state, {
        isFetching: false,
        items: state.items.map(db => (db.workspaceId !== action.databaseId ? db : { ...db, earliestRestorePoint: action.earliestRestorePoint, latestRestorePoint: action.latestRestorePoint })),
        receivedAt: action.receivedAt,
      });
    case RECEIVED_TOD_DATABASE_BACKUPS:
        return Object.assign({}, state, {
            isFetching: false,
            items: state.items.map(db => (db.workspaceId !== action.databaseId ? db : { ...db, backups: action.backups })),
            receivedAt: action.receivedAt,
        });
    case RECEIVED_ARCHIVED_FOR_TOD_DATABASE_BACKUP:
    case RECEIVED_TOD_DATABASE_ARCHIVED:
      return Object.assign({}, state, {
        isFetching: false,
        items: state.items.map(db => (db.workspaceId !== action.databaseId ? db : { ...db, archived: action.archived })),
        receivedAt: action.receivedAt,
      });
    case SAVING_TOD_DATABASE:
      return Object.assign({}, state, {
        isSaving: true,
      });
    case RECEIVED_TOD_DATABASE_DATALOADS:
      return Object.assign({}, state, {
        isFetchingDataLoads: false,
        dataLoads: action.dataLoads,
      });
    case RECEIVED_TOD_DATA_LOAD_LOG_FILE:
      return Object.assign({}, state, {
        txtFile: action.txtFile,
      });
      
    case RECEIVED_BACKUPRESTORE_LOG_FILE:
      return Object.assign({}, state, {
        txtFile: action.txtFile,
      });
    default:
      return state;
  }
};

export default todDatabaseReducer;
