import { callApi } from '../utilities/callApi';

export function createQuery(
  { userKey, databaseId, queryName, queryDescription, columns, defaultDateY },
) {
  return callApi(
    `/v2/factDatabases/${databaseId}/queries`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    { databaseId, queryName, queryDescription, columns, defaultDateY },
  );
}

export function updateQuery(
  {
    userKey,
    databaseId,
    queryId,
    queryName,
    queryDescription,
    columns,
    defaultDateY,
  },
) {
  return callApi(
    `/v2/factDatabases/${databaseId}/queries/${queryId}`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    { databaseId, queryName, queryDescription, columns, defaultDateY },
  );
}

export function retrieveQueries(userKey, databaseId) {
  return callApi(`/factDatabases/${databaseId}/queries`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveQueryDataSource(userKey, databaseId, params) {
  return callApi(`/factDatabases/${databaseId}/datacontext`, {
    'X-AE-USERKEY': userKey,
  }, 'POST', params);
}

export function retrieveSegmentPreview(userKey, databaseId, params) {
  return callApi(`/factDatabases/${databaseId}/splitspreview`, {
    'X-AE-USERKEY': userKey,
  }, 'POST', params);
}

export function retrieveDatasetColumns({ userKey, databaseId }) {
  return callApi(
    `/factdatabases/${databaseId}/rollupDefinition/dataSetColumns`,
    { 'X-AE-USERKEY': userKey },
  );
}

export function retrieveQuery(userKey, databaseId, queryId) {
  return callApi(`/factDatabases/${databaseId}/queries/${queryId}`, {
    'X-AE-USERKEY': userKey,
  });
}

export function deleteQuery(userKey, databaseId, queryId) {
  return callApi(
    `/factDatabases/${databaseId}/queries/${queryId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function retrieveRollupDefinitions(userKey, databaseId) {
  return callApi(
    `/factDatabases/${databaseId}/rollupDefinition`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function retrieveRollupDefinitionsForDataSet(
  { userKey, factDatabaseId, dataSetColumnId },
) {
  return callApi(
    `/factdatabases/${factDatabaseId}/rollupDefinition/${dataSetColumnId}`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function retrieveRollupDefinition(
  { userKey, factDatabaseId, dataSetColumnId, rollupDefinitionId },
) {
  return callApi(
    `/factdatabases/${factDatabaseId}/rollupDefinition/${dataSetColumnId}/${rollupDefinitionId}`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function createRollupDefinition(
  {
    userKey,
    factDatabaseId,
    dataSetColumnId,
    rollupName,
    rollupDescription,
    rollupData,
    allSelected,
  },
) {
  return callApi(
    `/factdatabases/${factDatabaseId}/rollupDefinition`,
    { 'X-AE-USERKEY': userKey },
    'POST',
    { dataSetColumnId, rollupName, rollupDescription, rollupData, allSelected },
  );
}

export function updateRollupDefinition(
  {
    userKey,
    factDatabaseId,
    rollupDefinitionId,
    rollupName,
    rollupDescription,
    rollupData,
    allSelected,
  },
) {
  return callApi(
    `/factdatabases/${factDatabaseId}/rollupDefinition/${rollupDefinitionId}`,
    { 'X-AE-USERKEY': userKey },
    'PUT',
    { rollupName, rollupDescription, rollupData, allSelected },
  );
}

export function deleteRollupDefinition(
  { userKey, factDatabaseId, rollupDefinitionId },
) {
  return callApi(
    `/factdatabases/${factDatabaseId}/rollupDefinition/${rollupDefinitionId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}
