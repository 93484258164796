import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { Form } from 'react-bootstrap';
import * as GlobalStyles from '../../../utilities/globalStyles';
import SaveButtons from '../../shared/presentational/saveButtons';
import { WORKSPACE_MANAGESECURITY, SITE_MANAGESECURITY } from '../../../utilities/permissions';
import { APPLICATION_NAMES } from '@app/utilities/constants';

const style = {
  tableCellRole: {
    maxWidth: '30px',
  },
  cellPositioning: {
    margin: 0,
    verticalAlign: 'middle',
    height: 'initial',
    fontSize: 14,
    paddingTop: 2,
    paddingRight: 2,
    paddingBottom: 2,
    paddingLeft: 8,
  },
  addButton: {
    ...GlobalStyles.button,
  },
  workspaceInfo: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 15,
    height: 50,
    flexDirection: 'row',
    maxWidth: '50vw',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectBox: {
    width: '20vw',
    margin: 10,
    height: '55vh',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '1em',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  userToolbar: {
    backgroundColor: '#EAEAEA',
  },
};

class WorkspaceSecurityList extends Component {
  static propTypes = {
    currentWorkspace: PropTypes.object,
    users: PropTypes.array,
    groups: PropTypes.array,
    roleDescriptions: PropTypes.array,
    userKey: PropTypes.string,
    assignRoleHandler: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      isAddingItem: false,
      currentWorkspace: null,
      users: [],
      roleDescriptions: [],
      workspaces: [],
      selectedUsers: [],
      selectedRoles: [],
      userFilter: '',
      roleFilter: '',
    };
    this.filterUsers = this.filterUsers.bind(this);
    this.filterRoles = this.filterRoles.bind(this);
    this.assignRoles = this.assignRoles.bind(this);
  }

  componentDidMount() {
    const { currentWorkspace, users, roleDescriptions, browserHistory } = this.props,
      newState = {};

    if (currentWorkspace) {
      // currentWorkspace.users = [];
      // currentWorkspace.groups = [];
      this.setState({ currentWorkspace });
      if (currentWorkspace.permissions.indexOf(WORKSPACE_MANAGESECURITY) === -1 &&
          currentWorkspace.permissions.indexOf(SITE_MANAGESECURITY) === -1) {
        browserHistory.push('/unauthorized');
      }
    }

    if (users && users.length) {
      newState.users = users;
    }

    if (roleDescriptions && roleDescriptions.length) {
        newState.roleDescriptions = this.getRelevantRoles();
    }

    if (newState.users || newState.roleDescriptions) {
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps) {
    const { currentWorkspace, users, roleDescriptions, browserHistory } = this.props,
      newState = {};

    if (currentWorkspace && prevProps.currentWorkspace !== currentWorkspace) {
      if (
        currentWorkspace.permissions.indexOf(WORKSPACE_MANAGESECURITY) === -1 &&
        currentWorkspace.permissions.indexOf(SITE_MANAGESECURITY) === -1) {
        browserHistory.push('/unauthorized');
      }
      currentWorkspace.users = [];
      currentWorkspace.groups = [];
      this.setState({ currentWorkspace });
    }

    if (users && users.length && users !== prevProps.users) {
      newState.users = users;
    }

    if (
      roleDescriptions &&
      roleDescriptions.length &&
      roleDescriptions !== prevProps.roleDescriptions
    ) {
        newState.roleDescriptions = this.getRelevantRoles();
    }

    if (newState.users || newState.roleDescriptions) {
      this.setState(newState);
    }
  }

  getRelevantRoles() {
    const { roleDescriptions } = this.props;
    if (roleDescriptions && roleDescriptions.length){
        return roleDescriptions.filter((x) => x.application === APPLICATION_NAMES.ARIUS_ENTERPRISE)
            .sort((a,b) => a.name.localeCompare(b.name));
    }
    return [];
  }

  selectUser(user) {
    const { selectedUsers } = this.state, idx = selectedUsers.indexOf(user);

    if (idx === -1) {
      selectedUsers.push(user);
    } else {
      selectedUsers.splice(idx, 1);
    }
    this.setState({ selectedUsers });
  }

  selectRole(role) {
    const { selectedRoles } = this.state, idx = selectedRoles.indexOf(role);

    if (idx === -1) {
      selectedRoles.push(role);
    } else {
      selectedRoles.splice(idx, 1);
    }
    this.setState({ selectedRoles });
  }

  filterUsers(e) {
    const { users } = this.props;
    const filteredUsers = users.filter(
      u => u.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
    );
    this.setState({ userFilter: e.target.value, users: filteredUsers });
  }

  filterRoles(e) {
    let roleDescriptions = this.getRelevantRoles();
    let filteredRoles = roleDescriptions.filter(
        rd =>
          rd.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1,
      );
    this.setState({
      roleFilter: e.target.value,
      roleDescriptions: filteredRoles,
    });
  }

  assignRoles() {
    const {
      currentWorkspace,
      selectedRoles,
      selectedUsers,
      operation,
    } = this.state,
      { assignRoleHandler, userKey } = this.props,
      site = false,
      databases = [currentWorkspace.id],
      users = selectedUsers,
      roles = selectedRoles;

    assignRoleHandler({ userKey, users, roles, site, databases, operation, currentWorkspace });
    this.setState({
      selectedRoles: [],
      selectedUsers: [],
    });
  }

  render() {
    const { browserHistory } = this.props;
    const {
      users,
      roleDescriptions,
      currentWorkspace,
      selectedRoles,
      selectedUsers,
      userFilter,
      roleFilter,
    } = this.state;

    let contentMarkup = <div>Loading...</div>,
      availableUsersJSX = <div />,
      roleDescriptionsJSX = <div />;

    if (users && users.length) {
      availableUsersJSX = users
        .sort((a,b) => a.name.localeCompare(b.name))
        .map((u, idx) => (
          <div style={style.checkRow} key={`userSelection-${idx}`}>
            <Form.Check
              checked={selectedUsers.indexOf(u.userId) !== -1}
              onChange={() => this.selectUser(u.userId)}
              label={u.name}
              inline
            />
          </div>
        ));
    }

    if (roleDescriptions && roleDescriptions.length) {
      roleDescriptionsJSX = roleDescriptions.map((rd, idx) => (
        <div style={style.checkRow} key={`roleSelection-${idx}`}>
          <Form.Check
            checked={selectedRoles.indexOf(rd.roleId) !== -1}
            onChange={() => this.selectRole(rd.roleId)}
            label={rd.name}
            inline
          />
        </div>
      ));
    }

    if (currentWorkspace) {
      contentMarkup = (
        <div>
          <div style={style.workspaceInfo}>
            <h3>{currentWorkspace.name}</h3>
          </div>
          <div style={{ width: '80vw', margin: 'auto' }}>
            <div
              style={{
                width: '75vw',
                margin: 20,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={[GlobalStyles.card, { display: 'flex' }]}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="form-group">
                      <input
                        id="userFilter"
                        placeholder="filter users"
                        value={userFilter}
                        onChange={this.filterUsers}
                        className="form-control"
                        type="text"
                        style={{ width: '20vw', margin: 10 }}
                      />
                    </div>
                    <div style={style.selectBox}>
                      {availableUsersJSX}
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="form-group">
                      <input
                        id="roleFilter"
                        placeholder="filter roles"
                        value={roleFilter}
                        onChange={this.filterRoles}
                        className="form-control"
                        type="text"
                        style={{ width: '20vw', margin: 10 }}
                      />
                    </div>
                    <div style={style.selectBox}>
                      {roleDescriptionsJSX}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      );
    }

    return (
      <div>
        <div style={{ width: '100vw', padding: '0vh 10vw', overflowY: 'auto', height: 'calc(100vh - 106px)' }}>
          {contentMarkup}
        </div>
        <SaveButtons
          saveHandler={this.assignRoles}
          backButtonHander={() => browserHistory.goBack()}
          backButtonText="Back"
        />
      </div>
    );
  }
}

export default radium(WorkspaceSecurityList);
