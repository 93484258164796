import moment from 'moment';

export const
  TOD_DB = 'TOD_DB',
  WORKSPACE = 'WORKSPACE';

function convertDataShapeLength(lengthNum) {
  switch (lengthNum) {
    case 1:
      return 'M';
    case 3:
      return 'Q';
    case 6:
      return 'H';
    case 12:
      return 'Y';
    default:
      return 'n/a';
  }
}

function createProjectDataShape(project) {
  const projectWithData = project;
  const projectSettings = JSON.parse(projectWithData.projectSettings);
  projectWithData.valuationDate = projectSettings.ValuationDate;
  projectWithData.dataShape = `${projectSettings.NumberOfExposurePeriods}\
 ${convertDataShapeLength(projectSettings.LengthOfExposurePeriods)} x\
 ${projectSettings.NumberOfDevelopmentPeriods}\
 ${convertDataShapeLength(projectSettings.LengthOfDevelopmentPeriods)}, ${projectSettings.YearOfFirstExposurePeriod},\
 ${projectSettings.EndingMonthOfFirstExposurePeriod}, ${projectSettings.FirstDevelopmentAgeInMonths},\
 ${projectSettings.LengthOfLastCalendarPeriodInMonths},\
 ${projectSettings.ExposurePeriodType}, ${projectSettings.FirstExposurePeriodIncludesAllPrior ? 'Prior' : 'No Prior'}`;
  return projectWithData;
}


function getProjectDataShapes(projects) {
  let valuationDates = [];
  const projectsWithData = projects.map(project => {
    const projectWithData = createProjectDataShape(project);
    if (!valuationDates.includes(project.valuationDate)) {
      valuationDates.push(project.valuationDate);
    }
    return projectWithData;
  });
  valuationDates = valuationDates.sort((a, b) => {
    if (moment(b, 'YYYY-MM-DD').isAfter(moment(a, 'YYYY-MM-DD').format('MM-DD-YYYY'))) {
      return 1;
    } else {
      return -1;
    }
  });
  return { valuationDates, projectsWithData };
}

function mapValuationDates(dates) {
  return dates.map(date => moment(date).format('L')).sort((a, b) => {
    if (moment(b, 'L').isAfter(moment(a, 'L'))) {
      return 1;
    } else {
      return -1;
    }
  });
}

function getDataStructureParam(params){
    if (!params || !params.shape || params.shape === 'undefined') { return 'all';}
    return decodeURIComponent(params.shape);
}

function getDateParam(params){
    if (!params || !params.date || params.date === 'undefined') { return '';}
    return decodeURIComponent(params.date);
}

function getProjectListUrl(workspaceId, date, shape){
    return `/analysis/analysisTab/workspaces/${workspaceId}/projects/date/${encodeURIComponent(date)}/${encodeURIComponent(shape)}`;
}

export {
    mapValuationDates,
    createProjectDataShape,
    getProjectDataShapes,
    getDataStructureParam,
    getDateParam,
    getProjectListUrl
};
