import { callApi } from '../utilities/callApi';

export function archiveBackup(userKey, databaseId, backupId, description) {
  return callApi(
    `/v1/factDatabases/${databaseId}/backups/${backupId}/archived`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { description },
  );
}

export function retrieveDatabases(userKey) {
  return callApi('/factDatabases', { 'X-AE-USERKEY': userKey });
}

export function retrieveDatabase(userKey, databaseId) {
  return callApi(`/factDatabases/${databaseId}`, { 'X-AE-USERKEY': userKey });
}

export function retrieveDatabaseInfo(userKey, databaseId, factTableGuid) {
  return callApi(`/factDatabases/${databaseId}/${factTableGuid}/info`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveDataLoads(userKey, databaseId) {
  return callApi(`/factDatabases/${databaseId}/dataLoad`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveDatabaseColumns(userKey, databaseId, factTableGuid) {
  return callApi(`/factDatabases/${databaseId}/${factTableGuid}/factColumns`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveDatabaseMappings(userKey, databaseId) {
  return callApi(`/factDatabases/${databaseId}/dataMappers`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveDatabaseMapping(userKey, databaseId, mapperId) {
  return callApi(`/factDatabases/${databaseId}/dataMappers/${mapperId}`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveBackups(userKey, databaseId) {
  return callApi(`/v1/factDatabases/${databaseId}/backups`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrievePointInTimeRestoreRange(userKey, databaseId) {
    return callApi(`/v1/factDatabases/${databaseId}/pointintimerestorerange`, {
        'X-AE-USERKEY': userKey,
    });
}

// export function retrieveArchived(userKey, databaseId, backupId) {
//   return callApi(
//     `/v1/factDatabases/${databaseId}/backups/${backupId}/archived`,
//     { 'X-AE-USERKEY': userKey },
//   );
// }

export function retrieveArchived(userKey, databaseId) {
  return callApi(`/v1/factDatabases/${databaseId}/archived`, {
    'X-AE-USERKEY': userKey,
  });
}

export function retrieveDateFormats(userKey) {
  return callApi('/factDatabases/dateformats', { 'X-AE-USERKEY': userKey });
}

export function retrieveColumnTypes(userKey) {
  return callApi('/factDatabases/columntypes', { 'X-AE-USERKEY': userKey });
}

export function retrieveDateFormatDelimiters(userKey) {
  return callApi('/factDatabases/dateformatdelimiters', {
    'X-AE-USERKEY': userKey,
  });
}

export function createDatabase(
  { userKey, name, description, accByDiagonals, columns, useADX },
) {
  return callApi(
    '/factDatabases',
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { name, description, accByDiagonals, columns, useADX },
  );
}

export function createDataMapper(
  {
    userKey,
    databaseId,
    mapperName,
    mapperDescription,
    factTableGuid,
    columns,
  },
) {
  return callApi(
    `/factDatabases/${databaseId}/datamappers`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { mapperName, mapperDescription, factTableGuid, columns },
  );
}

export function cancelDataLoad(userKey, databaseId) {
  return callApi(
    `/factDatabases/${databaseId}/dataload/cancel`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
  );
}

export function createBackup(userKey, databaseId, description) {
  return callApi(
    `/v1/factDatabases/${databaseId}/backups`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { description },
  );
}

export function deleteDatabase(userKey, databaseId) {
  return callApi(
    `/factDatabases/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function deleteDataMapper(userKey, databaseId, mapperId) {
  return callApi(
    `/factDatabases/${databaseId}/datamappers/${mapperId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function deleteValuationDate(
  userKey,
  databaseId,
  factTableGuid,
  valuationDate,
) {
  return callApi(
    `/factDatabases/${databaseId}/${factTableGuid}/info/${valuationDate}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function deleteDataLoad(
  userKey,
  databaseId,
  dataLoadId,
  factTableGuid
) {
  return callApi(
    `/factDatabases/${databaseId}/${factTableGuid}/dataload/${dataLoadId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function deleteBackup(userKey, databaseId, backupId) {
  return callApi(
    `/v1/factDatabases/${databaseId}/backups/${backupId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function deleteArchived(userKey, databaseId, backupId, archiveId) {
  return callApi(
    `/v1/factDatabases/${databaseId}/archived/${archiveId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}

export function updateDatabase(
  { userKey, databaseId, name, description, accByDiagonals, columns },
) {
  return callApi(
    `/factDatabases/${databaseId}`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    { name, description, accByDiagonals, columns },
  );
}

export function updateDataMapper(
  {
    userKey,
    databaseId,
    mapperId,
    mapperName,
    mapperDescription,
    factTableGuid,
    columns,
  },
) {
  return callApi(
    `/factDatabases/${databaseId}/datamappers`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    { mapperId, mapperName, mapperDescription, factTableGuid, columns },
  );
}

export function updateDatabaseUsers(userKey, databaseId, users) {
  return callApi(
    `/factDatabases/${databaseId}/users`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    users,
  );
}

export function updateDatabaseGroups(userKey, databaseId, groups) {
  return callApi(
    `/factDatabases/${databaseId}/groups`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'PUT',
    groups,
  );
}

export function loadData(
  { userKey, databaseId, mapperId, csvFileName, valuationDate },
) {
  return callApi(
    `/factDatabases/${databaseId}/dataload`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { mapperId, csvFileName, valuationDate },
  );
}

export function restoreBackup(userKey, databaseId, backupId) {
  return callApi(
    `/v1/factDatabases/${databaseId}/backups/${backupId}/restore`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
  );
}

export function restorePointInTime(userKey, databaseId, timestamp) {
    return callApi(
        `/v1/factDatabases/${databaseId}/pointintimerestore`,
        { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
        'POST',
        '"' + timestamp + '"'
    );
}

export function undoPITR(userKey, databaseId, backupId) {
    return callApi(
        `/v1/factDatabases/${databaseId}/pointintimerestore/undo/${backupId}`,
        { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
        'POST',
        ''
    );
}

export function restoreArchivedBackup(
  userKey,
  databaseId,
  backupId,
  archiveId,
) {
  return callApi(
    `/v1/factDatabases/${databaseId}/archived/${archiveId}/restore`,
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
  );
}

export function validateCMFormulas({ userKey, columns }) {
  return callApi(
    '/factDatabases/validate',
    { 'Content-Type': 'application/json', 'X-AE-USERKEY': userKey },
    'POST',
    { columns },
  );
}

export function retrieveBackupRestoreLogFile(userKey, databaseId) {
  return callApi(`/todBackupRestoreLogFile/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'GET',
  );
}

export function deleteBackupRestoreLogFile(userKey, databaseId){
  return callApi(`/todBackupRestoreLogFile/${databaseId}`,
    { 'X-AE-USERKEY': userKey },
    'DELETE',
  );
}
